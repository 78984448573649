import React, { useRef } from 'react';
import Meta from 'widgets/Meta/Meta';
import { CalculatorSection } from 'pages/Landing/components/Calculator';
import Header from './components/Header';
import Preview from './components/Preview';
import About from './components/About';
import Tokens from './components/Tokens';
import WorksGuide from './components/WorkGuide';
import StartMining from './components/StartMining';
import StartDemo from './components/StartDemo';
import MobilePreview from './components/MobilePreview';
import Footer from './components/Footer';
import { FixedWrapper } from './style';

const Landing: React.FC = () => {
  const refAbout = useRef<HTMLDivElement>(null);
  const refWorking = useRef<HTMLDivElement>(null);
  const refEquipment = useRef<HTMLDivElement>(null);
  const refRace = useRef<HTMLDivElement>(null);
  const refMobileApp = useRef<HTMLDivElement>(null);

  const refs = [refAbout, refWorking, refEquipment, refRace, refMobileApp];

  return (
    <>
      <Meta
        title="Build your mining Data Center | Liquidmining.com"
        description="Discover the perfect opportunity to maximize your earning potential with our exclusive limited edition NFTs, exciting gaming experience and access to real mining equipment."
      />
      <FixedWrapper>
        <Header refs={refs} />
      </FixedWrapper>
      <Preview />
      <About refValue={refAbout} />
      <CalculatorSection />
      <Tokens />
      <WorksGuide refValue={refWorking} />
      <StartMining refValue={refEquipment} />
      <StartDemo refValue={refRace} />
      <MobilePreview refValue={refMobileApp} />
      <Footer refs={refs} />
    </>
  );
};
export default Landing;
