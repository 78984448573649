import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { HttpService } from 'services/client/types';
import CalculatorService from 'services/Django/CalculatorService';
import CryptoService from 'src/services/Django/CryptoService';
import UserService from 'src/services/Django/UserService';
import DataCenterService from 'src/services/Django/DataCenterService';
import PowerGridService from 'src/services/Django/PowerGridService';
import BalanceService from 'src/services/Django/BalanceService';
import BuilderService from 'services/Django/BuilderService';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';
import CartService from 'services/Django/CartService';
import MarketPlaceService from 'services/Django/MarketplaceService';
import GemsService from 'services/Django/GemsService';
import LiquidationService from 'services/Django/LiquidationService';
import AchievementsService from 'services/Django/AchievementsService';
import MyNftsService from 'services/Django/MyNftsService';
import SupportService from 'services/Django/SupportService';
import NewsService from 'services/Django/NewsService';
import NotificationService from 'services/Django/NotificationService';
import VaultService from 'services/Django/VaultService';
import Cookies from 'js-cookie';
import { AppRoutes } from 'router';

const API_URL = import.meta.env.VITE_DEV_DJANGO;

type RefreshType = { access: string; refresh: string };

let isRefreshingFunc: undefined | RefreshType;

export const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
  },
});

const refreshingFunc = async (token: string) => {
  const response = await instance.post<RefreshType, AxiosResponse<RefreshType>>('/v2/users/token/refresh/', {
    refresh: JSON.parse(token),
  });

  return response;
};

const onFulfilledRequest = (config: any) => {
  const token = Cookies.get(LocalStorageKeys.TOKEN_KEY);
  const vaultToken = Cookies.get(LocalStorageKeys.VAULT_TOKEN_KEY);
  if (token && !config.url.includes('status') && !config.url.includes('refresh')) {
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
    if (vaultToken) {
      config.headers['X-Vault-Access-Token'] = `Bearer ${JSON.parse(vaultToken)}`;
    }
  }
  return config;
};
const onRejectedRequest = (error: unknown): Promise<AxiosRequestConfig> => Promise.reject(error);
instance.interceptors.request.use(onFulfilledRequest, onRejectedRequest);

const onFulfilledResponse = (response: AxiosResponse<any, any>) => ({ isError: false, ...response });
const onRejectedResponse = async (error: AxiosError<any>) => {
  const originalRequest = error.config;
  if (originalRequest && error.response?.status === 401 && !error.config?.url?.includes('refresh')) {
    try {
      const refreshToken = Cookies.get(LocalStorageKeys.REFRESH_KEY) || '';

      if (!refreshToken) {
        Cookies.remove(LocalStorageKeys.TOKEN_KEY);
        Cookies.remove(LocalStorageKeys.REFRESH_KEY);
        Cookies.remove(LocalStorageKeys.VAULT_TOKEN_KEY);
        Cookies.remove(LocalStorageKeys.VAULT_REFRESH_KEY);

        window.location.href = AppRoutes.SIGN_IN;
      }
      if (!isRefreshingFunc) isRefreshingFunc = refreshingFunc(refreshToken);

      const {
        data: { access, refresh },
      } = await isRefreshingFunc;

      Cookies.set(LocalStorageKeys.TOKEN_KEY, JSON.stringify(access), { expires: 1 });
      Cookies.set(LocalStorageKeys.REFRESH_KEY, JSON.stringify(refresh), { expires: 3 });

      originalRequest.headers.Authorization = `Bearer ${access}`;
      return await axios(originalRequest);
    } catch {
      Cookies.remove(LocalStorageKeys.TOKEN_KEY);
      Cookies.remove(LocalStorageKeys.REFRESH_KEY);
      Cookies.remove(LocalStorageKeys.VAULT_TOKEN_KEY);
      Cookies.remove(LocalStorageKeys.VAULT_REFRESH_KEY);
      window.location.href = AppRoutes.SIGN_IN;
    } finally {
      isRefreshingFunc = undefined;
    }
  }

  return { ...error, isError: true };
};
instance.interceptors.response.use(onFulfilledResponse, onRejectedResponse);

export const httpService: HttpService = {
  CalculatorService,
  CryptoService,
  UserService,
  DataCenterService,
  PowerGridService,
  CartService,
  BalanceService,
  MarketPlaceService,
  BuilderService,
  GemsService,
  LiquidationService,
  AchievementsService,
  MyNftsService,
  NewsService,
  SupportService,
  NotificationService,
  VaultService,
};
