import React, { PropsWithChildren } from 'react';
import { useClickAway } from 'react-use';
import Modal from 'react-modal';
import useBodyBlockScroll from 'hooks/useBodyBlockScroll';
import { useAppSelector } from 'store';
import { TOAST_ID } from 'ui/Toast/Toast.component';
import { CenterWrapperStyled, RightStyled } from './AppModal.styled';
import './AppModal.css';

Modal.setAppElement('body');

interface AppModalProps {
  isOpen: boolean;
  bgIsTransparent?: boolean;
  closeModal: () => void;
  position?: 'center' | 'right';
  isCoinModal?: boolean;
  className?: string;
  needContentBackground?: boolean;
  centeredContent?: boolean;
  isBodyNotScrollable?: boolean;
  isCart?: boolean;
  modalContentWithoutOverlay?: boolean;
  height?: string;
}

interface StylesInterface {
  content: React.CSSProperties;
  overlay: React.CSSProperties;
}

export const AppModal: React.FC<PropsWithChildren<AppModalProps>> = ({
  isOpen = false,
  bgIsTransparent = false,
  position = 'center',
  closeModal,
  isCoinModal = false,
  needContentBackground = true,
  centeredContent = false,
  isCart = false,
  modalContentWithoutOverlay = false,
  height = 'auto',
  ...props
}) => {
  const { theme } = useAppSelector((state) => state.theme);
  const refLayout = React.useRef(null);

  useBodyBlockScroll(isOpen);

  useClickAway(refLayout, (event) => {
    const anyEvent = event as any;
    const isClickedOnToast = anyEvent.target.id === TOAST_ID || anyEvent.target.parentNode.id === TOAST_ID;
    if (!isClickedOnToast) {
      closeModal();
    }
  });

  const style: StylesInterface = {
    content: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      border: 'none',
      background: 'transparent',
      overflow: 'hidden',
    },
    overlay: {
      ...(isOpen && {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: 2147483001,
        overflow: 'hidden',
        scrollbarWidth: 'none',
      }),
      background: bgIsTransparent ? theme.bag.bgTransparent : theme.bag.bgLayout,
    },
  };

  return (
    <Modal isOpen={isOpen} style={style} closeTimeoutMS={300}>
      {position === 'center' ? (
        <CenterWrapperStyled
          className={props.className}
          $isCoinModal={isCoinModal}
          ref={refLayout}
          $isOpen={isOpen}
          $needBackground={needContentBackground}
          $centeredContent={centeredContent}
          $modalContentWithoutOverlay={modalContentWithoutOverlay}
        >
          {props.children}
        </CenterWrapperStyled>
      ) : (
        <RightStyled className={props.className} ref={refLayout} $isOpen={isOpen} $isCart={isCart} $height={height}>
          {props.children}
        </RightStyled>
      )}
    </Modal>
  );
};
