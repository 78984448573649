import React, { ChangeEvent, useState, KeyboardEvent, useEffect } from 'react';
import { Button, Flex, Input } from 'ui';
import { isNumber } from 'utils/isNumber';
import useMediaQuery from 'hooks/useMediaQuery';
import { EstimationBlockStyled } from './EstimationBlock.styled';

const DOT_CHAR_VALUE = '.';
const COMMA_CHAR_VALUE = ',';
const ESTIMATION_ERROR_TEXT = 'Enter the number';
const ESTIMATION_BLOCKED_SYMBLOLS = ['+', '-', '/', '*', '!', '@', '#', '$', '%', '^', '&', '(', ')', '_', '='];

interface EstimationBlockProps {
  min: number;
  value: number;
  onChange?: (num: number) => void;
  isTabChanged: boolean;
  setIsTabChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EstimationBlock: React.FC<EstimationBlockProps> = ({
  min,
  onChange,
  value,
  isTabChanged,
  setIsTabChanged,
}) => {
  const defaultValueEstimation = (value || min).toString();
  const [estimation, setEstimation] = useState(defaultValueEstimation);
  const [error, setError] = useState('');
  const { isMobile } = useMediaQuery();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setEstimation(target.value.replace(',', '.'));
    setError('');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const commaOrDotPressed = event.key === DOT_CHAR_VALUE || event.key === COMMA_CHAR_VALUE;
    if (estimation.includes('.') && commaOrDotPressed) {
      event.preventDefault();
      return;
    }

    const availableKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '.',
      ',',
      'Backspace',
      'Delete',
      'ArrowRight',
      'ArrowLeft',
      'ArrowUp',
      'ArrowDown',
    ];
    const isKeyAvailable = availableKeys.find((key) => event.key === key);
    if (!isKeyAvailable) {
      event.preventDefault();
    }
  };

  const handleClick = () => {
    if (!onChange) return;
    const estimationNum = Number(estimation);

    if (estimationNum < min) {
      setError('The number must not be less than 50,000');
      return;
    }

    onChange(estimationNum);
  };

  const onBlur = (event: React.WheelEvent) => {
    const target = event.target as HTMLInputElement;
    return target.blur();
  };

  const onPaste = (event: React.ClipboardEvent) => {
    const paste = event.clipboardData.getData('text/plain');
    if (isNumber(paste)) {
      setError('');
    } else {
      event.preventDefault();
      setEstimation('');
      setError(ESTIMATION_ERROR_TEXT);
    }
  };

  const directionFlex = isMobile ? 'column' : 'row';
  const sizeButton = isMobile ? '100%' : 'min-content';

  useEffect(() => {
    if (isTabChanged && onChange) {
      setEstimation(min.toString());
      onChange(Number(min));
      setError('');
      setIsTabChanged(false);
    }
  }, [isTabChanged]);

  useEffect(() => {
    const symbolsInEstimation = ESTIMATION_BLOCKED_SYMBLOLS.filter((char) => estimation.includes(char));
    if (Number.isNaN(Number(estimation)) || symbolsInEstimation.length) {
      setError(ESTIMATION_ERROR_TEXT);
      setEstimation('');
    }
  }, [estimation]);

  return (
    <EstimationBlockStyled $direction="column">
      <Flex $direction={directionFlex} $gap="form">
        <Input
          placeholder="Put in rate, 50,000 USDT min"
          type="text"
          autoComplete="off"
          inputMode="decimal"
          value={estimation}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          onPaste={onPaste}
          onWheel={onBlur}
          error={error}
        />
        <Button size="large" maxWidth={sizeButton} color="secondary" onClick={handleClick}>
          Calculate
        </Button>
      </Flex>
    </EstimationBlockStyled>
  );
};
