import React, { lazy } from 'react';
import { CurrentCase, Main } from 'pages/Liquidation';
import { LiquidationLayoutController } from 'router/LiquidationLayoutController';

const LiquidationLayoutComponent = lazy(() => import('components/LiquidationLayout'));

export const liquidationRoute = {
  path: 'liquidation',
  element: <LiquidationLayoutComponent />,
  children: [
    {
      index: true,
      element: <Main />,
    },
    {
      path: ':id',
      element: (
        <LiquidationLayoutController>
          <CurrentCase />
        </LiquidationLayoutController>
      ),
    },
  ],
};
