import { styled } from 'styled-components';
import { H1, P } from 'ui';
import { colors, media } from 'theme';

export const H1FAQ = styled(H1)`
  font-size: 3rem;
  letter-spacing: 0.12rem;

  @media (max-width: ${media.laptop}px) {
    font-size: 2.25rem;
  }

  @media (max-width: ${media.mobile}px) {
    font-size: 2rem;
  }
`;
export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const QuestionListWrapper = styled.div``;

export const FuqAccordionText = styled(P)`
  margin: 0.75rem 0 0 0;
`;

export const StyledIframe = styled.iframe`
  background-color: ${colors.white};
  padding: 24px;
  border-radius: 8px;
`;
