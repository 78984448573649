import { styled, css } from 'styled-components';
import { Flex } from 'ui';
import { font, media, colors } from 'theme';

type TabWrapperProps = {
  $active?: boolean;
  $notification?: boolean;
};

const TabWrapper = styled.button<TabWrapperProps>`
  background-color: ${({ theme, $active }) => ($active ? theme.tab.activeBgColor : theme.tab.bgColor)};
  color: ${({ theme, $active }) => ($active ? theme.tab.activeTextColor : theme.tab.textColor)};
  border: ${({ theme, $active }) =>
    `1px solid ${$active ? theme.tab.activeBorderColor : theme.tab.defaultBorderColor}`};
  padding: 16px 32px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  white-space: nowrap;
  font-family: ${font.primary};
  font-size: 1rem;
  position: relative;
  text-decoration: none;

  @media (max-width: ${media.mobile}px) {
    padding: 8px 16px;
  }

  ${({ $notification }) =>
    $notification &&
    css`
      &:after {
        content: '';
        width: 0.688rem;
        height: 0.688rem;
        border: 0.063rem solid ${colors.buttonSecondaryStroke};
        border-radius: 50%;
        background: ${colors.yellow};
        position: absolute;
        display: block;
        top: -0.25rem;
        right: -0.25rem;
      }
    `}
`;

export default TabWrapper;
