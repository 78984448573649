import React from 'react';
import { useTranslation } from 'react-i18next';
import { P } from 'ui/Text';
import { IMAGE_URL } from 'assets/Urls';
import { StartMiningSection, PaddingContainer, StartMiningTitle, StartMiningDescription } from './style';
import Slider from './components/Slider';

interface IMiningProps {
  refValue: React.RefObject<HTMLDivElement>;
}

const StartMining: React.FC<IMiningProps> = ({ refValue }) => {
  const { t } = useTranslation('landing');

  return (
    <StartMiningSection ref={refValue} $background={IMAGE_URL.Landing.LiquidPattern}>
      <PaddingContainer>
        <P fontFamily="secondary" size="lg" color="secondary" $isUpperCase>
          {t('start_mining_block.category')}
        </P>
        <StartMiningTitle>{t('start_mining_block.title')}</StartMiningTitle>
        <StartMiningDescription fontFamily="primary" size="md">
          {t('start_mining_block.text')}
        </StartMiningDescription>
      </PaddingContainer>
      <Slider />
    </StartMiningSection>
  );
};

export default StartMining;
