export interface RequestCalculatorStats {
  durability: number;
  price: number;
}

export type DurabilityType = {
  durability: number;
  prices: number[];
};

export interface ResponseCalculatorDurability {
  results: DurabilityType[];
}

export type CalculatorInfoBlock = {
  value: string;
  name: string;
  image: string;
};

export type CalculatorConstants = {
  name: string;
  value: string;
};

export enum CalculatorTypeNFT {
  LONG = 'long',
  SHORT = 'short',
}

type CalculatorConstantsContracts =
  | {
      type: CalculatorTypeNFT.LONG;
      dailyReward: number;
      totalElectricityCost: number;
    }
  | {
      type: CalculatorTypeNFT.SHORT;
      roi: number;
      totalReward: number;
    };

export type CalculatorFormattedConstants =
  | {
      totalMiningReward: number;
    } & CalculatorConstantsContracts;

export type ProductCalculatorContractType = {
  id: number;
  address: string;
  price: number;
  image: string | null;
};

export type ProductCalculatorCollectionType = {
  id: number;
  name: string;
};

export interface ProductCalculator {
  id: number;
  collection: ProductCalculatorCollectionType;
  contracts: ProductCalculatorContractType[];
  is_combo_pack: boolean;
  price_before_discount: number;
  created_at: string;
  title: string;
  desc: string;
  price: number;
  image: string;
  month_durability: number;
  hide: boolean;
  contract_purchase_id: number;
  order: number;
}

export interface ResponseCalculatorStats {
  product: ProductCalculator;
  info_blocks: CalculatorInfoBlock[];
  constants: CalculatorConstants[];
  created_at: string;
  updated_at: string;
}
