import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import { RequestCalculatorStats, ResponseCalculatorDurability, ResponseCalculatorStats } from 'types/calculator';

export const getCalculatorDurability = createAsyncThunk<ResponseCalculatorDurability, void, ThunkApiConfig>(
  'calculator/durability',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CalculatorService.getCalculatorDurability();

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getCalculatorStats = createAsyncThunk<ResponseCalculatorStats, RequestCalculatorStats, ThunkApiConfig>(
  'calculator/stats',
  async (params, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CalculatorService.getCalculatorStats(params);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);
