import numeral from 'numeral';

export const transformNumber = (number: number, format = '0a'): string => {
  if (!number) return '0';
  if (number < 1e-6) {
    const numberDegree = number.toString().split('-')[1];
    const formattedNumber = new Intl.NumberFormat('en', {
      style: 'decimal',
      minimumFractionDigits: Number(numberDegree) + 1,
    }).format(number);
    return `${formattedNumber}`;
  }
  return numeral(number).format(format).toUpperCase();
};
