import { ChapterSource } from 'pages/Profile/Wallet/components/Content/TransactionsHistory/constants';
import { createSelector } from '@reduxjs/toolkit';
import { GemsHistoryChapters } from 'pages/Profile/Gems/constants';
import { ReadClaimNft } from 'types/myNfts';
import { NotificationType } from 'types/notifications';
import { RootState } from '../store';
import { useAppSelector } from '../hooks';
import { TransactionCategory } from '../slices/balance/type';
import { TInitialState } from '../slices';
import { IMarketplaceStore } from '../slices/marketplace/types';
import { InitStateBag } from '../slices/bag/types';
import {
  AchievementStoreType,
  AchievementSerializerType,
  AchievementSortingOptions,
  AchievementCategories,
} from '../slices/achievements/types';

export const useAchievementSelector = (): AchievementStoreType => useAppSelector((store) => store.achievement);
export const getAchievementSortedChapter = (
  achievements: AchievementSerializerType[],
  category: AchievementCategories | null,
  sortOption: AchievementSortingOptions | null,
) => {
  const selectedAchievementCategory = category
    ? achievements.filter((achievement) => achievement.category === category)
    : achievements;
  if (sortOption) {
    const arrayToSort = [...selectedAchievementCategory];
    const isAchievementsAscendingSort = sortOption === AchievementSortingOptions.ascending;
    const sortedAchievements = arrayToSort.sort((a, b) =>
      isAchievementsAscendingSort ? a.levelProgress - b.levelProgress : b.levelProgress - a.levelProgress,
    );
    const activeAchievements = sortedAchievements.filter(({ levelProgress }) => levelProgress !== 100);
    const completedAchievements = sortedAchievements.filter(({ levelProgress }) => levelProgress === 100);
    return [...activeAchievements, ...completedAchievements];
  }
  return selectedAchievementCategory;
};
export const getSingleAchievement = (state: RootState, achievementId?: string) => {
  if (!achievementId) return undefined;
  const { achievements } = state.achievement;
  return achievements.find((achievement) => achievement.id === Number(achievementId));
};
export const getAchievementLoadingState = (store: RootState) => store.achievement.isAchievementsLoaded;
export const useThemeSelector = () => {
  const theme = useAppSelector((state) => state.theme.theme);

  return theme;
};

export const useUserSelector = () => {
  const {
    isLoading,
    username,
    avatar,
    isPasswordChanged,
    kyc,
    gems,
    email,
    walletAddress,
    id,
    myReferrer,
    referralLink,
    isActive,
    telegramUsername,
    phone,
    paymentService,
    KYCLevel,
    currentKyc,
  } = useAppSelector((state) => state.user);

  return {
    isLoading,
    isActive,
    username,
    avatar,
    email,
    walletAddress,
    kyc,
    gems,
    id,
    myReferrer,
    referralLink,
    isPasswordChanged,
    telegramUsername,
    phone,
    paymentService,
    KYCLevel,
    currentKyc,
  };
};

export const useBaseLayoutSelector = () => {
  const { bg, isScrollDisabled, isHaveButtonBack, isOpenNavBar, isImageBg, isAnotherBg } = useAppSelector(
    (state) => state.baseLayout,
  );

  return {
    bg,
    isScrollDisabled,
    isHaveButtonBack,
    isOpenNavBar,
    isAnotherBg,
    isImageBg,
  };
};

export const useLiquidationLayoutSelector = () => useAppSelector((state) => state.liquidationLayout);

export const useAuthLayoutSelector = () => {
  const { hasBackButton, navigateBackPagesAmount } = useAppSelector((state) => state.authLayout);

  return {
    hasBackButton,
    navigateBackPagesAmount,
  };
};

export const useBagSelector = (): InitStateBag => {
  const {
    step,
    nft,
    isNeedUpdateMarket,
    energy,
    isApprovedEarlier,
    isGettingBalance,
    builder,
    price,
    transactionHash,
    unSyncNFTMarket,
    unSyncNFTSC,
    shortContract,
    isCartInit,
    isNeedUpdateSC,
    amountUsedGems,
    lastSignature,
    isCartOpen,
    isItemLoading,
    selectedPayment,
    builderItemToRemove,
  } = useAppSelector((state) => state.bag);

  return {
    isCartOpen,
    unSyncNFTMarket,
    unSyncNFTSC,
    step,
    transactionHash,
    isNeedUpdateMarket,
    isNeedUpdateSC,
    nft,
    energy,
    builder,
    isApprovedEarlier,
    shortContract,
    isGettingBalance,
    price,
    isCartInit,
    amountUsedGems,
    lastSignature,
    isItemLoading,
    selectedPayment,
    builderItemToRemove,
  };
};

export const getSelectedShortContractNftsSelector = (state: RootState) => state.bag.shortContract;
export const getUnSyncSelectedShortContractNftsSelector = (state: RootState) => state.bag.unSyncNFTSC;

export const useDataCenterSelector = () => {
  const {
    isLoading,
    activeNft,
    inactiveNft,
    activeSingle,
    coins,
    config,
    isSocketOpen,
    socket,
    allNft,
    socketNumber,
    isDCUpdate,
  } = useAppSelector((state) => state.dataCenter);

  return {
    isLoading,
    activeNft,
    inactiveNft,
    activeSingle,
    coins,
    config,
    isSocketOpen,
    socket,
    socketNumber,
    isDCUpdate,
    allNft,
  };
};

export const usePowerGridSelector = () => {
  const { powerGrid, graph, mostEnergyNft, isLoading, consumptionSetups } = useAppSelector((state) => state.powerGrid);

  return {
    powerGrid,
    graph,
    mostEnergyNft,
    isLoading,
    consumptionSetups,
  };
};

export const useRewardsSelector = () => {
  const { me, shares, event, token, forex, timeline } = useAppSelector((state) => state.rewards);

  return {
    me,
    event,
    token,
    forex,
    shares,
    timeline,
  };
};

export const useRaceSelector = () => {
  const { username, seller, affiliateBoard, leaderBoard, leader, refLink, volume, isValidUsername, treeVolume } =
    useAppSelector((state) => state.race);

  return {
    username,
    seller,
    affiliateBoard,
    leaderBoard,
    leader,
    refLink,
    volume,
    isValidUsername,
    treeVolume,
  };
};

export const useBalanceSelector = () => {
  const {
    balance,
    transactions,
    transfers,
    miningActivity,
    filterDateRange,
    filterDate,
    isUserFindByWallet,
    codeIsSending,
    sendingUserId,
    isValidAddress,
    isLoading,
    isBalanceLoaded,
    isTransactionsLoaded,
    isTransfersLoaded,
    isMiningLoaded,
    isLoadingWithdrawCoin,
  } = useAppSelector((state) => state.balance);
  return {
    balance,
    transactions,
    transfers,
    miningActivity,
    filterDateRange,
    filterDate,
    sendingUserId,
    codeIsSending,
    isValidAddress,
    isUserFindByWallet,
    isLoading,
    isBalanceLoaded,
    isTransactionsLoaded,
    isTransfersLoaded,
    isMiningLoaded,
    isLoadingWithdrawCoin,
  };
};

export const transactionsSelector = (state: RootState) => state.balance.transactions;
export const withdrawalSelector = (state: RootState) =>
  state.balance.transactions.filter((el) => el.type === TransactionCategory.MINED);
export const referralSelector = (state: RootState) =>
  state.balance.transactions.filter((el) => el.type === TransactionCategory.REFERRAL);
export const miningSelector = (state: RootState) => state.balance.miningActivity;
export const transfersSelector = (state: RootState) => state.balance.transfers;

export type CoinType = {
  id: number;
  name: string;
  icon: string;
};
export const coinsSelector = (state: RootState): CoinType[] =>
  state.balance.balance.reduce((acc: CoinType[], el) => {
    const coin: CoinType = { id: el.id, name: el.name.toLocaleUpperCase(), icon: el.name };
    acc.push(coin);
    return acc;
  }, []);

export const chapterSelector = (state: RootState) => state.balance.chapter;

export const selectAllHistory = createSelector(
  [transfersSelector, miningSelector, transactionsSelector],
  (transfers, miningActivity, transactions) => [...transfers, ...transactions, ...miningActivity],
);

export const selectedHistoryLength = createSelector(
  [withdrawalSelector, referralSelector, miningSelector, transfersSelector],
  (withdrawal, referral, miningActivity, transfers) => [
    withdrawal.length,
    miningActivity.length,
    transfers.length,
    referral.length,
  ],
);

export const getTransactionHistorySelector = (chapter: ChapterSource) => {
  const historyChapters = new Map([
    [ChapterSource.all, selectAllHistory],
    [ChapterSource.transfers, transfersSelector],
    [ChapterSource.referral, referralSelector],
    [ChapterSource.withdrawal, withdrawalSelector],
    [ChapterSource.mining, miningSelector],
  ]);
  return historyChapters.get(chapter) || null;
};

export const historyLoadedSelector = (state: RootState) =>
  state.balance.isTransactionsLoaded && state.balance.isMiningLoaded && state.balance.isTransfersLoaded;

export const balanceLoadedSelector = (state: RootState) => state.balance.isBalanceLoaded;

export const useBuilderSelector = (): TInitialState => {
  const {
    filterType,
    currentItem,
    isSendSocket,
    allItems,
    isLoading,
    finalArray,
    marketEqp,
    inventoryEqp,
    isInserting,
    isRemoving,
  } = useAppSelector((state) => state.builder);

  return {
    filterType,
    marketEqp,
    inventoryEqp,
    isSendSocket,
    currentItem,
    allItems,
    isLoading,
    finalArray,
    isInserting,
    isRemoving,
  };
};

export const useMarketplaceSelector = (): IMarketplaceStore => {
  const { collections, selectedProductPage, landingProducts, isLandingProductsLoaded } = useAppSelector(
    (state) => state.marketplace,
  );
  return {
    collections,
    selectedProductPage,
    landingProducts,
    isLandingProductsLoaded,
  };
};

export const useNewsSelector = () => useAppSelector((state) => state.news);

export const useGemsSelector = () => {
  const { dateValue, dateRangeValue, isGemsTransactionLoaded } = useAppSelector((state) => state.gems);
  return { dateValue, dateRangeValue, isGemsTransactionLoaded };
};
export const getGemsLoaded = (state: RootState) => state.gems.isGemsTransactionLoaded;
export const getGemsTransactions = (state: RootState) => state.gems.transactions;
export const gemsPurchaseSelector = (state: RootState) =>
  state.gems.transactions.filter((el) => el.operation_type === 1);
export const gemsAchievementSelector = (state: RootState) =>
  state.gems.transactions.filter((el) => el.operation_type === 2);
export const gemsNftSelector = (state: RootState) => state.gems.transactions.filter((el) => el.operation_type === 3);
export const gemsBoxSelector = (state: RootState) => state.gems.transactions.filter((el) => el.operation_type === 4);
export const gemsLiquidationSelector = (state: RootState) =>
  state.gems.transactions.filter((el) => el.operation_type === 5);
export const gemsFrozenSelector = (state: RootState) => state.gems.transactions.filter((el) => el.operation_type === 6);

export const selectedGemsHistoryLength = createSelector(
  [
    gemsPurchaseSelector,
    gemsAchievementSelector,
    gemsNftSelector,
    gemsBoxSelector,
    gemsLiquidationSelector,
    gemsFrozenSelector,
  ],
  (purchase, achievement, nfts, boxes, rewards, frozen) => [
    purchase.length,
    achievement.length,
    nfts.length,
    boxes.length,
    rewards.length,
    frozen.length,
  ],
);

export const getGemsHistory = (chapter: GemsHistoryChapters) => {
  const gemsHistoryChapters = new Map([
    [GemsHistoryChapters.all, getGemsTransactions],
    [GemsHistoryChapters.gems, gemsPurchaseSelector],
    [GemsHistoryChapters.achievement, gemsAchievementSelector],
    [GemsHistoryChapters.nft, gemsNftSelector],
    [GemsHistoryChapters.box, gemsBoxSelector],
    [GemsHistoryChapters.liquidation, gemsLiquidationSelector],
    [GemsHistoryChapters.frozen, gemsFrozenSelector],
  ]);
  return gemsHistoryChapters.get(chapter) || getGemsTransactions;
};

export const useLiquidationSelector = () => useAppSelector((state) => state.liquidation);

export const useMyNftsSelector = () => useAppSelector((state) => state.myNfts);
export const getMyNfts = (nfts: ReadClaimNft[], claimed?: boolean) =>
  nfts.filter((nft) => (claimed ? nft.claimed : !nft.claimed));
export const getIsSomeNftsClaimed = (nfts: ReadClaimNft[]) => nfts.some((nft) => nft.claimed);

export const useFAQSelector = () => useAppSelector((state) => state.faq);

export const useNotificationSelector = () => useAppSelector((state) => state.notification);
export const getNotificationByCategory = (notifications: NotificationType[], category?: string) => {
  if (!category) return notifications;
  return notifications.filter((notification) => notification.category === category);
};
export const getNotificationsIDs = (notifications: NotificationType[]) => notifications.map(({ id }) => id);

export const useCalculatorSelector = () => useAppSelector((state) => state.calculator);

export const useShortContractSelector = () => useAppSelector((state) => state.shortContract);

export const useVaultSelector = () => useAppSelector((state) => state.vault);

export const getVaultSelector = (state: RootState) => state.vault;
