import React from 'react';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import { CustomDatepickerContainer } from 'ui/Datepicker/Datepicker.style';
import { startOfDay, subDays, subYears } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

type CustomDatepickerType = {
  forAge?: boolean;
};

const DATE_18_YEARS_AGO = startOfDay(subYears(subDays(new Date(), 1), 18));
const DATE_150_YEARS_AGO = startOfDay(subYears(new Date(), 150));

type CustomDatePickerProps = DatePickerProps & CustomDatepickerType;

export const CustomDatepicker = ({ forAge, ...props }: CustomDatePickerProps) => (
  <CustomDatepickerContainer>
    <DatePicker
      minDate={forAge ? DATE_150_YEARS_AGO : props.minDate}
      maxDate={forAge ? DATE_18_YEARS_AGO : props.maxDate}
      showMonthDropdown
      showYearDropdown
      peekNextMonth
      dropdownMode="select"
      {...props}
    />
  </CustomDatepickerContainer>
);
