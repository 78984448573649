import { ILandingLocale, AboutLocaleType, AboutStatsLocaleType } from 'pages/Landing/types';

export const navLinks: ILandingLocale<AboutLocaleType>[] = [
  { id: 0, value: 'about_project' },
  { id: 1, value: 'data_centers' },
  { id: 2, value: 'multimining' },
  { id: 3, value: 'refferal_system' },
];

interface IAboutStats {
  label: AboutStatsLocaleType;
  value: {
    source: string[];
    prefix?: string;
    postfix?: string;
  };
}

export interface IAboutData {
  locale: AboutLocaleType;
  values: IAboutStats[];
}

export const aboutData: IAboutData[] = [
  {
    locale: 'about_project',
    values: [
      { label: 'total_users', value: { source: ['10'], postfix: 'K+' } },
      { label: 'investment_capital', value: { source: ['9000000'], prefix: '$' } },
      { label: 'our_team', value: { source: ['200 ', 'PPL'], postfix: '+' } },
      { label: 'nft_collection', value: { source: ['3'] } },
    ],
  },
  {
    locale: 'data_centers',
    values: [
      { label: 'countries', value: { source: ['4'] } },
      { label: 'hashrate', value: { source: ['2376', 'TH/S'] } },
      { label: 'installations', value: { source: ['145 ', 'PC'] } },
      { label: 'power_usage', value: { source: ['11088', 'MWT'] } },
    ],
  },
  {
    locale: 'multimining',
    values: [
      { label: 'coins_available', value: { source: ['17'] } },
      { label: 'analytics', value: { source: ['40', 'PPL'], postfix: '+' } },
      { label: 'assets_mined', value: { source: ['13', 'BTC'] } },
      { label: 'success_payments', value: { source: ['1000'], postfix: '+' } },
    ],
  },
  {
    locale: 'refferal_system',
    values: [
      { label: 'in_udst', value: { source: ['3', 'POOLS'] } },
      { label: 'in_mining', value: { source: ['6', 'POOLS'] } },
      { label: 'upfront', value: { source: ['100 ', 'USDT'], postfix: 'K+' } },
      { label: 'residual', value: { source: ['400', 'USDT'], postfix: 'K+' } },
    ],
  },
];

export const timeout = 10000;
