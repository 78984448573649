enum Routes {
  HOME = '/',
  MARKET_ALL = '/marketplace/market',
  CALCULATOR = '/marketplace/calculator',
  SHORT_CONTRACTS = '/marketplace/short-contracts',
  MARKET_NFT = '/marketplace/market',
  DATA_CENTER_MAIN = '/data-center/nft',
  DATA_CENTER_MINING = '/data-center/nft/',
  DATA_CENTER_COIN = '/data-center/coin',
  DATA_CENTER_POWER_GRID = '/data-center/power-grid',
  REWARDS = '/rewards',
  // RACE = '/race',
  LIQUIDATION = '/liquidation',
  NEWS = '/news',
  INSTRUCTIONS = '/support/instructions',
  FAQ = '/support/faq',
  FAQ_LIQUID_WALLET = '/support/faq/liquid-wallet',
  PROFILE_WALLET = '/profile/wallet',
  PROFILE_ACHIEVEMENTS = '/profile/achievements',
  PROFILE_ACCOUNT = '/profile/account',
  PROFILE_TRANSACTION = '/profile/transaction',
  PROFILE_GEMS = '/profile/gems',
  PROFILE_MY_NFTS = '/profile/mynfts',
  PROFILE_TELEGRAM = '/profile/telegram',
  PROFILE_PHONE = '/profile/phone',
  PROFILE_NOTIFICATIONS = '/profile/notifications',
  PROFILE_DETAILS = '/profile/details',
  WALLET_CONNECT = '/wallet-connect',
  SIGN_UP = '/sign-up',
  SIGN_UP_LIQUID = '/sign-up-liquid',
  SIGN_IN_LIQUID = '/sign-in-liquid',
  SIGN_IN = '/sign-in',
  REGISTRATION_WITH_WALLET = '/sign-up/connect-wallet-registration',
  VERIFY_EMAIL = '/sign-up/code',
  VERIFY_PHONE = '/sign-up-liquid/code',
  UPDATE_VERIFY_EMAIL = '/update-email/code',
  UPDATE_EMAIL = '/update-email',
  UPDATE_PASSWORD = '/update-password',
  CHANGE_PASSWORD = '/profile/change-password',
  CONFIRM_UPDATE_PASSWORD = '/update-password/code',
  SET_PASSWORD = '/sign-up/set-password',
  CONGRATS_REGISTRATION = '/sign-up/congrats',
  BUILDER_INVENTORY = '/builder/inventory/',
  BUILDER_NEW_BUILD = '/builder/create/',
  BUILDER_EDIT = '/builder/edit',
  KYC = '/kyc',
  LIQUID_WALLET_CONFIRM = '/liquid-wallet/confirm-email',
  LIQUID_WALLET_CONFIRM_VERIFY = '/liquid-wallet/confirm-email/verify',
  CREATE_LIQUID_WALLET = '/create-liquid-wallet',
  CREATE_LIQUID_WALLET_VERIFY = '/create-liquid-wallet/code',
  CONFIRM_PURCHASE_GEMS = '/confirm-purchase/gems',
  CREATE_OFFER = '/profile/create-offer',
  ADD_CARD = '/profile/add-a-bank-card',
  CONFIRM_PAYMENT = '/profile/confirm-payment',
  ON_RAMP = '/payment/on-ramp/check',
}

export default Routes;
