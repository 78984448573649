export type TContract = {
  address: string;
  id: number;
  image: string | null;
  name: string;
  price: number;
};

export type TSingleNft = {
  created_at: string;
  number: number;
  id: number;
  name?: string;
  energy_consumption?: number;
  isMostEnergy?: boolean;
  contract: TContract;
  url_piece: string;
  image_link: string;
  status: boolean | number;
  components_count: number;
  collection_name: string;
  used: boolean;
  sortOption: string;
};

export type TSingleEqp = {
  id: number;
  contract: TContract;
  created_at: string;
  used: boolean;
  usage_time: number;
  name: string;
  status?: boolean;
  boost?: boolean;
  image_link: string;
  url_piece: string;
  title?: string;
};

export type TActiveCoin = {
  id: number;
  name: string;
  image: string;
  unit: string;
  power: number;
  earnings: number;
};

export type DCUrlPiece = 'rigs' | 'shelves' | 'containers';

export type TActiveNft = {
  id: number;
  boost: boolean;
  free_boost_ts: number;
  status: boolean | number;
  contract: TContract;
  durability: number;
  max_durability: number;
  energy_consumption: number;
  accessible_crypto_for_mining: number[];
  energy_diagram_percent: number;
  mining_now_data: TActiveCoin[];
  power: number;
  is_asic: boolean;
  components_count: number;
  created_at: string;
  used: boolean;
  url_piece?: DCUrlPiece;
  name: string;
  image_link: string;
  insideArray?: TSingleEqp[] | null;
  type?: string;
  mining_now?: number;
  isMostEnergy?: boolean;
  collection_name: string;
  constants: { value: string; name: string }[];
};

export interface ICoin {
  id: number;
  name: string;
  desc: string;
  unit: string;
  coin_stats_display: boolean;
  wallet_instructions: string;
  image: string;
  create_at: string;
  price: number;
}

export interface ICryptoCurrency {
  id: number;
  name: string;
  image: string;
  unit: string;
  my_rigs_count: number;
  my_power: number;
  market_cap: number;
  my_daily_earnings_usd: number;
  my_daily_earnings: number;
  network_hash_rate: number;
  network_hash_rate_unit: string;
  network_difficulty: number;
  network_difficulty_unit: string;
  network_reward_block: number;
  lc_daily_earnings_usd: number;
  lc_daily_earnings: number;
  lc_hash_rate: string;
  lc_hash_rate_unit: string;
  wallet_instructions: string;
  mined_coins: number;
  whitepaper: string;
  created_at: string;
  price: number;
  link: string;
}

export const LiquidStcCollectionType = 'Liquid STC';
