import React from 'react';

import { CodeInputStyled } from 'components/AuthComponents/CodeInput/CodeInput.styled';
import { Controller } from 'react-hook-form';

interface CodeInputProps {
  type?: 'text' | 'number' | 'password';
  name: string;
  fields: number;
  className?: string;
  isValid?: string;
  control: any;
}

export const CodeInput: React.FC<CodeInputProps> = ({ type = 'text', control, ...props }) => (
  <Controller
    name={props.name}
    control={control}
    render={({ field }) => (
      <CodeInputStyled
        type={type}
        inputMode="numeric"
        $isInValid={!!props.isValid}
        fields={props.fields}
        autoFocus
        isValid={!props.isValid}
        className={props.className}
        {...field}
      />
    )}
  />
);
