import { ApiEndpoints, instance } from 'services/client';
import { GemsTransactionsRequest } from 'src/types/gems';

class GemsService {
  static async getGemsTransactions(params: GemsTransactionsRequest) {
    const response = await instance.get(ApiEndpoints.v2.gems.getTransactions, {
      params,
    });

    return response;
  }
}

export default GemsService;
