export const ApiEndpointsVault = {
  profileData: '/v2/customer/profile',
  getWallets: '/v2/wallets',
  getHistory: '/v2/history/operations?currencyFilter=TRX&currencyFilter=USDT_TRON&offset=0&size=50',
  confirmEmail: '/v2/mobile/email/confirm',
  payIn: '/v3/payin/card',
  cardsData: '/v3/payin/data',
  createOffer: '/v3/payin/offer',
  confirmOffer: '/v3/payin/pay/',
  payInStatus: '/v3/payin/pay-callback',
} as const;
