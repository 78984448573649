import { styled } from 'styled-components';
import { Button, Flex, Form, H2 } from 'ui';
import { media } from 'theme';

export const DetailsContainer = styled(Flex)`
  gap: 2.5rem;

  @media screen and (max-width: ${media.tablet}px) {
    gap: 2rem;
  }

  @media screen and (max-width: ${media.mobile}px) {
    gap: 1.5rem;
  }
`;

export const ChangePasswordTitle = styled(H2)`
  font-size: 3.5rem;

  @media screen and (max-width: ${media.laptop}px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: ${media.mobile}px) {
    font-size: 2.25rem;
  }
`;

export const GroupContainer = styled(Flex)`
  gap: 0.5rem;

  Input {
    width: calc(100% - 3rem);
  }

  @media screen and (max-width: ${media.laptop}px) {
    flex-direction: column;
  }

  @media screen and (max-width: ${media.tablet}px) {
    flex-direction: row;
  }

  @media screen and (max-width: ${media.mobile}px) {
    flex-direction: column;
  }
`;

export const DetailsButton = styled(Button)`
  padding: 12px;
`;

export const LastGroupContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  Input {
    width: calc(100% - 3rem);
  }

  @media screen and (max-width: ${media.mobile}px) {
    grid-template-columns: minmax(200px, auto) minmax(120px, 35%);
  }

  @media screen and (max-width: 359px) {
    grid-template-columns: 1fr;
  }
`;

export const FormContainer = styled(Form)`
  gap: 2.5rem;

  @media screen and (max-width: ${media.tablet}px) {
    gap: 2rem;
  }

  @media screen and (max-width: ${media.mobile}px) {
    gap: 1.5rem;
    width: 100%;
  }
`;

export const DatePickerWrapper = styled('div')`
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;
