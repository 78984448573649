import { ApiEndpoints, instance } from 'services/client';
import { RequestCalculatorStats, ResponseCalculatorDurability, ResponseCalculatorStats } from 'types/calculator';

class CartService {
  static async getCalculatorDurability() {
    const response = await instance.get<ResponseCalculatorDurability>(`${ApiEndpoints.v2.calculator.durability}`, {
      params: {
        page: 1,
      },
    });

    return response;
  }

  static async getCalculatorStats({ durability, price }: RequestCalculatorStats) {
    const response = await instance.get<ResponseCalculatorStats>(`${ApiEndpoints.v2.calculator.default}`, {
      params: {
        durability,
        price,
      },
    });

    return response;
  }
}

export default CartService;
