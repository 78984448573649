import React, { FC, ReactNode, useEffect } from 'react';
import { AppRoutes } from 'router';
import { AppNavigate } from 'components/AppComponents';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';
import Cookies from 'js-cookie';
import { getKYC } from 'store/slices/vault/actionAsync';
import { KYCLevelToNumber } from 'widgets/LW/components/PersonalData';
import { useAppDispatch, useVaultSelector } from 'store';
import { Loader } from 'ui';

type PrivateRouteProviderProps = {
  children: ReactNode;
  kycCheck?: boolean;
};

const VaultRouteProvider: FC<PrivateRouteProviderProps> = ({ children, kycCheck = false }) => {
  const dispatch = useAppDispatch();
  const { kyc, isKYCLoading } = useVaultSelector();
  const vaultToken = Cookies.get(LocalStorageKeys.VAULT_TOKEN_KEY);

  useEffect(() => {
    if (vaultToken && kycCheck && !kyc) {
      dispatch(getKYC());
    }
  }, [kycCheck, vaultToken, kyc]);

  if (!vaultToken) {
    return <AppNavigate to={AppRoutes.MARKET_ALL} replace />;
  }

  if (isKYCLoading) {
    return <Loader />;
  }

  if (kycCheck && kyc) {
    const { current_kyc } = kyc;
    if (current_kyc && KYCLevelToNumber[current_kyc] !== 1) {
      return <AppNavigate to={AppRoutes.MARKET_ALL} replace />;
    }
  }

  return children;
};

export default VaultRouteProvider;
