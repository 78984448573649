import { keyframes, styled, css } from 'styled-components';
import { media } from 'theme';

interface RightWrapperProps {
  $isOpen: boolean;
  $isCart: boolean;
  $height: string;
}

const RightLayoutAnimationOpen = keyframes`
    from {
      transform: translateX(100%);
    }
    
    to {
      transform: translateX(0);
    }
  `;

const RightLayoutAnimationClose = keyframes`
    from {
      transform: translateX(0);
    }
    
    to {
      transform: translateX(100%);
    }
  `;

export const RightStyled = styled.div<RightWrapperProps>`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  flex-direction: column;
  width: ${({ $isCart }) => ($isCart ? '32.25rem' : '23.75rem')};
  height: ${({ $height }) => $height || 'auto'};
  position: absolute;
  right: 1.25rem;
  top: 2.5rem;
  bottom: 2.5rem;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.bag.bgColor};
  transform: translateX(${({ $isOpen }) => ($isOpen ? '0%' : '100%')});
  animation: ${({ $isOpen }) => ($isOpen ? RightLayoutAnimationOpen : RightLayoutAnimationClose)} 0.3s ease-in-out;
  overflow-y: scroll;
  overflow-x: hidden;

  & ~ div {
    opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
  }

  ${({ $isCart }) =>
    $isCart &&
    `
    @media screen and (max-width: ${media.laptop}px) {
      width: 27.625rem;
    }

    @media screen and (max-width: ${media.tablet}px) {
      width: 28.25rem;
    }
  `}

  @media (max-width: ${media.mobile}px) {
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1.5rem;
    box-sizing: border-box;
  }
`;

const CenterLayoutAnimationOpen = keyframes`
  from {
    transform: translateY(-50%) translateX(-50%);
  }
  
  to {
    transform: translateY(-50%) translateX(-50%);
  }
`;

const CenterLayoutAnimationClose = keyframes`
  from {
    transform: translateY(-50%) translateX(-50%);
  }
  
  to {
    transform: translateY(-50%) translateX(-50%);
  }
`;

interface CenterWrapperProps {
  $isOpen: boolean;
  $isCoinModal: boolean;
  $needBackground?: boolean;
  $centeredContent?: boolean;
  $modalContentWithoutOverlay?: boolean;
}

export const CenterWrapperStyled = styled.div<CenterWrapperProps>`
  gap: 1.5rem;
  ${({ $modalContentWithoutOverlay, $isCoinModal }) =>
    !$modalContentWithoutOverlay &&
    css`
      width: 46.875rem;
      padding: var(--spacing-paddings-medium, 2.5rem);
      height: min-content;

      @media (max-width: ${media.laptop}px) {
        width: ${$isCoinModal ? '100%' : '31.625rem'};
        max-width: 46.875rem;
      }

      @media (max-width: ${media.mobile}px) {
        width: ${$isCoinModal ? '100%' : '80%'};
        height: ${$isCoinModal ? '100%' : 'unset'};
        min-height: ${$isCoinModal ? '100vh' : 'unset'};
        justify-content: ${$isCoinModal ? 'flex-start' : 'center'};
        align-items: ${$isCoinModal ? 'center' : 'flex-start'};
        gap: ${$isCoinModal ? '0.75rem' : '1.5rem'};
      }
    `}
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ $centeredContent }) => ($centeredContent ? 'center' : 'flex-start')};
  border-radius: var(--border-radius-medium, 0.75rem);
  background: ${({ theme, $needBackground }) => ($needBackground ? theme.bag.bgColor : 'transparent')};
  transform: translateX(${({ $isOpen }) => ($isOpen ? '-50%' : '-50%')})
    translateY(${({ $isOpen }) => ($isOpen ? '-50%' : '-50%')});
  animation: ${({ $isOpen }) => ($isOpen ? CenterLayoutAnimationOpen : CenterLayoutAnimationClose)} 0.3s ease-in-out;

  & ~ div {
    opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
  }
`;
