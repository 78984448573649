import { ILandingLocale, TokenLocaleType } from 'pages/Landing/types';

export const titles: ILandingLocale<TokenLocaleType>[] = [
  { value: 'nft' },
  { value: 'tron' },
  { value: 'exclusives' },
  { value: 'events' },
  { value: 'updates' },
];

export const tokens = ['kas', 'btc', 'etc', 'hcfh', 'hcfc', 'hcfw', 'ethw'] as const;
