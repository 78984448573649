import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import { TEnergyGraph, TPowerGrid, IPowerGridNft, ConsumptionSetupsType } from 'src/types/powerGrid';

export const getPowerGrid = createAsyncThunk<TPowerGrid, void, ThunkApiConfig>(
  'power-grid/get-power-grid',
  async (_: void, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.PowerGridService.getPowerGrid();

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getMostEnergyNft = createAsyncThunk<IPowerGridNft, any, ThunkApiConfig>(
  'power-grid/get-most-energy-nft',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.PowerGridService.getPowerGridMostEnergyNft();

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getEnergyGraph = createAsyncThunk<TEnergyGraph, number, ThunkApiConfig>(
  'power-grid/get-energy-grapyh',
  async (year, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.PowerGridService.getEnergyGraph(year);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getConsumptionSetups = createAsyncThunk<ConsumptionSetupsType[], void, ThunkApiConfig>(
  'power-grid/consumption-setups',
  async (_: void, { extra: services, rejectWithValue }) => {
    const response = await services.httpService.PowerGridService.getConsumptionSetups();

    if (!response.data) {
      return rejectWithValue({ message: 'Something went wrong', isError: true });
    }

    return response.data;
  },
);
