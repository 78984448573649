import React from 'react';
import { FullPageLoaderContainer, LiquidLoader } from './Loader.styled';

export const Loader = () => (
  <LiquidLoader width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="liquid"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.4155 51.3675C89.4241 51.9725 89.3834 52.5771 89.2938 53.1753C88.3292 59.6463 82.9444 62.7851 77.0304 63.7197C67.4079 65.2348 57.7432 68.0762 49.9937 74.3254C41.6602 81.0306 38.2675 84.9458 35.8449 87.7416C35.1979 88.4882 34.6201 89.155 34.0358 89.7738C33.7033 90.1278 33.3662 90.4629 33.0197 90.7886C31.6704 91.9395 30.0229 92.6776 28.2712 92.916C26.5196 93.1543 24.7369 92.883 23.1331 92.134C21.5294 91.385 20.1715 90.1895 19.2194 88.6884C18.2673 87.1873 17.7608 85.4431 17.7595 83.6614V83.416C17.8105 79.8082 19.0458 76.1295 20.466 71.9004C22.6929 65.2687 25.3744 57.2834 24.6568 46.0954C23.9471 35.034 21.5401 28.8442 19.7665 24.2829C18.5794 21.2302 17.6759 18.9069 17.7548 16.3408C17.7548 13.8635 18.7311 11.4876 20.4689 9.73585C22.2068 7.98412 24.5638 7 27.0214 7C29.4791 7 31.8361 7.98412 33.5739 9.73585C35.3118 11.4876 36.2881 13.5694 36.2881 16.3408C36.2881 24.2724 34.4938 37.4524 35.0694 48.2083C35.593 57.9908 37.9612 65.7681 47.6525 65.7681C55.6127 65.7681 59.3587 58.7259 62.4819 52.3729C64.8231 47.6529 67.5249 42.6687 72.2449 40.4173C74.0773 39.5426 76.0994 39.1492 78.1231 39.2737C80.1469 39.3981 82.1065 40.0364 83.8199 41.1291C85.5333 42.2217 86.9448 43.7334 87.9231 45.5234C88.9014 47.3135 89.4148 49.3238 89.4155 51.3675Z"
      stroke="#FFD966"
      strokeWidth="3"
    />
  </LiquidLoader>
);

export const FullPageLoader: React.FC = () => (
  <FullPageLoaderContainer $direction="column" $align="center" $justify="center">
    <Loader />
  </FullPageLoaderContainer>
);
