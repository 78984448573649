import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Form, H6, Input } from 'ui';
import {
  CardStyled,
  StyledTitle,
  StyledStatus,
  ConfirmButton,
  StyledEmailButton,
  EmailInput,
} from 'widgets/LW/LW.styled';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getVaultSelector, useAppDispatch, useAppSelector, useUserSelector } from 'store';
import { VaultKYC, VaultKYCLevels, VaultKYCStatus, VaultProfileType } from 'store/slices/vault/types';
import { addEmail, getKYC, updateProfileDetailsRequest } from 'store/slices/vault/actionAsync';
import useNotification from 'hooks/useNotification';
import * as Yup from 'yup';
import { Validation } from 'services/Validation';
import useAppNavigate from 'hooks/useAppNavigate';
import { AppRoutes } from 'router';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerWrapper } from 'pages/Profile/Details/Details.styled';
import { iso31661 } from 'iso-3166';
import CountrySelect from 'pages/Profile/Details/components/CountrySelect/CountrySelect.component';
import { format } from 'date-fns';
import { assertUnreachable } from 'utils/assertUnreachable';
import { CustomDatepicker } from 'ui/Datepicker';

const schema = Yup.object({
  firstName: Validation.isString.required('Name is a required field'),
  lastName: Validation.isString.required('Last name is a required field'),
  dateOfBirth: Validation.isDate.required('Date is a required field'),
  residence_country: Validation.isString.required('Country is a required field'),
  residenceCity: Validation.isString.required('City is a required field'),
  residenceStreet: Validation.isString.required('Street is a required field'),
  residenceZipCode: Validation.isString.required('Code is a required field'),
  email: Validation.isEmail,
});

type DataProps = {
  level: string;
};

export interface DetailsFormProps {
  first_name: string;
  last_name: string;
  phone_number: string;
  email?: string;
  date_of_birth: Date;
  residence_country: string;
  residence_city: string;
  residence_street: string;
  residence_zip_code: string;
}

type plotOptions = {
  [key in VaultKYCLevels]: number;
};

function swapKeysAndValues<T extends Record<string, S>, S extends string | number>(
  obj: T,
): { [K in keyof T as T[K]]: K } {
  const res = {} as any;
  Object.entries(obj).forEach(([key, value]) => {
    res[value] = key;
  });

  return res;
}

export const KYCLevelToNumber: plotOptions = {
  KYC_0: 0,
  KYC_1: 1,
  KYC_2: 2,
  KYC_3: 3,
} as const;
const NumberToKYCLevel = swapKeysAndValues(KYCLevelToNumber);

function formatKYCstatus(status: VaultKYCStatus): string {
  let formattedStatus = '';

  switch (status) {
    case 'APPROVED':
      formattedStatus = 'Approved';
      break;
    case 'DENIED':
      formattedStatus = 'Denied';
      break;
    case 'UNDEFINED':
      formattedStatus = 'Unknown';
      break;
    case 'UNDER_REVIEW':
      formattedStatus = 'Under Review';
      break;
    case 'STARTED':
      formattedStatus = 'Started';
      break;
    default:
      assertUnreachable(status);
  }

  return formattedStatus;
}

export const statusesToShow: VaultKYCStatus[] = ['APPROVED', 'UNDER_REVIEW'] as const;

const PersonalData: React.FC<DataProps> = ({ level }) => {
  const dispatch = useAppDispatch();
  const { errorNotification, successNotification } = useNotification();
  const navigate = useAppNavigate();

  const { vaultProfile, isConfirmUserEmail } = useAppSelector(getVaultSelector);
  const { email } = useUserSelector();

  const [date, setDate] = useState();
  const [status, setStatus] = useState<VaultKYCStatus>('UNDEFINED');
  const [kycLevel, setKycLevel] = useState<VaultKYC>(null);

  const hasNextKyc = useMemo(() => {
    if (!kycLevel) {
      return true;
    }

    const currentKYCNumber: number = KYCLevelToNumber[kycLevel];
    const nextKYCLevel = NumberToKYCLevel[currentKYCNumber + 1];

    return !!nextKYCLevel;
  }, [kycLevel]);

  const country = iso31661.find((elem) => elem.alpha2 === vaultProfile?.residenceCountry);

  useEffect(() => {
    if (!level) {
      return;
    }

    dispatch(getKYC())
      .unwrap()
      .then((kyc) => {
        const { current_kyc, next_kyc } = kyc;

        if (next_kyc && next_kyc.kyc_level !== current_kyc && statusesToShow.includes(next_kyc.status)) {
          const { kyc_level: nextKycLevel, status: nextKycStatus } = next_kyc;

          setKycLevel(nextKycLevel as VaultKYC);
          setStatus(nextKycStatus);
        } else {
          setKycLevel(current_kyc);
          setStatus('APPROVED');
        }
      });
  }, [level]);

  const {
    handleSubmit,
    register,
    watch,
    control,
    clearErrors,
    setValue,
    formState,
    reset,
    setError,
    formState: { errors, dirtyFields },
  } = useForm<VaultProfileType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      ...vaultProfile,
      phone: `+${vaultProfile?.phone}`,
      dateOfBirth: vaultProfile?.dateOfBirth,
      residence_country: country?.name || '',
      email,
    },
  });

  const handleChange = (dateChange: Date) => {
    clearErrors('dateOfBirth');
    setValue('dateOfBirth', dateChange, { shouldValidate: true, shouldDirty: true });
    setDate(dateChange);
  };

  const confirmEmailAction = () => {
    dispatch(addEmail({ email: watch('email') }))
      .unwrap()
      .then(() => {
        successNotification('To confirm your email, please click the link in the email we sent you.', 'email');
      })
      .catch((error) => {
        errorNotification(error.message);
      });
  };

  const startKYC = () => {
    if (level === 'KYC_0') {
      navigate(AppRoutes.KYC);
    }
  };

  const onSubmit = (data: VaultProfileType) => {
    const updatedCountry = iso31661.find((elem) => elem.name === data.residence_country);
    const formattedDate = format(new Date(data.dateOfBirth), 'yyyy-MM-dd');
    dispatch(
      updateProfileDetailsRequest({
        first_name: data.firstName,
        last_name: data.lastName,
        residence_country: updatedCountry?.alpha3 || data.residenceCountry || '',
        residence_city: data.residenceCity,
        residence_street: data.residenceStreet,
        date_of_birth: formattedDate,
        residence_zip_code: data.residenceZipCode,
      }),
    )
      .unwrap()
      .then(() => {
        reset(data);
      })
      .catch((error) => {
        errorNotification(error.message);
      });
  };

  const isDirty = () => {
    const changedFields = Object.keys(dirtyFields).filter((field) => field !== 'email');
    return !!changedFields.length;
  };

  useEffect(() => {
    if (vaultProfile?.dateOfBirth) {
      setDate(vaultProfile.dateOfBirth);
    }
  }, [vaultProfile]);

  return (
    <Flex $direction="column">
      <CardStyled $direction="column">
        <Flex $gap="md" $align="flex-start" $direction="column">
          <Flex $justify="space-between">
            <H6 color="primary">KYC</H6>
            {kycLevel && <StyledTitle size="md">Level: {`${KYCLevelToNumber[kycLevel]}`}</StyledTitle>}
          </Flex>
          <Flex $justify="space-between">
            <StyledTitle size="md">Status:</StyledTitle>
            <StyledStatus size="md">{formatKYCstatus(status)}</StyledStatus>
          </Flex>
          {hasNextKyc && <Button onClick={startKYC}>Improve to next level</Button>}
        </Flex>
      </CardStyled>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <CardStyled $direction="column">
          <Input placeholder="First name" error={errors.firstName?.message} {...register('firstName')} />
          <Input placeholder="Last name" error={errors.lastName?.message} {...register('lastName')} />
          <Input placeholder="Phone number" error={errors.phone?.message} {...register('phone')} disabled isLocked />
          <div style={{ position: 'relative', width: '100%' }}>
            <EmailInput
              placeholder="Email"
              error={errors.email?.message}
              {...register('email')}
              disabled={!!email && isConfirmUserEmail}
              isLocked={!!email && isConfirmUserEmail}
              $fullyDisabled={!!email && isConfirmUserEmail}
            />
            {!isConfirmUserEmail ? (
              <StyledEmailButton
                disabled={!watch('email') || !!errors.email?.message}
                color="tertiary"
                onClick={confirmEmailAction}
              >
                Send link
              </StyledEmailButton>
            ) : null}
          </div>
          <DatePickerWrapper>
            <Controller
              name="dateOfBirth"
              control={control}
              defaultValue={date}
              render={() => (
                <CustomDatepicker
                  selected={date}
                  placeholderText="Date of birth"
                  onChange={handleChange}
                  dateFormat="MM/dd/yyyy"
                  forAge
                  customInput={<Input placeholder="Date of birth" error={errors.dateOfBirth?.message} />}
                />
              )}
            />
          </DatePickerWrapper>
        </CardStyled>
        <CardStyled $direction="column">
          <CountrySelect
            list={iso31661}
            setValue={setValue}
            register={register}
            formState={formState}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
          />
          <Input placeholder="Residence city" error={errors.residenceCity?.message} {...register('residenceCity')} />
          <Input
            placeholder="Residence street"
            error={errors.residenceStreet?.message}
            {...register('residenceStreet')}
          />
        </CardStyled>
        {isDirty() ? <ConfirmButton type="submit">Save personal data</ConfirmButton> : null}
      </Form>
    </Flex>
  );
};

export default PersonalData;
