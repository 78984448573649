import { RootState } from 'store';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';
import Cookies from 'js-cookie';
import { MeResponse, MeResponseShort } from './actionAsync';

export const saveUserInfo = (state: RootState['user'], payload: MeResponse, isTokenChange?: boolean) => {
  const {
    username,
    wallet_address: walletAddress,
    email,
    access_token,
    refresh_token,
    token: tokenForSpringApi,
    kyc,
    parent_referral: parentReferral,
    id,
    password_was_changed: isPasswordChanged,
    is_active: isActive,
    referral_link: referralLink,
    telegram_username: telegramUsername,
    phone,
    vault,
    payment_service,
    current_kyc,
  } = payload;
  state.username = username;
  state.email = email;
  state.walletAddress = walletAddress;
  state.kyc = kyc;
  state.id = id;
  state.myReferrer = parentReferral;
  state.isPasswordChanged = isPasswordChanged;
  state.isActive = isActive;
  state.referralLink = referralLink;
  state.telegramUsername = telegramUsername;
  state.phone = phone;
  state.paymentService = payment_service;
  state.KYCLevel = current_kyc;
  state.currentKyc = current_kyc;
  localStorage.setItem(LocalStorageKeys.SPRING_TOKEN, tokenForSpringApi);
  if (!Cookies.get(LocalStorageKeys.TOKEN_KEY) || isTokenChange) {
    Cookies.set(LocalStorageKeys.TOKEN_KEY, JSON.stringify(access_token), { expires: 1 });
  }
  if (!Cookies.get(LocalStorageKeys.REFRESH_KEY) || isTokenChange) {
    Cookies.set(LocalStorageKeys.REFRESH_KEY, JSON.stringify(refresh_token), { expires: 3 });
  }
  if ((!Cookies.get(LocalStorageKeys.VAULT_TOKEN_KEY) || isTokenChange) && vault?.access_token) {
    Cookies.set(LocalStorageKeys.VAULT_TOKEN_KEY, JSON.stringify(vault.access_token), { expires: 1 });
  }
  if ((!Cookies.get(LocalStorageKeys.VAULT_REFRESH_KEY) || isTokenChange) && vault?.refresh_token) {
    Cookies.set(LocalStorageKeys.VAULT_REFRESH_KEY, JSON.stringify(vault.refresh_token), { expires: 3 });
  }
};

export const saveUserInfoShort = (state: RootState['user'], payload: MeResponseShort) => {
  const { username, wallet_address: walletAddress, email, gems, id, frozen_gems } = payload;
  state.username = username;
  state.email = email;
  state.walletAddress = walletAddress;
  state.id = id;
  state.gems = gems - frozen_gems;
};
