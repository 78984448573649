import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useActions } from 'hooks/useActions';
import { useBuilderSelector, useDataCenterSelector, usePowerGridSelector, useUserSelector } from 'store';
import { useLocation } from 'react-router-dom';

interface IControllerBaseLayout {
  bg: 'primary' | 'secondary' | 'tertiary' | 'image' | string;
  isHaveButtonBack: boolean;
  isImageBg?: boolean;
}

const SOCKET_URL = import.meta.env.VITE_DEV_SOCKET;

const color = JSON.stringify({
  color: '#1C1C1CFF',
});

export const ControllerBaseLayout: React.FC<PropsWithChildren<IControllerBaseLayout>> = ({
  bg,
  isHaveButtonBack,
  children,
  isImageBg = false,
}) => {
  const actions = useActions();
  const {
    powerGrid: { image_link: image },
  } = usePowerGridSelector();
  const { username } = useUserSelector();
  const { config, isSocketOpen, activeSingle, isDCUpdate } = useDataCenterSelector();
  const { isSendSocket, isInserting, isRemoving } = useBuilderSelector();
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const { pathname } = useLocation();

  const { setSocketNumber, setSocketSend, setIsInserting, setIsRemoving, setDCUpdate } = useActions();

  useEffect(() => {
    if (username && !socket && isSocketOpen) {
      const randomNumber = Math.floor(Math.random() * 10_000 + 1);
      setSocketNumber(randomNumber);
      const ws = new WebSocket(`${SOCKET_URL}/?username=${username}${randomNumber}`);
      setSocket(ws);
    }
  }, [username, isSocketOpen]);

  useEffect(() => {
    if (config[0] && socket) {
      const json2 = JSON.stringify({
        text2: config,
      });
      const json3 = JSON.stringify({
        text3: config,
      });
      const json = JSON.stringify({
        text: config,
      });
      const activeJSON = JSON.stringify({
        bwEnabled: !activeSingle?.status,
      });
      socket.onmessage = (event) => {
        if (event.data.includes('Hello')) {
          socket?.send(json);
          socket?.send(color);
          if (pathname.includes('builder')) {
            socket?.send(JSON.stringify({ boost: true }));
          }
          if (pathname.includes('data-center')) {
            socket?.send(activeJSON);
          }
        }
      };
      if (isDCUpdate) {
        socket?.send(activeJSON);
        setDCUpdate(false);
      }
      if (isSendSocket) {
        if (isInserting) {
          socket?.send(json2);
          setIsInserting(false);
        }
        if (isRemoving) {
          socket?.send(json3);
          setIsRemoving(false);
        }
        if (!isInserting && !isRemoving) {
          socket?.send(json);
        }
        socket?.send(JSON.stringify({ bwEnabled: false }));
        socket?.send(JSON.stringify({ boost: true }));
        socket?.send(color);
        setSocketSend(false);
      }
    }
  }, [
    config[0],
    socket,
    activeSingle?.name,
    activeSingle?.boost,
    activeSingle?.mining_now_data,
    activeSingle?.status,
    pathname,
    isDCUpdate,
    isSendSocket,
  ]);

  useEffect(() => {
    actions.setIsHaveButtonBack(isHaveButtonBack);
    if (isImageBg) {
      actions.setIsImageBg(isImageBg);
      actions.setBg(image);
    }
    if (!isImageBg) {
      actions.setIsImageBg(false);
      actions.setBg(bg);
    }
  }, [bg, isHaveButtonBack, actions, isImageBg, image]);

  return children;
};
