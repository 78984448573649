import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import {
  GetProductPageRequest,
  GetProductPageResponse,
  ICollectionChapter,
  GetLandingProductsType,
} from 'src/types/marketplace';

export const getAllNft = createAsyncThunk<{ count: number; results: ICollectionChapter[] }, void, ThunkApiConfig>(
  'marketPlace/get-all',
  async (_, { extra: services, rejectWithValue }) => {
    // TODO: Specify a different type for response
    const response: any = await services.httpService.MarketPlaceService.getProductsCollection({});
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getSingleNftPage = createAsyncThunk<GetProductPageResponse, GetProductPageRequest, ThunkApiConfig>(
  'marketplace/product-page',
  async ({ id }, { extra: services, rejectWithValue }) => {
    // TODO: Specify a different type for response
    const response: any = await services.httpService.MarketPlaceService.getProductPage({ id });
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }
    return response.data;
  },
);

export const getScProductsLanding = createAsyncThunk<GetLandingProductsType, void, ThunkApiConfig>(
  'marketplace/sc-landing',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.MarketPlaceService.getScProductsLanding();
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);
