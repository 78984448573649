import { ApiEndpoints, instance } from 'services/client';
import { AxiosError, AxiosResponse } from 'axios';
import {
  GetProductsCollection,
  GetProductPageResponse,
  GetProductsCollectionResponse,
  GetProductPageRequest,
  GetLandingProductsType,
  SlugScProductPages,
  GetProductShortContractPageResponse,
} from 'src/types/marketplace';

class MarketPlaceService {
  static async getProductsCollection(
    params: GetProductsCollection,
  ): Promise<AxiosResponse<GetProductsCollectionResponse>> {
    const response = await instance.get<GetProductsCollection, AxiosResponse<GetProductsCollectionResponse>>(
      `${ApiEndpoints.v2.marketplace.get_products_collection}`,
      {
        params,
      },
    );

    return response;
  }

  static async getProductPage(params: GetProductPageRequest): Promise<AxiosResponse<GetProductPageResponse>> {
    const response = await instance.get<GetProductPageRequest, AxiosResponse<GetProductPageResponse>>(
      `${ApiEndpoints.v2.marketplace.get_products_pages}${params.id}/`,
    );

    return response;
  }

  static async getScProductsLanding() {
    const response = await instance.get<void, AxiosResponse<GetLandingProductsType>>(
      ApiEndpoints.v2.marketplace.get_sc_landing,
    );

    return response;
  }

  static async getProductShortsContractPage() {
    const response = await instance.get<void, AxiosResponse<GetProductShortContractPageResponse>>(
      `${ApiEndpoints.v2.marketplace.get_product_short_contact}${SlugScProductPages.SC_PRODUCT_PAGES}/`,
    );

    return response;
  }
}

export default MarketPlaceService;
