import { AxiosResponse } from 'axios';
import { ApiEndpoints, instance } from 'services/client';
import {
  AchievementSerializerType,
  AchievementCategories,
  AchievementStatisticType,
} from 'store/slices/achievements/types';

class AchievementsService {
  static async getAchievements(params?: AchievementCategories) {
    const response: AxiosResponse<AchievementSerializerType[]> = await instance.get(
      ApiEndpoints.v2.achievements.get_all,
      {
        params,
      },
    );

    return response;
  }

  static async getAchievementsStatistic() {
    const response: AxiosResponse<AchievementStatisticType> = await instance.get(
      ApiEndpoints.v2.achievements.statistic,
    );

    return response;
  }
}

export default AchievementsService;
