import { CalculatorConstants, CalculatorTypeNFT } from 'types/calculator';

export const findByName = (constants: CalculatorConstants[], name: string) =>
  constants.find((el) => el.name === name)?.value;

export const findAndTransformValueToNumber = (constants: CalculatorConstants[], name: string): number =>
  Number(findByName(constants, name)) || 0;

export const getConstants = (constants: CalculatorConstants[]) => {
  const type = findByName(constants, 'type') as CalculatorTypeNFT;

  return type === CalculatorTypeNFT.LONG
    ? {
        type,
        totalMiningReward: findAndTransformValueToNumber(constants, 'total_mining_reward'),
        totalElectricityCost: findAndTransformValueToNumber(constants, 'total_electricity_cost'),
        dailyReward: findAndTransformValueToNumber(constants, 'daily_reward'),
      }
    : {
        type,
        totalMiningReward: findAndTransformValueToNumber(constants, 'total_mining_reward'),
        roi: findAndTransformValueToNumber(constants, 'roi'),
        totalReward: findAndTransformValueToNumber(constants, 'total_reward'),
      };
};
