export const SIGN_IN_STATUS = 'SIGN_IN_STATUS';
export const BIND_STATUS = 'BIND_STATUS';
export const RACE_REFERRAL_LINK = `${import.meta.env.VITE_DEV_SITE}/race/ref/`;
export const isUrl = /^(https?:\/\/)/;

export const TIMER_RESEND_CODE = 60;

export enum VerifiedUserStatus {
  FULL = 'FULL', // there is all the data
  LIQUID = 'LIQUID', // In liquid, but not in daisy
  NO_EMAIL = 'NO_EMAIL', // In liquid, there is no mail (there is a password, name, wallet)
  DAISY = 'DAISY', // In daisy, but not in liquid
  NONE = 'None', // Not daisy, and not in liquid
}
