import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import RewardsService from 'services/Spring/RewardsService';
import { HttpServiceSpring } from 'services/Spring/client/types';
import RaceService from 'services/Spring/RaceService';
import UserService from 'services/Spring/UserService';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';
import Cookies from 'js-cookie';

const API_URL = import.meta.env.VITE_DEV_SPRING;

// todo refactor duplicate
export const instanceSpring = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
});

const onFulfilledRequest = (config: any) => {
  // todo change to JWT
  const token = Cookies.get(LocalStorageKeys.TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }
  return config;
};
const onRejectedRequest = (error: unknown): Promise<AxiosRequestConfig> => Promise.reject(error);
instanceSpring.interceptors.request.use(onFulfilledRequest, onRejectedRequest);

const onFulfilledResponse = (response: AxiosResponse<any, any>) => ({ isError: false, ...response });
const onRejectedResponse = (error: AxiosError<any>) => ({ isError: true, ...error });
instanceSpring.interceptors.response.use(onFulfilledResponse, onRejectedResponse);

export const httpServiceSpring: HttpServiceSpring = {
  RewardsService,
  RaceService,
  UserService,
};
