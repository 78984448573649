import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { ProductsMonthDurability } from 'types/marketplace';
import { PaymentServiceEnum } from 'types/wallets';
import { removeBuilderItem } from 'store/slices';
import { store } from 'store/store';
import {
  addProductToCart,
  changeAmountMarketItem,
  changeValueEnergyObject,
  createEnergyObject,
  deleteBuilderItemObject,
  deleteMarketItem,
  deleteSCItem,
  deleteValueEnergyObject,
  getGemsSignature,
  getInitialStateBag,
  syncNFTsMarketCart,
  syncNFTsSCCart,
} from './actionAsync';
import { BagReducers, CategoryMarketObjectEnum, InitStateBag } from './types';
import {
  addingNFTs,
  addingSC,
  countPrice,
  deleteSyncedNFT,
  incrementPrice,
  LogoutMatcher,
  recountPrice,
  recountUnSyncPrice,
  syncNft,
} from './utils';

const initialState: InitStateBag = {
  nft: [],
  unSyncNFTMarket: [],
  unSyncNFTSC: [],
  energy: null,
  builder: [],
  shortContract: [],
  price: {
    total: 0,
    NFTs: 0,
    builder: 0,
    shortContact: 0,
    energy: 0,
  },
  step: 1,
  transactionHash: '',
  isNeedUpdateMarket: false,
  isNeedUpdateSC: false,
  isCartInit: false,
  amountUsedGems: 0,
  lastSignature: null,
  isApprovedEarlier: false,
  isGettingBalance: true,
  isCartOpen: false,
  isItemLoading: false,
  selectedPayment: PaymentServiceEnum.VAULT,
  builderItemToRemove: null,
};

const bagSlice: Slice<InitStateBag, BagReducers> = createSlice<InitStateBag, BagReducers>({
  name: 'bag',
  initialState,
  reducers: {
    disableMarketButton: (state, action) => {
      state.isItemLoading = action.payload;
    },
    changeNFTsMarketPlace: (state, action) => {
      state.unSyncNFTMarket = addingNFTs(state.unSyncNFTMarket, action.payload);
      state.price = recountUnSyncPrice(state);
      state.isNeedUpdateMarket = state.unSyncNFTMarket.length > 0;
      state.isItemLoading = false;
    },
    setIsCartOpen: (state, action) => {
      state.isCartOpen = action.payload;
    },
    clearUnSyncNFTAll: (state) => {
      state.unSyncNFTMarket = [];
      state.isNeedUpdateMarket = false;
    },
    addUnSyncSCMarketPlace: (state, action) => {
      const unSyncNfts = addingSC(state.unSyncNFTSC, action.payload);
      state.unSyncNFTSC = unSyncNfts.map((nft) => ({
        ...nft,
        month_durability: ProductsMonthDurability[nft.id],
      }));
      state.price = recountUnSyncPrice(state);
      state.isNeedUpdateMarket = state.unSyncNFTMarket.length > 0;
    },
    removeUnSyncSCMarketPlace: (state, action) => {
      state.unSyncNFTSC = state.unSyncNFTSC.filter((el) => el.id !== action.payload);
    },
    clearAllUnSyncSCMarketPlace: (state) => {
      state.unSyncNFTSC = [];
    },
    setTransactionHash: (state, action: PayloadAction<string>) => {
      state.transactionHash = action.payload;
    },
    nextStep: (state) => {
      state.step += 1;
    },
    setIsApprovedEarlier: (state, action: PayloadAction<boolean>) => {
      state.isApprovedEarlier = action.payload;
    },
    setIsGettingBalance: (state, action: PayloadAction<boolean>) => {
      state.isGettingBalance = action.payload;
    },
    prevStep: (state) => {
      state.step -= 1;
    },
    clearNFTs: (state) => {
      state.nft = initialState.nft;
    },
    setAmountUsedGems: (state, action) => {
      state.amountUsedGems = action.payload;
    },
    clearLastSignature: (state) => {
      state.lastSignature = null;
    },
    selectPaymentService: (state, action) => {
      state.selectedPayment = action.payload;
    },
    clearBag: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(getInitialStateBag.fulfilled, (state, action) => {
        const { shortContracts, builder: builderElements, energy, market } = action.payload;

        const notSyncedShortsContract = state.unSyncNFTSC.filter((unSync) =>
          shortContracts.every((sync) => sync.product.contract_purchase_id !== unSync.product.contract_purchase_id),
        );

        state.isNeedUpdateSC = notSyncedShortsContract.length > 0;
        state.unSyncNFTSC = notSyncedShortsContract;
        state.shortContract = shortContracts.map((nft) => ({
          ...nft,
          month_durability: ProductsMonthDurability[nft.id],
        }));

        state.nft = syncNft(market, state.unSyncNFTMarket);
        state.unSyncNFTMarket = deleteSyncedNFT(market, state.unSyncNFTMarket);
        state.energy = energy || null;
        state.builder = builderElements;
        state.price = countPrice(market, energy, builderElements, shortContracts);
        state.isCartInit = true;
      })
      .addCase(addProductToCart.fulfilled, (state, action) => {
        if (action.payload.category === CategoryMarketObjectEnum.shortContracts) {
          state.shortContract = [...state.shortContract, action.payload];
          const shortProductsWithDurability = state.shortContract.map((nft) => ({
            ...nft,
            month_durability: ProductsMonthDurability[nft.id],
          }));
          state.shortContract = shortProductsWithDurability;
          state.price = recountPrice(state, 'shortContact');
        } else {
          state.nft = [...state.nft, action.payload];
          state.price = incrementPrice(state.price, action.payload.product.price, 'NFTs');
        }
        state.step = 1;
        state.isApprovedEarlier = false;
        state.isGettingBalance = true;
        state.isItemLoading = false;
      })
      .addCase(getGemsSignature.fulfilled, (state, action) => {
        const { signature, expiration_ts, gems } = action.payload;

        state.lastSignature = {
          signature,
          expirationTS: expiration_ts,
          amountGems: gems,
        };
      })
      .addCase(changeAmountMarketItem.fulfilled, (state, action) => {
        state.nft = state.nft.map((el) =>
          el.id === action.payload.id
            ? {
                ...el,
                count: action.payload.count,
              }
            : el,
        );
        state.price = recountPrice(state, 'NFTs');
        state.step = 1;
        state.isApprovedEarlier = false;
        state.isGettingBalance = true;
      })
      .addCase(deleteMarketItem.fulfilled, (state, action) => {
        state.nft = state.nft.filter((el) => el.id !== action.payload.id);
        // Searching for items that need to delete from builder
        const itemToRemove = [...state.builder].find((el) => el.id === action.payload.id);
        if (itemToRemove) {
          state.builderItemToRemove = itemToRemove.builder_item.id;
        }
        state.builder = state.builder.filter((el) => el.id !== action.payload.id);
        state.price = recountPrice(state, 'NFTs');
        state.step = 1;
        state.isApprovedEarlier = false;
        state.isGettingBalance = true;
      })
      .addCase(deleteSCItem.fulfilled, (state, action) => {
        state.shortContract = state.shortContract.filter((el) => el.id !== action.payload.id);
        state.price = recountPrice(state, 'shortContact');
        state.step = 1;
        state.isApprovedEarlier = false;
        state.isGettingBalance = true;
      })
      .addCase(createEnergyObject.fulfilled, (state, action) => {
        state.energy = action.payload;
        state.price = recountPrice(state, 'energy');
        state.step = 1;
        state.isApprovedEarlier = false;
        state.isGettingBalance = true;
      })
      .addCase(changeValueEnergyObject.fulfilled, (state, action) => {
        state.energy = action.payload;
        state.price = recountPrice(state, 'energy');
        state.step = 1;
        state.isApprovedEarlier = false;
        state.isGettingBalance = true;
      })
      .addCase(deleteValueEnergyObject.fulfilled, (state) => {
        state.energy = null;
        state.price = recountPrice(state, 'energy');
        state.step = 1;
        state.isApprovedEarlier = false;
        state.isGettingBalance = true;
      })
      .addCase(deleteBuilderItemObject.fulfilled, (state, action) => {
        state.builder = state.builder.filter((el) => el.id !== action.payload.id);
        state.price = recountPrice(state, 'builder');
        state.step = 1;
        state.isApprovedEarlier = false;
        state.isGettingBalance = true;
      })
      .addCase(syncNFTsMarketCart.fulfilled, (state, action) => {
        state.nft = action.payload;
        state.unSyncNFTMarket = [];
        state.isNeedUpdateMarket = false;
        state.price = recountPrice(state, 'NFTs');
      })
      .addCase(syncNFTsSCCart.fulfilled, (state, action) => {
        state.shortContract = action.payload;
        state.unSyncNFTSC = [];
        state.isNeedUpdateSC = false;
        state.price = recountPrice(state, 'shortContact');
      })

      .addCase(syncNFTsSCCart.rejected, (state, action) => {
        state.shortContract = action.payload.data;
        state.unSyncNFTSC = [];
        state.isNeedUpdateSC = false;
        state.price = recountPrice(state, 'shortContact');
      })
      .addMatcher(LogoutMatcher, () => initialState),
});

export const {
  clearBag,
  setTransactionHash,
  prevStep,
  addUnSyncSCMarketPlace,
  removeUnSyncSCMarketPlace,
  clearAllUnSyncSCMarketPlace,
  nextStep,
  setIsApprovedEarlier,
  changeNFTsMarketPlace,
  clearNFTs,
  clearUnSyncNFTAll,
  setAmountUsedGems,
  clearLastSignature,
  setIsGettingBalance,
  setIsCartOpen,
  disableMarketButton,
  selectPaymentService,
} = bagSlice.actions;

export default bagSlice.reducer;
