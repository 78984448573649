import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { URLPiece, MarketplaceMock, SingleChildren, InventoryPageItem, StateOpened, Final } from 'src/types/builder';
import { NewItem, NftToBuyObject } from 'pages/Builder/Build/contants';
import { getAllItems, getInventoryEqp, getItem, getMarketEqp } from './actionsAsync';

type MaxComponentsPerType = {
  [key in URLPiece]: number;
};

const maxComponents: MaxComponentsPerType = {
  rig: 6,
  shelf: 4,
  container: 4,
  gpu: 0,
};

export type TInitialState = {
  currentItem: StateOpened | NewItem;
  filterType: URLPiece | '';
  isSendSocket: boolean;
  allItems: InventoryPageItem[];
  isLoading: boolean;
  finalArray: Final[];
  isInserting: boolean;
  isRemoving: boolean;
  inventoryEqp: SingleChildren[];
  marketEqp: SingleChildren[];
};

const initialState: TInitialState = {
  currentItem: {
    can_be_purchased: false,
    contract_purchase_id: 0,
    id: 0,
    image_link: '',
    max_components: 0,
    product_name: '',
    price: 0,
    created_at: '',
    used: false,
    name: '',
    is_purchased: false,
    is_purchasing: false,
    is_cart: false,
    synced: false,
    components: 0,
    power_rate: 0,
    energy_consumption: 0,
    product: 0,
    config: [''],
    url_piece: 'shelf',
  },
  filterType: '',
  inventoryEqp: [],
  marketEqp: [],
  isSendSocket: false,
  allItems: [],
  isLoading: true,
  finalArray: [],
  isInserting: false,
  isRemoving: false,
};

const builderSlice = createSlice({
  name: 'builder',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<URLPiece | ''>) => {
      state.filterType = action.payload;
    },
    setCurrentItem: (state, action: PayloadAction<StateOpened | NewItem>) => {
      state.currentItem = action.payload;
    },
    setSocketSend: (state, action: PayloadAction<boolean>) => {
      state.isSendSocket = action.payload;
    },
    setFinalArray: (state, action: PayloadAction<Final[]>) => {
      state.finalArray = action.payload;
    },
    setIsInserting: (state, action: PayloadAction<boolean>) => {
      state.isInserting = action.payload;
    },
    setIsRemoving: (state, action: PayloadAction<boolean>) => {
      state.isRemoving = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllItems.fulfilled, (state, action) => {
        state.allItems = action.payload.filter((el) => !el.used && el.components !== 0);
      })
      .addCase(getItem.fulfilled, (state, action) => {
        state.currentItem = action.payload;
      })
      .addCase(getMarketEqp.fulfilled, (state, action) => {
        const ids = action.payload.map((el: MarketplaceMock) => el.contract_purchase_id);
        state.marketEqp = NftToBuyObject.filter((el) => ids.includes(el.contract_purchase_id));
      })
      .addCase(getInventoryEqp.fulfilled, (state, action) => {
        const ids = state.finalArray.map((el) => el?.id);
        state.inventoryEqp = action.payload
          .filter((el: SingleChildren) => el.components === maxComponents[el.url_piece])
          .filter((el: SingleChildren) => !ids.includes(el.id) && !el.is_cart && el.synced && !el.used);
      })
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('builder') && type.endsWith('/pending');
        },
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('builder') && type.endsWith('/rejected');
        },
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('builder') && type.endsWith('/fulfilled');
        },
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const { setFilter, setSocketSend, setFinalArray, setIsInserting, setIsRemoving, setCurrentItem, setLoading } =
  builderSlice.actions;

export default builderSlice.reducer;
