import { ApiEndpoints, instance } from 'services/client';
import {
  VaultAddEmailType,
  VaultAuthorizeType,
  VaultPhoneConfirmType,
  VaultRegistrationType,
  VaultWalletConnectType,
} from 'services/Django/VaultService/types';
import { DetailsFormProps } from 'pages/Profile/Details/Details.component';

class VaultService {
  static async vaultRegistrationRequest(data: VaultRegistrationType) {
    const { phone, username, password, confirmPassword } = data;
    const body = { phone_number: phone, username, password, password2: confirmPassword };
    const url = ApiEndpoints.v2.vault.vaultRegistration;
    const response = await instance.post(url, body);

    return response;
  }

  static async confirmRegistrationPhoneRequest(data: VaultPhoneConfirmType) {
    const { phone, code } = data;
    const body = { phone, sms_code: code, fingerprint: '1' };
    const url = ApiEndpoints.v2.vault.confirmPhoneRegistration;
    const response = await instance.post(url, body);

    return response;
  }

  static async vaultAuthorizeRequest(data: VaultAuthorizeType) {
    const { phone, password } = data;
    const body = { phone_number: phone, password };
    const url = ApiEndpoints.v2.vault.vaultAuthorize;
    const response = await instance.post(url, body);

    return response;
  }

  static async resendPhoneVerifyCodeRequest(data: VaultPhoneConfirmType) {
    const { phone } = data;
    const body = { phone, fingerprint: '1' };
    const url = ApiEndpoints.v2.vault.resend_code;
    const response = await instance.post(url, body);

    return response;
  }

  static async updateProfileDetailsRequest(data: DetailsFormProps) {
    const url = ApiEndpoints.v2.vault.personal_data;
    const response = await instance.put(url, data);

    return response;
  }

  static async addEmail(data: VaultAddEmailType) {
    const url = ApiEndpoints.v2.vault.addEmail;
    const response = await instance.post(url, data);

    return response;
  }

  static async purchaseGems(count: number) {
    const url = ApiEndpoints.v2.vault.purchaseGems;
    const response = await instance.post(url, { count });

    return response;
  }

  static async passKYC1Request() {
    const url = ApiEndpoints.v2.vault.kyc1start;
    const response = await instance.post(url);

    return response;
  }

  static async finishKYC1Request(id: string) {
    const url = ApiEndpoints.v2.vault.kyc1finish;
    const response = await instance.post(url, { id });

    return response;
  }

  static async getKYC() {
    const url = ApiEndpoints.v2.vault.kyc;
    const response = await instance.get(url);

    return response;
  }

  static async vaultConnectWalletRequest(data: VaultWalletConnectType) {
    const { phone, password, confirmPassword } = data;
    const body = { phone_number: phone, password, password2: confirmPassword };
    const url = ApiEndpoints.v2.vault.vaultWalletConnect;
    const response = await instance.post(url, body);

    return response;
  }

  static async vaultUserInfo() {
    const url = ApiEndpoints.v2.vault.vaultUserInfo;
    const response = await instance.get(url);

    return response;
  }
}

export default VaultService;
