import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import { ColumnEventDTO, TreeMatrix } from './types';

export const getRaceInfo = createAsyncThunk<TreeMatrix, string, ThunkApiConfig>(
  'race/matrix',
  async (wallet, { extra, rejectWithValue }) => {
    const response: any = await extra.httpServiceSpring.RaceService.getTreeMatrix(wallet);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const confirmUser = createAsyncThunk<string, string, ThunkApiConfig>(
  'race/confirm',
  async (userName, { extra, rejectWithValue }) => {
    const response: any = await extra.httpServiceSpring.RaceService.confirmUser(userName);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const formCheck = createAsyncThunk<string, string, ThunkApiConfig>(
  'race/formCheck',
  async (userName, { extra, rejectWithValue }) => {
    const response: any = await extra.httpServiceSpring.RaceService.formCheck(userName);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const sendAffiliateForm = createAsyncThunk<
  string,
  { wallet: string; txId: string; pack: number },
  ThunkApiConfig
>('race/sendAffiliateForm', async (body, { extra, rejectWithValue }) => {
  const response: any = await extra.httpServiceSpring.RaceService.sendAffiliateForm(body);

  if (response.isError) {
    const message = getSimpleErrorMessage(response.response.data);
    return rejectWithValue({ message, isError: true });
  }

  return response.data;
});

export const sendLeaderForm = createAsyncThunk<
  string,
  {
    username: string;
    name: string;
    contact: string;
    type: 'TG' | 'WA';
    audience: number;
    usdt: number;
    additional: string;
  },
  ThunkApiConfig
>('race/sendLeaderForm', async (body, { extra, rejectWithValue }) => {
  const response: any = await extra.httpServiceSpring.RaceService.sendLeaderForm(body);

  if (response.isError) {
    const message = getSimpleErrorMessage(response.response.data);
    return rejectWithValue({ message, isError: true });
  }

  return response.data;
});

export const treeAdd = createAsyncThunk<string, { userName: string; userReferrer: string }, ThunkApiConfig>(
  'race/treeAdd',
  async (body, { extra, rejectWithValue }) => {
    const response: any = await extra.httpServiceSpring.RaceService.treeAdd(body);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getAffiliateBoard = createAsyncThunk<{ content: ColumnEventDTO[] }, undefined, ThunkApiConfig>(
  'race/affiliate-board',
  async (arg, { extra, rejectWithValue }) => {
    const response: any = await extra.httpServiceSpring.RaceService.getAffiliateBoard();

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getLeaderBoard = createAsyncThunk<{ content: ColumnEventDTO[] }, undefined, ThunkApiConfig>(
  'race/leader-board',
  async (arg, { extra, rejectWithValue }) => {
    const response: any = await extra.httpServiceSpring.RaceService.getLeaderBoard();

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);
