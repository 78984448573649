import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { GemsTransactionsResponse } from 'src/types/gems';
import { getSimpleErrorMessage } from 'utils/error';

const getGemsTransactions = createAsyncThunk<GemsTransactionsResponse, void, ThunkApiConfig>(
  'gems/transactions',
  async (_, { extra: services, rejectWithValue, getState }) => {
    const { gems } = getState();
    const requestParams = {
      created_at__gte: gems.dateValue,
      created_at__date__range: gems.dateRangeValue,
    };
    const response: any = await services.httpService.GemsService.getGemsTransactions(requestParams);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export default getGemsTransactions;
