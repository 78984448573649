import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { RewardsSlice } from './types';

export const getRewards = createAsyncThunk<RewardsSlice, string, ThunkApiConfig>(
  'rewards',
  async (username, { extra: { httpServiceSpring } }) => {
    const responseMerkleTree: any = await httpServiceSpring.RewardsService.getMerkleTree(username);
    const responseShares: any = await httpServiceSpring.RewardsService.getMerkleTreeShares(username);
    const responseEvent: any = await httpServiceSpring.RewardsService.getMerkleTreeEvent(username);
    const responseToken: any = await httpServiceSpring.RewardsService.getMerkleTreeToken(username);
    const responseForex: any = await httpServiceSpring.RewardsService.getMerkleTreeForex(username);
    const responsePayoutsTimeline = await httpServiceSpring.RewardsService.getPayoutsTimeline();

    return {
      me: responseMerkleTree.data,
      shares: responseShares.data,
      event: responseEvent.data,
      forex: responseForex.data,
      token: responseToken.data,
      timeline: responsePayoutsTimeline.data,
    };
  },
);
