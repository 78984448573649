import { IMAGE_URL } from 'assets/Urls';

const asicsData = [
  {
    id: 0,
    title: 'Liquid ASIC',
    coin: IMAGE_URL.Landing.Con1,
    subtitle: 'Liquid ASIC',
    img: IMAGE_URL.Landing.LiquidASIC_BTC,
    duration: 48,
    power: '120',
    effectivity: 1800,
    price: '3 800 USDT',
    marketId: 16,
    color: '#FFD966',
  },
  {
    id: 1,
    title: 'Liquid KASPA ASIC',
    coin: IMAGE_URL.Landing.Coin2,
    subtitle: 'Liquid KASPA ASIC',
    img: IMAGE_URL.Landing.LiquidASIC_KAS,
    duration: 36,
    power: '12,320',
    effectivity: 292.8,
    price: '3 000 USDT',
    color: '#71C9BB',
    marketId: 17,
  },
  {
    id: 2,
    title: 'Liquid CONTAINER',
    coin: IMAGE_URL.Landing.Coin3,
    subtitle: 'Liquid CONTAINER',
    img: IMAGE_URL.Landing.LiquidPLUS_Container_installed,
    duration: 24,
    power: 5760,
    effectivity: '11448.48',
    price: '67 200 USDT',
    color: '#2D52DA',
    marketId: 3,
  },
  {
    id: 3,
    title: 'Liquid ASIC',
    coin: IMAGE_URL.Landing.Con1,
    subtitle: 'Liquid ASIC',
    img: IMAGE_URL.Landing.LiquidASIC_BTC,
    duration: 24,
    power: '120',
    effectivity: 1800,
    price: '3 800 USDT',
    marketId: 16,
    color: '#FFD966',
  },
  {
    id: 4,
    title: 'Liquid KASPA ASIC',
    coin: IMAGE_URL.Landing.Coin2,
    subtitle: 'Liquid KASPA ASIC',
    img: IMAGE_URL.Landing.LiquidASIC_KAS,
    duration: 36,
    power: '12,320',
    effectivity: 292.8,
    price: '3 000 USDT',
    color: '#71C9BB',
    marketId: 17,
  },
  {
    id: 5,
    title: 'Liquid CONTAINER',
    coin: IMAGE_URL.Landing.Coin3,
    subtitle: 'Liquid CONTAINER',
    img: IMAGE_URL.Landing.LiquidPLUS_Container_installed,
    duration: 24,
    power: 5760,
    effectivity: '11448.48',
    price: '67 200 USDT',
    color: '#2D52DA',
    marketId: 3,
  },
  {
    id: 6,
    title: 'Liquid ASIC',
    coin: IMAGE_URL.Landing.Con1,
    subtitle: 'Liquid ASIC',
    img: IMAGE_URL.Landing.LiquidASIC_BTC,
    duration: 24,
    power: '120',
    effectivity: 1800,
    marketId: 16,
    price: '3 800 USDT',
    color: '#FFD966',
  },
  {
    id: 7,
    title: 'Liquid KASPA ASIC',
    coin: IMAGE_URL.Landing.Coin2,
    subtitle: 'Liquid KASPA ASIC',
    img: IMAGE_URL.Landing.LiquidASIC_KAS,
    duration: '36',
    power: '12,320',
    effectivity: 292.8,
    price: '3 000 USDT',
    color: '#71C9BB',
    marketId: 17,
  },
  {
    id: 8,
    title: 'Liquid CONTAINER',
    coin: IMAGE_URL.Landing.Coin3,
    subtitle: 'Liquid CONTAINER',
    img: IMAGE_URL.Landing.LiquidPLUS_Container_installed,
    duration: 24,
    power: 5760,
    effectivity: '11448.48',
    price: '67 200 USDT',
    color: '#2D52DA',
    marketId: 3,
  },
] as const;

export default [...asicsData, ...asicsData, ...asicsData];
