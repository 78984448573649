import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import { ReadClaimNft, NftClaimPrice } from 'types/myNfts';

export const getAllNfts = createAsyncThunk<ReadClaimNft[], void, ThunkApiConfig>(
  'myNfts/get-all',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.MyNftsService.getAllNfts();
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data.results;
  },
);

export const getNftPrice = createAsyncThunk<number, void, ThunkApiConfig>(
  'myNfts/get-price',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.MyNftsService.getNftPrice();
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data.price;
  },
);
