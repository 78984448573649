import { createSlice } from '@reduxjs/toolkit';
import { getRewards } from './actionAsync';
import { RewardsSlice } from './types';
import { getRewardsState } from './utils';

const initState: RewardsSlice = {
  shares: null,
  me: {
    node: null,
    refers: [],
  },
};

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRewards.fulfilled, (state, action) => {
        getRewardsState(state, action.payload);
      })
      .addMatcher(
        (action) => action.type.endsWith('user/logout'),
        () => initState,
      );
  },
});

export default rewardsSlice.reducer;
