import React from 'react';
import { useTranslation } from 'react-i18next';
import verticalLogo from 'assets/liquid-vertical.svg';
import { Icon, P } from 'ui';
import { ButtonLine, MobileLinkButton } from 'pages/Landing/components/MobilePreview/style';
import headerLinks from 'pages/Landing/components/Header/constants';
import { IMAGE_URL } from 'assets/Urls';
import { Links } from 'appConstants/Links';
import {
  FooterWrap,
  StyledMailTo,
  FooterLeadingText,
  FooterLink,
  InversionListGap,
  LiquidIcon,
  ListGap,
  QuestionBlock,
  SocialLine,
  StyledButtonIcon,
  FooterText,
  GetAppListGap,
} from './style';

interface IFooterProps {
  refs: React.RefObject<HTMLDivElement>[];
}

const Footer: React.FC<IFooterProps> = ({ refs }) => {
  const { t } = useTranslation('landing');
  const handleLinkClick = (index: number) => {
    refs[index].current?.scrollIntoView();
  };

  return (
    <FooterWrap $background={IMAGE_URL.Landing.GrainTexture}>
      <ListGap offset="large">
        <LiquidIcon src={verticalLogo} alt="liquid" width={134} height={111} />
        <SocialLine>
          <StyledButtonIcon
            color="secondary"
            icon="twitter"
            onlyIcon
            to={Links.Twitter}
            target="_blank"
            rel="noopener noreferer"
          />
          {/* TODO: Discord link. Hidden within https://atomglobal.atlassian.net/browse/LM20N-3347 */}

          {/* <StyledButtonIcon */}
          {/*  color="secondary" */}
          {/*  icon="discord" */}
          {/*  onlyIcon */}
          {/*  to={Links.Discord} */}
          {/*  target="_blank" */}
          {/*  rel="noopener noreferer" */}
          {/* /> */}
          <StyledButtonIcon color="secondary" icon="telegram" onlyIcon to={Links.Telegram} target="_blank" />
          <StyledButtonIcon
            color="secondary"
            icon="youtube"
            onlyIcon
            to={Links.YouTube}
            target="_blank"
            rel="noopener noreferer"
          />
        </SocialLine>
        <FooterText style={{ color: '#A0A0A0' }} size="sm" align="left">
          {t('footer.liquid_mining')}
          <br />
          <span>Version {import.meta.env.VITE_MAIN_VERSION}</span>
        </FooterText>
      </ListGap>
      <ListGap offset="large">
        <FooterLeadingText fontFamily="secondary" size="md">
          {t('footer.navigation')}
        </FooterLeadingText>
        <ListGap offset="medium">
          {headerLinks.map(({ value }, i) => (
            <FooterLink size="md" key={value} align="left" onClick={() => handleLinkClick(i)}>
              {t(`header_links.${value}`)}
            </FooterLink>
          ))}
        </ListGap>
        <InversionListGap offset="large">
          <FooterLeadingText fontFamily="secondary" size="md">
            {t('footer.have_questions')}
          </FooterLeadingText>
          <StyledMailTo to={Links.mailTo}>support@liquidmining.com</StyledMailTo>
        </InversionListGap>
        <GetAppListGap offset="large">
          <FooterLeadingText fontFamily="secondary" size="md" align="left">
            {t('footer.get_app')}
          </FooterLeadingText>
          <ButtonLine>
            <MobileLinkButton to={Links.downloadAppAndroid} size="large" color="secondary">
              <Icon name="playMarket" />
              {t('mobile_preview_block.android')}
            </MobileLinkButton>
            <MobileLinkButton to={Links.downloadAppIOS} size="large" color="secondary">
              <Icon name="appStore" />
              {t('mobile_preview_block.ios')}
            </MobileLinkButton>
          </ButtonLine>
        </GetAppListGap>
      </ListGap>
      <QuestionBlock offset="extraLarge">
        <ListGap offset="large">
          <FooterLeadingText fontFamily="secondary" size="md" align="left">
            {t('footer.have_questions')}
          </FooterLeadingText>
          <P size="md" align="left">
            support@liquidmining.com
          </P>
        </ListGap>
        <ListGap offset="large">
          <FooterLeadingText fontFamily="secondary" size="md" align="left">
            {t('footer.get_app')}
          </FooterLeadingText>
          <ButtonLine>
            <MobileLinkButton to={Links.downloadAppAndroid} size="large" color="secondary">
              <Icon name="playMarket" />
              {t('mobile_preview_block.android')}
            </MobileLinkButton>
            <MobileLinkButton to={Links.downloadAppIOS} size="large" color="secondary">
              <Icon name="appStore" />
              {t('mobile_preview_block.ios')}
            </MobileLinkButton>
          </ButtonLine>
        </ListGap>
      </QuestionBlock>
    </FooterWrap>
  );
};

export default Footer;
