import React from 'react';
import { useTranslation } from 'react-i18next';
import { AboutLocaleType } from 'pages/Landing/types';
import { NavigationItemWrapper, NavigationLabel } from './style';
import DynamicCircle from '../DynamicCircle';

type NavigationItemProps = {
  label: AboutLocaleType;
  active?: boolean;
  onCLick?: () => void;
};

const NavigationItem: React.FC<NavigationItemProps> = ({ label, active, onCLick }) => {
  const { t } = useTranslation('landing');
  const aboutLocaleLinks = `about_block.links`;
  return (
    <NavigationItemWrapper onClick={onCLick}>
      <NavigationLabel $active={active} size="md">
        {t(`${aboutLocaleLinks}.${label}`)}
      </NavigationLabel>
      <DynamicCircle active={active} />
    </NavigationItemWrapper>
  );
};

export default NavigationItem;
