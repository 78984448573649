import { styled } from 'styled-components';
import colors from 'theme/colors';
import media from 'theme/media';

export const NavLinks = styled.nav`
  display: flex;
  gap: 2.5rem;
  align-items: center;

  @media (max-width: ${media.tablet}px) {
    display: none;
  }
`;

export const NavLink = styled.p`
  color: ${colors.white};
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;

  transition: color 0.3s ease;

  &:hover {
    color: ${colors.yellow};
  }
`;

export const HeaderWrap = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.75rem;

  @media (max-width: ${media.tablet}px) {
    padding: 3.75rem 2.5rem;
  }

  @media (max-width: ${media.mobile}px) {
    padding: 3.75rem 1rem;
  }
`;
