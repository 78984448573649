export const ApiEndpointsSpring = {
  shares: '/api/v2/merkletree/dto/shares',
  merkleTree: '/api/v2/merkletree/matrix',
  forexPool: '/api/v1/whitelist/forex/username',
  eventPool: '/api/v1/whitelist/event/username',
  tokenPool: '/api/v1/whitelist/token/username',
  payoutsTimeline: '/api/v1/rewards/timeline',
  raceTreeMatrix: 'api/v1/event/tree/matrix',
  getLeaderBoard: 'api/v1/event/leaderboard/leader',
  getAffiliateBoard: 'api/v1/event/leaderboard/seller',
  sendLeaderForm: 'api/v1/event/form/leader',
  sendAffiliateForm: 'api/v1/event/form/seller',
  treeAdd: 'api/v1/event/tree/add',
  optionCheck: '/option/check/',
  confirmUser: (userName: string) => `api/v1/event/confirm/${userName}`,
  formCheck: (userName: string) => `api/v1/event/form/check/${userName}`,
} as const;
