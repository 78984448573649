import { Desc } from 'pages/Landing/style';
import { styled } from 'styled-components';
import media from 'theme/media';
import offsets from 'theme/offsets';
import { Flex } from 'ui';
import { H3 } from 'ui/Text';

export const InfoBlockWrapper = styled.article`
  padding: 0 ${offsets.sectionHorizontal} 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: ${media.laptop}px) {
    padding: 0 3.75rem 5rem;
  }

  @media (max-width: ${media.tablet}px) {
    grid-template-columns: 1fr;
    padding: 0 2.5rem 3.75rem;
  }

  @media (max-width: ${media.mobile}px) {
    grid-template-columns: 1fr;
    padding: 0 1rem 3.75rem;
  }
`;

export const ValuesFlex = styled(Flex)`
  width: 100%;
`;

export const ValuesWrapper = styled(Flex)`
  @media (max-width: ${media.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ValueLabel = styled(Desc)`
  text-align: right;
  align-self: center;

  @media (max-width: ${media.tablet}px) {
    align-self: flex-start;
    text-align: left;
  }
`;

export const NumberCount = styled(H3)`
  font-size: 2.5rem;
  @media (max-width: ${media.laptop}px) {
    font-size: 1.5rem;
  }

  @media (max-width: ${media.mobile}px) {
    font-size: 1.75rem;
  }
`;
