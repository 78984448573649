import { ApiEndpoints, instance } from 'services/client';
import { TActiveNft, TSingleNft } from '../../../types/dataCenter';

class DataCenterService {
  static async getAllNft() {
    const response: TSingleNft[] | null = await instance.get(ApiEndpoints.v2.dataCenter.allItems, {});

    return response;
  }

  static async getSingleNft(id: number, type: string) {
    const urlPiece = type === 'asics' ? 'rigs' : type;
    const response = await instance.get(`${ApiEndpoints.v2.mock_version}${urlPiece}/${id}/`);

    return response;
  }

  static async updateNft(body: Partial<TActiveNft>, type: string, id: number) {
    const urlPiece = type === 'asics' ? 'rigs' : type;
    const response = await instance.patch(`${ApiEndpoints.v2.mock_version}${urlPiece}/${id}/`, body);

    return response;
  }

  static async getCoinsRequest() {
    const response = await instance.get(`${ApiEndpoints.v2.dataCenter.get_coins}`);

    return response;
  }

  static async getCoinRequest(id: string) {
    const url = `${ApiEndpoints.v2.dataCenter.get_coin}${id}/`;
    const response = await instance.get(url);

    return response;
  }
}

export default DataCenterService;
