import Cookies from 'js-cookie';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';
import { AppNavigate } from 'components/AppComponents';
import { AppRoutes } from 'router';
import React, { FC, ReactNode } from 'react';
import { useUserSelector } from 'store';

export const AuthWithoutPaymentProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { paymentService } = useUserSelector();
  const token = Cookies.get(LocalStorageKeys.TOKEN_KEY) && Cookies.get(LocalStorageKeys.REFRESH_KEY);

  if (token && paymentService) {
    return <AppNavigate to={AppRoutes.MARKET_ALL} replace />;
  }

  return children;
};
