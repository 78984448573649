import styled from 'styled-components';
import { Flex, H2, P } from 'ui';
import { media } from 'theme';

export const CalculatorHeader = styled(H2)`
  font-size: 3rem;
  letter-spacing: 0.12rem;

  @media (max-width: ${media.laptop}px) {
    font-size: 2.25rem;
  }

  @media (max-width: ${media.mobile}px) {
    font-size: 2rem;
  }
`;

export const DurabilityTitle = styled(P)`
  line-height: 140%;
  font-weight: 400;
`;

export const CalculatorDesc = styled(P)`
  max-width: 37.5rem;
  line-height: 150%;
  font-style: normal;
`;

export const TabsWrapper = styled(Flex)`
  overflow-x: auto;
`;

export const WrapperCalculatedContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
`;
