import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TActiveNft, ICoin, TSingleNft, LiquidStcCollectionType } from 'src/types/dataCenter';
import { getEquipmentArray } from 'utils/getEquipmentArray';
import { getAllNft, getCoinsRequest, getSingleNft, updateNft } from './actionsAsync';

type TInitialState = {
  activeNft: TSingleNft[];
  inactiveNft: TSingleNft[];
  allNft: TSingleNft[];
  isLoading: boolean;
  activeSingle: TActiveNft;
  coins: ICoin[];
  config: string[];
  isDCUpdate: boolean;
  isSocketOpen: boolean;
  socketNumber: number;
};

const initialState: TInitialState = {
  activeNft: [],
  inactiveNft: [],
  allNft: [],
  isLoading: false,
  coins: [],
  isDCUpdate: false,
  activeSingle: {
    id: 0,
    boost: false,
    status: 0,
    contract: {
      address: '',
      id: 0,
      image: null,
      name: '',
      price: 0,
    },
    collection_name: '',
    durability: 0,
    max_durability: 0,
    energy_consumption: 0,
    accessible_crypto_for_mining: [],
    energy_diagram_percent: 0,
    mining_now_data: [],
    power: 0,
    is_asic: false,
    created_at: '',
    used: false,
    name: '',
    free_boost_ts: 0,
    components_count: 0,
    image_link: '',
  },
  config: [''],
  socketNumber: 0,
  isSocketOpen: false,
};

const dataCenterSlice = createSlice({
  name: 'data-center',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<string[]>) => {
      state.config = action.payload;
      state.isSocketOpen = true;
    },
    setSocketNumber: (state, action: PayloadAction<number>) => {
      state.socketNumber = action.payload;
    },
    setDCUpdate: (state, action: PayloadAction<boolean>) => {
      state.isDCUpdate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNft.fulfilled, (state, action) => {
        state.activeNft = action.payload.active;
        state.inactiveNft = action.payload.inactive;
        state.allNft = action.payload.allNfts.map((nft) => {
          if (nft.collection_name === LiquidStcCollectionType) {
            nft.sortOption = 'short';
          } else {
            nft.sortOption = nft.url_piece;
          }

          return nft;
        });
      })
      .addCase(getSingleNft.fulfilled, (state, action) => {
        state.activeSingle = { ...action.payload, insideArray: getEquipmentArray(action.payload) };
      })
      .addCase(updateNft.fulfilled, (state, action) => {
        state.activeSingle = { ...action.payload, insideArray: getEquipmentArray(action.payload) };
      })
      .addCase(getCoinsRequest.fulfilled, (state, action) => {
        state.coins = action.payload;
      })
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('data-center') && type.endsWith('/pending');
        },
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('data-center') && type.endsWith('/rejected');
        },
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('data-center') && type.endsWith('/fulfilled');
        },
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const { setConfig, setSocketNumber, setDCUpdate } = dataCenterSlice.actions;

export default dataCenterSlice.reducer;
