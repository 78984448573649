import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { TActiveNft, ICoin, TSingleNft, ICryptoCurrency } from 'src/types/dataCenter';
import { getSimpleErrorMessage } from 'utils/error';

export const getAllNft = createAsyncThunk<
  { active: TSingleNft[]; inactive: TSingleNft[]; allNfts: TSingleNft[] },
  void,
  ThunkApiConfig
>('data-center/get-all', async (_, { extra: services, rejectWithValue }) => {
  const response: any = await services.httpService.DataCenterService.getAllNft();

  if (response.isError) {
    const message = getSimpleErrorMessage(response.data);

    return rejectWithValue({ message, isError: true });
  }
  const active = response.data.results.filter((el: TSingleNft) => el.status && !el.used);
  const inactive = response.data.results.filter(
    (el: TSingleNft) => !el.status && !el.used && (el.components_count > 0 || el.url_piece === 'asics'),
  );
  const allNfts = response.data.results;
  return {
    active,
    inactive,
    allNfts,
  };
});

export const getSingleNft = createAsyncThunk<
  Omit<TActiveNft, 'insideArray'>,
  { id: number; type: string },
  ThunkApiConfig
>('data-center/get-single', async ({ id, type }, { extra: services, rejectWithValue }) => {
  const response: any = await services.httpService.DataCenterService.getSingleNft(id, type);

  if (response.isError) {
    const message = getSimpleErrorMessage(response.response.data);
    return rejectWithValue({ message, isError: true });
  }

  return response.data;
});

export const updateNft = createAsyncThunk<
  Omit<TActiveNft, 'insideArray'>,
  { id?: number; type?: string; body: Partial<TActiveNft> },
  ThunkApiConfig
>('data-center/update-nft', async ({ id, type, body }, { extra: services, rejectWithValue }) => {
  if (!id || !type) return {};
  const response: any = await services.httpService.DataCenterService.updateNft(body, type, id);

  if (response.isError) {
    const message = getSimpleErrorMessage(response.response.data);
    return rejectWithValue({ message, isError: true });
  }

  return response.data;
});

export const getCoinsRequest = createAsyncThunk<ICoin[], void, ThunkApiConfig>(
  'data-center/get-coins',
  async (_: void, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.DataCenterService.getCoinsRequest();

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data.results;
  },
);

export const getCoinRequest = createAsyncThunk<ICryptoCurrency, string, ThunkApiConfig>(
  'data-center/get-coin',
  async (coinId, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.DataCenterService.getCoinRequest(coinId);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);
