import styled from 'styled-components';
import { Button, Flex, H2, Icon, Input, P } from 'ui';
import { colors, SkeletonStyle } from 'theme';

export const H2Styled = styled(H2)`
  font-size: 1rem;
  text-transform: unset;
  letter-spacing: 0.1rem;
`;

export const StyledIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.bag.textColor};
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.bag.textHoverColor};
  }
`;

export const CardStyled = styled(Flex)`
  border-radius: 0.75rem;
  border: 2px solid ${colors.buttonSecondaryStroke};
  background: ${colors.transparent};
  padding: 1.5rem 1rem;
`;

export const WalletStyled = styled(P)`
  line-height: 140%;
`;

export const StyledTitle = styled(P)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

export const StyledStatus = styled(P)`
  color: ${colors.yellow};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 100%;
`;

export const StyledCoinName = styled(P)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export const StyledIconCoin = styled(StyledIcon)`
  border-radius: 50%;
`;

export const BalanceStyledIconCoin = styled(StyledIconCoin)`
  width: 2.5rem;
  height: 2.5rem;
`;

export const CurrencyBlock = styled(Flex)`
  gap: 0.25rem;
`;

export const ConfirmButton = styled(Button)`
  position: sticky;
  bottom: 0;
`;

export const BalanceValue = styled(P)<{ $isLoading: boolean }>`
  ${({ $isLoading }) => $isLoading && SkeletonStyle({ width: '8rem', height: '1.2rem', borderRadius: '0.25rem' })};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  white-space: pre;
`;

export const StyledEmailButton = styled(Button)`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
`;

export const EmailInput = styled(Input)<{ $fullyDisabled?: boolean }>`
  & input {
    max-width: ${({ $fullyDisabled }) => ($fullyDisabled ? '250px' : '210px')};
  }
`;
