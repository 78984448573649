import { styled } from 'styled-components';
import { font, colors } from 'theme';

const StyledChapter = styled.div<{ $active?: boolean; $isNotTouchDevice?: boolean }>`
  padding: 10px 10px 10px 0;
  font-family: ${font.primary};
  font-size: 0.938rem;
  font-weight: 600;
  color: ${({ $active }) => ($active ? colors.yellow : colors.white)};
  cursor: pointer;
  transition: color 0.3s ease;
  ${({ $isNotTouchDevice }) =>
    $isNotTouchDevice &&
    `&:hover {
    color: ${colors.yellow};
  }`}
`;

export default StyledChapter;
