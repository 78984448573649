import React from 'react';
import media from 'theme/media';
import { nanoid } from '@reduxjs/toolkit';
import { SwiperSlide, Swiper } from 'swiper/react';

import asicsData from 'pages/Landing/constants';

import SliderItem from '../SliderItem';
import { SwiperWrapper } from '../../style';

import 'swiper/css';

const Slider: React.FC = () => (
  <SwiperWrapper>
    <Swiper
      loop
      centeredSlides
      spaceBetween={16}
      slideToClickedSlide
      slidesPerView={3.8}
      breakpoints={{
        [media.start]: {
          slidesPerView: 1.3,
          spaceBetween: 20,
        },
        [media.xs]: {
          slidesPerView: 1.3,
          spaceBetween: 40,
        },
        [media.mobile]: {
          slidesPerView: 1.3,
          spaceBetween: 40,
        },
        [media.tablet]: {
          slidesPerView: 1.7,
        },
        [media.laptop]: {
          slidesPerView: 2.5,
        },
        [media.desktop]: {
          slidesPerView: 3.3,
        },
      }}
    >
      {asicsData.map((asic) => (
        <SwiperSlide key={nanoid()}>{({ isActive }) => <SliderItem {...asic} active={isActive} />}</SwiperSlide>
      ))}
    </Swiper>
  </SwiperWrapper>
);
export default Slider;
