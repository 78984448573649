import { createSlice } from '@reduxjs/toolkit';
import { IGemsStore } from './type';
import getGemsTransactions from './actionsAsync';

const initialState: IGemsStore = {
  transactions: [],
  dateValue: '',
  dateRangeValue: '',
  isGemsTransactionLoaded: false,
};

const gemsSlice = createSlice({
  name: 'gem',
  initialState,
  reducers: {
    setDateValue: (state, action) => {
      state.dateValue = action.payload;
      state.dateRangeValue = '';
      state.isGemsTransactionLoaded = false;
    },
    setDateRangeValue: (state, action) => {
      state.dateRangeValue = action.payload;
      state.dateValue = '';
      state.isGemsTransactionLoaded = false;
    },
    clearGemsState: (state) => {
      state.transactions = [];
      state.dateValue = '';
      state.dateRangeValue = '';
      state.isGemsTransactionLoaded = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGemsTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload.results;
      state.isGemsTransactionLoaded = true;
    });
  },
});

export default gemsSlice.reducer;

export const { setDateValue, setDateRangeValue, clearGemsState } = gemsSlice.actions;
