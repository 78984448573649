import { ApiEndpoints, instance } from 'services/client';
import {
  ChangeAmountMarketObjectBody,
  CreateMarketBuilderObjectBody,
  CreateMarketObjectBody,
  DeleteMarketObjectBody,
  EnergyObject,
  MarketObjectBase,
  ProductCodes,
  UpdateMarketObjectBody,
} from 'store/slices/bag/types';

class CartService {
  static async getMarketInitialState() {
    const response = await instance.get<{ count: number; results: MarketObjectBase[] }>(
      `${ApiEndpoints.v2.cart.marketItems}`,
      {
        params: {
          is_purchased: false,
        },
      },
    );

    return response;
  }

  static async addProductToCart(product: CreateMarketObjectBody) {
    const response = await instance.post(`${ApiEndpoints.v2.cart.addProduct}`, {
      count: product.count,
      product: product.id,
    });

    return response;
  }

  static async getVaultCartCommission(use_gems: boolean) {
    const response = await instance.post(`${ApiEndpoints.v2.cart.getVaultCommission}`, { use_gems });

    return response;
  }

  static async changeAmountMarketObject({ id, count }: ChangeAmountMarketObjectBody) {
    const response = await instance.put(`${ApiEndpoints.v2.cart.marketItems}${id}/`, {
      count,
      is_purchased: false,
    });

    return response;
  }

  static async updateMarketObject({ id }: UpdateMarketObjectBody) {
    const response = await instance.put(`${ApiEndpoints.v2.cart.marketItems}${id}/`, {
      is_purchased: true,
    });

    return response;
  }

  static async deleteMarketObject(id: DeleteMarketObjectBody) {
    const response = await instance.delete(`${ApiEndpoints.v2.cart.marketItems}${id}/`);

    return response;
  }

  static async getEnergyInitialState() {
    const response = await instance.get<{ count: number; results: EnergyObject[] }>(`${ApiEndpoints.v2.cart.energy}`);

    return response;
  }

  static async addProductCodeItemToCart(count: number, product_code: ProductCodes) {
    const response = await instance.post(`${ApiEndpoints.v2.cart.addProductCode}`, {
      count,
      product_code,
    });

    return response;
  }

  static async deleteBuilderItem(id: number) {
    const response = await instance.delete(`${ApiEndpoints.v2.builder.mainUrl}${id}/`);

    return response;
  }

  static async updateBuilderItem(
    id: number,
    body: {
      is_purchased: boolean;
      is_cart: boolean;
    },
  ) {
    const response = await instance.patch(`${ApiEndpoints.v2.builder.mainUrl}${id}/`, {
      is_purchased: body.is_purchased,
      is_cart: body.is_cart,
    });

    return response;
  }

  static async getSignatureGems(gems: number) {
    const response = await instance.post(`${ApiEndpoints.v2.cart.getGemsSignature}`, {
      gems,
    });

    return response;
  }

  static async purchase(use_gems: boolean) {
    const response = await instance.post(`${ApiEndpoints.v2.cart.purchase}`, {
      use_gems,
    });

    return response;
  }
}

export default CartService;
