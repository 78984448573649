import { ControllerBaseLayout } from 'router/ControllerBaseLayout';
import React from 'react';
import { Rewards } from 'pages';
import PrivateRouteProvider from '../../components/AuthComponents/AuthProvider/PrivateRouteProvider';

export const rewardsRoute = {
  path: 'rewards',
  element: (
    <ControllerBaseLayout bg="secondary" isHaveButtonBack={false}>
      <PrivateRouteProvider>
        <Rewards />
      </PrivateRouteProvider>
    </ControllerBaseLayout>
  ),
  children: [],
};
