import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { NewsResponseType, NewsCategoriesResponseType } from 'types/news';
import { NewsCategoryType, NewsType } from 'store/slices/news/types';

export const getNews = createAsyncThunk<NewsResponseType, boolean, ThunkApiConfig>(
  'news',
  async (isMobileDevice, { extra: services, rejectWithValue, getState }) => {
    const { news } = getState();
    const response: any = await services.httpService.NewsService.getAllNews(
      news.activeCategory,
      news.page,
      isMobileDevice,
    );
    if (response.isError) {
      return rejectWithValue(response.data.results);
    }

    return response.data;
  },
);

export const getTopicArticle = createAsyncThunk<NewsType, number, ThunkApiConfig>(
  'news/topic',
  async (articleID, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.NewsService.getTopicNews(articleID);
    if (response.isError) {
      return rejectWithValue(response.data.results);
    }

    return response.data;
  },
);

export const getOtherNews = createAsyncThunk<NewsResponseType, NewsCategoryType, ThunkApiConfig>(
  'news/other',
  async (category, { extra: services, rejectWithValue, getState }) => {
    const { news } = getState();
    const response: any = await services.httpService.NewsService.getAllNews(category);
    if (response.isError) {
      return rejectWithValue(response.data.results);
    }

    return response.data;
  },
);

export const getNewsCategories = createAsyncThunk<NewsCategoriesResponseType, void, ThunkApiConfig>(
  'news/categories',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.NewsService.getAllCategories();
    if (response.isError) {
      return rejectWithValue(response.data.results);
    }

    return response.data;
  },
);
