import { css, DefaultTheme, styled } from 'styled-components';
import Tooltip from 'ui/Tooltip';

const Sizes = {
  md: css`
    width: 100%;
    height: 100%;
  `,
  lg: css`
    width: 100%;
    height: 7.5rem;
  `,
} as const;

export const ChartWrapper = styled.div<{ $size?: keyof typeof Sizes }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

export const ChartSVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CoverSVG = styled.div`
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
  flex-shrink: 0;
`;

const colorPick = (percent: number, color: 'primary' | 'secondary', theme: DefaultTheme) => {
  if (color === 'primary') return theme.circleProgress.primaryColor;
  switch (color === 'secondary') {
    case percent >= 70:
      return theme.circleProgress.fullColor;
    case percent >= 50:
      return theme.circleProgress.warningColor;
    case percent >= 20:
      return theme.circleProgress.lowColor;
    default:
      return theme.circleProgress.dangerColor;
  }
};

export const CircleSvg = styled.circle<{
  $percent: number;
  $color: 'primary' | 'secondary';
  $width: number;
}>`
  fill: none;
  stroke: ${({ $color, theme, $percent }) => colorPick($percent, $color, theme)};
  stroke-dasharray: 450;
  stroke-dashoffset: ${({ $percent, $width }) => 450 - ((450 - (468 - $width * 3.2)) * $percent) / 100};
  stroke-linecap: round;
  cx: 50%;
  cy: 50%;
  r: 49%;
  ${({ $width }) => css`
    cx: ${$width / 2}px;
    cy: ${$width / 2}px;
    r: ${$width / 2 - 2}px;
  `}
  stroke-width: 2px;
  transition: stroke-dashoffset 0.3s linear;
`;

export const CircleSvgBg = styled.circle<{
  $width: number;
}>`
  fill: none;
  stroke-width: 2px;
  ${({ $width }) => css`
    cx: ${$width / 2}px;
    cy: ${$width / 2}px;
    r: ${$width / 2 - 2}px;
  `}
  stroke: ${({ theme }) => theme.circleProgress.bgColor};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.75rem;
`;

export const StyledTooltip = styled(Tooltip)`
  width: unset;
`;
