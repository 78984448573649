import { configureStore } from '@reduxjs/toolkit';
import { ExtraArgumentType } from 'services/client/types';
import { httpService } from 'services/client';
import { httpServiceSpring } from 'src/services/Spring/client';
import { httpServiceVault } from 'src/services/Vault/client';
import liquidationLayoutSlice from 'store/slices/liquidationLayoutSlice';
import faqSlice from './slices/faq/faqSlice';
import themeSlice from './slices/themeSlice';
import userSlice from './slices/user/userSlice';
import baseLayoutSlice from './slices/baseLayoutSlice';
import authLayoutSlice from './slices/authLayoutSlice';
import bagSlice from './slices/bag/bagSlice';
import dataCenterSlice from './slices/data-center/dataCenterSlice';
import powerGridSlice from './slices/powerGrid/powerGridSlice';
import rewardsSlice from './slices/rewards/rewardsSlice';
import RaceSlice from './slices/race/raceSlice';
import BalanceSlice from './slices/balance/balanceSlice';
import builderSlice from './slices/builder/builderSlice';
import MarketplaceSlice from './slices/marketplace/marketplaceSlice';
import gemsSlice from './slices/gems/gemsSlice';
import newsSlice from './slices/news/newsSlice';
import liquidationSlice from './slices/liquidation/liquidationSlice';
import achievementSlice from './slices/achievements/achievementsSlice';
import myNftsSlice from './slices/my-nfts/myNftsSlice';
import notificationSlice from './slices/notification/notificationSlice';
import calculatorSlice from './slices/calculator/calculatorSlice';
import shortContractSlice from './slices/short-contract/shortContractSlice';
import vaultSlice from './slices/vault/vaultSlice';

export const store = configureStore({
  reducer: {
    theme: themeSlice,
    user: userSlice,
    baseLayout: baseLayoutSlice,
    liquidationLayout: liquidationLayoutSlice,
    authLayout: authLayoutSlice,
    bag: bagSlice,
    dataCenter: dataCenterSlice,
    powerGrid: powerGridSlice,
    rewards: rewardsSlice,
    race: RaceSlice,
    balance: BalanceSlice,
    marketplace: MarketplaceSlice,
    builder: builderSlice,
    news: newsSlice,
    gems: gemsSlice,
    liquidation: liquidationSlice,
    faq: faqSlice,
    achievement: achievementSlice,
    myNfts: myNftsSlice,
    notification: notificationSlice,
    calculator: calculatorSlice,
    shortContract: shortContractSlice,
    vault: vaultSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware<ExtraArgumentType>({
      thunk: {
        extraArgument: { httpService, httpServiceSpring, httpServiceVault },
      },
    }),
  enhancers: [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
