import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { InitStateType } from 'store/slices/short-contract/type';
import { getSimpleErrorMessage } from 'utils/error';
import { SCProductType } from 'types/marketplace';

export const getShortsContract = createAsyncThunk<Omit<InitStateType, 'isLoading'>, void, ThunkApiConfig>(
  'shortContract/init-state',
  async (_, { extra: { httpService }, rejectWithValue }) => {
    const responseShortContract: any = await httpService.MarketPlaceService.getProductShortsContractPage();

    if (responseShortContract.isError) {
      const message = getSimpleErrorMessage(responseShortContract.data);

      return rejectWithValue({ message, isError: true });
    }

    const { data } = responseShortContract;

    return {
      durabilityOneMonth: data.products
        .filter((el: SCProductType) => el.month_durability === 1)
        .sort((a: SCProductType, b: SCProductType) => a.price - b.price),
      durabilityThreeMonth: data.products
        .filter((el: SCProductType) => el.month_durability === 3)
        .sort((a: SCProductType, b: SCProductType) => a.price - b.price),
      banner: data.sections[0],
      getToKnow: data.sections[1],
    };
  },
);
