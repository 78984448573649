import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const AppLink: React.FC<PropsWithChildren<LinkProps>> = ({ children, ...props }) => {
  // const { i18n } = useTranslation();
  // const lang = i18n.language.split('-')[0];
  const isExternal = typeof props.to === 'string' && props.to.includes('http');

  return (
    <Link {...props} to={`${props.to}`}>
      {children}
    </Link>
  );
};

export default AppLink;
