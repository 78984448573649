import { ApiEndpoints, instance } from 'services/client';
import { AxiosResponse } from 'axios';
import { ConsumptionSetupsResponseType, ConsumptionSetupsType } from '../../../types/powerGrid';

class PowerGridService {
  static async getPowerGrid() {
    const response = await instance.get(`${ApiEndpoints.v2.powerGrid.powerGridGeneral}`);

    return response;
  }

  static async getPowerGridMostEnergyNft() {
    const response = await instance.get(`${ApiEndpoints.v2.powerGrid.mostEnergyNft}`);

    return response;
  }

  static async getEnergyGraph(year: number) {
    const response = await instance.get(`${ApiEndpoints.v2.powerGrid.energyGraph}?year=${year}`);

    return response;
  }

  static async getConsumptionSetups(): Promise<AxiosResponse<ConsumptionSetupsType[]>> {
    const response = await instance.get<ConsumptionSetupsType[], AxiosResponse<ConsumptionSetupsType[]>>(
      `${ApiEndpoints.v2.powerGrid.consumptionSetups}`,
      {},
    );

    return response;
  }
}

export default PowerGridService;
