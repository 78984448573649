import styled from 'styled-components';
import { Icon } from 'ui';

interface ComponentWrapperProps {
  inline?: boolean;
  $width?: string;
}

export const ComponentWrapper = styled.div<ComponentWrapperProps>`
  position: relative;
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  width: ${({ $width = '100%' }) => $width};
`;

export interface TooltipPosition {
  $top?: string;
  $left?: string;
  $bottom?: string;
  $right?: string;
  $arrowOnTop?: boolean;
  $arrowLeft?: number;
  $arrowRight?: number;
  $maxWidth?: string;
  $width?: string;
}

export const TooltipWrapper = styled.span<TooltipPosition>`
  top: ${({ $top }) => $top || 'unset'};
  left: ${({ $left }) => $left || 'unset'};
  bottom: ${({ $bottom }) => $bottom || 'unset'};
  right: ${({ $right }) => $right || 'unset'};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  min-height: 2.75rem;
  border: 1px solid ${({ theme }) => theme.tooltip.borderColor};
  border-radius: 8px;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.tooltip.textColor};
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
  padding: 0.75rem 1.25rem;
  width: ${({ $width }) => $width || 'unset'};
  max-width: ${({ $maxWidth }) => $maxWidth || 'unset'};
  z-index: 2147483002;

  &::before {
    content: '';
    position: absolute;
    left: ${({ $arrowLeft }) => ($arrowLeft !== undefined ? `${$arrowLeft}px` : 'unset')};
    right: ${({ $arrowRight }) => ($arrowRight !== undefined ? `${$arrowRight}px` : 'unset')};
    top: ${({ $arrowOnTop }) => ($arrowOnTop ? '0%' : '100%')};
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.tooltip.backgroundColor};
    border-left: 1px solid ${({ theme }) => theme.tooltip.borderColor};
    border-bottom: 1px solid ${({ theme }) => theme.tooltip.borderColor};
    transform: ${({ $arrowOnTop }) =>
      $arrowOnTop ? 'translateY(-6px) rotate(-225deg)' : 'translateY(-5px) rotate(-45deg)'};
    clip-path: polygon(0 -20%, 120% 100%, 0 100%);
    z-index: 2;
  }
`;

export const TooltipIcon = styled(Icon)`
  margin-right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
`;
