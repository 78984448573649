import { useAppDispatch, useCalculatorSelector } from 'store';
import { Flex } from 'ui';
import { CalculatorDesc, CalculatorHeader } from 'widgets/Calculator/Calculator.styled';
import { Calculator } from 'widgets/Calculator';
import { CalculatorWrapper } from 'pages/Landing/style';
import React, { useEffect } from 'react';
import { getCalculatorDurability } from 'store/slices/calculator/actionAsync';
import { useActions } from 'hooks/useActions';

export const CalculatorSection = () => {
  const { durability } = useCalculatorSelector();
  const { clearStateCalculator } = useActions();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCalculatorDurability());

    return () => {
      dispatch(clearStateCalculator());
    };
  }, []);

  if (!durability.length) return null;

  return (
    <CalculatorWrapper $gap="xl" $align="flex-start" $justify="flex-start" $direction="column">
      <Flex $gap="form" $direction="column" $align="flex-start" $justify="flex-start">
        <CalculatorHeader>Profitability Calculator</CalculatorHeader>
        <CalculatorDesc size="md" color="secondary" align="left">
          Easily determine potential income, hashrate, energy consumption, durability and estimated electricity expenses
          by entering the details of the corresponding NFT contract
        </CalculatorDesc>
      </Flex>
      <Calculator />
    </CalculatorWrapper>
  );
};
