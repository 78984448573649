import { styled } from 'styled-components';
import { Flex, Button, P } from 'ui';

export const PhoneVerifyStyled = styled(Flex)``;

export const CustomButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.primary};
  margin-bottom: 1.5rem;
  font-size: 1rem;
`;

export const CustomText = styled(P)`
  margin-bottom: 1.5rem;
  cursor: not-allowed;
`;
