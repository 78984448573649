export enum AchievementCategories {
  coins = 'coins',
  mining = 'mining',
  event = 'event',
  fresh_start = 'fresh_start',
  energy = 'energy',
  referral_system = 'referral_system',
  collection = 'collection',
}

export enum AchievementSortingOptions {
  descending = 'Descending progress',
  ascending = 'Ascending progress',
}

export type AchievementType = {
  id: number;
  name: string;
  desc: string;
  parsedDesc: string;
  value: string;
  gem_reward: number;
  achievement: number;
  image_link: string;
  video_link: string;
};

export type AchievementSerializerType = {
  id: number;
  items: AchievementType[];
  completed_items: AchievementType[];
  progress: string;
  count_total_items: number;
  count_completed_items: number;
  category: AchievementCategories;
  image: string;
  levelProgress: number;
};

export type AchievementStatisticType = {
  count_total: number;
  count_completed: number;
  amount_total_gems: number;
  amount_received_gems: number;
};

export type AchievementStoreType = {
  achievements: AchievementSerializerType[];
  isAchievementsLoaded: boolean;
  statistic: AchievementStatisticType;
  activeAchievementCategory: AchievementCategories | null;
  activeAchievementSortOption: AchievementSortingOptions | null;
};
