import { ApiEndpointsSpring, instanceSpring } from 'src/services/Spring/client';
import { checkLiquidWalletAuth } from 'services/Spring/UserService/utils';
import { CheckUsernameResponse, CheckWalletResponse } from 'services/Spring/UserService/types';

class UserService {
  static async checkAuthWalletRequest(wallet: string): Promise<CheckWalletResponse> {
    const {
      data: { username, isVerified },
    } = await instanceSpring.get(`${ApiEndpointsSpring.optionCheck}${wallet}`);
    const authStatus = checkLiquidWalletAuth(isVerified);
    return { ...authStatus, username };
  }

  static async checkAuthUsernameRequest(username: string): Promise<CheckUsernameResponse> {
    const { data } = await instanceSpring.get(`${ApiEndpointsSpring.optionCheck}username/${username}`);

    return data;
  }
}

export default UserService;
