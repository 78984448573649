import React, { useId, useState } from 'react';
import { Icon } from 'ui';
import { Controller } from 'react-hook-form';
import { CheckboxInput, CheckboxWrapper, CheckboxStyledInput, CheckboxLabel } from './Checkbox.styled';

export interface CheckboxProps {
  className?: string;
  label?: string;
  name: string;
  control?: any;
}

export const Checkbox: React.FC<CheckboxProps> = ({ control, ...props }) => {
  const id = useId();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <CheckboxWrapper className={props.className} htmlFor={id}>
          <CheckboxInput type="checkbox" {...field} id={id} />
          <CheckboxStyledInput>
            <Icon name="checked" />
          </CheckboxStyledInput>
          {props.label && <CheckboxLabel>{props.label}</CheckboxLabel>}
        </CheckboxWrapper>
      )}
    />
  );
};

export interface CheckboxNotControlledProps {
  onChange: (state: boolean) => void;
  defaultValue?: boolean;
  className?: string;
  label?: string;
}

export const CheckboxState: React.FC<CheckboxNotControlledProps> = ({ defaultValue = false, ...props }) => {
  const id = useId();
  const [checked, setChecked] = useState<boolean>(defaultValue);

  const onChange = () => {
    props.onChange(!checked);
    setChecked(!checked);
  };

  return (
    <CheckboxWrapper className={props.className} htmlFor={id}>
      <CheckboxInput type="checkbox" defaultChecked={checked} onChange={onChange} id={id} />
      <CheckboxStyledInput>
        <Icon name="checked" />
      </CheckboxStyledInput>
      {props.label && <CheckboxLabel>{props.label}</CheckboxLabel>}
    </CheckboxWrapper>
  );
};
