import React from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery';
import { IMAGE_URL } from 'assets/Urls';
import { ElementRow, TokenSection, Card, CardInner, TokenIcon, CardTitle, CardText, ElementRowToken } from './style';
import { titles, tokens } from './constants';
import SchemePattern from './components/SchemePattern';

const Tokens: React.FC = () => {
  const { t } = useTranslation('landing');
  const { isMobile, isTablet, isLaptop } = useMediaQuery();
  const fuckingIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line no-nested-ternary
      width={isLaptop ? '27' : isMobile ? '18.75' : '40'}
      // eslint-disable-next-line no-nested-ternary
      height={isLaptop ? '27' : isMobile ? '18.75' : '40'}
      viewBox="0 0 41 40"
      fill="none"
    >
      <g clipPath="url(#clip0_9112_29617)">
        <path
          d="M20.1436 40C31.1893 40 40.1436 31.0457 40.1436 20C40.1436 8.9543 31.1893 0 20.1436 0C9.09786 0 0.143555 8.9543 0.143555 20C0.143555 31.0457 9.09786 40 20.1436 40Z"
          fill="#E6ECFF"
        />
        <path d="M10.5435 20L20.1435 4V15.95L10.5435 20Z" fill="url(#paint0_linear_9112_29617)" />
        <path d="M29.7435 20L20.1435 4V15.95L29.7435 20Z" fill="url(#paint1_linear_9112_29617)" />
        <path d="M20.1435 16L10.5435 20.0269L20.1435 25.6V16Z" fill="url(#paint2_linear_9112_29617)" />
        <path d="M20.1435 16L29.7435 20.0269L20.1435 25.6V16Z" fill="url(#paint3_linear_9112_29617)" />
        <path d="M10.5435 21.6L20.1435 36V27.3996L10.5435 21.6Z" fill="url(#paint4_linear_9112_29617)" />
        <path d="M29.7435 21.6L20.1435 36V27.3996L29.7435 21.6Z" fill="url(#paint5_linear_9112_29617)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.9435 22.4V12.8H15.7435V22.4H14.9435Z" fill="#B0B4E9" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.5435 22.4V12.8H25.3435V22.4H24.5435Z" fill="#B0B4E9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0235 22.16L19.8235 15.76L20.4635 16.24L15.6635 22.64L15.0235 22.16Z"
          fill="#B0B4E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6235 22.64L19.8235 16.24L20.4635 15.76L25.2635 22.16L24.6235 22.64Z"
          fill="#B0B4E9"
        />
        <path
          d="M15.1398 14.1192C15.7981 14.1192 16.3317 13.5855 16.3317 12.9273C16.3317 12.269 15.7981 11.7354 15.1398 11.7354C14.4816 11.7354 13.9479 12.269 13.9479 12.9273C13.9479 13.5855 14.4816 14.1192 15.1398 14.1192Z"
          fill="url(#paint6_linear_9112_29617)"
        />
        <path
          d="M15.974 12.4749C15.9112 12.5448 15.7474 12.5003 15.6083 12.3755C15.4691 12.2506 15.4079 12.0924 15.4707 12.0224C15.5335 11.9525 15.6973 11.997 15.8365 12.1218C15.9756 12.2467 16.0368 12.4049 15.974 12.4749Z"
          fill="url(#paint7_radial_9112_29617)"
        />
        <path
          d="M15.6432 23.5779C16.3015 23.5779 16.8351 23.0442 16.8351 22.386C16.8351 21.7277 16.3015 21.1941 15.6432 21.1941C14.9849 21.1941 14.4513 21.7277 14.4513 22.386C14.4513 23.0442 14.9849 23.5779 15.6432 23.5779Z"
          fill="url(#paint8_linear_9112_29617)"
        />
        <path
          d="M16.4773 21.9336C16.4145 22.0035 16.2507 21.959 16.1115 21.8342C15.9724 21.7093 15.9112 21.5511 15.974 21.4811C16.0368 21.4112 16.2006 21.4557 16.3397 21.5805C16.4789 21.7054 16.5401 21.8636 16.4773 21.9336Z"
          fill="url(#paint9_radial_9112_29617)"
        />
        <path
          d="M25.3467 14.1192C26.0049 14.1192 26.5385 13.5855 26.5385 12.9273C26.5385 12.269 26.0049 11.7354 25.3467 11.7354C24.6884 11.7354 24.1548 12.269 24.1548 12.9273C24.1548 13.5855 24.6884 14.1192 25.3467 14.1192Z"
          fill="url(#paint10_linear_9112_29617)"
        />
        <path
          d="M26.1809 12.4749C26.1181 12.5448 25.9543 12.5003 25.8152 12.3755C25.676 12.2506 25.6148 12.0924 25.6776 12.0224C25.7404 11.9525 25.9042 11.997 26.0434 12.1218C26.1825 12.2467 26.2437 12.4049 26.1809 12.4749Z"
          fill="url(#paint11_radial_9112_29617)"
        />
        <path
          d="M24.7885 23.5779C25.4467 23.5779 25.9803 23.0443 25.9803 22.386C25.9803 21.7277 25.4467 21.1941 24.7885 21.1941C24.1302 21.1941 23.5966 21.7277 23.5966 22.386C23.5966 23.0443 24.1302 23.5779 24.7885 23.5779Z"
          fill="url(#paint12_linear_9112_29617)"
        />
        <path
          d="M25.6226 21.9336C25.5598 22.0036 25.396 21.959 25.2568 21.8342C25.1177 21.7094 25.0565 21.5511 25.1193 21.4812C25.1821 21.4112 25.3459 21.4557 25.485 21.5806C25.6242 21.7054 25.6862 21.8636 25.6226 21.9336Z"
          fill="url(#paint13_radial_9112_29617)"
        />
        <path
          d="M20.3517 17.2312C21.01 17.2312 21.5436 16.6976 21.5436 16.0394C21.5436 15.3811 21.01 14.8475 20.3517 14.8475C19.6935 14.8475 19.1599 15.3811 19.1599 16.0394C19.1599 16.6976 19.6935 17.2312 20.3517 17.2312Z"
          fill="url(#paint14_linear_9112_29617)"
        />
        <path
          d="M21.1859 15.587C21.1231 15.6569 20.9593 15.6124 20.8202 15.4876C20.681 15.3627 20.6198 15.2045 20.6826 15.1345C20.7454 15.0646 20.9092 15.1091 21.0484 15.2339C21.1875 15.3588 21.2495 15.517 21.1859 15.587Z"
          fill="url(#paint15_radial_9112_29617)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9112_29617"
          x1="-0.737123"
          y1="39.4719"
          x2="27.5054"
          y2="-7.58577"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3243" stopColor="#AC9CF2" />
          <stop offset="0.326" stopColor="#AC9CF2" />
          <stop offset="0.4645" stopColor="#B1B3EA" />
          <stop offset="0.6134" stopColor="#B4C3E5" />
          <stop offset="0.7803" stopColor="#B6CDE2" />
          <stop offset="1" stopColor="#B7D0E1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9112_29617"
          x1="41.0242"
          y1="39.4719"
          x2="12.7817"
          y2="-7.58577"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3243" stopColor="#B2DBF5" />
          <stop offset="0.4427" stopColor="#C4E3F4" />
          <stop offset="0.5977" stopColor="#D4EAF4" />
          <stop offset="0.7713" stopColor="#DEEFF3" />
          <stop offset="1" stopColor="#E1F0F3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9112_29617"
          x1="17.7409"
          y1="20.0004"
          x2="57.9559"
          y2="20.0004"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3243" stopColor="#D6CDFB" />
          <stop offset="0.4284" stopColor="#D9D4FA" />
          <stop offset="0.6546" stopColor="#DCDEF9" />
          <stop offset="0.9528" stopColor="#DDE1F9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9112_29617"
          x1="22.5462"
          y1="20.0004"
          x2="-17.6688"
          y2="20.0004"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3243" stopColor="#ECF2F2" />
          <stop offset="0.3653" stopColor="#EEF4F3" />
          <stop offset="0.6187" stopColor="#F8FAF6" />
          <stop offset="0.9528" stopColor="#FBFCF7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9112_29617"
          x1="0.1428"
          y1="20"
          x2="40.1436"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3243" stopColor="#6E89D3" />
          <stop offset="0.3255" stopColor="#6E89D3" />
          <stop offset="0.5001" stopColor="#6A83E3" />
          <stop offset="0.6954" stopColor="#6880ED" />
          <stop offset="0.9528" stopColor="#677FF0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9112_29617"
          x1="40.1443"
          y1="20"
          x2="0.143555"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3243" stopColor="#C4C5FC" />
          <stop offset="0.5439" stopColor="#C8CBF8" />
          <stop offset="0.9528" stopColor="#CACEF6" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_9112_29617"
          x1="11.4157"
          y1="36.9314"
          x2="29.7401"
          y2="1.36811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#718BD4" />
          <stop offset="0.0121" stopColor="#758ED5" />
          <stop offset="0.0579" stopColor="#7790D6" />
          <stop offset="0.099" stopColor="#7A92D7" />
          <stop offset="0.2589" stopColor="#889DDD" />
          <stop offset="0.473" stopColor="#9AAAE4" />
          <stop offset="0.695" stopColor="#AEB9EC" />
          <stop offset="1" stopColor="#C6CBF5" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_9112_29617"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.0019 20.6298) rotate(43.8132) scale(19.2108 19.3001)"
        >
          <stop stopColor="#DADCF4" />
          <stop offset="0.00819061" stopColor="#DADCF4" />
          <stop offset="0.0579" stopColor="#DADCF4" />
          <stop offset="0.099" stopColor="#DADCF4" />
          <stop offset="0.473" stopColor="#DADCF4" />
          <stop offset="1" stopColor="#DADCF4" />
        </radialGradient>
        <linearGradient
          id="paint8_linear_9112_29617"
          x1="11.4167"
          y1="36.9281"
          x2="29.7412"
          y2="1.36478"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#718BD4" />
          <stop offset="0.0121" stopColor="#758ED5" />
          <stop offset="0.0579" stopColor="#7790D6" />
          <stop offset="0.099" stopColor="#7A92D7" />
          <stop offset="0.2589" stopColor="#889DDD" />
          <stop offset="0.473" stopColor="#9AAAE4" />
          <stop offset="0.695" stopColor="#AEB9EC" />
          <stop offset="1" stopColor="#C6CBF5" />
        </linearGradient>
        <radialGradient
          id="paint9_radial_9112_29617"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.9992 20.575) rotate(43.8132) scale(19.2108 19.3001)"
        >
          <stop stopColor="#DADCF4" />
          <stop offset="0.00819061" stopColor="#DADCF4" />
          <stop offset="0.0579" stopColor="#DADCF4" />
          <stop offset="0.099" stopColor="#DADCF4" />
          <stop offset="0.473" stopColor="#DADCF4" />
          <stop offset="1" stopColor="#DADCF4" />
        </radialGradient>
        <linearGradient
          id="paint10_linear_9112_29617"
          x1="11.4164"
          y1="36.9314"
          x2="29.7409"
          y2="1.36811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#718BD4" />
          <stop offset="0.0121" stopColor="#758ED5" />
          <stop offset="0.0579" stopColor="#7790D6" />
          <stop offset="0.099" stopColor="#7A92D7" />
          <stop offset="0.2589" stopColor="#889DDD" />
          <stop offset="0.473" stopColor="#9AAAE4" />
          <stop offset="0.695" stopColor="#AEB9EC" />
          <stop offset="1" stopColor="#C6CBF5" />
        </linearGradient>
        <radialGradient
          id="paint11_radial_9112_29617"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.9658 20.6357) rotate(43.8132) scale(19.2108 19.3001)"
        >
          <stop stopColor="#DADCF4" />
          <stop offset="0.00819061" stopColor="#DADCF4" />
          <stop offset="0.0579" stopColor="#DADCF4" />
          <stop offset="0.099" stopColor="#DADCF4" />
          <stop offset="0.473" stopColor="#DADCF4" />
          <stop offset="1" stopColor="#DADCF4" />
        </radialGradient>
        <linearGradient
          id="paint12_linear_9112_29617"
          x1="11.4185"
          y1="36.9281"
          x2="29.743"
          y2="1.36478"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#718BD4" />
          <stop offset="0.0121" stopColor="#758ED5" />
          <stop offset="0.0579" stopColor="#7790D6" />
          <stop offset="0.099" stopColor="#7A92D7" />
          <stop offset="0.2589" stopColor="#889DDD" />
          <stop offset="0.473" stopColor="#9AAAE4" />
          <stop offset="0.695" stopColor="#AEB9EC" />
          <stop offset="1" stopColor="#C6CBF5" />
        </linearGradient>
        <radialGradient
          id="paint13_radial_9112_29617"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.9588 20.5804) rotate(43.8309) scale(19.2047 19.2943)"
        >
          <stop stopColor="#DADCF4" />
          <stop offset="0.00819061" stopColor="#DADCF4" />
          <stop offset="0.0579" stopColor="#DADCF4" />
          <stop offset="0.099" stopColor="#DADCF4" />
          <stop offset="0.473" stopColor="#DADCF4" />
          <stop offset="1" stopColor="#DADCF4" />
        </radialGradient>
        <linearGradient
          id="paint14_linear_9112_29617"
          x1="11.4185"
          y1="36.9292"
          x2="29.743"
          y2="1.36584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#718BD4" />
          <stop offset="0.0121" stopColor="#758ED5" />
          <stop offset="0.0579" stopColor="#7790D6" />
          <stop offset="0.099" stopColor="#7A92D7" />
          <stop offset="0.2589" stopColor="#889DDD" />
          <stop offset="0.473" stopColor="#9AAAE4" />
          <stop offset="0.695" stopColor="#AEB9EC" />
          <stop offset="1" stopColor="#C6CBF5" />
        </linearGradient>
        <radialGradient
          id="paint15_radial_9112_29617"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.946 15.3687) rotate(42.0006) scale(0.257723)"
        >
          <stop stopColor="#DADCF4" />
          <stop offset="0.00819061" stopColor="#DADCF4" />
          <stop offset="0.0579" stopColor="#DADCF4" />
          <stop offset="0.099" stopColor="#DADCF4" />
          <stop offset="0.473" stopColor="#DADCF4" />
          <stop offset="1" stopColor="#DADCF4" />
        </radialGradient>
        <clipPath id="clip0_9112_29617">
          <rect width="40" height="40" fill="white" transform="translate(0.143555)" />
        </clipPath>
      </defs>
    </svg>
  );
  return (
    <TokenSection $background={IMAGE_URL.Landing.GrainTexture}>
      <ElementRow>
        {titles.map(({ value }) => (
          <Card key={value}>
            <CardInner>
              <CardTitle fontFamily="secondary" size="md">
                {t(`tokens.${value}.title`)}
              </CardTitle>
              <CardText size="md">{t(`tokens.${value}.text`)}</CardText>
            </CardInner>
          </Card>
        ))}
      </ElementRow>

      <SchemePattern />

      <Card size="large" $isLiquid>
        <CardInner>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={isMobile || isTablet ? '32' : '72'}
            height={isMobile || isTablet ? '32' : '72'}
            viewBox="0 0 72 72"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M68.9991 37.1449C69.0062 37.6514 68.9722 38.1576 68.8971 38.6584C68.0896 44.076 63.5813 46.7038 58.6301 47.4862C50.574 48.7547 42.4827 51.1335 35.9947 56.3654C29.0179 61.9791 26.1775 65.2569 24.1492 67.5976C23.6075 68.2227 23.1238 68.7809 22.6346 69.299C22.3563 69.5954 22.074 69.8759 21.7839 70.1486C20.6543 71.1121 19.275 71.7301 17.8085 71.9296C16.342 72.1292 14.8495 71.9021 13.5068 71.275C12.1641 70.6479 11.0273 69.647 10.2302 68.3903C9.43312 67.1335 9.00904 65.6733 9.00793 64.1817V63.9762C9.05067 60.9557 10.0849 57.8759 11.2739 54.3352C13.1383 48.7831 15.3832 42.0978 14.7824 32.731C14.1883 23.4703 12.1731 18.2882 10.6882 14.4694C9.69436 11.9136 8.938 9.96854 9.00401 7.82019C9.00401 5.74615 9.82138 3.75705 11.2763 2.29048C12.7312 0.823911 14.7045 0 16.7621 0C18.8197 0 20.793 0.823911 22.2479 2.29048C23.7029 3.75705 24.5202 5.5 24.5202 7.82019C24.5202 14.4606 23.0181 25.495 23.5 34.5C23.9383 42.69 25.921 49.2012 34.0346 49.2012C40.699 49.2012 43.8352 43.3054 46.45 37.9866C48.4101 34.035 50.672 29.8621 54.6236 27.9772C56.1578 27.245 57.8507 26.9157 59.545 27.0198C61.2392 27.124 62.8799 27.6584 64.3143 28.5732C65.7488 29.488 66.9305 30.7535 67.7495 32.2522C68.5686 33.7508 68.9984 35.4339 68.9991 37.1449Z"
              fill="#FFD966"
            />
          </svg>
        </CardInner>
      </Card>

      <ElementRowToken>
        {tokens.map((token) => (
          <Card key={token} size="small" $target="token">
            <CardInner $target="token">
              {token === 'ethw' ? fuckingIcon : <TokenIcon name={token} />}
              <CardTitle fontFamily="secondary" size="md" $isTokenTitle>
                {token}
              </CardTitle>
            </CardInner>
          </Card>
        ))}
      </ElementRowToken>
    </TokenSection>
  );
};

export default Tokens;
