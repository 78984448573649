import { ApiEndpoints, instance } from 'services/client';
import { AxiosResponse } from 'axios';
import { PaginatedReadClaimNftList, NftClaimPrice, NftClaimSignature } from 'types/myNfts';

class MyNftsService {
  static async getAllNfts(): Promise<AxiosResponse<PaginatedReadClaimNftList>> {
    const response = await instance.get(ApiEndpoints.v2.myNfts.getAllNfts);
    return response;
  }

  static async getNftPrice(): Promise<AxiosResponse<NftClaimPrice>> {
    const response = await instance.get(ApiEndpoints.v2.myNfts.getPrice);
    return response;
  }

  static async getNftClaimSignature(claimIds: number[]): Promise<AxiosResponse<NftClaimSignature>> {
    const response = await instance.post(ApiEndpoints.v2.myNfts.generateSignature, { claim_nfts: claimIds });
    return response;
  }
}

export default MyNftsService;
