import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Input } from 'ui';
import Menu from 'ui/Menu/Menu';
import { UseFormClearErrors, UseFormRegister, UseFormSetError, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { DetailsFormProps } from 'pages/Profile/Details/Details.component';
import { useClickAway } from 'react-use';
import {
  IconStyled,
  MenuListItemStyled,
  MenuListWrapStyled,
  SearchInputWrapStyled,
  SearchStyled,
} from './CountrySelect.styled';

interface CountrySelectProps {
  setValue: UseFormSetValue<DetailsFormProps>;
  register: UseFormRegister<DetailsFormProps>;
  errors: any;
  setError: UseFormSetError<DetailsFormProps>;
  clearErrors: UseFormClearErrors<DetailsFormProps>;
  list: ListType[];
  placeholder?: string;
}

type ListType = {
  name: string;
};

const CountrySelect: FC<CountrySelectProps> = ({
  list,
  setValue,
  register,
  errors,
  setError,
  clearErrors,
  placeholder = 'Residence country *',
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [data, setData] = useState(list);
  const selectRef = useRef(null);

  useClickAway(selectRef, () => setOpenMenu(false));

  const handleChange = ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => {
    clearErrors('residence_country');
    const tempArray = [...list].filter((elem) => elem.name.toLowerCase().includes(newValue.toLowerCase()));
    setData(tempArray);

    if (!openMenu) {
      setOpenMenu(true);
    }

    if (!newValue) {
      setError('residence_country', { type: 'custom', message: 'This field is required' });
    }
  };

  const handleSelectMenuItem = (name: string) => {
    setValue('residence_country', name, { shouldValidate: true, shouldDirty: true });
    setOpenMenu(false);
  };

  const toggleMenu = () => setOpenMenu(!openMenu);

  return (
    <SearchStyled ref={selectRef}>
      <SearchInputWrapStyled>
        <Input
          style={{ width: 'inherit' }}
          onClick={toggleMenu}
          placeholder={placeholder}
          {...register('residence_country')}
          error={errors.residence_country?.message}
          onChange={handleChange}
          inputEndAdornment={<IconStyled name="arrowDown" onClick={toggleMenu} />}
        />
      </SearchInputWrapStyled>

      <Menu open={openMenu} close={() => {}}>
        <MenuListWrapStyled $align="flex-start" $direction="column">
          {data.map(({ name }) => (
            <MenuListItemStyled onClick={() => handleSelectMenuItem(name)} size="sm" key={name}>
              {name}
            </MenuListItemStyled>
          ))}
        </MenuListWrapStyled>
      </Menu>
    </SearchStyled>
  );
};

export default CountrySelect;
