import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';
import { SetUserNameBody } from 'services/Django/UserService/types';
import Cookies from 'js-cookie';
import { MyReferrerType, ResponseMyReferrerType } from 'types/auth';
import { SetPhoneNumberResponse, SetTelegramUsernameResponse } from 'services/Spring/UserService/types';
import { StringNullType } from 'types/common/text';
import {
  confirmRegistrationPhoneRequest,
  updateProfileDetailsRequest,
  vaultAuthorizeRequest,
} from 'store/slices/vault/actionAsync';
import { PaymentServiceEnum } from 'types/wallets';
import {
  bindWalletRequest,
  changeUserNameRequest,
  confirmBindEmailRequest,
  confirmRegistrationEmailRequest,
  emailLoginRequest,
  emailRegistrationRequest,
  loginWalletRequest,
  meRequest,
  meRequestShort,
  MeResponse,
  MeResponseShort,
  registerWalletRequest,
  setMyReferrerRequest,
  setPhoneNumber,
  setTelegramUsername,
  userLogoutRequest,
} from './actionAsync';
import { saveUserInfo, saveUserInfoShort } from './utils';

type InitState = {
  username: string;
  avatar: StringNullType;
  isLoading: boolean;
  email: string;
  walletAddress: string;
  gems: number;
  id: number;
  kyc: boolean;
  myReferrer: ResponseMyReferrerType;
  isPasswordChanged: boolean;
  isActive: boolean;
  telegramUsername: string;
  phone: string;
  referralLink: string;
  paymentService: PaymentServiceEnum | '';
  currentKyc: string | null;
  KYCLevel: string;
};

const initState: InitState = {
  username: '',
  avatar: null,
  isLoading: false,
  myReferrer: {},
  email: '',
  walletAddress: '',
  gems: 0,
  id: 0,
  kyc: false,
  isPasswordChanged: false,
  isActive: true,
  telegramUsername: '',
  phone: '',
  referralLink: '',
  paymentService: '',
  KYCLevel: 'None',
  currentKyc: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    logout: () => {
      Cookies.remove(LocalStorageKeys.TOKEN_KEY);
      Cookies.remove(LocalStorageKeys.REFRESH_KEY);
      return initState;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    passwordChanged: (state, action: PayloadAction<boolean>) => {
      state.isPasswordChanged = action.payload;
    },
    setMyReferrer: (state, action: PayloadAction<MyReferrerType>) => {
      state.myReferrer = action.payload;
    },
    subtractGems: (state, action: PayloadAction<number>) => {
      state.gems -= action.payload;
    },
    addGems: (state, action: PayloadAction<number>) => {
      state.gems += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerWalletRequest.fulfilled, (state, action: PayloadAction<MeResponse>) =>
        saveUserInfo(state, action.payload),
      )
      .addCase(bindWalletRequest.fulfilled, (state, action: PayloadAction<MeResponse>) => {
        saveUserInfo(state, action.payload, true);
      })
      .addCase(loginWalletRequest.fulfilled, (state, action: PayloadAction<MeResponse>) => {
        saveUserInfo(state, action.payload);
      })
      .addCase(emailRegistrationRequest.fulfilled, (state, action: PayloadAction<MeResponse>) =>
        saveUserInfo(state, action.payload),
      )
      .addCase(meRequest.fulfilled, (state, action: PayloadAction<MeResponse>) => {
        saveUserInfo(state, action.payload);
      })
      .addCase(meRequestShort.fulfilled, (state, action: PayloadAction<MeResponseShort>) => {
        saveUserInfoShort(state, action.payload);
      })
      .addCase(emailLoginRequest.fulfilled, (state, action: PayloadAction<MeResponse>) => {
        saveUserInfo(state, action.payload);
      })
      .addCase(setMyReferrerRequest.fulfilled, (state, action: PayloadAction<MyReferrerType>) => {
        state.myReferrer = action.payload;
      })
      .addCase(confirmRegistrationEmailRequest.fulfilled, (state) => {
        state.isActive = true;
      })
      .addCase(changeUserNameRequest.fulfilled, (state, action: PayloadAction<SetUserNameBody>) => {
        state.username = action.payload.username;
      })
      .addCase(confirmBindEmailRequest.fulfilled, (state, action: PayloadAction<MeResponse>) => {
        saveUserInfo(state, action.payload, true);
      })
      .addCase(userLogoutRequest.fulfilled, () => {
        Cookies.remove(LocalStorageKeys.TOKEN_KEY);
        Cookies.remove(LocalStorageKeys.REFRESH_KEY);
        Cookies.remove(LocalStorageKeys.VAULT_TOKEN_KEY);
        Cookies.remove(LocalStorageKeys.VAULT_REFRESH_KEY);
        return initState;
      })
      .addCase(setTelegramUsername.fulfilled, (state, action: PayloadAction<SetTelegramUsernameResponse>) => {
        state.telegramUsername = action.payload.telegram_username;
      })
      .addCase(setPhoneNumber.fulfilled, (state, action: PayloadAction<SetPhoneNumberResponse>) => {
        state.phone = action.payload.phone;
      })
      .addCase(confirmRegistrationPhoneRequest.fulfilled, (state, action: PayloadAction<MeResponse>) => {
        saveUserInfo(state, action.payload, true);
      })
      .addCase(vaultAuthorizeRequest.fulfilled, (state, action: PayloadAction<MeResponse>) => {
        saveUserInfo(state, action.payload, true);
      })
      .addCase(updateProfileDetailsRequest.fulfilled, (state) => {
        state.KYCLevel = 'KYC_0';
      })
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('user') && type.endsWith('/pending');
        },
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('user') && type.endsWith('/rejected');
        },
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('user') && type.endsWith('/fulfilled');
        },
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const { logout, setEmail, passwordChanged, setUsername, subtractGems, addGems } = userSlice.actions;

export default userSlice.reducer;
