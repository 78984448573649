import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import media from 'theme/media';

const AnimatedSVG = styled.svg`
  position: absolute;
  display: block;
  width: 934px;
  height: 524px;
  transform: translateY(30px);
  z-index: 2;

  @media (max-width: ${media.laptop}px) {
    width: 664.178px;
    height: 372.622px;
  }

  @media (max-width: ${media.tablet}px) {
    display: none;
  }
`;

const AnimatedSVGShort = styled.svg`
  display: none;
  position: absolute;
  width: 80%;
  z-index: 2;

  @media (max-width: ${media.tablet}px) {
    display: block;
    width: 528.197px;
    height: 524px;
  }

  @media (max-width: ${media.mobile}px) {
    transform: translateY(10px);
    width: 247.592px;
    height: 245.625px;
  }

  @media (max-width: 500px) {
    transform: translateY(35px);
  }
`;

interface AnimatedSVGShortProps {
  className?: string;
}

const MyComponent: React.FC<AnimatedSVGShortProps> = ({ className }) => (
  <>
    <AnimatedSVGShort className={className} viewBox="0 0 532 526" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M262.343 274.056H245.208M262.343 274.056V263.781M262.343 274.056H290.751M319.16 274.056V165.089H357.94M319.16 274.056H290.751M319.16 274.056H431.424V402M262.343 249.839V147.791H85.6262V1H76.1782V165.089H231.68V249.839M262.343 249.839H231.68M262.343 249.839V263.781M290.751 274.056V155.479H304.064H452.587V1M290.751 274.056V299.426M171.906 299.426V525H181.951V263.781M171.906 299.426H290.751M171.906 299.426V249.839H231.68M290.751 299.426H357.94M290.751 299.426V387.453M357.94 165.089V181.618M357.94 165.089H460.902V1H452.587M357.94 299.426V181.618M357.94 299.426H486V402M245.208 274.056V408.21H431.424M245.208 274.056H80.7131V428.583H1.89844V525H12.5V263.781H181.951M290.751 387.453V414.36H363.191V525H347.5V387.453H290.751ZM452.587 1H435.581V181.618H357.94M181.951 263.781H262.343M431.424 408.21H530.096V525H515.5V402H486M431.424 408.21V402M431.424 402H486"
        stroke="url(#paint0_radial_1470_66328)"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1470_66328"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(293.866 295.666) scale(389.153 202.866)"
        >
          <stop stopColor="#FFDE89" />
          <stop offset="0.135417" stopColor="#552D1C" />
          <stop offset="0.286458" stopColor="#FFD873" />
          <stop offset="0.432292" stopColor="#CB8C67" />
          <stop offset="0.583333" stopColor="#54411E" />
          <stop offset="0.723958" stopColor="#F0BA5F" />
          <stop offset="0.859375" stopColor="#A76040" />
          <stop offset="1" stopColor="#8B5E39" />
          <animate attributeName="r" values="0.01; 4; 0.01" dur="3s" repeatCount="indefinite" />
        </radialGradient>
      </defs>
    </AnimatedSVGShort>
    <AnimatedSVG
      className={className}
      width="936"
      height="526"
      viewBox="0 0 936 526"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M466.06 274.056H446.004M466.06 274.056H482.685M466.06 274.056V263.781M532.56 274.056V165.089H577.949M532.56 274.056H499.31M532.56 274.056H663.956V408.21M466.06 147.791V249.839M466.06 147.791V1H474.372M466.06 147.791H259.226M466.06 249.839H430.171M466.06 249.839V263.781M499.31 274.056V155.479H514.892M499.31 274.056H482.685M499.31 274.056V287.985M301.689 299.426V387.453H1V525M301.689 299.426H499.31M301.689 299.426V249.839H430.171M499.31 299.426H577.949V181.618M499.31 299.426V387.453M499.31 299.426V287.985M577.949 165.089V181.618M577.949 165.089H698.458M430.171 249.839V165.089H50.4467V19.1479M446.004 274.056V408.21H466.06M446.004 274.056H372.02M499.31 387.453V414.36H623.889V525M499.31 387.453H717.155M688.727 1V155.479H514.892M688.727 1H698.458V165.089M688.727 1H668.822V181.618M577.949 181.618H668.822M698.458 165.089H904.141V1H893.525V181.618H668.822M482.685 274.056V1H474.372M474.372 1V116.203H514.892V155.479M259.226 147.791V1H248.168V19.1479M259.226 147.791H248.168M248.168 147.791H38.9462V1H50.4467V19.1479M248.168 147.791V19.1479M935 525V387.453H717.155M779.445 525V408.21H663.956M161.229 525V428.583H253.475V274.056H372.02M313.446 525V263.781H466.06M717.155 387.453V287.985H499.31M466.06 408.21V437.436M466.06 408.21H663.956M466.06 525V437.436M466.06 437.436H372.02V274.056M50.4467 19.1479H248.168"
        stroke="url(#paint0_radial_74_4575)"
        strokeWidth="3"
        strokeLinecap="square"
      />
      <defs>
        <radialGradient id="paint0_radial_74_4575" cx="0.5" cy="0.5" r="0">
          <stop stopColor={colors.grey} />
          <stop offset="0.135417" stopColor="#552D1C" />
          <stop offset="0.286458" stopColor="#FFD873" />
          <stop offset="0.432292" stopColor="#CB8C67" />
          <stop offset="0.583333" stopColor="#54411E" />
          <stop offset="0.723958" stopColor="#F0BA5F" />
          <stop offset="0.859375" stopColor="#A76040" />
          <stop offset="1" stopColor={colors.grey} />
          <animate attributeName="r" values="0.01; 18" dur="4s" repeatCount="indefinite" />
          {/* <animate attributeName="cy" values="-1; 2; -1" dur="2s" repeatCount="indefinite" /> */}
          {/* <animate attributeName="cx" values="-1; 2; -1" dur="2s" repeatCount="indefinite" /> */}
        </radialGradient>
      </defs>
    </AnimatedSVG>
  </>
);

export default MyComponent;
