import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getNews, getNewsCategories, getOtherNews, getTopicArticle } from 'store/slices/news/actionAsync';
import { NewsCategoryType, NewsStoreType } from './types';

const initialState: NewsStoreType = {
  news: [],
  otherNews: [],
  categories: [],
  topicArticle: null,
  activeCategory: null,
  isNewsLoaded: false,
  isArticleLoaded: false,
  page: 1,
  totalPage: 1,
  isNewsFetchAvailable: true,
  isCategoriesLoaded: false,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setActiveCategory: (state, action: PayloadAction<NewsCategoryType>) => {
      if (state.activeCategory && state.activeCategory.id === action.payload.id) {
        state.activeCategory = null;
      } else {
        state.activeCategory = action.payload;
      }
      state.news = [];
      state.isNewsLoaded = false;
      state.page = 1;
    },
    setLoadedArticle: (state, action: PayloadAction<boolean>) => {
      state.isArticleLoaded = action.payload;
    },
    setIncrementedNewsPage: (state) => {
      state.isNewsFetchAvailable = false;
      state.page += 1;
    },
    clearNewsState: (state) => {
      state.otherNews = [];
      state.isArticleLoaded = false;
    },
    setInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNews.fulfilled, (state, action) => {
      state.news = [...state.news, ...action.payload.results];
      state.page = action.payload.current_page;
      state.totalPage = action.payload.total_pages;

      state.isNewsLoaded = true;
      state.isNewsFetchAvailable = true;
    });
    builder.addCase(getNewsCategories.fulfilled, (state, action) => {
      state.categories = action.payload.results;
      state.isCategoriesLoaded = true;
    });
    builder.addCase(getOtherNews.fulfilled, (state, action) => {
      state.otherNews = action.payload.results.filter(
        (article) => state.topicArticle && article.id !== state.topicArticle.id,
      );
    });
    builder.addCase(getTopicArticle.fulfilled, (state, action) => {
      state.topicArticle = action.payload;
      state.isArticleLoaded = true;
    });
    builder.addCase(getTopicArticle.rejected, (state) => {
      state.isArticleLoaded = true;
      state.topicArticle = null;
    });
  },
});

export const { setActiveCategory, setLoadedArticle, setIncrementedNewsPage, clearNewsState, setInitialState } =
  newsSlice.actions;

export default newsSlice.reducer;
