const media = {
  desktop: 1920,
  laptop: 1439,
  tablet: 1023,
  mobile: 767,
  xs: 400,
  start: 0,
} as const;

export default media;
