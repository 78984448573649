import { ApiEndpoints, instance } from 'services/client';
import { ResendEmailCodes } from 'services/Django/UserService/constants';
import {
  AuthWalletType,
  BindEmailType,
  ChangeUserPassword,
  ConfirmEmailType,
  IConfirmUpdatePassword,
  IWalletRegistration,
  ResendCodeType,
  SetUserNameBody,
  UpdateEmailType,
} from 'services/Django/UserService/types';
import { AuthWallet, EmailRegistrationType } from '../../../types/auth';

class UserService {
  static async authWalletRequest(data: AuthWallet) {
    const { username, signature, publicAddress, referralValue } = data;
    const body = { signature, username, public_address: publicAddress, referral_signature: referralValue };
    const url = ApiEndpoints.v2.users.user_wallet_login;
    const response = await instance.post(url, body);

    return response;
  }

  static async checkUserRequest(userInfo: Partial<{ email: string; username: string }>) {
    const url = ApiEndpoints.v2.users.check_user;
    const response = await instance.post(url, userInfo);

    return response;
  }

  static async emailRegistrationRequest(data: EmailRegistrationType) {
    const { email, username, password } = data;
    const body = { email, username, password };
    const url = ApiEndpoints.v2.users.email_registration;
    const response = await instance.post(url, body);

    return response;
  }

  static async confirmRegistrationEmailRequest(code: string) {
    const url = `${ApiEndpoints.v2.users.confirm_registration_email}${code}/`;
    const response = await instance.post(url, code);

    return response;
  }

  static async meRequest() {
    const url = ApiEndpoints.v2.users.me;
    const response = await instance.get(url, {
      withCredentials: true,
    });

    return response;
  }

  static async meRequestShort() {
    const url = ApiEndpoints.v2.users.meShort;
    const response = await instance.get(url, {
      withCredentials: true,
    });

    return response;
  }

  static async emailLoginRequest(data: EmailRegistrationType) {
    const url = ApiEndpoints.v2.users.email_login;
    const response = await instance.post(url, data);

    return response;
  }

  static async resendEmailVerifyCodeRequest(key: ResendCodeType) {
    const code = ResendEmailCodes[key];
    const body = { code_type: code };
    const url = ApiEndpoints.v1.users.resend_code_verify_email;
    const response = await instance.post(url, body);

    return response;
  }

  static async verifyEmailCodeRequest(code: string) {
    const requestBody = { code, code_type: 4 };
    const url = ApiEndpoints.v1.users.code_verify;
    const response = await instance.post(url, requestBody);

    return response;
  }

  static async updateEmailRequest(data: UpdateEmailType) {
    const url = ApiEndpoints.v2.users.update_email;
    const response = await instance.post(url, data);
    return response;
  }

  static async confirmUpdateEmailRequest(data: ConfirmEmailType) {
    const { email, code } = data;
    if (email) {
      const url = `${ApiEndpoints.v2.users.confirm_update_email}`;

      const response = await instance.post(url, { code });

      return response;
    }

    return { isError: true, message: 'No email to send' };
  }

  static async bindEmailRequest(data: BindEmailType) {
    const url = ApiEndpoints.v2.users.bind_email;
    const response = await instance.post(url, data);
    return response;
  }

  static async confirmBindEmailRequest(data: ConfirmEmailType) {
    const { email, code } = data;
    if (email) {
      const url = `${ApiEndpoints.v2.users.confirm_bind_email}`;

      const response = await instance.post(url, { code });

      return response;
    }

    return { isError: true, message: 'No email to send' };
  }

  static async updatePasswordRequest(login: string) {
    const url = `${ApiEndpoints.v2.users.update_password}${login}/`;
    await instance.post(url, { login });
  }

  static async changePasswordRequest(data: ChangeUserPassword) {
    const response = await instance.post(ApiEndpoints.v2.users.change_password, data);

    return response;
  }

  static async confirmUpdatePasswordRequest(data: IConfirmUpdatePassword) {
    const { code, password1, password2 } = data;
    const url = `${ApiEndpoints.v2.users.confirm_update_password}${code}/`;

    const response = await instance.post(url, { password1, password2 });

    return response;
  }

  static async setMyReferrerRequest(username: string) {
    const url = ApiEndpoints.v2.users.setMyReferrer;
    const response = await instance.post(url, { referral_signature: username });

    return response;
  }

  static async registerWalletRequest(body: IWalletRegistration) {
    const url = ApiEndpoints.v2.users.registerWallet;
    const response = await instance.post(url, body);

    return response;
  }

  static async loginWalletRequest(body: AuthWalletType) {
    const url = ApiEndpoints.v2.users.loginWallet;
    const response = await instance.post(url, body);

    return response;
  }

  static async bindWalletRequest(body: AuthWalletType) {
    const url = ApiEndpoints.v2.users.bindWallet;
    const response = await instance.post(url, body);

    return response;
  }

  static async setUserName(body: SetUserNameBody) {
    const url = ApiEndpoints.v2.users.setUsername;
    const response = await instance.patch(url, body);

    return response;
  }

  static async healthCheck() {
    const url = ApiEndpoints.v2.users.healthCheck;
    const response = await instance.get(url);

    return response;
  }

  static async refreshTokenBeforeLogout(token: string) {
    const url = ApiEndpoints.v2.users.refreshTokenBeforeLogout;
    const response = await instance.post(url, { refresh: token });

    return response;
  }

  static async logout(token: string) {
    const url = ApiEndpoints.v3.users.logout;
    const response = await instance.post(url, { refresh_token: token });

    return response;
  }

  static async setTelegramUsername(telegramUsername: string) {
    const url = ApiEndpoints.v2.users.telegramUsername;
    const response = await instance.patch(url, { telegram_username: telegramUsername });

    return response;
  }

  static async setPhone(phone: string) {
    const url = ApiEndpoints.v2.users.setPhone;
    const response = await instance.patch(url, { phone });

    return response;
  }
}

export default UserService;
