import { LandingProductsBtcReward, LandingProductsRoiPercent } from 'types/marketplace';
import { createSlice } from '@reduxjs/toolkit';
import { getAllNft, getSingleNftPage, getScProductsLanding } from './actionAsync';
import { IMarketplaceStore } from './types';

const initialState: IMarketplaceStore = { collections: [], landingProducts: [], isLandingProductsLoaded: false };

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    clearSelectedProduct: (state) => {
      state.selectedProductPage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNft.fulfilled, (state, action) => {
      state.collections = action.payload.results;
    });
    builder.addCase(getSingleNftPage.fulfilled, (state, action) => {
      state.selectedProductPage = action.payload;
    });
    builder.addCase(getScProductsLanding.fulfilled, (state, action) => {
      const parsedLandingProducts = action.payload.results
        .map((product) => {
          product.collectionName = 'STC Collection';
          product.btcReward = LandingProductsBtcReward[product.id];
          product.roiPercent = LandingProductsRoiPercent[product.id];
          product.title = `Liquid ${product.title}`;
          return product;
        })
        .reverse();
      state.landingProducts = [...parsedLandingProducts, ...parsedLandingProducts, ...parsedLandingProducts];
      state.isLandingProductsLoaded = true;
    });
  },
});

export const { clearSelectedProduct } = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
