import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'router';
import { AppNavigate } from 'components/AppComponents';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';
import Cookies from 'js-cookie';

type PrivateRouteProviderProps = {
  children: ReactNode;
};

const PrivateRouteProvider: FC<PrivateRouteProviderProps> = ({ children }) => {
  const location = useLocation();
  const token = Cookies.get(LocalStorageKeys.TOKEN_KEY) || Cookies.get(LocalStorageKeys.REFRESH_KEY);

  if (!token) {
    return <AppNavigate to={AppRoutes.SIGN_IN} replace state={{ from: location }} />;
  }

  return children;
};

export default PrivateRouteProvider;
