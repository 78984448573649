import styled, { css, DefaultTheme } from 'styled-components';

interface PropsSwitch {
  color: 'primary';
  size: 'small' | 'large';
  checked: boolean;
}

export const SwitchInvisibleCheckbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const getColorButton = (theme: DefaultTheme, color: PropsSwitch['color']) => theme.switch[color];

const getSizeLabel = (size: PropsSwitch['size']) => {
  switch (size) {
    case 'small':
      return css`
        width: 3.25rem;
        height: 1.75rem;
        border-radius: 1.875rem;
        padding: 0.25rem;
      `;
    case 'large':
    default:
      return css`
        width: 3.75rem;
        height: 2.25rem;
        border-radius: 1.875rem;
        padding: 0.25rem;
      `;
  }
};

export const SwitchLabel = styled.label<PropsSwitch>`
  position: relative;
  background: ${({ theme, color, checked }) =>
    checked ? getColorButton(theme, color).bgActive : getColorButton(theme, color).bgInactive};
  transition: 0.2s;
  cursor: pointer;

  display: flex;
  ${({ size }) => getSizeLabel(size)}
`;

const getSizeDot = (size: PropsSwitch['size']) => {
  switch (size) {
    case 'small':
      return css`
        width: 1.25rem;
        height: 1.25rem;
      `;
    case 'large':
    default:
      return css`
        width: 1.75rem;
        height: 1.75rem;
      `;
  }
};

const getLeftArg = (size: PropsSwitch['size'], checked: boolean) => {
  if (checked) {
    switch (size) {
      case 'small':
        return css`
          left: calc(100% - 1.25rem - 0.25rem);
        `;
      case 'large':
      default:
        return css`
          left: calc(100% - 1.75rem - 0.25rem);
        `;
    }
  }

  switch (size) {
    case 'small':
      return css`
        left: 0.125rem;
      `;
    case 'large':
    default:
      return css`
        left: 0.25rem;
      `;
  }
};

export const SwitchSpan = styled.span<PropsSwitch>`
  ${({ size }) => getSizeDot(size)}
  filter: drop-shadow(0px 4px 4px rgba(36, 36, 36, 0.15));
  border-radius: 50%;
  transition: 0.2s;
  background: ${({ theme, color }) => getColorButton(theme, color).dotColor};
  display: flex;
  position: absolute;

  ${({ checked, size }) => getLeftArg(size, checked)};
`;
