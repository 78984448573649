import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import {
  CartCommission,
  ChangeAmountMarketObjectBody,
  ChangeValueEnergyObjectBody,
  CreateEnergyObjectBody,
  CreateMarketBuilderObjectBody,
  CreateMarketObjectBody,
  DeleteBuilderObjectBody,
  DeleteEnergyObjectBody,
  DeleteMarketObjectBody,
  IGemsSignature,
  MarketObject,
  MarketObjectBuilder,
  MarketObjectEnergy,
  ProductCodes,
  ResponseChangeValueEnergyObject,
  ResponseChangeValueMarketItem,
  ResponseCreateEnergyObject,
  ResponseCreateMarketItem,
  ResponseInitState,
  SyncCartMarketObjectBody,
  SyncCartObjectResponse,
  SyncCartSCObjectBody,
  UpdateBuilderObjectBody,
  UpdateMarketObjectBody,
} from 'store/slices/bag/types';
import { NftToBuyObject } from 'pages/Builder/Build/contants';
import { TEnergyGraph } from 'types/powerGrid';

export const sendPurchase = createAsyncThunk<TEnergyGraph, void, ThunkApiConfig>(
  'bag/purchase',
  async (_: void, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CartService.purchase(false);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getInitialStateBag = createAsyncThunk<ResponseInitState, void, ThunkApiConfig>(
  'bag/init',
  async (_: void, { extra: services, rejectWithValue }) => {
    const market = await services.httpService.CartService.getMarketInitialState();

    const marketItems = market.data.results.filter(
      (el) => el.builder_item === null && el.product_code === null,
    ) as MarketObject[];
    const energyItems = market.data.results.filter(
      (el) => el.product_code === ProductCodes.ENERGY,
    ) as MarketObjectEnergy[];
    const builderItems = market.data.results.filter(
      (el) => el.builder_item !== null && el.product === null && el.product_code === null,
    ) as MarketObjectBuilder[];

    // TODO: uncomment when need to add SC
    const shortContracts: MarketObject[] = []; // = market.data.results.filter((el) => el.category === CategoryMarketObjectEnum.shortContracts);

    const result: ResponseInitState = {
      market: marketItems,
      energy: energyItems[0],
      builder: builderItems,
      shortContracts,
    };

    return result;
  },
);

export const createNewMarketItem = createAsyncThunk<ResponseCreateMarketItem, CreateMarketObjectBody, ThunkApiConfig>(
  'bag/market/create',
  async (product, { extra: services, rejectWithValue }) => {
    const response = await services.httpService.CartService.addProductToCart(product);

    if (!response.data) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const addProductToCart = createAsyncThunk<ResponseCreateMarketItem, CreateMarketObjectBody, ThunkApiConfig>(
  'cart/add-product',
  async (product, { extra: services, rejectWithValue }) => {
    const response = await services.httpService.CartService.addProductToCart(product);

    if (!response.data) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getVaultCartCommission = createAsyncThunk<CartCommission, { useGems: boolean }, ThunkApiConfig>(
  'vault/cart/commission',
  async ({ useGems }, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CartService.getVaultCartCommission(useGems);

    if (response.isError) {
      const message = getSimpleErrorMessage(
        response.response?.data?.message ? { message: response.response.data.message } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const changeAmountMarketItem = createAsyncThunk<
  ResponseChangeValueMarketItem,
  ChangeAmountMarketObjectBody,
  ThunkApiConfig
>('bag/market/change', async (product, { extra: services, rejectWithValue }) => {
  const response = await services.httpService.CartService.changeAmountMarketObject(product);

  if (!response.data) {
    const message = 'Could not get data, try updating';

    return rejectWithValue({ message, isError: true });
  }

  return response.data;
});

export const updateMarketItem = createAsyncThunk<ResponseChangeValueMarketItem, UpdateMarketObjectBody, ThunkApiConfig>(
  'bag/market/update',
  async (product, { extra: services, rejectWithValue }) => {
    const response = await services.httpService.CartService.updateMarketObject(product);

    if (!response.data) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const deleteMarketItem = createAsyncThunk<{ id: number }, DeleteMarketObjectBody, ThunkApiConfig>(
  'bag/market/delete',
  async (product, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CartService.deleteMarketObject(product);

    if (response.isError) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    return { id: product };
  },
);

export const deleteSCItem = createAsyncThunk<{ id: number }, DeleteMarketObjectBody, ThunkApiConfig>(
  'bag/market/short-contract/delete',
  async (product, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CartService.deleteMarketObject(product);

    if (response.isError) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    return { id: product };
  },
);

export const createEnergyObject = createAsyncThunk<ResponseCreateEnergyObject, CreateEnergyObjectBody, ThunkApiConfig>(
  'cart/add-product-code/energy',
  async (product, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CartService.addProductCodeItemToCart(product, ProductCodes.ENERGY);

    if (response.isError) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const changeValueEnergyObject = createAsyncThunk<
  ResponseChangeValueEnergyObject,
  ChangeValueEnergyObjectBody,
  ThunkApiConfig
>('bag/energy/change', async (product, { extra: services, rejectWithValue }) => {
  const response: any = await services.httpService.CartService.changeAmountMarketObject({
    id: product.id,
    count: product.value,
  });

  if (response.isError) {
    const message = 'Could not get data, try updating';

    return rejectWithValue({ message, isError: true });
  }

  return response.data;
});

export const deleteValueEnergyObject = createAsyncThunk<boolean, DeleteEnergyObjectBody, ThunkApiConfig>(
  'bag/energy/delete',
  async ({ id }, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CartService.deleteMarketObject(id);

    if (response.isError) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    return true;
  },
);

export const deleteBuilderItemObject = createAsyncThunk<{ id: number }, DeleteBuilderObjectBody, ThunkApiConfig>(
  'bag/builder/delete',
  async (id, { extra: services, rejectWithValue }) => {
    const mapped = NftToBuyObject.map((el) => el.id);
    if (!id || id === 0 || mapped.includes(id)) return rejectWithValue({ message: 'null', isError: true });

    const response: any = await services.httpService.CartService.deleteBuilderItem(id);

    if (response.isError) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    return {
      id,
    };
  },
);

export const updateBuilderItem = createAsyncThunk<
  UpdateBuilderObjectBody,
  { id: number; is_purchased: boolean; is_cart: boolean },
  ThunkApiConfig
>('bag/builder/update', async ({ id, is_purchased = false, is_cart = false }, { extra: services, rejectWithValue }) => {
  const response: any = await services.httpService.CartService.updateBuilderItem(id, { is_purchased, is_cart });

  if (response.isError) {
    const message = 'Could not get data, try updating';

    return rejectWithValue({ message, isError: true });
  }

  return response.data;
});

const isRejected = (input: PromiseSettledResult<unknown>): input is PromiseRejectedResult =>
  input.status === 'rejected';

export const syncNFTsMarketCart = createAsyncThunk<SyncCartObjectResponse, SyncCartMarketObjectBody, ThunkApiConfig>(
  'bag/cart/sync',
  async ({ unSyncCart, syncCart }, { extra: services, rejectWithValue }) => {
    const unSyncMarket = await Promise.allSettled(
      unSyncCart.map((value) =>
        services.httpService.CartService.addProductToCart({
          count: value.count,
          id: value.id,
        }),
      ),
    );

    const sync = await Promise.allSettled(
      syncCart.map((value) =>
        services.httpService.CartService.changeAmountMarketObject({
          id: value.id,
          count: value.count,
        }),
      ),
    );

    if (sync.find(isRejected) || unSyncMarket.find(isRejected)) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    const syncResult = sync.reduce<unknown[]>((acc, el) => {
      if (el.status === 'rejected') return acc;

      return [...acc, el.value.data];
    }, []);

    const unSyncResult = unSyncMarket.reduce<unknown[]>((acc, el) => {
      if (el.status === 'rejected') return acc;

      return [...acc, el.value.data];
    }, []);

    return [...unSyncResult, ...syncResult];
  },
);

const isRejectedUnSync = (input: PromiseSettledResult<unknown>): input is PromiseRejectedResult =>
  input.status === 'rejected' || input.value?.isError;

const transformData = (arr: unknown[]) =>
  arr.reduce<unknown[]>((acc, el) => {
    if (el.status === 'rejected' || el.value.isError) return acc;

    return [...acc, el.value.data];
  }, []);

export const syncNFTsSCCart = createAsyncThunk<SyncCartObjectResponse, SyncCartSCObjectBody, ThunkApiConfig>(
  'bag/cart/short-contract/sync',
  async ({ unSyncSC, syncSC }, { extra: services, rejectWithValue }) => {
    const unSyncSCResponses = await Promise.allSettled(
      unSyncSC.map((value) =>
        services.httpService.CartService.addProductToCart({
          count: 1,
          id: value.id,
        }),
      ),
    );

    const rejectedPromise = unSyncSCResponses.find(isRejectedUnSync);
    if (rejectedPromise) {
      const message = getSimpleErrorMessage(rejectedPromise.value.response.data);

      const arr = transformData(unSyncSCResponses.filter((el) => !isRejectedUnSync(el)));
      return rejectWithValue({ message, isError: true, data: arr });
    }

    const unSyncSCResult = transformData(unSyncSCResponses);

    return [...syncSC, ...unSyncSCResult];
  },
);

export const getGemsSignature = createAsyncThunk<IGemsSignature, number, ThunkApiConfig>(
  'bag/cart/gems-signature',
  async (gems, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CartService.getSignatureGems(gems);

    if (response.isError) {
      const message = 'Could not get data, try updating';

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const purcase = createAsyncThunk<IGemsSignature, boolean, ThunkApiConfig>(
  'bag/cart/purchase',
  async (useGems, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.CartService.purchase(useGems);

    if (response.isError) {
      const message = response.response?.data?.message || response.message;
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);
