import React, { useState } from 'react';
import { P } from 'ui/Text';
import { useTranslation } from 'react-i18next';
import { IMAGE_URL } from 'assets/Urls';
import { OffsetTitle, WorkGuideSection, Wrapper } from './style';
import TabList from './components/TabList';
import Guides from './components/Guides';

interface IPreviewProps {
  refValue: React.RefObject<HTMLDivElement>;
}

const WorksGuide: React.FC<IPreviewProps> = ({ refValue }) => {
  const { t } = useTranslation('landing');
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <WorkGuideSection ref={refValue} $background={IMAGE_URL.Landing.LinesPattern}>
      <Wrapper>
        <P fontFamily="secondary" size="lg" color="primary" align="left">
          {t('how_it_works_block.category')}
        </P>
        <OffsetTitle>{t('how_it_works_block.title')}</OffsetTitle>
      </Wrapper>
      <TabList currentTabIndex={selectedTab} onTabChange={setSelectedTab} />

      <Wrapper>
        <Guides selectedTab={selectedTab} />
      </Wrapper>
    </WorkGuideSection>
  );
};

export default WorksGuide;
