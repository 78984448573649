import { NewItem } from 'pages/Builder/Build/contants';
import { Final, InventoryPageItem, StateOpened } from 'src/types/builder';

type TKeyName = 'shelf' | 'rig' | 'gpu';

const keyNames: TKeyName[] = ['gpu', 'rig', 'shelf'];

export const getEquipmentArrayBuilder = (initNft: StateOpened | NewItem): Final[] => {
  const finalArr = Object.entries(initNft)
    .filter(([a, b]: [string, any]) => a.includes(keyNames.find((el: string) => a.includes(el)) || '\n'))
    .map(([a, b]: [string, any]) => (b ? { ...b, title: a } : null));
  return finalArr;
};

const getBuilderConfig = (initNft: StateOpened | NewItem, insideArray: Final[], all: InventoryPageItem[]): string[] => {
  let equipmentArray = insideArray;

  const getRigsGpus = (rig: StateOpened | NewItem) => {
    const rigEquipment = getEquipmentArrayBuilder(rig);

    const gpuString = rigEquipment.map((el) => {
      if (!el) return 0;
      if (el.name.toLowerCase().includes('plus') || el.contract_purchase_id === 1) return 1;
      return 2;
    });

    const parsedGpuString =
      gpuString.length < 6 ? [...gpuString, ...new Array(6 - gpuString.length).fill(0)] : gpuString;

    return [`0:${parsedGpuString.join(':')}`];
  };

  if (insideArray?.length === 0 || !insideArray) {
    equipmentArray = getEquipmentArrayBuilder(initNft);
  }

  if (initNft?.url_piece === 'container' || initNft?.product_name.toLowerCase().includes('container')) {
    const initial = ['5:1', '6:1', '7:1', '8:1'];
    const sliced = initial.map((el) => el.split(':'));
    const sorted = equipmentArray.map((eqp, idx) => {
      const plusedValue = 4 * idx;
      if (eqp) {
        return sliced.map((el1) =>
          el1
            .map((str, idx1) => (idx1 === 0 ? Number(str) + plusedValue : str))
            .flat()
            .join(':'),
        );
      }
      return sliced.map((el2) =>
        el2
          .map((str2, idx2) => {
            if (idx2 === 0) return Number(str2) + plusedValue;
            return '0';
          })
          .flat()
          .join(':'),
      );
    });

    if (!sorted[0]) return ['27:0:0:0:0:0:0'];

    return sorted
      .flat()
      .map((el) => (el.split(':')[1].includes('0') ? '' : el))
      .filter((el) => !!el);
  }

  if (initNft?.url_piece === 'shelf' || initNft?.product_name.toLowerCase().includes('rack')) {
    const wtf = equipmentArray
      .map((el) => {
        if (el) {
          const newArr = new Array(6);
          return newArr.fill(1).join(':');
        }
        return el;
      })
      .map((el, idx) => (el ? `${idx + 1}:${el}` : ''))
      .filter((el) => !!el);
    if (!wtf[0]) return ['26:0:0:0:0:0:0'];
    return wtf;
  }

  if (initNft?.url_piece === 'rig' || initNft.product_name.toLowerCase().includes('rig')) {
    const sorted = getRigsGpus(initNft);

    return sorted;
  }

  return [''];
};

export { getBuilderConfig };
