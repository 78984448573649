import { MenuStyled } from 'ui/Menu/Menu.styled';
import React, { FC, ReactNode, useRef } from 'react';
import { useClickAway } from 'react-use';

type TMenuProps = {
  open: boolean;
  close: () => void;
  children: ReactNode;
};
const Menu: FC<TMenuProps> = ({ children, open, close }) => {
  const menuRef = useRef(null);
  useClickAway(menuRef, close);
  return (
    <MenuStyled ref={menuRef} open={open}>
      {children}
    </MenuStyled>
  );
};

export default Menu;
