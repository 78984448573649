import React from 'react';
import { Flex } from 'ui';
import { Dot, Dots, NavigationList } from './style';
import { navLinks } from '../../constants';
import NavigationItem from '../NavigationItem';

type NavigationProps = {
  onInfoSelect: (index: number) => void;
  currentSlideIndex: number;
  refValue: React.RefObject<HTMLDivElement>;
};

const Navigation: React.FC<NavigationProps> = ({ currentSlideIndex, onInfoSelect, refValue }) => (
  <NavigationList ref={refValue}>
    {navLinks.map(({ value, id }, i) => (
      <>
        {i !== 0 && (
          <Dots $maxWidth="min-content">
            <Dot />
            <Dot />
            <Dot />
          </Dots>
        )}
        <Flex key={id} $align="center">
          <NavigationItem label={value} active={currentSlideIndex === i} onCLick={() => onInfoSelect(i)} />
        </Flex>
      </>
    ))}
  </NavigationList>
);
export default Navigation;
