import { styled } from 'styled-components';

export const MenuStyled = styled.div<{ open: boolean }>`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.7s;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  width: 100%;
  z-index: 15;
  border-radius: 0.5rem;
  background: #2e2e2e;
  padding: 1rem;
  max-height: 13.875rem;
  overflow-y: auto;
`;
