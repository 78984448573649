import { AuthStatuses } from 'services/Spring/UserService/types';
import { VerifiedUserStatus } from '../../../../appConstants/Auth';

export const checkLiquidWalletAuth = (status: VerifiedUserStatus): AuthStatuses => {
  const liquidAuthStatuses = [VerifiedUserStatus.FULL, VerifiedUserStatus.LIQUID, VerifiedUserStatus.NO_EMAIL];
  const isLiquidUser = liquidAuthStatuses.includes(status);
  const isDaisyUser = status === VerifiedUserStatus.DAISY;
  const isNewUser = status === VerifiedUserStatus.NONE;
  return {
    isDaisyUser,
    isLiquidUser,
    isNewUser,
  };
};
