import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'ui';
import { H3, H5 } from 'ui/Text';
import colors from 'theme/colors';
import { Desc } from 'pages/Landing/style';
import CountUp from 'react-countup';
import { styled } from 'styled-components';
import { media } from 'theme';
import { InfoBlockWrapper, NumberCount, ValueLabel, ValuesWrapper, ValuesFlex } from './style';
import { IAboutData } from '../../constants';

const YellowSpan = styled(H3)`
  color: ${colors.yellow};

  @media (max-width: ${media.laptop}px) {
    font-size: 1.5rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > h5 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.fontsColor.secondary};
    font-size: 1rem;
    font-weight: 700;
  }
`;

interface IAboutInfoProps {
  aboutData: IAboutData;
}

const InfoBlock: React.FC<IAboutInfoProps> = ({ aboutData }) => {
  const { t } = useTranslation('landing');
  const infoBlockRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) setIsVisible(true);
      });
    };

    let observerRefValue: React.MutableRefObject<HTMLDivElement>['current'] | null = null;

    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0.1,
    });

    if (infoBlockRef.current) {
      observer.observe(infoBlockRef.current);
      observerRefValue = infoBlockRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, []);

  const aboutLocaleValue = `about_block.${aboutData.locale}` as const;
  const aboutStatsValues = `about_block.values`;

  const getAboutDataValues = () =>
    aboutData.values.map(({ label, value }) => (
      <ValuesWrapper $justify="space-between" key={label}>
        <Flex $maxWidth="50%" $justify="flex-start" $gap="sm" $columnGap="sm">
          <NumberCount>
            {value.prefix}
            <CountUp start={isVisible ? 0 : undefined} end={+value.source[0]} duration={2.75} />
            {value.postfix}
          </NumberCount>
          {value.source[1] && <YellowSpan key={label}>{value.source[1]}</YellowSpan>}
        </Flex>
        <ValueLabel>{t(`${aboutStatsValues}.${label}`)}</ValueLabel>
      </ValuesWrapper>
    ));

  const aboutDataValues = getAboutDataValues();

  return (
    <InfoBlockWrapper ref={infoBlockRef}>
      <TextWrapper>
        <H3>{t(`${aboutLocaleValue}.title`)}</H3>
        <H5>{t(`${aboutLocaleValue}.subtitle`)}</H5>
        <Desc>{t(`${aboutLocaleValue}.desc`)}</Desc>
      </TextWrapper>
      <ValuesFlex $direction="column" $align="flex-start" $justify="space-between">
        {aboutDataValues}
      </ValuesFlex>
    </InfoBlockWrapper>
  );
};

export default InfoBlock;
