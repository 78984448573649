import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILiquidationSliceStore, LiquidationSortOptions } from 'store/slices/liquidation/types';
import {
  casesRibbon,
  freeCase,
  getBox,
  getCases,
  getPrizeFreeCase,
  openCase,
  userPrizes,
} from 'store/slices/liquidation/actionAsync';

const initialState: ILiquidationSliceStore = {
  cases: [],
  prizes: [],
  box: null,
  currentCases: [],
  isLoading: false,
  indexOpeningCaseInRow: -1,
  isHasButtonBack: false,
  isSkippedOpening: false,
  isAnimationEnd: false,
  isUuidResultLoaded: false,
  ribbons: [],
  online: 0,
  isAnimationFreeCaseShow: true,
  liquidationSortOption: LiquidationSortOptions.lowerPrice,
  freeCasePrizeNonAuth: null,
  isMorePrizesLoading: false,
  prizesLimit: 0,
  prizesCurrentPage: 1,
  prizesTotalPage: 0,
};

const liquidationSlice = createSlice({
  name: 'liquidation',
  initialState,
  reducers: {
    setIsHasButtonBack: (state, action: PayloadAction<boolean>) => {
      state.isHasButtonBack = action.payload;
    },
    setIndexOpening: (state, action: PayloadAction<number>) => {
      state.indexOpeningCaseInRow = action.payload;
    },
    setLiquidationSortOption: (state, action: PayloadAction<LiquidationSortOptions | null>) => {
      state.liquidationSortOption = action.payload;
    },
    setIsSkippedOpening: (state, action: PayloadAction<boolean>) => {
      state.isSkippedOpening = action.payload;
    },
    setIsAnimationEnd: (state, action: PayloadAction<boolean>) => {
      state.isAnimationEnd = action.payload;
    },
    setIsAnimationFreeCaseShow: (state, action: PayloadAction<boolean>) => {
      state.isAnimationFreeCaseShow = action.payload;
    },
    handlePrizesPagePagination: (state) => {
      if (state.prizesCurrentPage < state.prizesTotalPage) {
        state.prizesCurrentPage += 1;
        state.isMorePrizesLoading = true;
      } else {
        state.isMorePrizesLoading = true;
        state.prizes = [];
        state.prizesCurrentPage = 1;
        window.scrollTo(0, 0);
      }
    },
    clearBox: (state) => {
      state.box = state.currentCases.length ? state.box : null;
    },
    clearStateLiquidation: (state) => {
      state.isSkippedOpening = false;
      state.indexOpeningCaseInRow = -1;
      state.prizes = [];
      state.isAnimationEnd = false;
      state.box = null;
      state.currentCases = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCases.fulfilled, (state, action) => {
        state.cases = action.payload.cases;
        if (action.payload.freeCasePrizeNonAuth) {
          state.freeCasePrizeNonAuth = action.payload.freeCasePrizeNonAuth;
        }
        state.isUuidResultLoaded = true;
      })
      .addCase(getBox.fulfilled, (state, action) => {
        state.box = action.payload;
        state.cases = state.cases.filter((el) => (el.price === 0 ? el.free_spin > 0 : el));
      })
      .addCase(userPrizes.fulfilled, (state, action) => {
        state.prizes = action.payload.results;
        state.prizesCurrentPage = action.payload.current_page;
        state.prizesTotalPage = action.payload.total_pages;
        state.prizesLimit = action.payload.limit;
        state.isMorePrizesLoading = false;
      })
      .addCase(freeCase.fulfilled, (state, action) => {
        state.currentCases = [{ prize: action.payload }];
        state.cases = state.cases.filter((el) => (el.price === 0 ? el.free_spin > 0 : el));
      })
      .addCase(openCase.fulfilled, (state, action) => {
        state.currentCases = action.payload;
        state.cases = state.cases.filter((el) => (el.price === 0 ? el.free_spin > 0 : el));
      })
      .addCase(casesRibbon.fulfilled, (state, action) => {
        const SLICE_AMOUNT_RIBBON_PRIZES = 15;

        state.online = action.payload.online;
        state.ribbons = action.payload.results.slice(0, SLICE_AMOUNT_RIBBON_PRIZES);
      })
      .addCase(getPrizeFreeCase.fulfilled, (state, action) => {
        state.prizes = [
          {
            id: 1,
            chance_rounded: 0,
            prize: action.payload,
          },
        ];
      })
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('liquidation') && type.endsWith('/pending');
        },
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('liquidation') && type.endsWith('/rejected');
        },
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('liquidation') && type.endsWith('/fulfilled');
        },
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('logout');
        },
        (state) => {
          state.prizes = [];
          state.isSkippedOpening = false;
          state.indexOpeningCaseInRow = -1;
          state.isAnimationEnd = false;
          state.box = null;
          state.isSkippedOpening = false;
          state.isAnimationEnd = false;
        },
      );
  },
});

export const {
  setIndexOpening,
  setIsAnimationFreeCaseShow,
  clearStateLiquidation,
  setIsSkippedOpening,
  setIsAnimationEnd,
  clearBox,
  setLiquidationSortOption,
  setIsHasButtonBack,
  handlePrizesPagePagination,
} = liquidationSlice.actions;

export default liquidationSlice.reducer;
