import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllNfts, getNftPrice } from './actionAsync';
import { MyNftsStoreType, MyNftsChaptersList } from './types';

const initialState: MyNftsStoreType = {
  myNfts: [],
  selectedNfts: [],
  activeChapter: MyNftsChaptersList.unclaimed,
  isNftsLoaded: false,
  claimPrice: 0,
  isClaimDisabled: false,
};

const myNftsSlice = createSlice({
  name: 'myNfts',
  initialState,
  reducers: {
    setMyNftsActiveChapter: (state, action: PayloadAction<MyNftsChaptersList>) => {
      state.activeChapter = action.payload;
    },
    addNftToClaim: (state, action: PayloadAction<number>) => {
      const nfts = state.selectedNfts;
      if (nfts.includes(action.payload)) {
        state.selectedNfts = nfts.filter((nft) => nft !== action.payload);
      } else {
        state.selectedNfts.push(action.payload);
      }
    },
    setClaimedNft: (state) => {
      const claimedNft =
        state.selectedNfts.length > 0
          ? state.myNfts.map((nft) => {
              if (state.selectedNfts.includes(nft.id)) {
                nft.claimed = true;
              }
              return nft;
            })
          : state.myNfts.map((nft) => {
              nft.claimed = true;
              return nft;
            });

      state.myNfts = claimedNft;
      state.selectedNfts = [];
      state.isClaimDisabled = true;
    },
    setClaimDisabled: (state, action: PayloadAction<boolean>) => {
      state.isClaimDisabled = action.payload;
    },
    clearSelectedNftState: (state) => {
      state.selectedNfts = [];
    },
    clearMyNftState: (state) => {
      state.myNfts = [];
      state.selectedNfts = [];
      state.activeChapter = MyNftsChaptersList.unclaimed;
      state.isNftsLoaded = false;
      state.claimPrice = 0;
      state.isClaimDisabled = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNfts.fulfilled, (state, action) => {
      state.myNfts = action.payload;
      state.isNftsLoaded = true;
    });
    builder.addCase(getNftPrice.fulfilled, (state, action) => {
      state.claimPrice = action.payload;
    });
  },
});

export const {
  setMyNftsActiveChapter,
  addNftToClaim,
  setClaimedNft,
  setClaimDisabled,
  clearSelectedNftState,
  clearMyNftState,
} = myNftsSlice.actions;

export default myNftsSlice.reducer;
