import { styled } from 'styled-components';
import { P } from 'ui';
import colors from 'theme/colors';
import offsets from 'theme/offsets';
import media from 'theme/media';
import Button from 'ui/Button';
import { AppLink } from 'components/AppComponents';
import { font } from 'theme';

type FooterProps = {
  $background: string;
};

export const FooterWrap = styled.footer<FooterProps>`
  background-image: ${({ $background }) => `url(${$background})`};
  background-color: ${colors.onBlack};
  padding: ${offsets.sectionVertical} ${offsets.sectionHorizontal};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${offsets.large};

  @media (max-width: ${media.laptop}px) {
    padding: 6.25rem 3.75rem;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 5rem 2.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${media.mobile}px) {
    padding: 3.75rem 1rem;
    text-align: center;
    grid-template-columns: 1fr;
  }
`;

export const FooterLeadingText = styled(P)`
  color: ${({ theme }) => theme.fontsColor.secondary};
  text-transform: uppercase;
  text-align: left;

  @media (max-width: ${media.mobile}px) {
    text-align: center;
  }
`;

export const FooterLink = styled(P)`
  font-weight: 700;
  cursor: pointer;

  transition: color 0.3s ease;

  &:hover {
    color: ${colors.yellow};
  }
`;

export const SocialLine = styled.div`
  display: flex;
  gap: 1.25rem;
`;

export const StyledButtonIcon = styled(Button.LinkIcon)`
  background: transparent;
  &:hover {
    background: transparent;
  }
`;

export const ListGap = styled.div<{ offset: keyof typeof offsets }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ offset }) => offsets[offset]};

  @media (max-width: ${media.mobile}px) {
    align-items: center;
  }
`;

export const LiquidIcon = styled.img`
  width: 8.375rem;
`;

export const Enigma = styled.img`
  width: 7.063rem;
`;

export const AtomMining = styled.img`
  width: 15rem;
`;

export const GetAppListGap = styled(ListGap)`
  display: none;

  @media (max-width: ${media.tablet}px) {
    display: flex;
  }
`;

export const QuestionBlock = styled(ListGap)`
  @media (max-width: ${media.tablet}px) {
    display: none;
  }
`;

export const InversionListGap = styled(ListGap)`
  display: none;

  @media (max-width: ${media.tablet}px) {
    display: flex;
  }
`;

export const FooterText = styled(P)`
  text-align: left;
  & > span {
    color: ${({ theme }) => theme.navBar.iconBgInactive};
    font-size: 0.875rem;
  }
  @media screen and (max-width: ${media.mobile}px) {
    text-align: center;
  }
`;

export const StyledMailTo = styled(AppLink)`
  color: ${colors.white};
  font-family: ${font.primary};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: left;
  text-decoration: none;
`;
