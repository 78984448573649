import { ApiEndpoints, instance } from 'services/client';
import { NewsCategoryType } from 'store/slices/news/types';

class NewsService {
  static async getAllNews(category: NewsCategoryType | null, page?: number, isMobileDevice?: boolean) {
    const pageLimit = isMobileDevice ? 4 : 10;
    const response = await instance.get(ApiEndpoints.v1.blog.get_all, {
      params: {
        page,
        category: category ? category.id : '',
        ordering: '-created_at',
        limit: pageLimit,
      },
    });
    return response;
  }

  static async getTopicNews(articleID: number) {
    const response = await instance.get(`${ApiEndpoints.v1.blog.get_all}${articleID}/`);
    return response;
  }

  static async getAllCategories() {
    const response = await instance.get(ApiEndpoints.v1.blog.get_categories);
    return response;
  }
}

export default NewsService;
