import { Flex, Icon, P } from 'ui';
import styled from 'styled-components';
import { colors, SkeletonStyle } from 'theme';

export const StyledInfoCalculatorBlock = styled(Flex)`
  padding: 1rem;
  border: 1px solid ${colors.whiteTransparent};
  border-radius: 0.75rem;
  overflow: hidden;
`;

export const StyledImage = styled.img`
  width: 5rem;
  height: 5rem;
  object-fit: cover;
`;

export const StyledIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
`;

export const StyledP = styled(P)<{ $isLoading?: boolean; $widthSkeleton?: string }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  text-align: left;

  ${({ $isLoading, $widthSkeleton = '12rem' }) =>
    $isLoading && SkeletonStyle({ width: $widthSkeleton, height: '1rem', borderRadius: '0.5rem' })}
`;

export const SkeletonImage = styled.div<{ $isLoading?: boolean }>`
  flex-shrink: 0;
  ${({ $isLoading }) => $isLoading && SkeletonStyle({ width: '5rem', height: '5rem', borderRadius: '0.5rem' })}
`;
