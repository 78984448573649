export enum GemsChapters {
  purchase = 'purchase',
  history = 'history',
}

export interface IGemsTab {
  id: number;
  title: string;
  chapter: GemsChapters;
}

export const gemsTabsData: IGemsTab[] = [
  {
    id: 1,
    title: 'Fill your balance',
    chapter: GemsChapters.purchase,
  },
  {
    id: 2,
    title: 'Transaction history',
    chapter: GemsChapters.history,
  },
];

export enum GemsHistoryChapters {
  gems = 'GEMS Purchase',
  achievement = 'Achievement Reward',
  nft = 'NFT Purchase',
  box = 'Box Purchase',
  liquidation = 'Liquidation Reward',
  frozen = 'Frozen Gems',
  all = 'all',
}

export interface IGemsHistoryChapter {
  id: number;
  title: string;
  chapter: GemsHistoryChapters;
}

export const blankChapter: IGemsHistoryChapter = {
  id: 0,
  title: '',
  chapter: GemsHistoryChapters.all,
};

export const gemsHistoryChapters: IGemsHistoryChapter[] = [
  {
    id: 1,
    title: 'GEMS Purchase',
    chapter: GemsHistoryChapters.gems,
  },
  {
    id: 2,
    title: 'Achievement Reward',
    chapter: GemsHistoryChapters.achievement,
  },
  {
    id: 3,
    title: 'NFT Purchase',
    chapter: GemsHistoryChapters.nft,
  },
  {
    id: 4,
    title: 'Box Purchase',
    chapter: GemsHistoryChapters.box,
  },
  {
    id: 5,
    title: 'Liquidation Reward',
    chapter: GemsHistoryChapters.liquidation,
  },
  {
    id: 6,
    title: 'Frozen Gems',
    chapter: GemsHistoryChapters.frozen,
  },
];

export interface IGemsColumn {
  id: number;
  category: GemsHistoryChapters;
  date: string;
  amount: string;
}

export type DateFilterType = {
  id: number;
  name: string;
};

export const FILTER_CALENDAR_ID = 4;

export const dateFilters: DateFilterType[] = [
  { id: 1, name: 'Week' },
  { id: 2, name: 'Month' },
  { id: 3, name: 'Year' },
  { id: 4, name: 'During the period' },
];
