import React, { RefObject } from 'react';
import TabWrapper from './Tab.styled';

type TabProps = React.ComponentProps<'button'> & {
  className?: string;
  active?: boolean;
  notification?: boolean;
  onClick?: () => void;
  customRef?: RefObject<HTMLButtonElement>;
};

const Tab: React.FC<TabProps> = ({ children, active, notification, onClick, ...props }) => (
  <TabWrapper
    ref={props.customRef}
    type="button"
    {...props}
    onClick={onClick}
    $active={active}
    $notification={notification}
  >
    {children}
  </TabWrapper>
);
export default Tab;
