import { createSlice } from '@reduxjs/toolkit';
import { confirmUser, getAffiliateBoard, getLeaderBoard, getRaceInfo, sendAffiliateForm } from './actionAsync';
import { IRaceStore } from './types';

const initState: IRaceStore = {
  leader: false,
  seller: false,
  username: '',
  volume: 0,
  leaderBoard: [],
  affiliateBoard: [],
  treeVolume: 0,
  refLink: undefined,
};

const raceSlice = createSlice({
  name: 'rewards',
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRaceInfo.fulfilled, (state, action) => {
        state.username = action.payload.user.username;
        state.leader = action.payload.user.leader;
        state.seller = action.payload.user.seller;
        state.volume = action.payload.user.volume;
        state.treeVolume = action.payload.user.treeVolume;
      })
      .addCase(confirmUser.fulfilled, (state, action) => {
        state.refLink = action.payload;
        state.isValidUsername = true;
      })
      .addCase(getLeaderBoard.fulfilled, (state, action) => {
        state.leaderBoard = action.payload.content;
      })
      .addCase(getAffiliateBoard.fulfilled, (state, action) => {
        state.affiliateBoard = action.payload.content;
      })
      .addCase(confirmUser.rejected, (state) => {
        state.isValidUsername = false;
      })
      .addCase(sendAffiliateForm.fulfilled, (state) => {
        state.seller = true;
      })
      .addMatcher(
        (action) => action.type.endsWith('/logout'),
        (state) => {
          state.isValidUsername = initState.isValidUsername;
          state.seller = initState.seller;
          state.affiliateBoard = initState.affiliateBoard;
          state.leader = initState.leader;
          state.leaderBoard = initState.leaderBoard;
          state.username = initState.username;
          state.volume = initState.volume;
          state.refLink = initState.refLink;
          state.treeVolume = initState.treeVolume;
        },
      );
  },
});

export default raceSlice.reducer;
