import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import { FAQType, TopicsType } from './types';

export const getFaqLiquidation = createAsyncThunk<FAQType[], void, ThunkApiConfig>(
  'faq/liquidation',
  async (params, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.SupportService.getFAQ({
      search: null,
      topic: null,
      group: 'liquidation_faq',
    });
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data.results;
  },
);

export const getFaqSearchList = createAsyncThunk<FAQType[], string, ThunkApiConfig>(
  'faq/liquidation/faqSearchList',
  async (search, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.SupportService.getFAQ({
      search,
      topic: null,
      group: 'liquidation_faq',
    });
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data.results;
  },
);

export const getFaqSupport = createAsyncThunk<
  FAQType[],
  { topic: number | null; search: string | null },
  ThunkApiConfig
>('faq/support', async (params, { extra: services, rejectWithValue }) => {
  const response: any = await services.httpService.SupportService.getFAQ({ ...params, group: 'support_faq' });

  if (response.isError) {
    const message = getSimpleErrorMessage(response.data);

    return rejectWithValue({ message, isError: true });
  }

  return response.data.results;
});

export const getFaqSupportSearchList = createAsyncThunk<
  FAQType[],
  { topic: number | null; search: string | null },
  ThunkApiConfig
>('faq/support/search', async (params, { extra: services, rejectWithValue }) => {
  const response: any = await services.httpService.SupportService.getFAQ({ ...params, group: 'support_faq' });

  if (response.isError) {
    const message = getSimpleErrorMessage(response.data);

    return rejectWithValue({ message, isError: true });
  }

  return response.data.results;
});

export const getFAQTopics = createAsyncThunk<TopicsType[], void, ThunkApiConfig>(
  'faq/support/topics',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.SupportService.getFAQTopics('support_faq');

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data.results;
  },
);
