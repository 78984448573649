import { AxiosResponse } from 'axios';
import { ApiEndpoints, instance } from 'services/client';
import { TBox } from 'types/liquidation';

class LiquidationService {
  static async getCases(requestSign: string) {
    const response = await instance.get(
      `${ApiEndpoints.v2.liquidation.cases}/?ordering=-is_free_case,-free_spin,${requestSign}price`,
    );

    return response.data;
  }

  static async getBox(id: string) {
    const response = await instance.get(`${ApiEndpoints.v2.liquidation.cases}/${id}/`);

    return response;
  }

  static async getPrizes(page?: number) {
    const response = await instance.get(`${ApiEndpoints.v2.liquidation.userPrizes}/`, {
      params: {
        ordering: '-created_at',
        page,
      },
    });

    return response;
  }

  static async getPrize(id: string) {
    const response = await instance.get(`${ApiEndpoints.v2.liquidation.userPrizes}/${id}/`);

    return response.data;
  }

  static async getPrizeFreeCase(id: string) {
    const response = await instance.get(`${ApiEndpoints.v2.liquidation.prizes}/${id}/`);

    return response.data;
  }

  static async openCase({ id, quantity }: { id: string; quantity: number }) {
    const response = await instance.post(
      `${ApiEndpoints.v2.liquidation.cases}/${id}/open-case/`,
      { quantity },
      {
        withCredentials: true,
      },
    );

    return response;
  }

  static async freeCase(uuid: string) {
    const response = await instance.post(ApiEndpoints.v2.liquidation.freeCase, { uuid });

    return response;
  }

  static async casesRibbon() {
    const response = await instance.get(ApiEndpoints.v2.liquidation.casesRibbon);

    return response.data;
  }

  static async getUuid(uuid: string): Promise<AxiosResponse<TBox>> {
    const response = await instance.get<TBox, AxiosResponse<TBox>>(
      `${ApiEndpoints.v2.liquidation.freeCaseUuid}/${uuid}/`,
    );

    return response;
  }

  static async getPrizeWithUuid(uuid: string): Promise<AxiosResponse<TBox>> {
    const response = await instance.get<TBox, AxiosResponse<TBox>>(
      `${ApiEndpoints.v2.liquidation.freeCaseUuid}/${uuid}/get-prize/`,
    );

    return response;
  }
}

export default LiquidationService;
