import React, { useState } from 'react';
import { Button, Flex, P, Tooltip } from 'ui';
import { InfoCalculatorBlock } from 'widgets/Calculator/components/InfoCalculatorBlock';
import { EstimationBlock } from 'src/widgets/Calculator/components/EstimationBlock';
import {
  CalculatorFormattedConstants,
  CalculatorInfoBlock,
  CalculatorTypeNFT,
  ProductCalculator,
} from 'types/calculator';
import { FormattedConstants, useGetCalculatedContent } from 'widgets/Calculator/hooks/useGetCalculatedContent';
import { CalculatorConstants } from 'appConstants/Calculator';
import { AppRoutes } from 'router';
import useMediaQuery from 'hooks/useMediaQuery';
import numeral from 'numeral';
import {
  CalculatedContentStyled,
  Column,
  HeaderContent,
  LeftContainerInfo,
  PriceText,
  StatsGrid,
  StyledBlockSkeleton,
  StyledIcon,
  StyledImage,
  HeaderCollection,
} from './CalculatedContent.styled';

interface CalculatedContentProps {
  constants: CalculatorFormattedConstants;
  infoBlock: CalculatorInfoBlock[];
  product: ProductCalculator | null;
  durability: number;
  price: number | null;
  isLoading: boolean;
  isShown: boolean;
  isTabChanged: boolean;
  setIsTabChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CalculatedContent: React.FC<CalculatedContentProps> = ({
  infoBlock,
  constants,
  product,
  durability,
  isLoading,
  isShown,
  isTabChanged,
  setIsTabChanged,
}) => {
  const { isMobile } = useMediaQuery();
  const [estimation, setEstimation] = useState(CalculatorConstants.minValueEstimation);
  const calculatedConstants = useGetCalculatedContent({ constants, estimation, durability });

  const handleOnChange = (val: number) => {
    setEstimation(val);
  };

  const styleWidthTooltip = isMobile ? '100%' : undefined;
  const getContent = (array: FormattedConstants[] | CalculatorInfoBlock[]) =>
    !isLoading ? (
      array.map((el) => {
        const { value, name, ...mappedEl } = el;
        if ('id' in mappedEl) {
          return (
            <Tooltip
              key={mappedEl.id}
              content={mappedEl.tooltip}
              icon="warning"
              place="left"
              $width={styleWidthTooltip}
            >
              <InfoCalculatorBlock header={value} description={name} icon={mappedEl.icon} headerSize="sm" />
            </Tooltip>
          );
        }

        return (
          <InfoCalculatorBlock
            header={value}
            key={value}
            description={name}
            image={mappedEl.image}
            headerSize="xl"
            isNftInfo
          />
        );
      })
    ) : (
      <>
        <InfoCalculatorBlock isLoading />
        <InfoCalculatorBlock isLoading />
      </>
    );

  const isShortContract = constants.type === CalculatorTypeNFT.SHORT;

  const productPrice = !isLoading && `${numeral(product?.price).format('0,0')} USDT`;
  const productTitle = !isLoading && product?.title;
  const productCollection = !isLoading && product?.collection.name;

  return (
    <CalculatedContentStyled $isShown={isShown}>
      <Column $gap="lg" $direction="column" $align="flex-start">
        {!isLoading ? <StyledImage src={product?.image} alt={productTitle || ''} /> : <StyledBlockSkeleton />}
        <Flex $gap="form" $direction="column" $align="flex-start">
          <P size="md" fontFamily="secondary" bold="lg" align="left">
            Estimation on BTC rate
          </P>
          {!isShortContract && (
            <EstimationBlock
              min={CalculatorConstants.minValueEstimation}
              value={estimation}
              onChange={handleOnChange}
              isTabChanged={isTabChanged}
              setIsTabChanged={setIsTabChanged}
            />
          )}
        </Flex>
        <LeftContainerInfo $isShortContract={isShortContract}>{getContent(calculatedConstants)}</LeftContainerInfo>
      </Column>
      <Column $gap="lg" $direction="column" $align="flex-start" $justify="space-between">
        <Flex $gap="lg" $direction="column" $align="flex-start">
          <Flex $gap="sm" $direction="column" $align="flex-start">
            <HeaderContent>{productTitle}</HeaderContent>
            <HeaderCollection size="md" color="secondary">
              {productCollection}
            </HeaderCollection>
          </Flex>
          <StatsGrid>{getContent(infoBlock)}</StatsGrid>
        </Flex>
        <Flex $gap="md" $direction="column">
          <Flex $gap="sm" $justify="flex-end">
            <StyledIcon name="usdt" />
            <PriceText size="md" color="tertiary" bold="lg" fontFamily="secondary" $isLoading={isLoading}>
              {productPrice}
            </PriceText>
          </Flex>
          <Flex>
            <Button.LinkIcon
              to={isShortContract ? AppRoutes.SHORT_CONTRACTS : AppRoutes.MARKET_ALL}
              state={{ shortContractID: product?.id }}
              icon="bag"
              size="large"
            >
              Add to cart
            </Button.LinkIcon>
          </Flex>
        </Flex>
      </Column>
    </CalculatedContentStyled>
  );
};
