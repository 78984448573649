import React from 'react';
import { Flex, P } from 'ui';
import { AppRoutes } from 'router';

import asicsData from 'pages/Landing/constants';
import Blur from '../Blur';
import {
  AsicCardWrapper,
  AsicImg,
  AsicPreview,
  AsicTitle,
  AttributeButton,
  AttributeIcon,
  AttributeItem,
  AttributeList,
  CoinIcon,
  BagIcon,
  LeftWrap,
  SliderImgWrapper,
  TitlePrimary,
  TitleWrap,
} from '../../style';

const SliderItem: React.FC<(typeof asicsData)[number] & { active: boolean }> = ({
  img,
  title,
  coin,
  active,
  subtitle,
  duration,
  effectivity,
  power,
  color,
  price,
  marketId,
}) => (
  <AsicCardWrapper $active={active}>
    <LeftWrap $active={active}>
      <SliderImgWrapper>
        <AsicImg src={img} alt={title} />
      </SliderImgWrapper>
      <Blur color={color} />
      <CoinIcon src={coin} $active={active} placement="left" alt="silver coin" />

      <AsicTitle fontFamily="secondary" size="md" $active={!active}>
        {title}
      </AsicTitle>
    </LeftWrap>
    {active && (
      <AsicPreview>
        <TitleWrap>
          <div>
            <TitlePrimary fontFamily="secondary" size="md" align="left">
              {title}
            </TitlePrimary>
            <P fontFamily="primary" size="sm" color="secondary" align="left">
              {subtitle}
            </P>
          </div>
          <CoinIcon src={coin} $active={active} placement="right" alt="gold coin" />
        </TitleWrap>
        <AttributeList>
          <AttributeItem>
            <AttributeIcon name="timer" />
            <P fontFamily="primary" size="md">
              {duration} months
            </P>
          </AttributeItem>
          <AttributeItem>
            <AttributeIcon name="speedDash" />
            <P fontFamily="primary" size="md">
              {power} MH/s
            </P>
          </AttributeItem>
          <AttributeItem>
            <AttributeIcon name="flash" />
            <P fontFamily="primary" size="md">
              {`${effectivity}`} kW/m
            </P>
          </AttributeItem>
        </AttributeList>
        <AttributeButton to={`${AppRoutes.MARKET_ALL}/${marketId}`} size="large">
          <Flex $maxWidth="100%" $gap="sm">
            {price}
            <BagIcon name="bag" />
          </Flex>
        </AttributeButton>
      </AsicPreview>
    )}
  </AsicCardWrapper>
);

export default SliderItem;
