import React from 'react';

import { Button, Line } from './style';

interface IBurgerMenuProps {
  isOpen: boolean;
  openMenu: () => void;
}

const BurgerMenu: React.FC<IBurgerMenuProps> = ({ isOpen, openMenu }) => (
  <Button onClick={openMenu}>
    <Line $isOpen={isOpen} />
    <Line $isOpen={isOpen} />
    <Line $isOpen={isOpen} />
  </Button>
);

export default BurgerMenu;
