import React, { useEffect, useState } from 'react';
import { Button, Flex, InputRangeDiscrete, Tab, TabsGroup } from 'ui';
import useMediaQuery from 'hooks/useMediaQuery';
import { getCalculatorDurability, getCalculatorStats } from 'store/slices/calculator/actionAsync';
import { useAppDispatch, useCalculatorSelector } from 'store';
import useNotification from 'hooks/useNotification';
import { CalculatedContent } from 'widgets/Calculator/components/CalculatedContent';
import { nanoid } from '@reduxjs/toolkit';
import { useActions } from 'hooks/useActions';
import { useCalculatorTabs } from './hooks';
import { WrapperCalculatedContent, DurabilityTitle } from './Calculator.styled';

export const Calculator: React.FC = () => {
  const { costArray, durability, constants, product, infoBlock, isLoading } = useCalculatorSelector();
  const [discrete, setDiscrete] = useState(durability[0]);
  const { isMobile } = useMediaQuery();
  const { errorNotification } = useNotification();
  const { clearStateCalculator } = useActions();
  const dispatch = useAppDispatch();
  const [isTabChanged, setIsTabChanged] = useState(false);

  const { tab, handleClickTab } = useCalculatorTabs(discrete, product);

  useEffect(() => {
    if (!durability.length) {
      dispatch(getCalculatorDurability());
    }

    return () => {
      dispatch(clearStateCalculator());
    };
  }, []);

  useEffect(() => {
    if (durability.length > 0) {
      setDiscrete(durability[0]);
    }
  }, [durability]);

  const calculate = async () => {
    if (!tab) return;

    const body = {
      durability: discrete,
      price: tab,
    };

    dispatch(getCalculatorStats(body))
      .unwrap()
      .catch(() => errorNotification("We can't get data. Please try again later"));
    setIsTabChanged(!isTabChanged);
  };

  const onChangeDiscrete = (discreteVal: number) => {
    setDiscrete(discreteVal);
  };

  if (!discrete && !costArray.length) return null;

  const currentPrices = costArray.find((el) => el.durability === discrete)?.prices || [];
  const sortedCurrentPrices = [...currentPrices]
    .sort((a, b) => a - b)
    .map((el) => ({
      value: el,
      id: nanoid(),
    }));

  const isTabActive = (tabId: number) => tab === tabId;
  const sizeButton = isMobile ? 'medium' : 'large';

  const showContent = isLoading || !!product;
  const isDisabled = !tab || (product?.price === tab && product.month_durability === discrete);

  return (
    <>
      <Flex $direction="column" $align="flex-start">
        <DurabilityTitle size="md" color="secondary" $isUpperCase fontFamily="secondary">
          Durability (months)
        </DurabilityTitle>
        <InputRangeDiscrete discrete={durability} onChange={onChangeDiscrete} defaultValue={discrete} />
      </Flex>
      <WrapperCalculatedContent>
        <Flex $gap="md" $direction="column" $align="flex-start">
          <DurabilityTitle align="left" size="md" fontFamily="secondary" color="secondary" $isUpperCase>
            Price
          </DurabilityTitle>
          <Flex $gap="md" $justify="space-between" $wrap={isMobile}>
            <TabsGroup>
              {sortedCurrentPrices.map(({ value, id }) => (
                <Tab onClick={() => handleClickTab(value)} key={id} active={isTabActive(value)}>
                  {value} USDT
                </Tab>
              ))}
            </TabsGroup>
            <Button
              size={sizeButton}
              maxWidth={isMobile ? '100%' : '10rem'}
              onClick={calculate}
              disabled={isDisabled}
              loading={isLoading}
            >
              Calculate
            </Button>
          </Flex>
        </Flex>
        <CalculatedContent
          price={tab}
          durability={discrete}
          infoBlock={infoBlock}
          constants={constants}
          product={product}
          isLoading={isLoading}
          isShown={showContent}
          isTabChanged={isTabChanged}
          setIsTabChanged={setIsTabChanged}
        />
      </WrapperCalculatedContent>
    </>
  );
};
