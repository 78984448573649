import colors from 'theme/colors';
import { styled } from 'styled-components';
import media from 'theme/media';
import { Flex } from 'ui';

export const NavigationList = styled.nav`
  display: flex;
  justify-content: space-between;
  margin: 0 7.5rem;
  align-items: center;
  background-color: ${colors.onBlack};
  transform: translateY(-50px);
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 0 3.75rem;

  @media (max-width: ${media.laptop}px) {
    margin: 0 3.75rem;
    padding: 0 2.5rem;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 0;
    margin: 0;
  }

  @media (max-width: ${media.mobile}px) {
    padding: 0 1rem;
  }
`;

export const Dots = styled(Flex)`
  gap: 0.5rem;
  @media (max-width: ${media.mobile}px) {
    display: none;
  }
`;

export const Dot = styled.div`
  background: ${colors.grey};
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
`;
