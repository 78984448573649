import React, { PropsWithChildren, MouseEvent } from 'react';

import { IconsName } from 'ui';
import { ButtonLink, ButtonText, ButtonWrapper, IconButton } from './Button.styled';

interface DefaultPropsButton {
  /**
   * Color Button
   */
  color?: 'primary' | 'secondary' | 'tertiary';
  /**
   * Size Button
   */
  size?: 'small' | 'medium' | 'large';
  className?: string;
  maxWidth?: string;
}

type IconsProps = {
  /**
   * Icon name
   */
  icon: (typeof IconsName)[number];
  /**
   * Icon position
   */
  align?: 'left' | 'right';
  /**
   * Only icon?
   */
  onlyIcon?: boolean;
};

interface ButtonProps extends DefaultPropsButton {
  /**
   * Type Button
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * Button action
   */
  onClick?: (e?: MouseEvent) => void;
  /**
   * Button is disabled?
   */
  disabled?: boolean;
  /**
   * Button is loading?
   */
  loading?: boolean;
  id?: string;
}

interface LinkProps extends DefaultPropsButton {
  to: string;
  state?: Record<string, unknown>;
  replace?: boolean;
  target?: '_target' | '_blank' | '_self' | '_parent' | '_top';
  rel?: 'noopener noreferer';
}

const Button = ({
  className,
  color = 'primary',
  size = 'medium',
  type = 'button',
  children,
  maxWidth,
  ...props
}: PropsWithChildren<ButtonProps>) =>
  props.loading ? (
    <ButtonWrapper
      $maxWidth={maxWidth}
      type={type}
      className={className}
      color={color}
      size={size}
      {...props}
      onClick={() => {}}
    >
      <IconButton name="loading" />
    </ButtonWrapper>
  ) : (
    <ButtonWrapper $maxWidth={maxWidth} type={type} className={className} color={color} size={size} {...props}>
      <ButtonText>{children}</ButtonText>
    </ButtonWrapper>
  );

Button.Icon = ({
  className,
  color = 'primary',
  size = 'medium',
  icon,
  align = 'right',
  type = 'button',
  onlyIcon = false,
  children,
  ...props
}: PropsWithChildren<ButtonProps & IconsProps>) =>
  props.loading ? (
    <ButtonWrapper
      type={type}
      className={className}
      color={color}
      size={size}
      {...props}
      $maxWidth={props.maxWidth}
      onClick={() => {}}
    >
      <IconButton name="loading" />
    </ButtonWrapper>
  ) : (
    <ButtonWrapper
      $maxWidth={props.maxWidth}
      type={type}
      size={size}
      color={color}
      className={className}
      $onlyIcon={onlyIcon}
      {...props}
    >
      {onlyIcon ? (
        <IconButton name={icon} />
      ) : (
        <>
          {align === 'left' && <IconButton name={icon} size={size} />}
          {children && <ButtonText>{children}</ButtonText>}
          {align === 'right' && <IconButton name={icon} />}
        </>
      )}
    </ButtonWrapper>
  );

Button.Link = ({
  className,
  color = 'primary',
  size = 'medium',
  to,
  target,
  rel,
  children,
  state,
  ...props
}: PropsWithChildren<LinkProps>) => (
  <ButtonLink
    $maxWidth={props.maxWidth}
    className={className}
    state={state}
    color={color}
    size={size}
    to={to}
    target={target}
    rel={rel}
    {...props}
  >
    <ButtonText>{children}</ButtonText>
  </ButtonLink>
);

Button.LinkIcon = ({
  className,
  color = 'primary',
  size = 'medium',
  to,
  target,
  align = 'right',
  icon,
  state,
  onlyIcon = false,
  children,
  ...props
}: PropsWithChildren<LinkProps & IconsProps>) => (
  <ButtonLink
    to={to}
    state={state}
    className={className}
    color={color}
    size={size}
    target={target}
    $onlyIcon={onlyIcon}
    $maxWidth={props.maxWidth}
    {...props}
  >
    {onlyIcon ? (
      <IconButton name={icon} />
    ) : (
      <>
        {align === 'left' && <IconButton name={icon} size={size} />}
        {children && <ButtonText>{children}</ButtonText>}
        {align === 'right' && <IconButton name={icon} />}
      </>
    )}
  </ButtonLink>
);

export default Button;
