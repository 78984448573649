import styled, { css } from 'styled-components';
import { Flex, Icon, P, Tooltip } from 'ui';

interface PropsInputWrapper {
  error?: string;
  disabled?: boolean;
  isLocked?: boolean;
}

export const ToolTipWrapper = styled(Tooltip)`
  width: 100%;
`;

export const ToolTipIcon = styled(Tooltip)`
  width: unset;
`;

export const InputWrapper = styled.div<PropsInputWrapper>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 3.5rem;
  width: 100%;
  border: 1px solid
    ${({ theme, error, disabled, isLocked }) => {
      if (isLocked) return theme.inputs.defaultBorderColor;
      if (disabled) return theme.inputs.disabledBorderColor;
      if (error) return theme.inputs.errorBorderColor;
      return theme.inputs.defaultBorderColor;
    }};
  border-radius: 8px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.inputs.disabledBackgroundColor : theme.inputs.backgroundColor};
  &:focus-within {
    border-color: ${({ theme, error }) => {
      if (error) return theme.inputs.errorBorderColor;
      return theme.inputs.activeBorderColor;
    }};
  }
  ${({ isLocked }) =>
    isLocked &&
    css`
      &:before {
        position: absolute;
        display: block;
        top: 1rem;
        right: 1rem;
        width: 24px;
        height: 24px;
        content: url('/inputLock.svg#inputLock');
      }
    `};
`;

interface PropsInputLabel {
  disabled?: boolean;
}

export const InputLabel = styled.label<PropsInputLabel>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1rem;
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  line-height: 1.5rem;
  transition: all 0.3s ease-in-out;
  color: ${({ theme, disabled }) => (disabled ? theme.inputs.disabledTextColor : theme.inputs.placeholderColor)};
  pointer-events: none;
  input:focus + &,
  input:not(:placeholder-shown) + & {
    color: ${({ theme }) => theme.inputs.placeholderColor};
    font-size: 0.75rem;
    top: 0.75rem;
    line-height: 0.75rem;
  }
`;

interface PropsInputField {
  error?: string;
  $isEllipsis?: boolean;
}

export const InputField = styled.input<PropsInputField>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1rem;
  flex: 1;
  border: none;
  max-width: none;
  background-color: transparent;
  line-height: 1rem;
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1rem;
  outline: none;
  text-overflow: ${({ $isEllipsis }) => ($isEllipsis ? 'ellipsis' : 'none')};
  overflow: ${({ $isEllipsis }) => ($isEllipsis ? 'hidden' : 'none')};
  color: ${({ theme, error }) => (error ? theme.inputs.errorTextColor : theme.inputs.textColor)};
  user-select: none;
  &::placeholder {
    color: transparent;
  }
  &:disabled {
    color: ${({ theme }) => theme.inputs.disabledTextColor};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const HideToggle = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1.5rem;
  color: ${({ theme }) => theme.inputs.placeholderColor};
  cursor: pointer;

  input:focus ~ &,
  input:not(:placeholder-shown) ~ & {
    color: ${({ theme }) => theme.inputs.textColor};
  }
`;

export const SmallInput = styled.input<PropsInputWrapper>`
  font-family: ${({ theme }) => theme.fonts.primary};
  height: 2.5rem;
  font-size: 1rem;
  border: 1px solid
    ${({ theme, error, disabled }) => {
      if (disabled) return theme.inputs.disabledBorderColor;
      if (error) return theme.inputs.errorBorderColor;
      return theme.inputs.defaultBorderColor;
    }};
  border-radius: 8px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.inputs.disabledBackgroundColor : theme.inputs.backgroundColor};
  outline: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: ${({ theme, error }) => (error ? theme.inputs.errorTextColor : theme.inputs.textColor)};
  &:disabled {
    color: ${({ theme }) => theme.inputs.disabledTextColor};
  }
  &:focus {
    border-color: ${({ theme, error }) => {
      if (error) return theme.inputs.errorBorderColor;
      return theme.inputs.activeBorderColor;
    }};
  }
  &::placeholder {
    color: ${({ theme, disabled }) => (disabled ? theme.inputs.disabledTextColor : theme.inputs.placeholderColor)};
  }
`;

export const InputNumberWrapper = styled.div<PropsInputWrapper>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0.25rem 0.75rem;
  border-radius: 2rem;
  background: ${({ theme, disabled }) =>
    disabled ? theme.inputs.disabledBackgroundColor : theme.inputs.backgroundInputNumberColor};
  user-select: none;
  &:focus-within {
    border: 1px solid ${({ theme }) => theme.inputs.activeBorderColor};
  }
`;

export const InputNumberText = styled.p<PropsInputWrapper>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.inputs.textColorSecondary};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const IconInputNumber = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.inputs.textColor};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.inputs.textColorSecondary};
  }
`;

export const InputRangeWrapper = styled.div`
  width: 100%;
`;
export const CustomInputRange = styled.input<{ min?: number; max?: number; $width?: number | string }>`
  width: 100%;
  height: 1.25rem;
  background: ${({ $width, theme }) =>
    css`
    linear-gradient(
            to right,
            ${theme.inputs.progressColor} 0%,
            ${theme.inputs.progressColor} ${$width}%,
            ${theme.inputs.trackColor} ${$width}%,
            ${theme.inputs.trackColor} 100%
    )`};
  border: none;
  border-radius: 8px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    height: 1.25rem;
    border-radius: 0.5rem;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0.375rem;
    height: 1.875rem;
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.inputs.thumbColor};
    margin-top: calc((1.25rem / 2) - (1.875rem / 2)); // track height / 2 - thumb height / 2
  }
`;

export const RangeValue = styled(P)`
  margin: 0.75rem 0 0 0;
`;

export const RadioWrapper = styled(Flex)`
  margin: 0 0 0.5rem 0;
  gap: 1.5rem;
`;
export const Radio = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

export const TextRadio = styled.label`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.fontsColor.primary};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const RadioContainer = styled(Flex)`
  gap: 0.75rem;
  width: fit-content;
`;

export const CustomRadioContainer = styled(Flex)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.inputs.radioBorder};
  background-color: ${({ theme }) => theme.inputs.radioBg};
`;

type CustomRadioProps = {
  $checked: boolean;
};
export const CustomRadio = styled.div<CustomRadioProps>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ theme, $checked }) => ($checked ? theme.inputs.radioChecked : 'transparent')};
`;

export const InputRangeDiscreteStyled = styled.input.attrs({ type: 'range' })<{ $isGrabbing: boolean }>`
  width: 100%;
  height: 0.5rem;
  background: #2e2e2e;
  border: none;
  border-radius: 8px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    height: 1.25rem;
    border-radius: 0.5rem;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.inputs.thumbColor};
    margin-top: calc((1.25rem / 2) - (1.875rem / 2));
    cursor: ${({ $isGrabbing }) => ($isGrabbing ? 'grabbing' : 'grab')};
  }
`;

export const GridInput = styled.div<{ $columns: number }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  gap: 0;
  height: 1rem;
  width: 100%;
  align-items: center;

  & > ${P} {
    width: min-content;
    white-space: nowrap;

    &:first-child {
      align-self: flex-start;
      text-align: left;
    }
    &:last-child {
      align-self: flex-end;
      text-align: end;
    }
  }
`;

export const StyledP = styled(P)<{ $left: number; $amendmentOnThumb: number }>`
  position: absolute;
  top: 0;
  left: ${({ $left, $amendmentOnThumb }) => `calc(${$left}% - 1.875rem * (${$amendmentOnThumb} - 50) / 100)`};
  transform: translateX(-50%);
  white-space: nowrap;
`;

export const DiscreteFlex = styled(Flex)<{ $width: number }>`
  width: ${({ $width }) => `calc(100% - ${$width}px)`};
`;
