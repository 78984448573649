import React from 'react';
import { CoinsStats, DataCenterMain, ActiveNft, CurrentCoinStats, PowerGrid } from 'pages';
import { ControllerBaseLayout } from 'router/ControllerBaseLayout';
import { PrivateRouteProvider } from 'components/AuthComponents/AuthProvider';
import bg from './PowerGrid/assets/bg.jfif';

export const dataCenterRoute = {
  path: 'data-center',
  children: [
    {
      path: 'nft',
      element: (
        <ControllerBaseLayout bg="primary" isHaveButtonBack={false}>
          <PrivateRouteProvider>
            <DataCenterMain />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'power-grid',
      element: (
        <ControllerBaseLayout bg="" isImageBg isHaveButtonBack={false}>
          <PrivateRouteProvider>
            <PowerGrid />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'coin',
      element: (
        <ControllerBaseLayout bg="secondary" isHaveButtonBack>
          <PrivateRouteProvider>
            <CoinsStats />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'coin/:coinId',
      element: (
        <ControllerBaseLayout bg="secondary" isHaveButtonBack>
          <PrivateRouteProvider>
            <CurrentCoinStats />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'nft/:id',
      element: (
        <ControllerBaseLayout bg="secondary" isHaveButtonBack>
          <PrivateRouteProvider>
            <ActiveNft />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
  ],
};
