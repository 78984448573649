import { styled } from 'styled-components';

export const LineWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  width: 100%;
  margin: auto;
  align-items: center;
  gap: 0.7rem;
  max-width: 17.8125rem;
`;

export const LineDiv = styled.div`
  height: 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.fontsColor.secondary};
`;
