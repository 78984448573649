import { styled } from 'styled-components';
import { colors, font } from 'theme';
import { Icon } from 'ui';

export const LiquidCalendarStyled = styled.div`
  width: 100%;
  max-width: 343px;
  color: #a0a0a0;
  text-decoration: none;
  font-family: ${font.primary};
  padding: 16px;
  background-color: #2e2e2e;
  border-radius: 8px;
  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  }
  .react-calendar {
    background-color: #2e2e2e;
    margin-bottom: 8px;
    border: none;
  }
  .react-calendar__navigation button:disabled {
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background: none;
  }
  .react-calendar__navigation__label__labelText {
    color: #ffd966;
    font-family: ${font.primary};
    font-size: 20px;
    font-style: normal;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #ffffff;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }
  .react-calendar__tile {
    font-family: ${font.primary};
    text-transform: uppercase;
    color: #ffffff;
    font-size: 20px;
  }
  .react-calendar__tile:disabled {
    background: none;
    color: inherit;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    transition: all 0.3s ease;
    background: #ffd966;
    color: #242424;
    border-radius: 4px;
  }

  .react-calendar__tile--now {
    background: none;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffd966;
    color: #242424;
    border-radius: 4px;
  }

  .react-calendar__tile--hasActive {
    background: #ffd966;
    color: #242424;
    border-radius: 4px;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #ffd966;
    color: #242424;
    border-radius: 4px;
  }

  .react-calendar__tile--active {
    background: #5f5f5f;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #ffd966;
    color: #242424;
    border-radius: 4px;
  }

  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    background: #ffd966;
    color: #242424;
    border-radius: 4px;
  }

  .react-calendar__navigation__prev-button {
    color: #ffd966;
    font-size: 30px;
  }
  .react-calendar__navigation__next-button {
    color: #ffd966;
    font-size: 30px;
  }
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background: #5f5f5f;
  }
`;

export const MobileDatePicker = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.app.backgroundPrimary};
`;

export const MobileCalendar = styled(LiquidCalendarStyled)`
  position: absolute;
  top: 13rem;
`;

export const MobileDatePickerClose = styled(Icon)`
  position: absolute;
  top: 3.25rem;
  right: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  color: ${colors.white};
`;

export const MobileDatePickerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 6.5rem 1rem 0;
  & > button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #ffd966;
  }
`;

export const MobileTriggerButton = styled.button<{ $isSelected?: boolean }>`
  width: 100%;
  padding: ${({ $isSelected }) => ($isSelected ? '0.34rem' : '0.75rem')} 1.25rem;
  font-size: ${({ $isSelected }) => ($isSelected ? '0.75rem' : '1rem')};
  border: 1px solid #5f5f5f;
  border-radius: 0.5rem;
  background-color: #2e2e2e;
  color: #a0a0a0;
  font-family: ${font.primary};
  text-align: left;
  cursor: pointer;
  & > span {
    color: white;
    padding-top: 0.25rem;
    font-size: 1rem;
  }
`;
