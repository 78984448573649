import React from 'react';
import { PrivateRouteProvider } from 'components/AuthComponents/AuthProvider';
import { AuthLayoutController } from 'router/AuthLayoutController';
import { OnRamp } from 'pages/Payment/OnRamp';

export const paymentRoutes = {
  path: 'payment',
  children: [
    {
      path: 'on-ramp/check',
      element: (
        <AuthLayoutController hasBackButton={false}>
          <PrivateRouteProvider>
            <OnRamp />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
  ],
};
