import React, { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css/effect-fade';

import AboutSection from './style';
import Info from './components/Info';
import { aboutData, timeout } from './constants';
import Navigation from './components/Navigation';

interface IAboutProps {
  refValue: React.RefObject<HTMLDivElement>;
}

const About: React.FC<IAboutProps> = ({ refValue }) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleSelectLink = (index: number) => {
    swiper?.slideTo(index);
    setCurrentSlideIndex(index);
  };

  return (
    <AboutSection>
      <Navigation refValue={refValue} onInfoSelect={handleSelectLink} currentSlideIndex={currentSlideIndex} />

      <Swiper
        modules={[EffectFade, Autoplay]}
        autoplay={{
          delay: timeout,
          disableOnInteraction: false,
        }}
        fadeEffect={{ crossFade: true }}
        onSwiper={setSwiper}
        onSlideChange={(e) => setCurrentSlideIndex(e.activeIndex)}
        effect="fade"
      >
        {aboutData.map((el) => (
          <SwiperSlide key={el.locale}>
            <Info aboutData={el} />
          </SwiperSlide>
        ))}
      </Swiper>
    </AboutSection>
  );
};

export default About;
