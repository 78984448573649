import { useEffect } from 'react';

const setBodyPosition = (overflow: string, position: string) => {
  document.body.style.position = position;
  document.body.style.overflowY = overflow;
};

const useBodyBlockScroll = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      setBodyPosition('hidden', 'absolute');
    } else {
      setBodyPosition('unset', 'unset');
    }
    return () => {
      setBodyPosition('unset', 'unset');
    };
  }, [isOpen]);
};

export default useBodyBlockScroll;
