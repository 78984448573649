import { styled } from 'styled-components';

export const ProgressLineInner = styled.div<{ $percent: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ $percent }) => $percent}%;
  transition: width 0.3s ease-in-out;
  height: 100%;
  background-color: ${({ theme }) => theme.progressLine.bgActiveColor};
`;

export const ProgressLineOuter = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.progressLine.bgColor};
`;

export const ProgressLineWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  height: 0.25rem;
`;
