import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getFaqLiquidation,
  getFaqSearchList,
  getFaqSupport,
  getFaqSupportSearchList,
  getFAQTopics,
} from 'store/slices/faq/actionAsync';
import { FAQType, IFAQSliceStore, TopicsType } from 'store/slices/faq/types';

const initialState: IFAQSliceStore = {
  faq: [],
  topics: [],
  faqSearchList: [],
  isLoading: false,
};

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setFaqSearchList: (state, action) => {
      state.faqSearchList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFaqLiquidation.fulfilled, (state, action: PayloadAction<FAQType[]>) => {
        state.faq = action.payload;
      })
      .addCase(getFaqSearchList.fulfilled, (state, action: PayloadAction<FAQType[]>) => {
        state.faqSearchList = action.payload;
      })
      .addCase(getFaqSupport.fulfilled, (state, action: PayloadAction<FAQType[]>) => {
        state.faq = action.payload;
        state.faqSearchList = [];
      })
      .addCase(getFAQTopics.fulfilled, (state, action: PayloadAction<TopicsType[]>) => {
        state.topics = action.payload;
      })
      .addCase(getFaqSupportSearchList.fulfilled, (state, action: PayloadAction<FAQType[]>) => {
        state.faqSearchList = action.payload;
      })
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('faq') && type.endsWith('/pending');
        },
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('faq') && type.endsWith('/rejected');
        },
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('faq') && type.endsWith('/fulfilled');
        },
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const { setFaqSearchList } = faqSlice.actions;

export default faqSlice.reducer;
