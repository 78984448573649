import { styled } from 'styled-components';
import media from 'theme/media';
import offsets from 'theme/offsets';
import { P } from 'ui/Text';
import { CustomButton } from 'pages/Landing/style';

export const DemoBlockButton = styled(CustomButton)`
  width: 100%;
  max-width: 160px;
  display: block;
  @media (max-width: ${media.mobile}px) {
    max-width: 100%;
  }
`;

export const VideoStart = styled.section`
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 100vh;
`;

export const VideoBg = styled.video`
  width: 100%;
  position: absolute;
  z-index: -1;
  opacity: 0.6;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  object-fit: cover;
  object-position: 50% 50%;
  height: 100vh;
`;

export const DemoTitle = styled(P)`
  text-transform: uppercase;
`;

export const StartSectionInner = styled.div`
  padding: ${offsets.sectionVertical} ${offsets.sectionHorizontal};
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: ${media.tablet}px) {
    padding: 0 40px;
  }

  @media (max-width: ${media.mobile}px) {
    padding: 0 16px;
  }
`;

export const RP = styled(P)<{ max: number; $marginTop?: string }>`
  margin-top: ${({ $marginTop }) => $marginTop ?? 'unset'};
  max-width: ${({ max }) => max}px;
`;
