import { styled } from 'styled-components';
import { media } from 'theme';
import colors from 'theme/colors';

type LineProps = {
  $isOpen: boolean;
};

export const Line = styled.span<LineProps>`
  display: block;
  width: 30px;
  height: 3px;
  border-radius: 3px;
  background-color: #000;
  margin-bottom: 8px;
  background-color: ${colors.white};
  transition: 0.2s;

  &:nth-child(2) {
    width: 24px;
    margin-left: auto;
    opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)};
  }

  &:first-child {
    transform: ${({ $isOpen }) => ($isOpen ? 'translateY(11px) rotate(45deg)' : 'translateY(0) rotate(0)')};
  }

  &:last-child {
    transform: ${({ $isOpen }) => ($isOpen ? 'translateY(-11px) rotate(-45deg)' : 'translateY(0) rotate(0)')};
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: none;
  position: relative;
  z-index: 6;

  @media (max-width: ${media.tablet}px) {
    display: block;
  }
`;
