import { Action } from '@reduxjs/toolkit';
import { COST_ENERGY_PER_KW } from 'appConstants/Energy';
import {
  BuilderType,
  EnergyObject,
  InitStateBag,
  MarketObject,
  MarketObjectBuilder,
  MarketObjectEnergy,
  PriceType,
  UnSyncNFTMarketType,
  UnSyncNFTSCType,
} from './types';

const addNFTItem = (NFTs: UnSyncNFTMarketType[], payload: UnSyncNFTMarketType) => {
  const NFTsItem = NFTs.find((el) => el.id === payload.id);

  if (payload.count === 0) {
    const result = NFTs.filter((el) => el.id !== payload.id);
    return result;
  }

  if (!NFTsItem) {
    NFTs.push({ ...payload });
    return NFTs;
  }

  return NFTs.map((el) =>
    el.id === payload.id
      ? {
          ...el,
          count: payload.count,
        }
      : el,
  );
};

export const addingNFTs = (bag: UnSyncNFTMarketType[], payload: UnSyncNFTMarketType) => addNFTItem(bag, payload);
export const addingSC = (bag: UnSyncNFTSCType[], payload: UnSyncNFTSCType) => {
  const resultBag = bag.reduce<UnSyncNFTSCType[]>((acc, currentValue) => {
    if (currentValue.product.contract_purchase_id === payload.product.contract_purchase_id) {
      return [...acc, payload];
    }

    return [...acc, currentValue];
  }, []);

  const ItemWithSameDurability = resultBag.find(
    (el) => el.product.contract_purchase_id === payload.product.contract_purchase_id,
  );

  if (!ItemWithSameDurability) return [...resultBag, payload];

  return resultBag;
};

export const incrementPrice = (price: PriceType, currentPrice: number, type: keyof PriceType): PriceType => ({
  ...price,
  [type]: price[type] + currentPrice,
  total: price.total + currentPrice,
});

export const decrementPrice = (price: PriceType, currentPrice: number, type: keyof PriceType): PriceType => ({
  ...price,
  [type]: price[type] - currentPrice,
  total: price.total - currentPrice,
});

export const recountPrice = (stateBag: InitStateBag, type: keyof PriceType): PriceType => {
  const NFTs =
    type === 'NFTs'
      ? stateBag.nft.reduce<number>((acc, item) => acc + item.product.price * item.count, 0)
      : stateBag.price.NFTs;

  const shortContact =
    type === 'shortContact'
      ? stateBag.shortContract.reduce((acc, currentValue) => acc + currentValue.product.price, 0)
      : stateBag.price.shortContact;

  const energy = type === 'energy' ? (stateBag.energy?.count || 0) * COST_ENERGY_PER_KW : stateBag.price.energy;
  const builder =
    type === 'builder'
      ? stateBag.builder.reduce<number>((acc, build) => acc + build.builder_item.price, 0)
      : stateBag.price.builder;

  return {
    NFTs,
    energy,
    builder,
    shortContact,
    total: NFTs + energy + builder + shortContact,
  };
};

export const recountUnSyncPrice = (stateBag: InitStateBag): PriceType => {
  const unSyncPriceMarket = stateBag.unSyncNFTMarket.reduce((acc, item) => acc + item.product.price * item.count, 0);
  const unSyncPriceSC = stateBag.unSyncNFTSC.reduce((acc, item) => acc + item.product.price, 0);

  return {
    NFTs: unSyncPriceMarket,
    energy: 0,
    builder: 0,
    shortContact: unSyncPriceSC,
    total: unSyncPriceSC + unSyncPriceMarket,
  };
};

export const countPrice = (
  market: MarketObject[],
  energy: MarketObjectEnergy | null,
  builder: MarketObjectBuilder[],
  shortContract: MarketObject[],
): PriceType => {
  const energyCost = energy ? energy.count * COST_ENERGY_PER_KW : 0;
  const builderCost = builder.reduce<number>((acc, build) => acc + build.builder_item.price, 0);
  const marketCost = market.reduce<number>((acc, item) => acc + item.product.price * item.count, 0);
  const shortContractCost = shortContract.reduce((acc, item) => acc + item.product.price, 0);

  return {
    total: energyCost + builderCost + marketCost + shortContractCost,
    shortContact: shortContractCost,
    energy: energyCost,
    builder: builderCost,
    NFTs: marketCost,
  };
};

export const syncNft = (market: MarketObject[], unSyncMarket: UnSyncNFTMarketType[]): MarketObject[] => {
  const NFTNotNeededSync = market.map((initObject) => ({
    ...initObject,
    count: initObject.count + (unSyncMarket.find((el) => el.id === initObject.id)?.count || 0),
  }));

  return NFTNotNeededSync;
};
export const deleteSyncedNFT = (market: MarketObject[], unSyncMarket: UnSyncNFTMarketType[]): UnSyncNFTMarketType[] => {
  const SyncedNft = unSyncMarket.filter(({ id }) => !market.some(({ product }) => id === product.id));

  return SyncedNft;
};

export const LogoutMatcher = (action: Action) => action.type.includes('logout');
