import styled, { css } from 'styled-components';
import { colors, media, SkeletonStyle } from 'theme';
import { Flex, H2, Icon, P } from 'ui';

export const CalculatedContentStyled = styled.div<{ $isShown?: boolean }>`
  width: 100%;
  height: 100%;
  padding: ${({ $isShown }) => ($isShown ? '2rem' : '0')};

  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: 2.5rem;
  border: 2px solid ${colors.whiteTransparent};
  border-radius: 0.75rem;
  visibility: ${({ $isShown }) => ($isShown ? 'visible' : 'hidden')};
  opacity: ${({ $isShown }) => ($isShown ? '1' : '0')};
  transition: all 1.2s ease-in-out, padding 0s ease-in-out;
  overflow: hidden;
  max-height: ${({ $isShown }) => ($isShown ? '2000px' : '0')};
  margin-top: ${({ $isShown }) => ($isShown ? '3.75rem' : '0')};

  @media (max-width: ${media.laptop}px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${media.tablet}px) {
    padding: ${({ $isShown }) => ($isShown ? '1.5rem' : '0')};
  }

  @media (max-width: ${media.mobile}px) {
    padding: ${({ $isShown }) => ($isShown ? '1.25rem 1.5rem' : '0')};
  }
`;

export const Column = styled(Flex)`
  width: 100%;
  height: 100%;
`;

export const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 0.75rem;
  aspect-ratio: 4 / 3;
`;

export const StyledBlockSkeleton = styled.div`
  ${SkeletonStyle({
    width: '100%',
    aspectRatio: '4 / 3',
    borderRadius: '0.75rem',
  })};
`;

export const HeaderContent = styled(H2)`
  font-size: 2rem;

  @media (max-width: ${media.laptop}px) {
    font-size: 1.5rem;
  }

  @media (max-width: ${media.mobile}px) {
    font-size: 1.5rem;
  }
`;

export const LeftContainerInfo = styled.div<{ $isShortContract: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: ${media.mobile}px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
`;

export const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;
  justify-content: stretch;
  align-content: center;

  @media (max-width: ${media.laptop}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${media.mobile}px) {
    grid-template-columns: 1fr;
  }

  :nth-child(3) {
    @media (max-width: ${media.laptop}px) {
      grid-area: 2 / span 2;
    }
    @media (max-width: ${media.mobile}px) {
      grid-area: unset;
    }
  }
`;

export const PriceText = styled(P)<{ $isLoading?: boolean }>`
  white-space: nowrap;
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      display: block;
      ${SkeletonStyle({ width: '8rem', height: '1.5rem', borderRadius: '0.25rem' })}
    `}
`;

export const HeaderCollection = styled(P)`
  line-height: 140%;
`;
