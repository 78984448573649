import { styled } from 'styled-components';
import ReactCodeInput from 'react-code-input';

export const CodeInputStyled = styled(ReactCodeInput)<{ $isInValid?: boolean }>`
  width: 100%;
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.75rem;

  & > input {
    border-radius: 0.5rem;
    font-family: ${({ theme }) => theme.fonts.secondary};
    border: 1px solid
      ${({ theme, $isInValid }) => ($isInValid ? theme.codeInput.incorrectBorderColor : theme.codeInput.borderColor)};
    background: ${({ theme }) => theme.codeInput.bgColor};
    color: ${({ theme }) => theme.codeInput.fontsColor};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.06rem;
    padding: 1rem 0;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    -moz-appearance: textfield;

    &:focus {
      border: 1px solid
        ${({ theme, $isInValid }) =>
          $isInValid ? theme.codeInput.incorrectBorderColor : theme.codeInput.activeBorderColor};
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:invalid {
      border: 1px solid ${({ theme }) => theme.codeInput.incorrectBorderColor};
    }
  }
`;
