export const getSeparatedNumber = (value: number) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getBillingPeriod = (dateArgument?: string) => {
  if (!dateArgument) return '';
  const billingPeriodEnd = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }).format(new Date(dateArgument));
  const billingDate = new Date(dateArgument);
  const billingDateMonthEarlier = billingDate.setMonth(billingDate.getMonth() - 1);
  const billingPeriodStart = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }).format(new Date(billingDateMonthEarlier));
  const [monthStart, dayStart, yearStart] = billingPeriodStart.split('/');
  const [monthEnd, dayEnd, yearEnd] = billingPeriodEnd.split('/');

  return `${dayStart}/${monthStart}/${yearStart}-${dayEnd}/${monthEnd}/${yearEnd}`;
};
