import { ApiEndpoints, instance } from 'services/client';
import {
  GetMiningActivityParamsType,
  GetTransactionParamsType,
  GetTransfersParamsType,
  IPartialWithdrawCoin,
  ISendCodeToEmail,
  ISendCoin,
  IUpdateWallet,
  IValidateAddress,
  IWithdrawCoin,
} from 'types/profile';

class BalanceService {
  static async getBalance(page: number) {
    const response = await instance.get(`${ApiEndpoints.v2.balance.getBalance}`, {
      params: {
        page,
      },
    });

    return response;
  }

  static async getTransactions(params: GetTransactionParamsType) {
    const response = await instance.get(`${ApiEndpoints.v2.balance.getTransactions}`, {
      params,
    });

    return response;
  }

  static async getTransfers(params: GetTransfersParamsType) {
    const response = await instance.get(`${ApiEndpoints.v2.balance.getTransfers}`, {
      params,
    });

    return response;
  }

  static async getMiningActivity(params: GetMiningActivityParamsType) {
    const response = await instance.get(`${ApiEndpoints.v2.balance.getMiningActivity}`, {
      params,
    });

    return response;
  }

  static async sendCodeToEmail(body: ISendCodeToEmail) {
    const response = await instance.post(`${ApiEndpoints.v2.balance.sendCodeToEmail}`, {
      ...body,
    });

    return response;
  }

  static async sendCoin(body: ISendCoin) {
    const response = await instance.post(`${ApiEndpoints.v2.balance.sendCoin}`, [
      {
        ...body,
      },
    ]);

    return response;
  }

  static async getUserId(username: string) {
    const response = await instance.get(`${ApiEndpoints.v2.balance.getUserId}${username}/`);

    return response;
  }

  static async withdrawCoin(body: IWithdrawCoin) {
    const response = await instance.post(`${ApiEndpoints.v2.balance.withdrawCoin}`, [body]);

    return response;
  }

  static async partialCoinWithdraw(body: IPartialWithdrawCoin) {
    const response = await instance.post(`${ApiEndpoints.v2.balance.partialCoinWithdraw}`, body);

    return response;
  }

  static async validateAddress(body: IValidateAddress) {
    const response = await instance.post(`${ApiEndpoints.v2.balance.validateAddress}`, body);

    return response;
  }

  static async updateWallet({ id, ...body }: IUpdateWallet) {
    const response = await instance.post(`${ApiEndpoints.v2.balance.updateWallet}${id}/create_or_update/`, body);

    return response;
  }

  static async getUserByWallet(wallet_address__in: string) {
    const response = await instance.get(`${ApiEndpoints.v2.balance.getUserByWallet}`, {
      params: {
        wallet_address__in,
      },
    });

    return response;
  }
}

export default BalanceService;
