import { styled } from 'styled-components';
import { Flex, Icon, P } from 'ui';
import colors from 'theme/colors';

export const SearchStyled = styled.div`
  width: 100%;
  position: relative;
`;

export const MenuListItemStyled = styled(P)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  width: 100%;
  text-align: left;

  &:hover {
    color: ${colors.yellow};
  }
`;

export const MenuListWrapStyled = styled(Flex)`
  gap: 0;
`;

export const SearchInputWrapStyled = styled.div`
  position: relative;
  width: 100%;
`;

export const IconStyled = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${colors.grey};
  margin-right: 1.25rem;
  cursor: pointer;

  input:focus ~ &,
  input:not(:placeholder-shown) ~ & {
    color: ${({ theme }) => theme.inputs.textColor};
  }
`;
