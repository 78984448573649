import React, { PropsWithChildren, useEffect } from 'react';
import { useActions } from 'hooks/useActions';

interface IControllerBaseLayout {
  hasBackButton: boolean;
}

export const AuthLayoutController: React.FC<PropsWithChildren<IControllerBaseLayout>> = ({
  children,
  hasBackButton,
}) => {
  const actions = useActions();

  useEffect(() => {
    actions.setHasBackButtonAuth(hasBackButton);
  }, [hasBackButton, actions]);

  return children;
};
