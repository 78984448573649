import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'ui';
import { H3, P } from 'ui/Text';
import useMediaQuery from 'hooks/useMediaQuery';
import { IMAGE_URL } from 'assets/Urls';
import { Links } from 'appConstants/Links';
import { MobileLinkButton, ButtonLine, LeftWrapper, PhoneImg, SectionContainer, SectionWrap, TitleWrap } from './style';

interface IMobilePrevProps {
  refValue: React.RefObject<HTMLDivElement>;
}

const MobilePreview: React.FC<IMobilePrevProps> = ({ refValue }) => {
  const { t } = useTranslation('landing');
  const { isLaptop } = useMediaQuery();

  const getImageSize = () => {
    let width = 496;
    let height = 725;

    if (isLaptop) {
      width = 326;
      height = 476;
    }
    return {
      width,
      height,
    };
  };

  const { width, height } = getImageSize();
  return (
    <SectionWrap ref={refValue} $background={IMAGE_URL.Landing.LinesPattern}>
      <SectionContainer>
        <LeftWrapper>
          <TitleWrap>
            <P fontFamily="secondary" size="md" color="secondary" align="left">
              {t('mobile_preview_block.category')}
            </P>
            <H3>{t('mobile_preview_block.title')}</H3>
          </TitleWrap>
          <P size="md" align="left">
            {t('mobile_preview_block.text_1')}
            <br />
            <br />
            {t('mobile_preview_block.text_2')}
            <br />
            <br />
            {t('mobile_preview_block.text_3')}
          </P>
          <ButtonLine>
            <MobileLinkButton to={Links.downloadAppAndroid} size="large" color="secondary">
              <Icon name="playMarket" />
              {t('mobile_preview_block.android')}
            </MobileLinkButton>
            <MobileLinkButton to={Links.downloadAppIOS} size="large" color="secondary">
              <Icon name="appStore" />
              {t('mobile_preview_block.ios')}
            </MobileLinkButton>
          </ButtonLine>
        </LeftWrapper>
        <PhoneImg
          src={IMAGE_URL.Landing.ApplicationMockup}
          width={width}
          height={height}
          loading="lazy"
          alt="liquid mobile app"
        />
      </SectionContainer>
    </SectionWrap>
  );
};

export default MobilePreview;
