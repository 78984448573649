import { ApiEndpointsSpring, instanceSpring } from 'src/services/Spring/client';

class RewardsService {
  static async getMerkleTree(username: string) {
    const response = await instanceSpring.get(ApiEndpointsSpring.merkleTree, {
      params: { username },
    });

    return response;
  }

  static async getMerkleTreeShares(username: string) {
    const response = await instanceSpring.get(ApiEndpointsSpring.shares, {
      params: { username },
    });

    return response;
  }

  static async getMerkleTreeForex(username: string) {
    const response = await instanceSpring.get(ApiEndpointsSpring.forexPool, {
      params: { username },
    });

    return response;
  }

  static async getMerkleTreeEvent(username: string) {
    const response = await instanceSpring.get(ApiEndpointsSpring.eventPool, {
      params: { username },
    });

    return response;
  }

  static async getMerkleTreeToken(username: string) {
    const response = await instanceSpring.get(ApiEndpointsSpring.tokenPool, {
      params: { username },
    });

    return response;
  }

  static async getPayoutsTimeline() {
    const response = await instanceSpring.get(ApiEndpointsSpring.payoutsTimeline);

    return response;
  }
}

export default RewardsService;
