import styled from 'styled-components';

export const CopyToClipboardButton = styled.span`
  background: transparent;
  color: inherit;
  cursor: pointer;
  outline: none;

  &:focus-visible {
    text-decoration: underline;
  }
`;
