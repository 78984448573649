import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { Loader } from 'ui';
import { AuthProviderWrapper } from 'components/AuthComponents/AuthProvider/AuthProvider.styled';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';
import { useLocation, useSearchParams } from 'react-router-dom';
import { HealthCheck } from 'pages/Tech/HealthCheck';
import Cookies from 'js-cookie';
import { healthCheck, meRequest, meRequestShort, setTelegramUsername } from 'store/slices/user/actionAsync';
import { ClickerConstantsList } from '../../../constants';

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isLoaded, setLoaded] = useState(true);
  const [isServerDead, setIsServerDead] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const token = Cookies.get(LocalStorageKeys.TOKEN_KEY);
  const telegramFromClicker = localStorage.getItem(LocalStorageKeys.TELEGRAM_USERNAME);

  const isFromClicker = searchParams.get(ClickerConstantsList.clicker);
  const userFromClickerTelegram = searchParams.get(ClickerConstantsList.user) || '';

  useEffect(() => {
    if (isFromClicker) {
      localStorage.setItem(LocalStorageKeys.TELEGRAM_USERNAME, userFromClickerTelegram);

      searchParams.delete(ClickerConstantsList.clicker);
      searchParams.delete(ClickerConstantsList.user);
      searchParams.delete(ClickerConstantsList.auth);

      setSearchParams(searchParams);
    }
  }, [isFromClicker]);

  useEffect(() => {
    const getServerStatus = async () => {
      try {
        await dispatch(healthCheck()).unwrap();
        setIsServerDead(false);
      } catch (err) {
        setIsServerDead(true);
      }
    };

    getServerStatus();
  }, [pathname]);

  useEffect(() => {
    const getFirstUserInfo = async () => {
      // TODO: REMOVE THIS CONDITION WHEN TASK LM2-2247 WOULD DONE
      if (token && !pathname.includes('open')) {
        try {
          await dispatch(meRequest(token)).unwrap();
          await dispatch(meRequestShort()).unwrap();
        } catch (error: any) {
          console.error(error.message);
        }
      }

      setLoaded(false);
    };

    getFirstUserInfo();
  }, []);

  useEffect(() => {
    if (token && telegramFromClicker) {
      dispatch(meRequestShort())
        .unwrap()
        .then(() => {
          dispatch(setTelegramUsername(telegramFromClicker));
          localStorage.removeItem(LocalStorageKeys.TELEGRAM_USERNAME);
        });
    }
  }, [token, telegramFromClicker]);

  if (isServerDead) {
    return <HealthCheck />;
  }

  if (isLoaded)
    return (
      <AuthProviderWrapper>
        <Loader />
      </AuthProviderWrapper>
    );

  return children;
};

export default AuthProvider;
