import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery';
import { Link } from 'react-router-dom';
import { Flex } from 'ui';
import headerLinks from 'pages/Landing/components/Header/constants';
import logo from '../../../../../public/logo.svg';
import { NavLink, NavLinks, HeaderWrap } from './style';
import BurgerMenu from './components/Burger';
import {
  HeaderMenuWrapper,
  HeaderMenuLiquid,
  HeaderLinksWrapper,
  HeaderMenuBackground,
} from './components/HeaderMenu.styled';

interface IHeaderProps {
  refs: React.RefObject<HTMLDivElement>[];
}

const Header: React.FC<IHeaderProps> = ({ refs }) => {
  const { t } = useTranslation('landing');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const { isMobile, isTablet } = useMediaQuery();
  const handleMenuOpen = () => {
    setIsMenuOpen((prev) => !prev);
    setIsBurgerOpen((prev) => !prev);
  };

  const handleLinkClick = (index: number) => {
    document.body.style.position = 'unset';
    refs[index].current?.scrollIntoView();
    setIsMenuOpen(false);
    setIsBurgerOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.position = 'fixed';
    } else {
      document.body.style.overflowY = 'unset';
      document.body.style.position = 'unset';
    }
  }, [isMenuOpen]);

  return (
    <HeaderWrap>
      <Link to="/">
        <img src={logo} alt="logo" width={223} height={62} />
      </Link>
      <BurgerMenu isOpen={isBurgerOpen} openMenu={handleMenuOpen} />
      <NavLinks>
        {headerLinks.map(({ value }, i) => (
          <NavLink key={value} onClick={() => handleLinkClick(i)}>
            {t(`header_links.${value}`)}
          </NavLink>
        ))}
      </NavLinks>
      {(isMobile || isTablet) && (
        <>
          <HeaderMenuWrapper $isOpen={isMenuOpen}>
            <Flex $direction="column" $align="flex-start" $gap="xl">
              <Flex $justify="space-between">
                <HeaderMenuLiquid name="logo-text" />
              </Flex>
              <HeaderLinksWrapper $direction="column" $align="flex-start">
                {headerLinks.map(({ value }, i) => (
                  <NavLink key={value} onClick={() => handleLinkClick(i)}>
                    {t(`header_links.${value}`)}
                  </NavLink>
                ))}
              </HeaderLinksWrapper>
            </Flex>
          </HeaderMenuWrapper>
          <HeaderMenuBackground $isOpen={isMenuOpen} onClick={handleMenuOpen} />
        </>
      )}
    </HeaderWrap>
  );
};

export default Header;
