import { styled } from 'styled-components';
import offsets from 'theme/offsets';
import { Icon } from 'ui/Icon';
import media from 'theme/media';
import colors from 'theme/colors';
import { P, H3 } from 'ui/Text';
import { Button } from 'ui';

type StartMiningSectionProps = {
  $background: string;
};

export const StartMiningSection = styled.section<StartMiningSectionProps>`
  background-image: ${({ $background }) => `url(${$background})`};
  display: flex;
  flex-direction: column;
  background-color: ${colors.onBlack};
  align-items: center;
  gap: ${offsets.extraLarge};
  padding-bottom: ${offsets.sectionVertical};
`;

export const StartMiningTitle = styled(H3)`
  text-align: center;
  max-width: 44.375rem;
`;

export const StartMiningDescription = styled(P)`
  text-align: center;
  max-width: 30.5rem;
`;

export const AsicCardWrapper = styled.article<{ $active?: boolean }>`
  cursor: pointer;
  position: relative;

  height: 309px;
  padding: 32px 16px;
  border-radius: 12px;
  border: 2px solid ${({ $active }) => ($active ? colors.yellow : '#ffffff20')};
  background: #ffffff01;
  backdrop-filter: blur(25px);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${offsets.medium};
  width: 100%;

  @media (max-width: ${media.mobile}px) {
    flex-direction: column;
    height: fit-content;
  }
`;

export const SwiperWrapper = styled.div`
  width: 100%;
  & .swiper {
    padding: 0 20px;
    padding: 0 20px 2px 20px;
  }
  & .swiper-wrapper {
    align-items: center;
  }

  @media (max-width: ${media.mobile}px) {
    height: 580px;
  }
`;

export const PaddingContainer = styled.div`
  padding: ${offsets.sectionVertical} ${offsets.sectionHorizontal} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${offsets.medium};

  @media (max-width: ${media.laptop}px) {
    padding: 80px 60px;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 60px 40px;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 60px 16px;
  }
`;

type CoinIconProps = {
  placement?: 'left' | 'right';
  $active?: boolean;
};

const getOpacityCoin = ({ $active, placement }: CoinIconProps) => {
  switch (placement) {
    case 'left':
      return $active ? '0' : '1';

    case 'right':
      return $active ? '1' : '0';

    default:
      return '1';
  }
};

export const CoinIcon = styled.img<CoinIconProps>`
  width: ${({ placement }) => (placement === 'right' ? '44px' : '60px')};
  opacity: ${(attrs) => getOpacityCoin(attrs)};
  aspect-ratio: 1;
  flex-shrink: 0;
`;

export const AsicPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;

  @media (max-width: ${media.mobile}px) {
    gap: 24px;
  }
`;

export const AsicTitle = styled(P)<{ $active?: boolean }>`
  opacity: ${({ $active }) => ($active ? '1' : '0')};
  text-align: center;
  font-weight: 600;
  align-items: center;
`;

export const AttributeList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AttributeItem = styled.li`
  display: flex;
  gap: 12px;
  align-items: center;
  & > p {
    font-weight: 600;
  }
`;

export const AttributeIcon = styled(Icon)`
  width: 24px;
  aspect-ratio: 1;
  color: ${colors.grey};
`;

export const TitleWrap = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TitlePrimary = styled(P)`
  font-weight: 600;
`;

export const AsicImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 20px;
  @media (max-width: ${media.tablet}px) {
    z-index: 1;
    margin-bottom: 0;
    position: relative;
  }
`;

export const SliderImgWrapper = styled.div`
  width: 299px;
  height: 224px;
`;

export const LeftWrap = styled.div<{ $active?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  transform: translateZ(0);

  & ${CoinIcon} {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translateX(-50%);
  }
`;

export const BagIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;

export const AttributeButton = styled(Button.Link)`
  width: 100%;
`;

export default StartMiningSection;
