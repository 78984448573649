import { CalculatorFormattedConstants, CalculatorTypeNFT } from 'types/calculator';
import { useMemo } from 'react';
import { IconName } from 'ui';
import { transformNumber } from 'utils/transformNumber';

interface CalculatedContentProps {
  constants: CalculatorFormattedConstants;
  durability: number;
  estimation: number;
}

export type FormattedConstants = {
  value: string;
  name: string;
  icon: IconName;
  id: string;
  tooltip: string;
};

export const useGetCalculatedContent = ({
  constants,
  estimation,
  durability,
}: CalculatedContentProps): FormattedConstants[] => {
  const { type, totalMiningReward } = constants;
  const transformCurrentNumber = (current: number) => {
    if (current > 0 && current < 1) return transformNumber(current, '0.[00000]');

    return transformNumber(current, '0.[00]');
  };

  return useMemo<FormattedConstants[]>(() => {
    if (type === CalculatorTypeNFT.SHORT) {
      return [
        {
          name: 'Total mining reward',
          value: transformCurrentNumber(totalMiningReward),
          icon: 'btc',
          id: 2 + type,
          tooltip: `Based on current BTC exchange rate`,
        },
        {
          name: 'Total reward',
          value: transformCurrentNumber(constants.totalReward),
          icon: 'usdt',
          id: 3 + type,
          tooltip: `Will be returned to your wallet`,
        },
      ];
    }

    const dailyRewardUSDT = constants.dailyReward * estimation;
    const totalRewardUSDT = constants.dailyReward * 30.5 * estimation * durability;
    const dailyRewardBTC = constants.dailyReward;
    const totalRewardBTC = totalMiningReward;

    return [
      {
        value: transformCurrentNumber(dailyRewardUSDT),
        name: 'Daily reward',
        icon: 'usdt',
        id: 2 + type,
        tooltip: `${dailyRewardUSDT} USDT`,
      },
      {
        value: transformCurrentNumber(totalRewardUSDT),
        name: 'Total reward',
        icon: 'usdt',
        id: 3 + type,
        tooltip: `${totalRewardUSDT} USDT`,
      },
      {
        value: transformCurrentNumber(dailyRewardBTC),
        name: 'Daily mining reward',
        icon: 'btc',
        id: 4 + type,
        tooltip: `${dailyRewardBTC} BTC`,
      },
      {
        value: transformCurrentNumber(totalRewardBTC),
        name: 'Total mining reward',
        icon: 'btc',
        id: 5 + type,
        tooltip: `${totalRewardBTC} BTC`,
      },
    ];
  }, [constants, estimation]);
};
