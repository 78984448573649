import { ILandingLocale, HeaderLinkType } from 'pages/Landing/types';

const headerLinks: ILandingLocale<HeaderLinkType>[] = [
  { id: 0, value: 'project' },
  { id: 1, value: 'how_it_works' },
  { id: 2, value: 'equipment' },
  { id: 3, value: 'the_race' },
  { id: 4, value: 'mobile_app' },
];

export default headerLinks;
