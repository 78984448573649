import styled, { css } from 'styled-components';
import { media } from 'theme';

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  font-size: 4rem;
  line-height: 120%;
  letter-spacing: 0.16rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.fontsColor.primary};

  @media (max-width: ${media.laptop}px) {
    font-size: 3.5rem;
  }

  @media (max-width: ${media.mobile}px) {
    font-size: 3rem;
  }
`;

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 120%;
  letter-spacing: 0.14rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.fontsColor.primary};

  @media (max-width: ${media.laptop}px) {
    font-size: 3rem;
  }

  @media (max-width: ${media.mobile}px) {
    font-size: 2.5rem;
  }
`;

export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  font-size: 3rem;
  line-height: 120%;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.fontsColor.primary};

  @media (max-width: ${media.laptop}px) {
    font-size: 2.5rem;
  }

  @media (max-width: ${media.mobile}px) {
    font-size: 2rem;
  }
`;

export const H4 = styled.h4`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 120%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.fontsColor.primary};

  @media (max-width: ${media.laptop}px) {
    font-size: 2rem;
  }

  @media (max-width: ${media.mobile}px) {
    font-size: 1.5rem;
  }
`;

export const H5 = styled.h5`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.08rem;
  color: ${({ theme }) => theme.fontsColor.primary};

  @media (max-width: ${media.laptop}px) {
    font-size: 1.5rem;
  }
`;

export const H6 = styled.h6`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.06rem;
  color: ${({ theme }) => theme.fontsColor.primary};

  @media (max-width: ${media.laptop}px) {
    font-size: 1.25rem;
  }
`;

export const EM = styled.em`
  color: ${({ theme }) => theme.fontsColor.tertiary};
  font-style: normal;
`;

interface PProps {
  fontFamily?: 'primary' | 'secondary';
  color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'error';
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  bold?: 'sm' | 'md' | 'lg';
  align?: 'left' | 'center' | 'right';
  letterSpacing?: boolean;
  $whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line';
  $isUpperCase?: boolean;
}

const getSizeP = (size: PProps['size']) => {
  switch (size) {
    case 'xs':
      return css`
        font-size: 0.75rem;
        line-height: 120%;
      `;
    case 'sm':
      return css`
        font-size: 0.875rem;
        line-height: 120%;
      `;
    case 'lg':
      return css`
        font-size: 1.125rem;
        line-height: 120%;
      `;
    case 'xl':
      return css`
        font-size: 1.25rem;
        line-height: 120%;
      `;
    case 'xxl':
      return css`
        font-size: 1.5rem;
        line-height: 120%;
      `;
    case 'md':
    default:
      return css`
        font-size: 1rem;
        line-height: 120%;
      `;
  }
};

const getBoldP = (bold: PProps['bold'], fontFamily: PProps['fontFamily']) => {
  switch (bold) {
    case 'md':
      return 500;
    case 'lg':
      if (fontFamily === 'secondary') {
        return 700;
      }
      return 700;
    case 'sm':
    default:
      if (fontFamily === 'secondary') {
        return 300;
      }
      return 400;
  }
};

export const P = styled.p<PProps>`
  font-family: ${({ theme, fontFamily = 'primary' }) =>
    fontFamily === 'secondary' ? theme.fonts.secondary : theme.fonts.primary};
  color: ${({ theme, color = 'primary' }) => theme.fontsColor[color]};
  ${({ size }) => getSizeP(size)};
  letter-spacing: ${({ letterSpacing }) => (letterSpacing ? `0.04rem` : 0)};
  ${({ bold, fontFamily }) => `font-weight: ${getBoldP(bold, fontFamily)}`};
  ${({ $whiteSpace }) => `white-space: ${$whiteSpace || 'normal'}`};
  ${({ align }) => `text-align: ${align || 'center'}`};
  ${({ $isUpperCase }) => `text-transform: ${$isUpperCase ? 'uppercase' : 'unset'}`}
`;
