import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  hasBackButton: boolean;
  navigateBackPagesAmount: number;
};

const initialState: InitialState = {
  hasBackButton: false,
  navigateBackPagesAmount: -1,
};

const authLayoutSlice = createSlice({
  name: 'authLayout',
  initialState,
  reducers: {
    setHasBackButtonAuth: (state: InitialState, action: PayloadAction<boolean>) => {
      state.hasBackButton = action.payload;
    },
    setNavigateBackPagesAmount: (state: InitialState, action: PayloadAction<number>) => {
      state.navigateBackPagesAmount = action.payload;
    },
  },
});

export const { setHasBackButtonAuth, setNavigateBackPagesAmount } = authLayoutSlice.actions;

export default authLayoutSlice.reducer;
