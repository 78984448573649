import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  hasBackButton: boolean;
  isPureOutlet: boolean;
};

const initialState: InitialState = {
  hasBackButton: false,
  isPureOutlet: false,
};

const liquidationLayoutSlice = createSlice({
  name: 'liquidationLayoutSlice',
  initialState,
  reducers: {
    setHasBackButtonLiquidationLayout: (state: InitialState, action: PayloadAction<boolean>) => {
      state.hasBackButton = action.payload;
    },
    setIsPureOutletLiquidationLayout: (state: InitialState, action: PayloadAction<boolean>) => {
      state.isPureOutlet = action.payload;
    },
  },
});

export const { setHasBackButtonLiquidationLayout, setIsPureOutletLiquidationLayout } = liquidationLayoutSlice.actions;

export default liquidationLayoutSlice.reducer;
