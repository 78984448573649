import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import {
  NotificationResponseType,
  NotificationCategoriesResponseType,
  GetNotificationParamsType,
} from 'types/notifications';
import { getSimpleErrorMessage } from 'utils/error';

export const getNotifications = createAsyncThunk<
  NotificationResponseType,
  GetNotificationParamsType | undefined,
  ThunkApiConfig
>('notifications', async (params, { extra: services, rejectWithValue }) => {
  const notificationsResponse: any = await services.httpService.NotificationService.getNotifications(params);

  if (notificationsResponse.isError) {
    const message = getSimpleErrorMessage(notificationsResponse.data);

    return rejectWithValue({ message, isError: true });
  }

  return notificationsResponse.data;
});

export const getNotificationCategories = createAsyncThunk<NotificationCategoriesResponseType, void, ThunkApiConfig>(
  'notifications/categories',
  async (_, { extra: services, rejectWithValue }) => {
    const notificationsResponse: any = await services.httpService.NotificationService.getNotificationCategories();

    if (notificationsResponse.isError) {
      const message = getSimpleErrorMessage(notificationsResponse.data);

      return rejectWithValue({ message, isError: true });
    }

    return notificationsResponse.data;
  },
);

export const postNotifications = createAsyncThunk<boolean, void, ThunkApiConfig>(
  'notifications/patch',
  async (params, { extra: services, rejectWithValue, getState }) => {
    const { notification } = getState();
    const notificationIDs = notification.notifications.map(({ id: notificationID }) => notificationID);
    const notificationsResponse: any = await services.httpService.NotificationService.postNotifications(
      notificationIDs,
    );

    if (notificationsResponse.isError) {
      const message = getSimpleErrorMessage(notificationsResponse.data);

      return rejectWithValue({ message, isError: true });
    }

    return true;
  },
);
