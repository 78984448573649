import { css, styled } from 'styled-components';
import { Flex } from 'ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { colors, media } from 'theme';

export const TabsWrapperParent = styled(Flex)`
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;

  position: relative;
`;

export const TabsSwiperWrapper = styled(Swiper)`
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: -1;
  padding-top: 2px;
`;

export const TabsSwiperSlide = styled(SwiperSlide)`
  width: max-content;
`;

export const TabsNavigationButton = styled.button<{
  $isLeftSide?: boolean;
  $isVisible?: boolean;
  $isPrimaryBackground?: boolean;
}>`
  margin-top: 2px;
  position: absolute;
  top: 0;
  z-index: 0;
  opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};

  ${({ $isLeftSide }) =>
    $isLeftSide
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}

  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;

  border: 1px solid ${colors.buttonSecondaryStroke};
  background-color: ${colors.onBlack};

  -webkit-box-shadow: 0.5rem 0 1rem 2rem
    ${({ $isPrimaryBackground }) => ($isPrimaryBackground ? 'rgba(28, 28, 28, 1)' : 'rgba(36, 36, 36, 1)')};
  -moz-box-shadow: 0.5rem 0 1rem 2rem
    ${({ $isPrimaryBackground }) => ($isPrimaryBackground ? 'rgba(28, 28, 28, 1)' : 'rgba(36, 36, 36, 1)')};
  box-shadow: 0.5rem 0 1rem 2rem
    ${({ $isPrimaryBackground }) => ($isPrimaryBackground ? 'rgba(28, 28, 28, 1)' : 'rgba(36, 36, 36, 1)')};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: all 0.3s linear;

  &:hover {
    border-color: ${colors.yellow};
  }

  & > svg {
    width: 1.25rem;
    height: 1.25rem;

    color: ${colors.white};
  }

  @media screen and (max-width: ${media.mobile}px) {
    width: 2.25rem;
    height: 2.25rem;
  }
`;
