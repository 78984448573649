import React from 'react';
import { getVaultSelector, useAppDispatch, useAppSelector } from 'store';
import useNotification from 'hooks/useNotification';
import { AppRoutes } from 'router';
import useAppNavigate from 'hooks/useAppNavigate';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { confirmRegistrationPhoneRequest, resendPhoneVerifyCodeRequest } from 'store/slices/vault/actionAsync';
import PhoneVerifyPage from 'pages/Auth/PhoneVerify/PhoneVerify.component';

const ConfirmPhoneRegistration: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { errorNotification } = useNotification();
  const { phone } = useAppSelector(getVaultSelector);

  const onSubmit = async (data: { code: string }, setErrorCallback: UseFormSetError<{ code: string }>) => {
    try {
      await dispatch(confirmRegistrationPhoneRequest({ code: data.code, phone })).unwrap();
      navigate(AppRoutes.CONGRATS_REGISTRATION, { state: { isRedirectedFromParent: true } });
    } catch (error: any) {
      errorNotification(error.message === 'Incorrect code' ? 'Invalid verification code' : error.message);
      setErrorCallback('code', { message: error.message, type: 'custom' });
    }
  };

  const onResendCode = async () => {
    try {
      await dispatch(resendPhoneVerifyCodeRequest({ phone })).unwrap();
    } catch (error: any) {
      errorNotification(error.message);
    }
  };

  return (
    <PhoneVerifyPage
      onSubmit={onSubmit}
      onResendCode={onResendCode}
      text="A verification code was sent to the phone number. Enter the received code below"
    />
  );
};

export default ConfirmPhoneRegistration;
