import React, { useEffect, useState } from 'react';
import { calculateTooltipPosition } from 'ui/Tooltip/utils/tooltipUtils';
import useMediaQuery from 'hooks/useMediaQuery';
import { ComponentWrapper, TooltipWrapper, TooltipIcon, TooltipPosition } from './Tooltip.styled';
import { IconsName } from '../Icon';

export interface TooltipProps {
  className?: string;
  children: React.ReactNode;
  content: React.ReactNode;
  icon: (typeof IconsName)[number];
  inline?: boolean;
  inactive?: boolean;
  arrowLeft?: number;
  disableAroundArrowPosition?: boolean;
  $width?: string;
  place?: 'top' | 'bottom' | 'combine' | 'left' | 'right';
}

const Tooltip = ({
  className,
  children,
  content,
  icon,
  inline,
  inactive,
  $width,
  place,
  arrowLeft,
  disableAroundArrowPosition,
}: TooltipProps) => {
  const [position, setPosition] = useState<TooltipPosition | null>(null);
  const { isMobile } = useMediaQuery();

  const mouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const positionCalculated = calculateTooltipPosition(rect, place, disableAroundArrowPosition, isMobile);
    setPosition(positionCalculated);
  };

  const mouseLeave = () => {
    setPosition(null);
  };

  useEffect(() => {
    if (position) window.addEventListener('scroll', mouseLeave);

    return () => {
      if (position) window.removeEventListener('scroll', mouseLeave);
    };
  }, [position]);

  return (
    <ComponentWrapper
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={className}
      inline={inline}
      $width={$width}
    >
      {children}
      {position && !inactive && (
        <TooltipWrapper
          $top={position.$top}
          $bottom={position.$bottom}
          $left={position.$left}
          $right={position.$right}
          $arrowOnTop={position.$arrowOnTop}
          $arrowLeft={arrowLeft || position.$arrowLeft}
          $arrowRight={position.$arrowRight}
          $maxWidth={position.$maxWidth}
          $width={position.$width}
          data-test-id="tooltip"
        >
          {icon && <TooltipIcon name={icon} />}
          {content}
        </TooltipWrapper>
      )}
    </ComponentWrapper>
  );
};

export default Tooltip;
