export const LocalStorageKeys = {
  REF_USER: 'REF_USER',
  RACE_REFERRAL: 'RACE_REFERRAL',
  TRON_WALLET_ADAPTER: 'tronAdapterName',
  SIGN_IN_STATUS: 'SIGN_IN_STATUS',
  BIND_STATUS: 'BIND_STATUS',
  TOKEN_KEY: 'ACCESS_TOKEN',
  REFRESH_KEY: 'REFRESH_TOKEN',
  FREE_CASE_DATE: 'FREE_CASE_DATE',
  FREE_CASE_USED_NON_AUTH: 'FREE_CASE_USED_NON_AUTH',
  WALLET_CONNECT_SUBSCRIPTION: 'wc@2:core:0.3//subscription',
  WALLET_CONNECT_PAIRING: 'wc@2:core:0.3//pairing',
  ANIMATION_KEY: 'animation',
  NANO_ID: 'nanoid',
  SPRING_TOKEN: 'SPRING_TOKEN',
  TELEGRAM_USERNAME: 'TELEGRAM_USERNAME',
  VAULT_TOKEN_KEY: 'VAULT_ACCESS_TOKEN',
  VAULT_REFRESH_KEY: 'VAULT_REFRESH_TOKEN',
};
