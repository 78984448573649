export const ApiEndpoints = {
  v1: {
    users: {
      encrypt_phrase: 'v1/base/encrypt_phrase/',
      resend_code_verify_email: 'v1/base/code/',
      code_verify: 'v1/base/code/exists/',
    },
    support: {
      getFAQ: 'v1/support/faq/',
      getFAQTopics: 'v1/support/faq-topics/',
    },
    blog: {
      get_all: 'v1/blog/',
      get_categories: 'v1/blog/categories/',
    },
  },
  v2: {
    mock_version: 'v2/',
    achievements: {
      get_all: 'v2/achievement/',
      statistic: 'v2/achievement/statistic/',
    },
    marketplace: {
      get_products_collection: 'v2/marketplace/product_collection_pages/',
      get_products_pages: 'v2/marketplace/product_pages/',
      get_products: 'v2/marketplace/products/',
      get_sc_landing: 'v2/marketplace/sc-product-landing/',
      get_product_short_contact: '/v2/marketplace/sc-product-pages/',
    },
    dataCenter: {
      get_coins: 'v2/wallet/crypto_tokens/',
      get_coin: 'v2/wallet/coin_stats/',
      allItems: 'v2/items/data_center/',
    },
    builder: {
      mainUrl: 'v2/builder/items/',
      productEqp: 'v2/builder/product_equipment/',
    },
    cart: {
      marketItems: 'v2/cart/items/',
      addProduct: 'v2/cart/items/add-product/',
      addProductCode: 'v2/cart/items/add-product-code/',
      getVaultCommission: 'v2/cart/checkout',
      energy: 'v2/builder/power_grid/',
      builder: 'v2/builder/items/',
      getGemsSignature: 'v2/marketplace/get-signature/',
      purchase: '/v2/cart/purchase',
    },
    calculator: {
      default: 'v2/calculators/btc-calculator/',
      durability: '/v2/calculators/btc-calculator/durability/',
    },
    powerGrid: {
      powerGridGeneral: 'v2/users/power_grid/',
      mostEnergyNft: 'v2/rigs/max_energy_consumption_mining_setup/',
      energyGraph: 'v2/rigs/energy_activity/graph/',
      consumptionSetups: 'v2/items/consumption_setups/',
    },
    myNfts: {
      getAllNfts: 'v2/items/my_nfts/',
      getPrice: 'v2/items/my_nfts/get_price/',
      generateSignature: 'v2/items/my_nfts/generate_signature/',
    },
    users: {
      user_wallet_login: 'v2/users/wallet/login/',
      email_registration: 'v2/users/registration/',
      email_login: 'v2/users/login/',
      check_user: 'v2/users/verify_user/',
      confirm_registration_email: 'v2/users/registration/confirm/',
      update_email: 'v2/users/set_email/pre-confirm/',
      confirm_update_email: 'v2/users/set_email/confirm/',
      bind_email: 'v2/users/set_email_and_password/pre-confirm/',
      confirm_bind_email: 'v2/users/set_email_and_password/confirm/',
      me: 'v2/users/me/',
      meShort: 'v2/users/me/short/',
      update_password: 'v2/users/forgot_password/',
      change_password: 'v2/users/change_password/',
      confirm_update_password: 'v2/users/forgot_password/confirm/',
      setMyReferrer: 'v2/users/set_referral/',
      bindWallet: 'v2/users/wallet/bind/',
      registerWallet: 'v2/users/wallet/register/',
      loginWallet: 'v2/users/wallet/login/',
      setUsername: 'v2/users/set_username/',
      refreshTokenBeforeLogout: 'v2/users/token/refresh/',
      healthCheck: 'v1/base/status/',
      telegramUsername: 'v2/users/set_telegram_username/',
      setPhone: 'v2/users/set_phone_number/',
    },
    balance: {
      getBalance: 'v2/users/user_wallets/',
      getTransactions: 'v2/wallet/transactions/',
      getTransfers: 'v2/wallet/transfer',
      getMiningActivity: 'v2/wallet/mining_activity',
      sendCodeToEmail: 'v1/base/code/',
      sendCoin: 'v1/wallet/payments/transfers/',
      withdrawCoin: 'v1/wallet/payments/transactions/',
      partialCoinWithdraw: '/v2/wallet/transactions/',
      validateAddress: 'v1/wallet/validate/address/',
      updateWallet: 'v2/users/user_wallets/',
      getUserId: 'v2/users/profiles/',
      getUserByWallet: 'v1/users/profiles/',
    },
    gems: {
      getTransactions: 'v2/gems/transactions',
    },
    liquidation: {
      cases: 'v2/liquidation/cases',
      freeCase: 'v2/liquidation/cases/non-auth-open-free-case/',
      prizes: 'v2/liquidation/prizes',
      faq: 'v2/liquidation/faq/',
      userPrizes: 'v2/liquidation/user_prizes',
      casesRibbon: '/v2/liquidation/cases/ribbon/',
      freeCaseUuid: 'v2/liquidation/uuid',
    },
    vault: {
      vaultRegistration: 'v2/services/vault/registration/',
      confirmPhoneRegistration: '/v2/services/vault/phone/confirm/',
      vaultAuthorize: '/v2/services/vault/login/',
      resend_code: '/v2/services/vault/phone/resend/',
      personal_data: '/v2/services/vault/personal-data/',
      kyc1start: '/v2/services/vault/kyc/1/start/',
      kyc1finish: '/v2/services/vault/kyc/1/finish/',
      kyc: '/v2/services/vault/kyc/',
      addEmail: '/v2/services/vault/email/add/',
      purchaseGems: '/v2/cart/purchase/gems',
      vaultWalletConnect: 'v2/services/vault/connect/',
      vaultUserInfo: '/v2/services/vault/user-info/',
    },
  },
  v3: {
    users: {
      logout: 'v3/users/logout/',
    },
    notifications: {
      getNotifications: 'v3/notifications/',
      getNotificationCategories: 'v3/notifications/categories/',
      postNotifications: 'v3/notifications/read/',
    },
  },
} as const;
