import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { AppRoutes } from 'router';
import { IMAGE_URL } from 'assets/Urls';
import { PreviewButton, PreviewText, PreviewWrapper } from './style';
import { Desc } from '../../style';

const DescLimited = styled(Desc)`
  max-width: 516px;
  line-height: 24px;
  text-align: center;
`;

const Preview: React.FC = () => {
  const { t } = useTranslation('landing');
  return (
    <PreviewWrapper $background={IMAGE_URL.Landing.LinesPattern}>
      <PreviewText>{t('preview_block.title')}</PreviewText>
      <DescLimited>{t('preview_block.text')}</DescLimited>
      <PreviewButton size="large" to={AppRoutes.MARKET_ALL}>
        {t('preview_block.button_text')}
      </PreviewButton>
    </PreviewWrapper>
  );
};

export default Preview;
