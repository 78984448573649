import React from 'react';
import { ControllerBaseLayout } from 'router/ControllerBaseLayout';
import { PrivateRouteProvider } from 'components/AuthComponents/AuthProvider';
import { Inventory } from './Inventory';
import { NewBuild } from './Build';

export const builderRoute = {
  path: 'builder',
  children: [
    {
      path: 'inventory',
      element: (
        <ControllerBaseLayout bg="secondary" isHaveButtonBack={false}>
          <PrivateRouteProvider>
            <Inventory />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'create',
      element: (
        <ControllerBaseLayout bg="secondary" isHaveButtonBack={false}>
          <PrivateRouteProvider>
            <NewBuild isEdit={false} />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'edit/:id',
      element: (
        <ControllerBaseLayout bg="secondary" isHaveButtonBack={false}>
          <PrivateRouteProvider>
            <NewBuild isEdit />
          </PrivateRouteProvider>
        </ControllerBaseLayout>
      ),
    },
  ],
};
