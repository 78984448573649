import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon } from 'ui/Icon';
import { AppLink } from 'components/AppComponents';

interface PropsButtonWrapper {
  size: 'small' | 'medium' | 'large';
  color: 'primary' | 'secondary' | 'tertiary';
  $onlyIcon?: boolean;
  $maxWidth?: string;
}

const getButtonSize = (size: PropsButtonWrapper['size'], color: PropsButtonWrapper['color'], $onlyIcon?: boolean) => {
  if (color === 'tertiary')
    return css`
      padding: 0;
      width: min-content;
    `;

  if ($onlyIcon)
    return css`
      padding: 0.75em;
      width: min-content;
    `;

  switch (size) {
    case 'small':
      return css`
        padding: 0.5rem 1.5rem;
        width: 100%;
      `;
    case 'medium':
      return css`
        padding: 0.625rem 1.5rem;
        width: 100%;
      `;
    case 'large':
    default:
      return css`
        padding: 1rem 2rem;
        width: 100%;
      `;
  }
};

const getColorButton = (theme: DefaultTheme, color: PropsButtonWrapper['color']) => theme.buttons[color];

export const ButtonText = styled.span`
  line-height: 140%;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonWrapper = styled.button<PropsButtonWrapper>`
  background-color: ${({ theme, color }) => getColorButton(theme, color).default.bgColor};
  color: ${({ theme, color }) => getColorButton(theme, color).default.textColor};
  border: ${({ theme, color }) => `1px solid ${getColorButton(theme, color).default.borderColor}`};
  border-radius: 0.5rem;
  padding: 1em 2em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  ${({ size, $onlyIcon, color }) => getButtonSize(size, color, $onlyIcon)}
  overflow: hidden;
  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};

  &:hover {
    background-color: ${({ theme, color }) => getColorButton(theme, color).hover.bgColor};
    color: ${({ theme, color }) => getColorButton(theme, color).hover.textColor};
    border: ${({ theme, color }) => `1px solid ${getColorButton(theme, color).hover.borderColor}`};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme, color }) => getColorButton(theme, color).disabled.bgColor};
    color: ${({ theme, color }) => getColorButton(theme, color).disabled.textColor};
    border: ${({ theme, color }) => `1px solid ${getColorButton(theme, color).disabled.borderColor}`};
  }
`;

export const ButtonLink = styled(ButtonWrapper).attrs({ as: AppLink })`
  text-decoration: none;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const IconButton = styled(Icon)`
  ${({ name, size }) =>
    name
      ? css`
          width: ${size === 'small' ? '1rem' : '1.25rem'};
          min-width: ${size === 'small' ? '1rem' : '1.25rem'};
          height: ${size === 'small' ? '1rem' : '1.25rem'};

          ${name === 'loading' &&
          css`
            min-width: ${size === 'small' ? '1rem' : '1.25rem'};
            width: ${size === 'small' ? '1rem' : '1.25rem'};
            height: ${size === 'small' ? '1rem' : '1.25rem'};

            animation: ${spin} 1s linear infinite;
          `}
        `
      : css`
          display: none;
        `}
`;
