import { styled } from 'styled-components';
import offsets from 'theme/offsets';
import colors from 'theme/colors';
import media from 'theme/media';
import { Button } from 'ui/index';
import { CustomButton } from 'pages/Landing/style';

export const MobileLinkButton = styled(CustomButton)`
  width: 100%;
  & > span > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  & > span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

type SectionWrapProps = {
  $background: string;
};

export const SectionWrap = styled.section<SectionWrapProps>`
  background-image: ${({ $background }) => `url(${$background})`};
  background-size: cover;
  background-color: ${colors.black};
`;

export const SectionContainer = styled.div`
  padding: 8.125rem ${offsets.sectionHorizontal};
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: ${offsets.large};

  @media (max-width: ${media.laptop}px) {
    padding: 3.75rem;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 3.75rem 2.5rem;
    gap: 0;
  }

  @media (max-width: ${media.mobile}px) {
    padding: 3.75rem 1rem;
    grid-template-columns: 1fr;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 32.25rem;
  gap: ${offsets.large};

  @media (max-width: ${media.mobile}px) {
    max-width: 100%;
  }
`;

export const PhoneImg = styled.img`
  object-fit: cover;
  width: 100%;
  max-width: 31rem;
  aspect-ratio: 2/3;
  height: auto;

  @media (max-width: ${media.mobile}px) {
    display: none;
  }
`;

export const ButtonLine = styled.div`
  display: grid;
  grid-template-columns: 10.18rem 10.18rem auto;
  gap: ${offsets.medium};

  @media (max-width: ${media.mobile}px) {
    grid-template-columns: 1fr;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsets.medium};
`;

export const WideIconButton = styled(Button.Icon)`
  @media (max-width: ${media.mobile}px) {
    width: 100%;
  }
`;
