import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import {
  GetMiningActivityParamsType,
  GetTransactionParamsType,
  GetTransfersParamsType,
  IPartialWithdrawCoin,
  ISendCodeToEmail,
  ISendCoin,
  IUpdateWallet,
  IValidateAddress,
  IWithdrawCoin,
} from 'src/types/profile';
import { getSimpleErrorMessage } from 'utils/error';
import {
  Coin,
  MiningActivityType,
  ResultSendCodeToEmailType,
  ResultSendCoinType,
  TransactionType,
  TransferType,
} from './type';

export const getBalance = createAsyncThunk<{ results: Coin[] }, void, ThunkApiConfig>(
  'balance',
  async (_: void, { extra, rejectWithValue }) => {
    const getBalanceResponse: any = await extra.httpService.BalanceService.getBalance(1);

    return getBalanceResponse.data;
  },
);

export const getTransactions = createAsyncThunk<
  { results: TransactionType[] },
  GetTransactionParamsType,
  ThunkApiConfig
>('balance/transactions', async (data, { extra, rejectWithValue, getState }) => {
  const { balance } = getState();
  const requestParams = {
    ...data,
    created_at__gte: balance.filterDate,
    created_at__range: balance.filterDateRange,
    user_wallet__wallet__crypto_token: balance.activeCoinId === 0 ? '' : balance.activeCoinId,
  };
  const getTransactionsResponse: any = await extra.httpService.BalanceService.getTransactions(requestParams);

  if (getTransactionsResponse.isError) {
    const message = getSimpleErrorMessage(getTransactionsResponse.data);

    return rejectWithValue({ message, isError: true });
  }

  return getTransactionsResponse.data;
});

export const getTransfers = createAsyncThunk<{ results: TransferType[] }, GetTransfersParamsType, ThunkApiConfig>(
  'balance/transfers',
  async (data, { extra, rejectWithValue, getState }) => {
    const { balance } = getState();
    const requestParams = {
      ...data,
      created_at__gte: balance.filterDate,
      created_at__range: balance.filterDateRange,
      crypto_token: balance.activeCoinId === 0 ? '' : balance.activeCoinId,
    };
    const getTransferResponse: any = await extra.httpService.BalanceService.getTransfers(requestParams);

    if (getTransferResponse.isError) {
      const message = getSimpleErrorMessage(getTransferResponse.data);

      return rejectWithValue({ message, isError: true });
    }

    return getTransferResponse.data;
  },
);

export const getMiningActivity = createAsyncThunk<
  { results: MiningActivityType[] },
  GetMiningActivityParamsType,
  ThunkApiConfig
>('balance/mining', async (data, { extra, rejectWithValue, getState }) => {
  const { balance } = getState();
  const requestParams = {
    ...data,
    created_at__date__gte: balance.filterDate,
    created_at__date__range: balance.filterDateRange,
    crypto_token: balance.activeCoinId === 0 ? '' : balance.activeCoinId,
  };
  const getMiningResponse: any = await extra.httpService.BalanceService.getMiningActivity(requestParams);

  if (getMiningResponse.isError) {
    const message = getSimpleErrorMessage(getMiningResponse.data);

    return rejectWithValue({ message, isError: true });
  }

  return getMiningResponse.data;
});

export const getHistory = createAsyncThunk<null, void, ThunkApiConfig>('balance/history', (_, { dispatch }) => {
  dispatch(getTransactions({}));
  dispatch(getTransfers({}));
  dispatch(getMiningActivity({}));
  return null;
});

export const sendCodeToEmailRequest = createAsyncThunk<ResultSendCodeToEmailType, ISendCodeToEmail, ThunkApiConfig>(
  'balance/code-email',
  async (data, { extra, rejectWithValue }) => {
    const getTransactionsResponse: any = await extra.httpService.BalanceService.sendCodeToEmail(data);

    if (getTransactionsResponse.isError) {
      const message = getSimpleErrorMessage(getTransactionsResponse.data);

      return rejectWithValue({ message, isError: true });
    }

    return getTransactionsResponse.data;
  },
);

export const sendCoinRequest = createAsyncThunk<ResultSendCoinType[], ISendCoin, ThunkApiConfig>(
  'balance/send-coin',
  async (data, { extra, rejectWithValue }) => {
    const getTransactionsResponse: any = await extra.httpService.BalanceService.sendCoin(data);

    if (getTransactionsResponse.isError) {
      const message = getSimpleErrorMessage(getTransactionsResponse.data);

      return rejectWithValue({ message, isError: true });
    }

    return getTransactionsResponse.data;
  },
);

export const getUserId = createAsyncThunk<{ id: number }, string, ThunkApiConfig>(
  'balance/userId',
  async (data, { extra, rejectWithValue }) => {
    const getTransactionsResponse: any = await extra.httpService.BalanceService.getUserId(data);

    if (getTransactionsResponse.isError) {
      return rejectWithValue({ message: 'Wrong username', isError: true });
    }

    return getTransactionsResponse.data;
  },
);

export const withdrawCoin = createAsyncThunk<{ id: number }, IWithdrawCoin, ThunkApiConfig>(
  'balance/withdraw-coin',
  async (data, { extra, rejectWithValue }) => {
    const getTransactionsResponse: any = await extra.httpService.BalanceService.withdrawCoin(data);

    if (getTransactionsResponse.isError) {
      const message = getSimpleErrorMessage(getTransactionsResponse.data);

      return rejectWithValue({ message, isError: true });
    }

    return getTransactionsResponse.data;
  },
);

export const partialWithdraw = createAsyncThunk<TransactionType, IPartialWithdrawCoin, ThunkApiConfig>(
  'balance/partial-coin-withdraw',
  async (data, { extra, rejectWithValue }) => {
    const getTransactionsResponse: any = await extra.httpService.BalanceService.partialCoinWithdraw(data);

    if (getTransactionsResponse.isError) {
      const message = getSimpleErrorMessage(getTransactionsResponse.data);

      return rejectWithValue({ message, isError: true });
    }

    return getTransactionsResponse.data;
  },
);

export const validateAddress = createAsyncThunk<{ detail: boolean }, IValidateAddress, ThunkApiConfig>(
  'balance/validate-address',
  async (data, { extra, rejectWithValue }) => {
    const response: any = await extra.httpService.BalanceService.validateAddress(data);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    if (!response.data.detail) return rejectWithValue({ message: 'Wrong address', isError: true });

    return response.data;
  },
);
export const updateWallet = createAsyncThunk<
  {
    id: number;
    address: string;
    crypto_token_id: number;
    crypto_token_name: string;
  },
  IUpdateWallet,
  ThunkApiConfig
>('balance/update-wallet', async (data, { extra, rejectWithValue }) => {
  const getTransactionsResponse: any = await extra.httpService.BalanceService.updateWallet(data);

  if (getTransactionsResponse.isError) {
    const message = getSimpleErrorMessage(getTransactionsResponse.data);

    return rejectWithValue({ message, isError: true });
  }

  return getTransactionsResponse.data;
});

export const getUserByWallet = createAsyncThunk<
  {
    count: number;
    results: {
      id: 0;
    }[];
  },
  string,
  ThunkApiConfig
>('balance/get-user-by-wallet', async (wallet, { extra, rejectWithValue }) => {
  const getTransactionsResponse: any = await extra.httpService.BalanceService.getUserByWallet(wallet);

  if (getTransactionsResponse.isError) {
    const message = getSimpleErrorMessage(getTransactionsResponse.data);

    return rejectWithValue({ message, isError: true });
  }

  return getTransactionsResponse.data;
});
