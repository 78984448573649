import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import { AchievementSerializerType, AchievementStatisticType } from './types';

export const getAchievements = createAsyncThunk<AchievementSerializerType[], void, ThunkApiConfig>(
  'achievement',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.AchievementsService.getAchievements();
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data.results;
  },
);

export const getAchievementsStatistic = createAsyncThunk<AchievementStatisticType, void, ThunkApiConfig>(
  'achievement/statistic',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.AchievementsService.getAchievementsStatistic();
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);
