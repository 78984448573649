import React from 'react';
import { H1FAQ, StyledIframe } from 'pages/Support/Faq/Faq.styled';

const FaqLiquidWallet: React.FC = () => (
  <>
    <H1FAQ>Liquid Wallet FAQ</H1FAQ>

    <StyledIframe
      title="LiquidWallet FAQ"
      src="https://admin.vault.ist/iframe/faq/12705760836124/13001370552220"
      frameBorder="0"
      width="100%"
      height="600px"
    />
  </>
);

export default FaqLiquidWallet;
