import React, { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import { useBaseLayoutSelector, useThemeSelector } from 'store';
import GlobalStyles from './GlobalStyles';

const ThemeAppProvider: FC<PropsWithChildren> = ({ children }) => {
  const theme = useThemeSelector();

  const { bg, isScrollDisabled, isAnotherBg, isImageBg } = useBaseLayoutSelector();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles $color={bg} $isImageBg={isImageBg} $isScrollDisabled={isScrollDisabled || isAnotherBg} />
      {children}
    </ThemeProvider>
  );
};
export default ThemeAppProvider;
