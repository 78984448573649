import React, { lazy, Suspense } from 'react';
import { RouterProvider, createBrowserRouter, RouteObject } from 'react-router-dom';
import { Landing, NotFound, ErrorBoundary, liquidationRoute } from 'pages';
import { appRouter } from 'src/pages/pagesRouter';
import authRouter from 'src/pages/Auth/AuthRoute';
import { store } from 'store';
import { Provider } from 'react-redux';
import { AuthProvider } from 'components/AuthComponents/AuthProvider';
import { FullPageLoader } from 'ui';
import { ThemeAppProvider } from 'theme';
import LocaleProvider from 'locales/LocaleProvider';
import { Clear } from 'pages/Tech/Clear';

const AppProviderComponent = lazy(() => import('src/AppProvider'));

const router = createBrowserRouter(
  [
    {
      path: '',
      element: <Landing />,
    },
    {
      path: 'clear',
      element: <Clear />,
    },
    {
      element: (
        <AuthProvider>
          <AppProviderComponent />
        </AuthProvider>
      ),
      errorElement: <ErrorBoundary />,
      children: [authRouter, appRouter, liquidationRoute],
    },
    {
      path: '*',
      element: <NotFound />,
      children: [],
    },
  ] as RouteObject[],
  {
    basename: '',
  },
);

const App = (
  <Provider store={store}>
    <ThemeAppProvider>
      <LocaleProvider>
        <Suspense fallback={<FullPageLoader />}>
          <RouterProvider router={router} />
        </Suspense>
      </LocaleProvider>
    </ThemeAppProvider>
  </Provider>
);

export default App;
