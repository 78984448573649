import { createSlice } from '@reduxjs/toolkit';
import { blankChapter } from 'pages/Profile/Wallet/components/Content/TransactionsHistory/constants';
import { LogoutMatcher } from 'store/slices/bag/utils';
import { IBalanceSlice, TransactionCategory } from './type';
import {
  getBalance,
  getMiningActivity,
  getTransactions,
  getTransfers,
  getUserByWallet,
  getUserId,
  partialWithdraw,
  sendCodeToEmailRequest,
  sendCoinRequest,
  updateWallet,
  validateAddress,
  withdrawCoin,
} from './actionAsync';

const initState: IBalanceSlice = {
  balance: [],
  transactions: [],
  transfers: [],
  miningActivity: [],
  chapter: blankChapter,
  filterDateRange: '',
  filterDate: '',
  activeCoinId: 0,
  codeIsSending: false,
  isValidAddress: null,
  sendingUserId: null,
  isUserFindByWallet: null,
  isLoading: false,
  isBalanceLoaded: false,
  isTransactionsLoaded: false,
  isTransfersLoaded: false,
  isLoadingWithdrawCoin: false,
  isMiningLoaded: false,
};

const balanceSlice = createSlice({
  name: 'rewards',
  initialState: initState,
  reducers: {
    clearCodeIsSending: (state) => {
      state.codeIsSending = false;
    },
    resetUserFindByWallet: (state) => {
      state.isUserFindByWallet = null;
    },
    setStoreDateFilter: (state, action) => {
      state.filterDate = action.payload.createdAdGte;
      state.filterDateRange = '';
    },
    setStoreDateRangeFilter: (state, action) => {
      state.filterDateRange = action.payload.dateRange;
      state.filterDate = '';
    },
    setActiveCoinFilter: (state, action) => {
      state.activeCoinId = action.payload;
    },
    setActiveChapter: (state, action) => {
      state.chapter = action.payload;
    },
    clearWalletStateBooleans: (state) => {
      state.isTransactionsLoaded = false;
      state.isTransfersLoaded = false;
      state.isMiningLoaded = false;
    },
    clearWalletBalanceDateState: (state) => {
      state.filterDateRange = '';
      state.filterDate = '';
    },
    clearWalletBalanceCoinState: (state) => {
      state.activeCoinId = 0;
    },
    clearBalance: (state) => {
      state.isBalanceLoaded = false;
      state.balance = [];
      state.isTransactionsLoaded = false;
      state.transactions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.fulfilled, (state, action) => {
        state.balance = action.payload.results;
        state.isBalanceLoaded = true;
      })
      .addCase(getBalance.pending, (state, action) => {
        state.isBalanceLoaded = false;
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.balance = [];
        state.isBalanceLoaded = false;
      });
    builder
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload.results.map((el) => {
          if (el.category === TransactionCategory.MINED) {
            el.type = TransactionCategory.MINED;
            el.category = 'Withdrawal operations';
          } else {
            el.type = TransactionCategory.REFERRAL;
            el.category = 'Referral transfers';
          }
          return el;
        });
        state.isTransactionsLoaded = true;
      })
      .addCase(getTransactions.pending, (state, action) => {
        state.isTransactionsLoaded = false;
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.transactions = [];
        state.isTransactionsLoaded = false;
      });
    builder
      .addCase(getTransfers.fulfilled, (state, action) => {
        state.transfers = action.payload.results.map((el) => {
          el.status = 1;
          el.category = 'Internal transfers';
          return el;
        });
        state.isTransfersLoaded = true;
      })
      .addCase(getTransfers.pending, (state) => {
        state.isTransfersLoaded = false;
      })
      .addCase(getTransfers.rejected, (state) => {
        state.transfers = [];
        state.isTransfersLoaded = false;
      });
    builder
      .addCase(getMiningActivity.fulfilled, (state, action) => {
        state.miningActivity = action.payload.results.map((el) => {
          el.value = Number(el.sum_value);
          el.category = 'Mining operations';
          el.created_at = el.created_at__date;
          el.status = 1;
          return el;
        });
        state.isMiningLoaded = true;
      })
      .addCase(getMiningActivity.pending, (state) => {
        state.isMiningLoaded = false;
      })
      .addCase(getMiningActivity.rejected, (state) => {
        state.transactions = [];
        state.isMiningLoaded = false;
      });
    builder.addCase(sendCodeToEmailRequest.fulfilled, (state, action) => {
      state.codeIsSending = true;
    });
    builder.addCase(sendCoinRequest.fulfilled, (state, { payload }) => {
      state.codeIsSending = false;
      state.balance = state.balance.map((el) =>
        el.id === payload[0].crypto_token.id
          ? {
              ...el,
              user_wallet: {
                ...el.user_wallet,
                value: (Number(el.user_wallet.value) - payload[0].value).toString(),
              },
            }
          : el,
      );
    });
    builder
      .addCase(getUserId.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserId.fulfilled, (state, action) => {
        state.sendingUserId = action.payload.id;
        state.isLoading = false;
      })
      .addCase(getUserId.rejected, (state, action) => {
        state.sendingUserId = null;
        state.isLoading = false;
      });
    builder.addCase(withdrawCoin.fulfilled, (state, action) => {});
    builder.addCase(partialWithdraw.pending, (state) => {
      state.isLoadingWithdrawCoin = true;
    });
    builder.addCase(partialWithdraw.fulfilled, (state, action) => {
      state.isLoadingWithdrawCoin = false;
    });
    builder.addCase(partialWithdraw.rejected, (state, action) => {
      state.isLoadingWithdrawCoin = false;
    });
    builder
      .addCase(validateAddress.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(validateAddress.fulfilled, (state, action) => {
        state.isValidAddress = true;
        state.isLoading = false;
      })
      .addCase(validateAddress.rejected, (state, action) => {
        state.isValidAddress = null;
        state.isLoading = false;
      });
    builder.addCase(updateWallet.fulfilled, (state, action) => {
      state.balance = state.balance.map((el) =>
        el.id === action.payload.id
          ? {
              ...el,
              user_wallet: {
                ...el.user_wallet,
                wallet_address: action.payload.address,
              },
            }
          : el,
      );
      state.isBalanceLoaded = false;
    });
    builder
      .addCase(getUserByWallet.fulfilled, (state, action) => {
        state.isUserFindByWallet = action.payload.count > 0;
      })
      .addCase(getUserByWallet.rejected, (state, action) => {
        state.isUserFindByWallet = null;
      });
    builder.addMatcher(LogoutMatcher, () => initState);
  },
});

export const {
  clearCodeIsSending,
  resetUserFindByWallet,
  setStoreDateFilter,
  setStoreDateRangeFilter,
  setActiveCoinFilter,
  setActiveChapter,
  clearWalletStateBooleans,
  clearWalletBalanceDateState,
  clearWalletBalanceCoinState,
  clearBalance,
} = balanceSlice.actions;

export default balanceSlice.reducer;
