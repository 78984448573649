import { styled } from 'styled-components';
import colors from 'theme/colors';
import media from 'theme/media';
import { P } from 'ui/Text';

export const NavigationItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 30px;
  position: relative;

  @media (max-width: ${media.mobile}px) {
    padding: 10px;
  }
`;

export const NavigationLabel = styled(P)<{ $active?: boolean }>`
  color: ${({ $active }) => ($active ? colors.yellow : colors.grey)};
  text-align: center;
  white-space: nowrap;
  transform: translateY(${({ $active }) => ($active ? '-10px' : '0px')});
  transition: all 0.3s;

  @media (max-width: ${media.mobile}px) {
    display: ${({ $active }) => ($active ? 'inline' : 'none')};
    transform: unset;
  }
`;
