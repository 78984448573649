import React, { FC, ReactNode } from 'react';
import { AppRoutes } from 'router';
import { AppNavigate } from 'components/AppComponents';
import Cookies from 'js-cookie';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';

type PrivateRouteProviderProps = {
  children: ReactNode;
};

const AuthorizedVaultProvider: FC<PrivateRouteProviderProps> = ({ children }) => {
  const token = Cookies.get(LocalStorageKeys.TOKEN_KEY) && Cookies.get(LocalStorageKeys.REFRESH_KEY);
  const vaultToken = Cookies.get(LocalStorageKeys.VAULT_TOKEN_KEY);

  if (token && vaultToken) {
    return <AppNavigate to={AppRoutes.MARKET_ALL} replace />;
  }

  return children;
};

export default AuthorizedVaultProvider;
