import React, { useEffect, useState } from 'react';
import colors from 'theme/colors';
import { ProgressRing, ProgressRingCircle, ProgressWrapper } from './style';
import { timeout } from '../../constants';

type DynamicCircleProps = {
  active?: boolean;
};

const RADIUS = 11;
const CIRCUMFERENCE = RADIUS * 2 * Math.PI;
const PROGRESSSTEP = (100 / timeout) * 100;

const DynamicCircle: React.FC<DynamicCircleProps> = ({ active }) => {
  const [progress, setProgress] = useState(0);
  const [circleProgress, setCircleProgress] = useState('70');

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (active && progress < 100) {
        setProgress((old) => old + PROGRESSSTEP);
      }
    }, 100);

    if (!active) {
      setProgress(0);
    }
    return () => clearInterval(intervalId);
  }, [active, progress]);

  useEffect(() => {
    function updateProgress(percent: number) {
      const offset = CIRCUMFERENCE - (percent / 100) * CIRCUMFERENCE;
      setCircleProgress(offset.toString());
    }

    updateProgress(progress);
  }, [progress]);

  return (
    <ProgressWrapper $active={active}>
      <ProgressRing width={26} height={26} $active={active}>
        <circle
          stroke="grey"
          r={RADIUS}
          cx={13}
          cy={13}
          strokeDashoffset={0}
          strokeDasharray={`${CIRCUMFERENCE} ${CIRCUMFERENCE}`}
        />
        {active && (
          <ProgressRingCircle
            strokeDashoffset={circleProgress}
            strokeDasharray={`${CIRCUMFERENCE} ${CIRCUMFERENCE}`}
            r={RADIUS}
            cx={13}
            cy={13}
          />
        )}
        <circle fill={active ? colors.yellow : colors.grey} r={5} cx={13} cy={13} />
      </ProgressRing>
    </ProgressWrapper>
  );
};

export default DynamicCircle;
