import { styled } from 'styled-components';
import { Icon, Flex } from 'ui';
import { colors } from 'theme';

export const HeaderMenuWrapper = styled.div<{ $isOpen?: boolean }>`
  padding: 3.75rem 1rem;
  width: 100%;
  max-width: 28.125rem;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${({ $isOpen }) => ($isOpen ? '3' : '50')};
  background: ${({ theme }) => theme.bag.bgColor};
  transform: translateX(${({ $isOpen }) => ($isOpen ? '0' : '100%')});
  transition: transform 0.3s ease-in-out;
`;

export const HeaderMenuLiquid = styled(Icon)`
  width: 208px;
  height: 58px;
`;

export const HeaderMenuClose = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${colors.white};
`;

export const HeaderMenuBackground = styled.div<{ $isOpen?: boolean }>`
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  transition: visibility 0.3s ease;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.7;
  background: ${({ theme }) => theme.bag.bgColor};
`;

export const HeaderLinksWrapper = styled(Flex)`
  & > p {
    font-family: 'Unbounded';
    font-weight: 400;
    font-size: 1.5rem;
  }
`;
