import { instance, ApiEndpoints } from 'services/client';
import {
  NotificationResponseType,
  GetNotificationParamsType,
  NotificationCategoriesResponseType,
} from 'types/notifications';

class NotificationService {
  static async getNotifications(params?: GetNotificationParamsType) {
    const response = await instance.get<NotificationResponseType>(ApiEndpoints.v3.notifications.getNotifications, {
      params,
    });

    return response;
  }

  static async getNotificationCategories() {
    const response = await instance.get<NotificationCategoriesResponseType>(
      ApiEndpoints.v3.notifications.getNotificationCategories,
    );

    return response;
  }

  static async postNotifications(notificationsIDs: number[]) {
    const response = await instance.post(ApiEndpoints.v3.notifications.postNotifications, {
      notification_ids: notificationsIDs,
    });

    return response;
  }
}

export default NotificationService;
