export const GEMS_PRIZES_IDS = [10, 11, 12, 13, 14, 15, 16, 28];

export enum WalletToastTypes {
  successConnectWallet = 'The wallet address is linked',
  successChangeWallet = 'Your wallet has been successfully changed',
  successTransaction = 'Transaction completed',
}

export enum ClickerConstantsList {
  user = 'user',
  clicker = 'clicker',
  auth = 'auth',
}

export const PURCHASE_TRON_COMMISSION = 120;
export const PURCHASE_TRON_COMMISSION_COIN = 'TRX';
