import { Flex } from 'ui';
import styled from 'styled-components';
import { SmallInput } from 'ui/Input/Input.styled';
import { colors } from 'theme';

export const EstimationBlockStyled = styled(Flex)`
  gap: 0;
  width: 100%;
  position: relative;
`;

export const SmallInputStyled = styled(SmallInput)`
  width: 100%;
  background: ${colors.onBlack};

  &:disabled {
    color: ${colors.grey};
  }
`;

export const InputRangeStyled = styled.input<{ $width: number }>`
  width: 100%;
`;
