import React from 'react';
import { useForm } from 'react-hook-form';
import { getVaultSelector, useAppSelector, useUserSelector } from 'store';
import { UseFormSetError } from 'react-hook-form/dist/types/form';

import { AppRoutes } from 'router';
import { AppNavigate } from 'components/AppComponents';

import { Validation } from 'services/Validation';
import { object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Flex, H1, P, Form, Button, Tooltip } from 'ui';
import { CodeInput } from 'components/AuthComponents/CodeInput';
import Timer from 'pages/Auth/VerifyEmail/Timer.component';
import { PhoneVerifyStyled } from './PhoneVerify.styled';

const schema = object({
  code: Validation.isCodePhone.length(4, '').required('Code is required'),
}).required();

type FormProps = {
  code: string;
};

type PhoneVerifyPageProps = {
  onSubmit: (data: { code: string }, cb: UseFormSetError<FormProps>) => Promise<void>;
  onResendCode: () => void;
  text: string;
};

const PhoneVerifyPage: React.FC<PhoneVerifyPageProps> = ({ onSubmit, onResendCode, text }) => {
  const { isLoading } = useUserSelector();
  const { phone } = useAppSelector(getVaultSelector);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormProps>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    mode: 'onChange',
  });

  const handleOnSubmit = async (data: FormProps) => {
    await onSubmit(data, setError);
  };

  if (!phone) {
    return <AppNavigate to={AppRoutes.SIGN_UP} />;
  }

  return (
    <PhoneVerifyStyled $direction="column" $gap="lg">
      <Flex $direction="column" $align="flex-start">
        <H1>Verify phone number</H1>
        <P size="md" align="left">
          {text}
        </P>
      </Flex>
      <Form onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off">
        <Tooltip content={errors.code?.message} inactive={!errors.code?.message} icon="warning">
          <CodeInput control={control} name="code" type="number" fields={4} isValid={errors.code?.message} />
        </Tooltip>
        <Timer callback={onResendCode} />
        <Button type="submit" disabled={isSubmitting || !isValid} loading={isLoading}>
          Continue
        </Button>
      </Form>
    </PhoneVerifyStyled>
  );
};

export default PhoneVerifyPage;
