import styled from 'styled-components';

export const CheckboxInput = styled.input`
  width: 0;
  height: 0;
  display: none;
  visibility: hidden;
`;

export const CheckboxStyledInput = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 0.5rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  input:not(:checked) ~ & {
    background-color: ${({ theme }) => theme.inputs.backgroundColor};
    border: ${({ theme }) => `1px solid ${theme.inputs.defaultBorderColor}`};

    & > svg {
      opacity: 0;
      color: ${({ theme }) => theme.inputs.textColor};
      width: 1rem;
      height: 1rem;
    }
  }

  input:checked ~ & {
    background-color: ${({ theme }) => theme.inputs.backgroundColor};
    border: ${({ theme }) => `1px solid ${theme.inputs.textColor}`};

    & > svg {
      opacity: 1;
      color: ${({ theme }) => theme.inputs.textColor};
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const CheckboxLabel = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.inputs.textColor};
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.75rem;
  user-select: none;
  cursor: pointer;

  &:hover > ${CheckboxStyledInput} {
    border: ${({ theme }) => `1px solid ${theme.inputs.activeBorderColor}`};
  }

  &:hover > ${CheckboxLabel} {
    color: ${({ theme }) => theme.inputs.hoverTextColor};
  }
`;
