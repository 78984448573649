import { TActiveNft, TSingleEqp } from 'src/types/dataCenter';

type TKeyName = 'shelf' | 'rig' | 'gpu';

const keyNames: TKeyName[] = ['gpu', 'rig', 'shelf'];

const getEquipmentArray = (initNft: Omit<TActiveNft, 'insideArray'>): TSingleEqp[] => {
  const finalArr = Object.entries(initNft)
    .filter(([a, b]: [string, any]) => a.includes(keyNames.find((el: string) => a.includes(el)) || '\n') && b !== null)
    .map(([a, b]: [string, any]) => ({ ...b, title: a }));
  return finalArr;
};

export { getEquipmentArray };
