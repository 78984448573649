import { useEffect, useState } from 'react';
import { ProductCalculator } from 'types/calculator';

export const useCalculatorTabs = (discrete: number, selectedProduct: ProductCalculator | null) => {
  const [tab, setTab] = useState<number | null>(null);

  const handleClickTab = (value: number | null) => {
    setTab((prevState) => (prevState === value ? null : value));
  };

  useEffect(() => {
    if (discrete) {
      if (discrete === selectedProduct?.month_durability) {
        setTab(selectedProduct?.price);
      } else {
        setTab(null);
      }
    }
  }, [discrete]);

  return {
    tab,
    handleClickTab,
  };
};
