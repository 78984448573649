import { Helmet } from 'react-helmet';
import React, { FC } from 'react';

type MetaProps = {
  title?: string;
  description: string;
  keywords?: string;
  lang?: string;

  type?: string;
  imageSrc?: string;
  siteName?: string;

  author?: string;
  copyright?: string;
  address?: string;

  redirect?: {
    time: number;
    url: string;
  };

  robots?: string;
};

const DOMAIN = 'https://liquidmining.com/';
const KEY_WORDS =
  'Liquid Liquid Mining Cloud Mining mining GPU GPU mining kaspa Neoxa Nexa ETC RVN FLUX ERGO POM bitcoin mining data mining mining journal genesis mining mining away mining accident china mining accident about bitcoin mining app for mining bitcoin about data mining about core mining mining bitcoin mining boost osrs mining boots mining boost mining bitcoin machine bitcoin mining machine bitcoin mining software bitcoin mining rig bitcoin mining app best gpu for mining btcs mining bitcoin mining sites mining calculator mining cryptocurrency mining crypto crypto mining cryptocurrency mining cloud mining data mining techniques data mining tools mining equipment mining ethereum evolution mining ethereum mining ecos mining etc mining calculator electra mining 2022 free bitcoin mining flux mining';
const Meta: FC<MetaProps> = (props) => {
  const {
    title = 'Liquid Mining | New Update',
    description,
    keywords = KEY_WORDS,
    lang = 'en_US',
    robots,
    redirect,
  } = props;
  const { author, copyright, address } = props;
  const { type = 'website', siteName = 'Liquid Mining', imageSrc = '%PUBLIC%/logo.svg' } = props;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={DOMAIN} />
      <meta property="og:locale" content={lang} />

      {robots && <meta name="robots" content={robots} />}
      {author && <meta name="Author" content={author} />}
      {copyright && <meta name="Copyright" content={copyright} />}
      {address && <meta name="Address" content={address} />}
      {redirect && <meta httpEquiv="refresh" content={`${redirect?.time}; url=${redirect?.url}`} />}

      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageSrc} />
      <meta property="og:url" content={DOMAIN} />
      <meta property="og:site_name" content={siteName} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageSrc} />
    </Helmet>
  );
};

export default Meta;
