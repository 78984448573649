export enum ChapterSource {
  all = 'all',
  withdrawal = 'withdrawal',
  mining = 'mining',
  transfers = 'transfers',
  referral = 'referral',
}

export const TextStatuses = {
  1: 'Success',
  2: 'Pending',
  3: 'Reject',
  4: 'Referral',
};

export type ChapterType = {
  id: number;
  title: string;
  filter: ChapterSource;
};

export const blankChapter = {
  id: 0,
  title: '',
  filter: ChapterSource.all,
};

export const chapterValues: ChapterType[] = [
  {
    id: 1,
    title: 'Withdrawal operations',
    filter: ChapterSource.withdrawal,
  },
  {
    id: 2,
    title: 'Mining operations',
    filter: ChapterSource.mining,
  },
  {
    id: 3,
    title: 'Internal transfers',
    filter: ChapterSource.transfers,
  },
  {
    id: 4,
    title: 'Referral bonus',
    filter: ChapterSource.referral,
  },
];

type DateFilterType = {
  id: number;
  name: string;
};

export const dateFilters: DateFilterType[] = [
  { id: 1, name: 'Week' },
  { id: 2, name: 'Month' },
  { id: 3, name: 'Year' },
  { id: 4, name: 'During the period' },
];
