const colors = {
  yellowBrighter: '#FFCF3F',
  yellow: '#FFD966',
  yellowSubdued: '#C0A655',
  orange: '#FF9100',
  white: '#FFFFFF',
  whiteTransparent: 'rgba(255,255,255,0.2)',
  whiteSubdued: '#ECECEC',
  grey: '#A0A0A0',
  softGrey: '#CCCCCC',
  darkGrey: '#595959',
  buttonSecondaryStroke: '#5F5F5F',
  inputDisabledStroke: '#444444',
  inputDisabledFill: '#393939',
  blackLight: '#333333',
  onBlack: '#2E2E2E',
  inputDefaultFill: '#292929',
  black: '#242424',
  ultraBlack: '#1C1C1C',
  blackTransparent: 'rgba(28, 28, 28, 0.7)',
  success: '#4CFF73',
  successSubdued: '#0AE800',
  warning: '#FFE500',
  warningSubdued: '#E8D100',
  error: '#DB7070',
  errorSubdued: '#FF4C4C',
  green: '#16C784',
  kaspaGreen: '#2b8778',
  transparent: 'transparent',
  blue: '#0d6efd',
  borderCardGrey: '#ffffff33',
} as const;
export default colors;
