import { bindActionCreators } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import {
  clearBag,
  logout,
  nextStep,
  setIsApprovedEarlier,
  setIsGettingBalance,
  passwordChanged,
  prevStep,
  setBg,
  setEmail,
  setIsHaveButtonBack,
  setIsImageBg,
  setIsScrollDisabled,
  setTransactionHash,
  setUsername,
  toggleNavBar,
  toggleTheme,
  clearImage,
  useAppDispatch,
  clearCodeIsSending,
  resetUserFindByWallet,
  setHasBackButtonAuth,
  setNavigateBackPagesAmount,
  changeNFTsMarketPlace,
  clearUnSyncNFTAll,
  clearNFTs,
  setConfig,
  setSocketNumber,
  setFilter,
  setSocketSend,
  setFinalArray,
  setIsInserting,
  setIsRemoving,
  setDCUpdate,
  setCurrentItem,
  setLoading,
  setHasBackButtonLiquidationLayout,
  setIsPureOutletLiquidationLayout,
  setFaqSearchList,
  setIndexOpening,
  setIsSkippedOpening,
  clearStateLiquidation,
  setIsAnimationEnd,
  setIsAnimationFreeCaseShow,
  clearBox,
  setAmountUsedGems,
  setIsHasButtonBack,
  clearLastSignature,
  setClaimDisabled,
  clearStateCalculator,
  addUnSyncSCMarketPlace,
  removeUnSyncSCMarketPlace,
  clearAllUnSyncSCMarketPlace,
} from 'store';

const actions = {
  setLoading,
  setFinalArray,
  setSocketNumber,
  toggleTheme,
  toggleNavBar,
  setIsScrollDisabled,
  setIsHaveButtonBack,
  setBg,
  nextStep,
  setIsApprovedEarlier,
  setIsGettingBalance,
  prevStep,
  clearBag,
  setTransactionHash,
  setIsImageBg,
  setEmail,
  passwordChanged,
  setConfig,
  logout,
  setUsername,
  resetUserFindByWallet,
  clearImage,
  clearCodeIsSending,
  setHasBackButtonAuth,
  setNavigateBackPagesAmount,
  setFilter,
  setSocketSend,
  setIsInserting,
  setIsRemoving,
  setDCUpdate,
  changeNFTsMarketPlace,
  clearNFTs,
  clearUnSyncNFTAll,
  setCurrentItem,
  setHasBackButtonLiquidationLayout,
  setFaqSearchList,
  setIsPureOutletLiquidationLayout,
  setIndexOpening,
  setIsSkippedOpening,
  clearStateLiquidation,
  setIsAnimationEnd,
  setIsAnimationFreeCaseShow,
  clearBox,
  setIsHasButtonBack,
  setAmountUsedGems,
  clearLastSignature,
  clearStateCalculator,
  setClaimDisabled,
  addUnSyncSCMarketPlace,
  removeUnSyncSCMarketPlace,
  clearAllUnSyncSCMarketPlace,
};

export const useActions = () => {
  const dispatch = useAppDispatch();
  const boundActionCreators = useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);

  return boundActionCreators;
};
