import { SingleChildren, URLPiece } from 'src/types/builder';

const RIG_TEXT =
  'Rigs are used for installation of multiple GPUs, serving as the minimum required equipment to start mining.';
const RIG_TEXT_2 = 'Our rigs support 6 GPUs installed at once.';
const RACK_TEXT =
  'Racks are designed for efficient rig installation, and requires at least one fully assembled rig to build it. However, it is recommended to purchase the rack if you already have at least 2 assembled rigs, ensuring optimal utilization.';
const CONTAINER_TEXT =
  'Containers are ideal for housing fully assembled racks. It requires a minimum of 1 rack for setup, but it is advisable to have at least 2 for optimal use.';

export type NftType = 'rig' | 'shelf' | 'container';

export type NewItem = {
  // FieldType
  id: number;
  name: string;
  product_name: string;
  description: string;
  image_link: string;
  text: string;
  text2?: string;
  config: string[];
  url_piece: Exclude<URLPiece, 'gpu'>;
  max_components: number;
  contract_purchase_id: number;
  can_be_purchased: boolean;
  used: boolean;
  is_purchasing: boolean;
  is_purchased: boolean;
  is_cart: boolean;
  synced: boolean;
  components: number;
};

export type EmptyConfigs = {
  [key in NftType]: NewItem;
};

const EmptyNfts: EmptyConfigs = {
  rig: {
    id: Number(import.meta.env.VITE_EMPTY_RIG_ID),
    contract_purchase_id: Number(import.meta.env.VITE_EMPTY_RIG_ID),
    can_be_purchased: true,
    is_purchasing: false,
    is_purchased: false,
    is_cart: false,
    synced: false,
    components: 0,
    name: 'Rig',
    product_name: 'Rig',
    description: '6 GPUs max',
    image_link: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/NFT/LiquidPLUS_Rig_empty.png',
    text: RIG_TEXT,
    text2: RIG_TEXT_2,
    config: ['0:0:0:0:0:0:0'],
    max_components: 6,
    url_piece: 'rig',
    used: false,
  },
  shelf: {
    id: Number(import.meta.env.VITE_EMPTY_RACK_ID),
    contract_purchase_id: Number(import.meta.env.VITE_EMPTY_RACK_ID),
    can_be_purchased: true,
    is_purchasing: false,
    is_purchased: false,
    is_cart: false,
    synced: false,
    components: 0,
    name: 'Rack',
    product_name: 'Rack',
    description: '4 Rigs max',
    image_link: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/NFT/LiquidPLUS_Rack_empty.png',
    text: RACK_TEXT,
    config: ['26:0:0:0:0:0:0'],
    max_components: 4,
    url_piece: 'shelf',
    used: false,
  },
  container: {
    id: Number(import.meta.env.VITE_EMPTY_CONTAINER_ID),
    contract_purchase_id: Number(import.meta.env.VITE_EMPTY_CONTAINER_ID),
    is_purchasing: false,
    is_purchased: false,
    is_cart: false,
    synced: false,
    components: 0,
    can_be_purchased: true,
    name: 'Container',
    product_name: 'Container',
    description: '4 Racks max',
    image_link: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/NFT/LiquidPLUS_Container_empty.png',
    text: CONTAINER_TEXT,
    config: ['27:0:0:0:0:0:0'],
    max_components: 4,
    url_piece: 'container',
    used: false,
  },
};

const NftToBuyObject: SingleChildren[] = [
  {
    id: Number(import.meta.env.VITE_FULL_RIG),
    contract_purchase_id: 2,
    product: 2,
    product_name: 'PLUS Rig',
    can_be_purchased: true,
    name: 'PLUS Rig',
    max_components: 6,
    url_piece: 'rig',
    created_at: '',
    is_purchasing: true,
    is_purchased: false,
    is_cart: false,
    synced: false,
    components: 6,
    price: 4200,
    power_rate: 360,
    energy_consumption: 841.8,
    image_link: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/NFT/LiquidPLUS_Rig_installed.png',
    used: false,
  },
  {
    id: Number(import.meta.env.VITE_FULL_RACK),
    contract_purchase_id: 3,
    product: 3,
    product_name: 'PLUS Rack',
    can_be_purchased: true,
    name: 'PLUS Rack',
    max_components: 4,
    url_piece: 'shelf',
    created_at: '',
    is_purchasing: true,
    is_purchased: false,
    is_cart: false,
    synced: false,
    components: 4,
    price: 16800,
    power_rate: 1440,
    energy_consumption: 3030,
    image_link: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/NFT/LiquidPLUS_Rack_installed.png',
    used: false,
  },
  {
    id: Number(import.meta.env.VITE_PLUS_GPU),
    contract_purchase_id: 1,
    product: 1,
    product_name: 'Plus GPU',
    can_be_purchased: true,
    max_components: 1,
    url_piece: 'gpu',
    created_at: '',
    is_purchasing: true,
    is_purchased: false,
    is_cart: false,
    synced: false,
    components: 1,
    name: 'Plus GPU',
    price: 750,
    power_rate: 54.55,
    energy_consumption: 140.3,
    image_link: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/NFT/LiquidPLUS_GPU_builder.png',
    used: false,
  },
  {
    id: Number(import.meta.env.VITE_START_GPU),
    contract_purchase_id: 9,
    product: 9,
    product_name: 'Start GPU',
    can_be_purchased: true,
    max_components: 1,
    url_piece: 'gpu',
    created_at: '',
    is_purchasing: true,
    is_purchased: false,
    is_cart: false,
    synced: false,
    components: 1,
    name: 'Start GPU',
    price: 100,
    power_rate: 6.55,
    energy_consumption: 25,
    image_link: 'https://storage.googleapis.com/liquid-bucket/Liquid%202.0/NFT/LiquidSTART_GPU_builder.png',
    used: false,
  },
];

export { EmptyNfts, NftToBuyObject };
