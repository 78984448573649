import React, { PropsWithChildren } from 'react';
import { I18nextProvider, initReactI18next, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources from './resources';
import { LocaleKeys } from './constant';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    ns: ['market', 'bag', 'auth', 'tech', 'support', 'rewards', 'landing', 'liquidWalletAuth'],
    fallbackLng: LocaleKeys.EN,
    supportedLngs: [LocaleKeys.EN],
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
    lowerCaseLng: true,
    nonExplicitSupportedLngs: true,
    resources,
  });

Object.values(LocaleKeys);

const LocaleProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // const { lang } = useParams();
  const { i18n } = useTranslation();
  // const location = useLocation();

  // if (!lang || !localesArr.includes(lang)) {
  //   return <AppNavigate to={location.pathname} />;
  // }

  // if (lang !== i18n.language) {
  //   i18n.changeLanguage(lang);
  // }

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default LocaleProvider;
