import { RootState } from 'store';
import { RewardsSlice } from './types';

export const getRewardsState = (state: RootState['rewards'], payload: RewardsSlice) => {
  state.me = payload.me;
  state.shares = payload.shares;
  state.forex = payload.forex;
  state.token = payload.token;
  state.event = payload.event;
  state.timeline = payload.timeline;
};
