import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useActions } from 'hooks/useActions';
import { useAppDispatch, useLiquidationSelector, addGems } from 'store';
import { TPrize } from 'types/liquidation';
import { GEMS_PRIZES_IDS } from 'src/constants';

interface IControllerBaseLayout {
  isPureOutlet?: boolean;
}

export const LiquidationLayoutController: React.FC<PropsWithChildren<IControllerBaseLayout>> = ({
  children,
  isPureOutlet,
}) => {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const [openedCases, setOpenedCases] = useState<number[]>([]);
  const { isAnimationEnd, isHasButtonBack, isSkippedOpening, currentCases, indexOpeningCaseInRow } =
    useLiquidationSelector();

  useEffect(() => {
    actions.setHasBackButtonLiquidationLayout(isHasButtonBack);
    actions.setIsPureOutletLiquidationLayout(isAnimationEnd ? false : !!isPureOutlet);

    return () => {
      actions.setHasBackButtonLiquidationLayout(false);
      actions.setIsPureOutletLiquidationLayout(false);
    };
  }, [isHasButtonBack, actions, isPureOutlet, isAnimationEnd]);

  useEffect(() => {
    if (isAnimationEnd && !isSkippedOpening) {
      const currentPrize = currentCases[indexOpeningCaseInRow];
      if (GEMS_PRIZES_IDS.includes(currentPrize.prize.id)) {
        setOpenedCases((prevState) => [...prevState, currentPrize.id ?? 0]);
        dispatch(addGems(currentPrize.prize.price));
      }
    }
    if (isSkippedOpening) {
      currentCases.forEach((elem) => {
        if (GEMS_PRIZES_IDS.includes(elem.prize.id) && !openedCases.includes(elem.id ?? 0)) {
          dispatch(addGems(elem.prize.price));
        }
      });
    }
  }, [isAnimationEnd, isSkippedOpening]);

  return children;
};
