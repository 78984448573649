import { ApiEndpointsSpring, instanceSpring } from 'src/services/Spring/client';

class RaceService {
  static async getTreeMatrix(wallet: string) {
    const response = await instanceSpring.get(ApiEndpointsSpring.raceTreeMatrix, {
      params: {
        wallet,
      },
    });
    return response;
  }

  static async getLeaderBoard() {
    const response = await instanceSpring.get(ApiEndpointsSpring.getLeaderBoard);
    return response;
  }

  static async getAffiliateBoard() {
    const response = await instanceSpring.get(ApiEndpointsSpring.getAffiliateBoard);
    return response;
  }

  static async confirmUser(userName: string) {
    const response = await instanceSpring.get(ApiEndpointsSpring.confirmUser(userName));
    return response;
  }

  static async formCheck(userName: string) {
    const response = await instanceSpring.get(ApiEndpointsSpring.formCheck(userName));
    return response;
  }

  static async sendAffiliateForm(body: { wallet: string; txId: string; pack: number }) {
    const response = await instanceSpring.post(ApiEndpointsSpring.sendAffiliateForm, body);
    return response;
  }

  static async treeAdd(body: { userName: string; userReferrer: string }) {
    const response = await instanceSpring.post(ApiEndpointsSpring.treeAdd, {
      referUsername: body.userReferrer,
      username: body.userName,
    });
    return response;
  }

  static async sendLeaderForm(body: {
    username: string;
    name: string;
    contact: string;
    type: 'TG' | 'WA';
    audience: number;
    usdt: number;
    additional: string;
  }) {
    const response = await instanceSpring.post(ApiEndpointsSpring.sendLeaderForm, body);

    return response;
  }
}

export default RaceService;
