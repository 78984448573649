import { ApiEndpointsVault, instanceVault, VaultCardType } from 'src/services/Vault/client';
import { ConfirmOfferType, CreateOfferType, UpdateOfferType } from 'store/slices/vault/types';

class VaultService {
  static async getVaultProfileData() {
    const response = await instanceVault.get(ApiEndpointsVault.profileData);

    return response;
  }

  static async getVaultWallets() {
    const response = await instanceVault.get(ApiEndpointsVault.getWallets);

    return response;
  }

  static async getVaultTransactionHistory() {
    const response = await instanceVault.get(ApiEndpointsVault.getHistory);

    return response;
  }

  static async addCard(data: VaultCardType) {
    const response = await instanceVault.post(ApiEndpointsVault.payIn, data);

    return response;
  }

  static async getVaultCardsData() {
    const response = await instanceVault.get(ApiEndpointsVault.cardsData);

    return response;
  }

  static async createOffer(data: CreateOfferType) {
    const response = await instanceVault.post(ApiEndpointsVault.createOffer, data);

    return response;
  }

  static async updateOffer(data: UpdateOfferType) {
    const { offerId: id } = data;
    const response = await instanceVault.put(`${ApiEndpointsVault.createOffer}/${id}`, data);

    return response;
  }

  static async confirmOffer(data: ConfirmOfferType) {
    const { offerId: id } = data;
    const response = await instanceVault.post(`${ApiEndpointsVault.confirmOffer}${id}`, data);

    return response;
  }

  static async getPayInStatus(id: number) {
    const response = await instanceVault.get(`${ApiEndpointsVault.payInStatus}?offerId=${id}`);

    return response;
  }
}

export default VaultService;
