import React, { useState, useEffect, MouseEvent, ChangeEventHandler } from 'react';
import useMediaQuery from 'hooks/useMediaQuery';
import { IconsName } from 'ui';
import { useClickAway } from 'react-use';
import {
  SelectContainer,
  DropdownContainer,
  Arrow,
  SelectButton,
  DropdownElement,
  DropdownInnerContainer,
  RangeDropdownContainer,
  RangeInputContainer,
  RangeInputsContainer,
  InputLabel,
  RangeInput,
  RangeButton,
  RangeDropdownContainerProps,
  CustomCheckbox,
} from './Select.styled';

export interface SelectItem {
  id: number | string;
  name: string;
}

type IconPathType = (typeof IconsName)[number];

const FILTERS_WITHOUT_REFETCH = ['During the period'];

export interface SelectProps {
  className?: string;
  options: SelectItem[];
  selectedItem: string | null;
  defaultPlaceholder: string;
  onSelectItemChoose: (id: string) => void;
  selectClearCallback: () => void;
  removeHardReset?: () => void;
  needHardReset?: boolean;
  align?: 'left' | 'right';
  icon?: IconPathType;
  iconAlign?: 'left' | 'right';
}

const Select = ({
  className,
  options,
  selectedItem,
  defaultPlaceholder,
  onSelectItemChoose,
  selectClearCallback,
  removeHardReset,
  needHardReset = false,
  align = 'left',
  icon = 'arrowDown',
  iconAlign = 'right',
}: SelectProps) => {
  const [open, setOpen] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const [chosenItem, setChosenItem] = useState<string | null>(selectedItem);
  const { isMobile } = useMediaQuery();

  useClickAway(ref, () => setOpen(false));
  const handleSelectClick = () => setOpen((prevState) => !prevState);

  const handleSelectSetState = (option: SelectItem) => {
    onSelectItemChoose(`${option.id}`);
    setChosenItem(option.name);
    setOpen(false);
  };

  const isSomeItemChoose = !!chosenItem;
  const selectPlaceholder = isSomeItemChoose ? selectedItem : defaultPlaceholder;
  const selectActiveIcon: IconPathType = isSomeItemChoose ? 'close' : icon;

  const resetSelectView = () => {
    setOpen(false);
    setChosenItem(null);
  };

  const handleSelectClearState = (event?: React.MouseEvent) => {
    if (!isSomeItemChoose) return;
    event?.stopPropagation();

    if (selectedItem && !FILTERS_WITHOUT_REFETCH.includes(selectedItem)) selectClearCallback();
    resetSelectView();
  };

  useEffect(() => {
    if (needHardReset && removeHardReset) {
      resetSelectView();
      removeHardReset();
    }
  }, [needHardReset]);

  return (
    <SelectContainer ref={ref} className={className}>
      <SelectButton
        onClick={handleSelectClick}
        $open={open}
        $isSomeItemChoose={isSomeItemChoose}
        $isMobileView={isMobile}
      >
        {iconAlign === 'left' && <Arrow name={icon} $isOpen={open} />}
        {selectPlaceholder}
        {iconAlign === 'right' && (
          <Arrow name={selectActiveIcon} onClick={(event) => handleSelectClearState(event)} $isOpen={open} />
        )}
      </SelectButton>

      <DropdownContainer align={align} $isOpen={open}>
        <DropdownInnerContainer>
          {options.map((el) => (
            <DropdownElement $isActive={el.name === chosenItem} key={el.id} onClick={() => handleSelectSetState(el)}>
              {el.name}
            </DropdownElement>
          ))}
        </DropdownInnerContainer>
      </DropdownContainer>
    </SelectContainer>
  );
};

type IdType = {
  id: string | number;
};

interface MultipleSelectItem<T extends IdType> {
  id: T;
  name: string;
}

export interface MultipleProps<T extends IdType> {
  className?: string;
  chosen: T[];
  options: MultipleSelectItem<T>[];
  onAdd: (id: T) => void;
  onDelete: (id: T) => void;
  placeholder: string;
  align?: 'left' | 'right';
  icon?: (typeof IconsName)[number];
  iconAlign?: 'left' | 'right';
}

const Multiple = <T extends IdType>({
  className,
  options,
  placeholder,
  chosen,
  align = 'left',
  onAdd,
  onDelete,
  icon = 'arrowDown',
  iconAlign = 'right',
}: MultipleProps<T>) => {
  const [open, setOpen] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const onDocumentClick = () => {
    setOpen(false);
  };

  useClickAway(ref, () => onDocumentClick());

  const onOpenButtonClick = () => {
    if (!open) setOpen(true);
  };

  const onDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <SelectContainer ref={ref} className={className}>
      <SelectButton onClick={open ? onDocumentClick : onOpenButtonClick} $open={open}>
        {iconAlign === 'left' && <Arrow name={icon} $isOpen={open} />}
        {placeholder}
        {iconAlign === 'right' && <Arrow name={icon} $isOpen={open} />}
      </SelectButton>

      <DropdownContainer onClick={onDropdownClick} align={align} $isOpen={open}>
        <DropdownInnerContainer>
          {options.map((el) => {
            const checked = chosen.includes(el.id);
            return (
              <CustomCheckbox
                label={el.name}
                checked={checked}
                onClick={() => (checked ? onDelete(el.id) : onAdd(el.id))}
                key={el.id}
              />
            );
          })}
        </DropdownInnerContainer>
      </DropdownContainer>
    </SelectContainer>
  );
};

export type InputRangeNumbersType = { from: number; to: number };
export interface RangeProps {
  className?: string;
  placeholder: string;
  buttonLabel: string;
  initValues: InputRangeNumbersType;
  onButtonClick: (arg: InputRangeNumbersType) => void;
  icon?: (typeof IconsName)[number];
  iconAlign?: 'left' | 'right';
}

const DEFAULT_RANGE_DROPDOWN_WIDTH = 330;
export const toDefaultValue = 125000;
export const fromDefaultValue = 100;

const Range = ({
  className,
  placeholder,
  buttonLabel,
  initValues,
  onButtonClick,
  icon = 'arrowDown',
  iconAlign = 'right',
}: RangeProps) => {
  const [values, setValues] = useState(initValues || { from: fromDefaultValue, to: toDefaultValue });

  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState<Omit<RangeDropdownContainerProps, '$isOpen'> | null>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const [isError, setError] = useState({ from: false, to: false });

  const onDocumentClick = () => {
    setOpen(false);
  };

  useClickAway(ref, () => onDocumentClick());

  const onOpenButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (open) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const { width, left } = rect;
    const right = document.body.clientWidth - rect.right;
    const positionArgs: Omit<RangeDropdownContainerProps, '$isOpen'> = {};

    if (width / 2 + left >= DEFAULT_RANGE_DROPDOWN_WIDTH / 2 && width / 2 + right >= DEFAULT_RANGE_DROPDOWN_WIDTH / 2) {
      positionArgs.$left = width / 2 - DEFAULT_RANGE_DROPDOWN_WIDTH / 2;
      positionArgs.$right = width / 2 - DEFAULT_RANGE_DROPDOWN_WIDTH / 2;
    } else if (
      width / 2 + left < DEFAULT_RANGE_DROPDOWN_WIDTH / 2 &&
      width + left + right >= DEFAULT_RANGE_DROPDOWN_WIDTH
    ) {
      positionArgs.$left = -left + 16;
      positionArgs.$right = -(DEFAULT_RANGE_DROPDOWN_WIDTH - width - left + 16);
    } else if (
      width / 2 + right < DEFAULT_RANGE_DROPDOWN_WIDTH / 2 &&
      width + left + right >= DEFAULT_RANGE_DROPDOWN_WIDTH
    ) {
      positionArgs.$right = -right + 16;
      positionArgs.$left = -(DEFAULT_RANGE_DROPDOWN_WIDTH - width - right + 16);
    } else {
      positionArgs.$left = -left + 10;
      positionArgs.$right = -right + 10;
    }

    if (!open) {
      setPosition(positionArgs);
      setOpen(true);
    }
  };

  const onDropdownClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value, name } = event.target;
    setValues({ ...values, [name]: value });
    setError({ ...isError, [name]: false });
  };

  const checkValidate = () => {
    const { from, to } = values;

    const isValidFrom = Number(from) >= 0;
    const isValidTo = Number(to) >= Number(from) || to.toString() === '';
    return { isValidFrom, isValidTo };
  };

  const onSubmit = () => {
    const { isValidFrom, isValidTo } = checkValidate();
    if (isValidFrom && isValidTo) {
      onButtonClick(values);
      setOpen(false);
    } else {
      setError({ from: !isValidFrom, to: !isValidTo });
    }
  };

  const isDisabledButton = isError.to || isError.from;

  return (
    <SelectContainer ref={ref} className={className}>
      <SelectButton onClick={open ? onDocumentClick : onOpenButtonClick} $open={open}>
        {iconAlign === 'left' && <Arrow name={icon} $isOpen={open} />}
        {placeholder}
        {iconAlign === 'right' && <Arrow name={icon} $isOpen={open} />}
      </SelectButton>

      <RangeDropdownContainer
        onClick={onDropdownClick}
        $left={position?.$left}
        $right={position?.$right}
        $isOpen={open}
      >
        <RangeInputsContainer>
          <RangeInputContainer>
            <InputLabel>From</InputLabel>
            <RangeInput type="number" name="from" value={values.from} onChange={onChange} $isError={isError.from} />
          </RangeInputContainer>
          <RangeInputContainer>
            <InputLabel>To</InputLabel>
            <RangeInput type="number" name="to" value={values.to} onChange={onChange} $isError={isError.to} />
          </RangeInputContainer>
        </RangeInputsContainer>
        <RangeButton size="large" onClick={onSubmit} disabled={isDisabledButton}>
          {buttonLabel}
        </RangeButton>
      </RangeDropdownContainer>
    </SelectContainer>
  );
};

Select.Multiple = Multiple;
Select.Range = Range;

export default Select;
