import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AchievementStoreType,
  AchievementStatisticType,
  AchievementSerializerType,
  AchievementCategories,
  AchievementSortingOptions,
} from './types';
import { getAchievements, getAchievementsStatistic } from './actionsAsync';

const initialState: AchievementStoreType = {
  achievements: [],
  isAchievementsLoaded: false,
  statistic: {
    amount_received_gems: 0,
    amount_total_gems: 0,
    count_completed: 0,
    count_total: 0,
  },
  activeAchievementCategory: AchievementCategories.coins,
  activeAchievementSortOption: AchievementSortingOptions.descending,
};

const YELLOW_COLOR = '#FFD966';

const achievementSlice = createSlice({
  name: 'achievements',
  initialState,
  reducers: {
    setAchievementLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isAchievementsLoaded = action.payload;
    },
    setAchievementsCategory: (state, action: PayloadAction<AchievementCategories | null>) => {
      state.activeAchievementCategory = action.payload;
    },
    setAchievementSortOption: (state, action: PayloadAction<AchievementSortingOptions>) => {
      state.activeAchievementSortOption = action.payload;
    },
    clearAchievementSortState: (state) => {
      state.activeAchievementSortOption = null;
    },
    clearAchievementState: (state) => {
      state.isAchievementsLoaded = false;
      state.activeAchievementCategory = AchievementCategories.coins;
      state.activeAchievementSortOption = AchievementSortingOptions.descending;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAchievements.fulfilled, (state, action: PayloadAction<AchievementSerializerType[]>) => {
      const parsedAchievements = action.payload.map((achievement) => {
        const {
          count_completed_items: completedItems,
          count_total_items: totalItems,
          items: totalLevels,
          progress,
        } = achievement;
        let levelProgress = 0;
        if (totalItems === 1) {
          levelProgress = Math.round((Number(progress) / Number(totalLevels[0].value)) * 100);
        } else {
          levelProgress = Math.round((completedItems / totalItems) * 100);
        }
        const parsedAchievementItems = totalLevels.map((item) => {
          const parsedDesc = item.desc
            .replace('<p>', '')
            .replace('</p>', '')
            .replace('<span style="color: #FFD966;">', '')
            .replace('<span style="color: #ffd966;">', '')
            .replace('</span>', '');
          return { ...item, parsedDesc };
        });
        return { ...achievement, items: parsedAchievementItems, levelProgress };
      });
      state.achievements = parsedAchievements;
      state.isAchievementsLoaded = true;
    });
    builder.addCase(getAchievementsStatistic.fulfilled, (state, action: PayloadAction<AchievementStatisticType>) => {
      state.statistic = action.payload;
    });
  },
});

export const {
  clearAchievementState,
  setAchievementsCategory,
  setAchievementSortOption,
  clearAchievementSortState,
  setAchievementLoadingState,
} = achievementSlice.actions;

export default achievementSlice.reducer;
