import React, { useEffect, useState } from 'react';
import { CustomButton, CustomText } from 'pages/Auth/VerifyEmail/VerifyEmail.styled';
import { TIMER_RESEND_CODE } from 'appConstants/Auth';
import { StyledSpan } from './Timer.styled';

type TimerProps = {
  callback: () => void;
  name: string;
};

const Timer: React.FC<TimerProps> = ({ callback, name = 'code' }) => {
  const [timer, setTimer] = useState(TIMER_RESEND_CODE);
  const [timerAvailable, setTimerAvailable] = useState(true);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timerAvailable && timer !== 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setTimerAvailable(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer, timerAvailable]);

  const handleResend = () => {
    setTimerAvailable(true);
    setTimer(TIMER_RESEND_CODE);
    callback();
  };

  return !timerAvailable ? (
    <CustomButton type="button" onClick={handleResend} color="tertiary" data-test-id="resendCodeBtn">
      Resend {name}
    </CustomButton>
  ) : (
    <CustomText size="md" color="secondary" data-test-id="verifyEmailThirdText">
      Resend {name} in <StyledSpan>{timer} </StyledSpan> {timer === 1 ? 'second' : 'seconds'}
    </CustomText>
  );
};

export default Timer;
