// Write Index styled component here
import styled from 'styled-components';

interface PropsSmallInput {
  error?: boolean;
  disabled?: boolean;
}

export default styled.input<PropsSmallInput>`
  font-family: ${({ theme }) => theme.fonts.primary};
  height: 2.5rem;
  font-size: 1rem;
  border: 1px solid
    ${({ theme, error, disabled }) => {
      if (disabled) return theme.inputs.disabledBorderColor;
      if (error) return theme.inputs.errorBorderColor;
      return theme.inputs.defaultBorderColor;
    }};
  border-radius: 8px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.inputs.disabledBackgroundColor : theme.inputs.backgroundColor};
  outline: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: ${({ theme, error }) => (error ? theme.inputs.errorTextColor : theme.inputs.textColor)};
  &:disabled {
    color: ${({ theme }) => theme.inputs.disabledTextColor};
  }
  &:focus {
    border-color: ${({ theme, error }) => {
      if (error) return theme.inputs.errorBorderColor;
      return theme.inputs.activeBorderColor;
    }};
  }
  &::placeholder {
    color: ${({ theme, disabled }) => (disabled ? theme.inputs.disabledTextColor : theme.inputs.placeholderColor)};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
