import React from 'react';
import { Flex, IconName } from 'ui';
import {
  SkeletonImage,
  StyledIcon,
  StyledImage,
  StyledInfoCalculatorBlock,
  StyledP,
} from './InfoCalculatorBlock.styled';

interface InfoCalculatorBlockProps {
  isNftInfo?: boolean;
  image?: string;
  icon?: IconName;
  header?: string;
  headerSize?: 'sm' | 'md' | 'lg' | 'xl';
  description?: string;
  isLoading?: boolean;
}

export const InfoCalculatorBlock: React.FC<InfoCalculatorBlockProps> = ({
  isNftInfo,
  image,
  icon,
  header,
  headerSize = 'sm',
  description,
  isLoading,
}) => {
  const directionCard = image ? 'row' : 'column';

  const getImageContent = () => (!isLoading ? <StyledImage src={image} alt={header} /> : <SkeletonImage $isLoading />);
  const innerGap = isNftInfo ? 'sm' : 'xsm';
  return (
    <StyledInfoCalculatorBlock $direction={directionCard} $align="center" $justify="flex-start">
      <Flex $direction="column" $gap={innerGap} $align="flex-start" $justify="flex-start">
        <Flex $gap={innerGap} $align="center" $justify="flex-start">
          {icon && <StyledIcon name={icon} />}
          <StyledP $isLoading={isLoading} size={headerSize} bold="lg" fontFamily="secondary" $whiteSpace="nowrap">
            {header}
          </StyledP>
        </Flex>
        <StyledP $isLoading={isLoading} $widthSkeleton="8rem" size="md" color="secondary" $whiteSpace="nowrap">
          {description}
        </StyledP>
      </Flex>
      {image && getImageContent()}
    </StyledInfoCalculatorBlock>
  );
};
