import { keyframes, styled } from 'styled-components';
import { Flex } from 'ui';

export const FullPageLoaderContainer = styled(Flex)`
  height: 100vh;
`;

const draw = keyframes`
  0% {
    stroke-dashoffset: 342;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -342;
  }
`;
export const LiquidLoader = styled.svg`
  #liquid {
    stroke-dasharray: 342;
    stroke-dashoffset: 310;
    animation: ${draw} 3s linear infinite;
  }
`;
