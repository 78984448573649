import { useNavigate, NavigateOptions, NavigateFunction, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useAppNavigate = (): NavigateFunction => {
  const navigate = useNavigate();
  const location = useLocation();

  const getStartUrl = () =>
    location.pathname.split('/').reduce((acc, el) => {
      if (el === '') return acc;
      return `../${acc}`;
    }, ``);

  return (delta, options?: NavigateOptions) => {
    if (typeof delta === 'number') return navigate(delta);

    if (typeof delta === 'object')
      return navigate(
        {
          ...delta,
          pathname: `${delta}`,
        },
        options,
      );

    return navigate(
      {
        pathname: `${delta}`,
      },
      {
        relative: 'route',
        ...options,
      },
    );
  };
};

export default useAppNavigate;
