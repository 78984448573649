import { useEffect, useState } from 'react';
import media from 'theme/media';

const useMediaQuery = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= media.mobile);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= media.tablet && window.innerWidth > media.mobile);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth <= media.laptop && window.innerWidth > media.tablet);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > media.laptop);
  const [width, setWidth] = useState(window.innerWidth);

  const resize = (event: Event) => {
    const target = event.target as Window;
    setWidth(target.innerWidth);
    setIsMobile(target.innerWidth <= media.mobile);
    setIsTablet(target.innerWidth <= media.tablet && target.innerWidth > media.mobile);
    setIsLaptop(target.innerWidth <= media.laptop && target.innerWidth > media.tablet);
    setIsDesktop(target.innerWidth > media.laptop);
  };

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    width,
  };
};

export default useMediaQuery;
