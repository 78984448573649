import { css, keyframes } from 'styled-components';

interface SkeletonProps {
  width?: string;
  height?: string;
  aspectRatio?: string;
  borderRadius?: string;
}

const AnimationSkeleton = keyframes`
  0% {
		left: -100%;
		transform: translateX(-100%);
	}
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const SkeletonStyle = (props: SkeletonProps) => css`
  position: relative;
  width: ${props.width || '100%'};
  background: #333333;
  height: ${props.height || '100%'};
  aspect-ratio: ${props.aspectRatio || ''};
  border-radius: ${props.borderRadius || 'unset'};
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(93deg, #333 -3.74%, #404040 50.38%, #333 105.58%);
    animation: ${AnimationSkeleton} linear 2s infinite;
  }
`;
