import { createSlice, Slice } from '@reduxjs/toolkit';
import { InitStateType } from 'store/slices/short-contract/type';
import { getShortsContract } from 'store/slices/short-contract/actionAsync';

const initState: InitStateType = {
  durabilityThreeMonth: [],
  durabilityOneMonth: [],
  banner: null,
  getToKnow: null,
  isLoading: false,
};

const ShortContractSlice = createSlice({
  name: 'shortContract',
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShortsContract.fulfilled, (state, { payload }) => {
        state.banner = payload.banner;
        state.getToKnow = payload.getToKnow;
        state.durabilityOneMonth = payload.durabilityOneMonth;
        state.durabilityThreeMonth = payload.durabilityThreeMonth;
        state.isLoading = false;
      })
      .addCase(getShortsContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShortsContract.rejected, (state) => {
        state.isLoading = false;
      })
      .addMatcher(
        (action) => action.type.endsWith('user/logout'),
        () => initState,
      );
  },
});

export default ShortContractSlice.reducer;
