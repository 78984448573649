import MarketEN from 'pages/MarketPlace/locales/en/market.json';
import MarketRU from 'pages/MarketPlace/locales/ru/market.json';
import BagEN from 'widgets/Bag/locales/en/bag.json';
import AuthEN from 'pages/Auth/locales/en/auth.json';
import LiquidWalletAuthEN from 'pages/Auth/locales/en/liquidWalletAuth.json';
import TechEN from 'pages/Tech/locales/en/tech.json';
import SupportEN from 'pages/Support/locales/en/support.json';
import RewardsEN from 'pages/Rewards/locales/en/rewards.json';
import DataCenterEN from 'pages/DataCenter/Main/locales/en/dataCenter.json';
import LandingEN from 'pages/Landing/locales/en/landing.json';

const en = {
  market: MarketEN,
  bag: BagEN,
  auth: AuthEN,
  tech: TechEN,
  support: SupportEN,
  rewards: RewardsEN,
  dataCenter: DataCenterEN,
  landing: LandingEN,
  liquidWalletAuth: LiquidWalletAuthEN,
} as const;

const ru = {
  market: MarketRU,
} as const;

const jp = {} as const;

export default { en, ru, jp };
