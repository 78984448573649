import { css, styled } from 'styled-components';

interface ChoiceGroupWrapperProps {
  $selected: boolean;
  $disabled?: boolean;
}

export const ChoiceGroupWrapper = styled.div`
  display: flex;
  gap: 0.25rem;

  border-radius: 0.75rem;
  border: ${({ theme }) => `1px solid ${theme.choiceGroup.borderColor}`};
  background: ${({ theme }) => theme.choiceGroup.bgColor};
  width: 100%;
  padding: 0.25rem;
`;

export const ChoiceGroupItem = styled.div<ChoiceGroupWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  background: ${({ theme, $selected }) =>
    $selected ? theme.choiceGroup.bgSelectedColorElement : theme.choiceGroup.bgColorElement};
  transition: all 0.3s ease-in-out;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;

      & > p {
        color: ${({ theme }) => theme.choiceGroup.disabledTextColor};
      }
    `}
`;
