import React, { FC } from 'react';
import { ToastCloseIcon, ToastIcon, ToastLink, ToastMessage, ToastWrapper } from 'ui/Toast/Toast.styled';
import { IconsName } from 'ui/Icon';

export const TOAST_ID = 'toastify';

type SimpleNotificationProps = {
  message: string;
  type: string;
  icon: (typeof IconsName)[number] | null;
};

type LinkNotificationProps = {
  link: string;
};

export const SimpleNotification: FC<SimpleNotificationProps> = ({ message, type, icon }) => (
  <ToastWrapper id={TOAST_ID}>
    <ToastIcon name={icon} $type={type} />
    <ToastMessage>{message}</ToastMessage>
    <ToastCloseIcon name="close" />
  </ToastWrapper>
);

export const LinkNotification: FC<LinkNotificationProps> = ({ link }) => (
  <ToastWrapper id={TOAST_ID}>
    <ToastIcon name="info" $type="error" />
    <ToastMessage>
      Please&nbsp;
      <ToastLink to={link}>pass KYC</ToastLink>
      &nbsp;to top up your wallet with Visa/Mastercard
    </ToastMessage>
    <ToastCloseIcon name="close" />
  </ToastWrapper>
);
