import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import {
  AuthWalletType,
  BindEmailType,
  ChangePasswordType,
  ChangeUserPassword,
  ConfirmEmailType,
  IConfirmUpdatePassword,
  IWalletRegistration,
  ResendCodeType,
  SetUserNameBody,
  UpdateEmailType,
} from 'services/Django/UserService/types';
import {
  CheckUsernameResponse,
  CheckWalletResponse,
  SetPhoneNumberResponse,
  SetTelegramUsernameResponse,
} from 'services/Spring/UserService/types';
import { AuthWallet, EmailRegistrationType, MyReferrerType, ResponseMyReferrerType } from 'types/auth';
import { ErrorType } from 'types/common/error';

type ThunkCheckResponseType = ErrorType | boolean;

export const getPhraseRequest = createAsyncThunk<string, string, ThunkApiConfig>(
  'user/get-phrase',
  async (publicKey, { extra: services }) => {
    const phrase = await services.httpService.CryptoService.getPhraseRequest(publicKey);

    return phrase;
  },
);

export type MeResponse = {
  access_token: string;
  refresh_token: string;
  token: string;
  email: string;
  username: string;
  wallet_address: string;
  kyc: boolean;
  gems: number;
  id: number;
  referral_link: string;
  password_was_changed: boolean;
  is_active: boolean;
  parent_referral: ResponseMyReferrerType;
  telegram_username: string;
  phone: string;
  vault: any;
  payment_service: string;
  current_kyc: string;
};

export type MeResponseShort = {
  id: number;
  email: string;
  username: string;
  wallet_address: string;
  gems: number;
  frozen_gems: number;
};

export const authWalletRequest = createAsyncThunk<MeResponse, AuthWallet, ThunkApiConfig>(
  'user/auth-wallet',
  async (data, { extra: services, rejectWithValue }) => {
    const { mode, ...body } = data;
    const response: any = await services.httpService.UserService.authWalletRequest(body);

    if (response.isError && mode) {
      // todo check axios types
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }
    return response.data;
  },
);

export const checkUserInfoRequest = createAsyncThunk<
  ThunkCheckResponseType,
  { email?: string; username?: string },
  ThunkApiConfig
>('user/check-user', async (userInfo, { extra: services, rejectWithValue }) => {
  const checkUserResponse: any = await services.httpService.UserService.checkUserRequest(userInfo);
  if (checkUserResponse.isError) {
    return rejectWithValue({ message: getSimpleErrorMessage(checkUserResponse.response.data) });
  }
  return true;
});

export const emailRegistrationRequest = createAsyncThunk<MeResponse, EmailRegistrationType, ThunkApiConfig>(
  'user/email-registration',
  async (data, { extra: services, rejectWithValue }) => {
    try {
      const response: any = await services.httpService.UserService.emailRegistrationRequest(data);
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const confirmRegistrationEmailRequest = createAsyncThunk<ThunkCheckResponseType, string, ThunkApiConfig>(
  'user/confirm-registration-email',
  async (code, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.confirmRegistrationEmailRequest(code);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return true;
  },
);

export const meRequest = createAsyncThunk<MeResponse, string, ThunkApiConfig>(
  'user/me',
  async (token, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.meRequest();
    if (response.isError) {
      return rejectWithValue({ message: 'The user is not logged in', isError: true });
    }

    return { ...response.data, token: JSON.parse(token) };
  },
);

export const meRequestShort = createAsyncThunk<MeResponseShort, void, ThunkApiConfig>(
  'user/me/short',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.meRequestShort();
    if (response.isError) {
      return rejectWithValue({ message: 'The user is not logged in', isError: true });
    }

    return response.data;
  },
);
export const emailLoginRequest = createAsyncThunk<MeResponse, EmailRegistrationType, ThunkApiConfig>(
  'user/email-login',
  async (body, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.emailLoginRequest(body);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const resendEmailVerifyCodeRequest = createAsyncThunk<string, ResendCodeType, ThunkApiConfig>(
  'user/resendCode',
  async (key, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.resendEmailVerifyCodeRequest(key);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const updateEmailRequest = createAsyncThunk<any, UpdateEmailType, ThunkApiConfig>(
  'user/update-email',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.updateEmailRequest(data);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response;
  },
);

export const confirmUpdateEmailRequest = createAsyncThunk<{ email: string }, ConfirmEmailType, ThunkApiConfig>(
  'user/confirm-update-email',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.confirmUpdateEmailRequest(data);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const bindEmailRequest = createAsyncThunk<any, BindEmailType, ThunkApiConfig>(
  'user/bind-email',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.bindEmailRequest(data);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response;
  },
);

export const confirmBindEmailRequest = createAsyncThunk<MeResponse, ConfirmEmailType, ThunkApiConfig>(
  'user/confirm-update-email',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.confirmBindEmailRequest(data);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const changePasswordRequest = createAsyncThunk<any, ChangeUserPassword, ThunkApiConfig>(
  'user/change-password',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.changePasswordRequest(data);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return true;
  },
);

export const updatePasswordRequest = createAsyncThunk<any, string, ThunkApiConfig>(
  'user/update-password',
  async (code, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.updatePasswordRequest(code);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response;
  },
);

export const confirmUpdatePasswordRequest = createAsyncThunk<
  ChangePasswordType,
  IConfirmUpdatePassword,
  ThunkApiConfig
>('user/confirm-update-password', async (data, { extra: services, rejectWithValue }) => {
  const response: any = await services.httpService.UserService.confirmUpdatePasswordRequest(data);

  if (response?.isError) {
    const message = getSimpleErrorMessage(response.response.data);
    return rejectWithValue({ message, isError: true });
  }

  return response.data;
});

export const setMyReferrerRequest = createAsyncThunk<MyReferrerType, string, ThunkApiConfig>(
  'user/set-referrer',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.setMyReferrerRequest(data);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const registerWalletRequest = createAsyncThunk<MeResponse, IWalletRegistration, ThunkApiConfig>(
  'user/wallet-registration',
  async (body, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.registerWalletRequest(body);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const loginWalletRequest = createAsyncThunk<MeResponse, AuthWalletType, ThunkApiConfig>(
  'user/wallet-login',
  async (body, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.loginWalletRequest(body);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);
export const bindWalletRequest = createAsyncThunk<MeResponse, AuthWalletType, ThunkApiConfig>(
  'user/bind-wallet',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.bindWalletRequest(data);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const checkWalletRequest = createAsyncThunk<CheckWalletResponse, string, ThunkApiConfig>(
  'user/check-wallet',
  async (walletAddress, { extra: { httpServiceSpring } }) =>
    httpServiceSpring.UserService.checkAuthWalletRequest(walletAddress),
);

export const checkUsernameRequest = createAsyncThunk<CheckUsernameResponse, string, ThunkApiConfig>(
  'user/check-username',
  async (username, { extra: { httpServiceSpring } }) =>
    httpServiceSpring.UserService.checkAuthUsernameRequest(username),
);

export const changeUserNameRequest = createAsyncThunk<CheckWalletResponse, SetUserNameBody, ThunkApiConfig>(
  'user/change-username',
  async (walletAddress, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.setUserName(walletAddress);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const userLogoutRequest = createAsyncThunk<CheckWalletResponse, string, ThunkApiConfig>(
  'user/logout',
  async (refreshToken, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.logout(refreshToken);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const healthCheck = createAsyncThunk<CheckWalletResponse, void, ThunkApiConfig>(
  'health-check',
  async (walletAddress, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.healthCheck();

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const setTelegramUsername = createAsyncThunk<SetTelegramUsernameResponse, string, ThunkApiConfig>(
  'user/telegram/set',
  async (telegramUsername, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.setTelegramUsername(telegramUsername);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const setPhoneNumber = createAsyncThunk<SetPhoneNumberResponse, string, ThunkApiConfig>(
  'user/phone/set',
  async (phone, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.UserService.setPhone(phone);

    if (response?.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);
