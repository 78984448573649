import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import {
  VaultAddEmailType,
  VaultAuthorizeType,
  VaultPhoneConfirmType,
  VaultRegistrationType,
  VaultWalletConnectType,
} from 'services/Django/VaultService/types';
import { MeResponse } from 'store/slices/user/actionAsync';
import { getSimpleErrorMessage } from 'utils/error';
import { DetailsFormProps } from 'pages/Profile/Details/Details.component';
import { VaultWalletResponseType } from 'widgets/WalletWidget/components/Vault/VaultWalletCard';
import {
  ConfirmOfferResponseType,
  ConfirmOfferType,
  CreateOfferType,
  PayInStatusResponse,
  ResponseOfferType,
  UpdateOfferType,
  VaultCardDataResponse,
  VaultKYC,
  VaultKYCStatus,
  VaultProfileDataAction,
} from 'store/slices/vault/types';
import { VaultCardType } from 'services/Vault/client';

export type VaultResponse = {
  username: string;
  phone?: string;
};

export type KYC1Response = {
  id: string;
};

export type VaultKYCResponse = {
  current_kyc: VaultKYC;
  next_kyc?: {
    status: VaultKYCStatus;
    kyc_level: Exclude<Omit<VaultKYC, 'KYC_0'>, null>;
  };
};

export const vaultRegistrationRequest = createAsyncThunk<VaultResponse, VaultRegistrationType, ThunkApiConfig>(
  'vault/registration',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.vaultRegistrationRequest(data);

    if (response.isError) {
      const message = getSimpleErrorMessage(
        response.response?.data?.message ? { message: response.response.data.message } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const confirmRegistrationPhoneRequest = createAsyncThunk<MeResponse, VaultPhoneConfirmType, ThunkApiConfig>(
  'vault/phone/confirm',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.confirmRegistrationPhoneRequest(data);

    if (response.isError) {
      const message = getSimpleErrorMessage(
        response.response?.data?.message ? { message: response.response.data.message } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const vaultAuthorizeRequest = createAsyncThunk<MeResponse, VaultAuthorizeType, ThunkApiConfig>(
  'vault/authorize',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.vaultAuthorizeRequest(data);

    if (response.isError) {
      const message = getSimpleErrorMessage(
        response.response?.data?.message ? { message: response.response.data.message } : response.response.data,
      );
      const errorCode = response.response?.status || null;
      return rejectWithValue({ message, isError: true, code: errorCode });
    }

    return response.data;
  },
);

export const resendPhoneVerifyCodeRequest = createAsyncThunk<VaultResponse, VaultPhoneConfirmType, ThunkApiConfig>(
  'vault/resendCode',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.resendPhoneVerifyCodeRequest(data);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.response.data);
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const updateProfileDetailsRequest = createAsyncThunk<MeResponse, DetailsFormProps, ThunkApiConfig>(
  'vault/details',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.updateProfileDetailsRequest(data);

    if (response.isError) {
      const message = getSimpleErrorMessage(
        response.response?.data?.message ? { message: response.response.data.message } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const addEmail = createAsyncThunk<VaultAddEmailType, VaultAddEmailType, ThunkApiConfig>(
  'vault/email/add',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.addEmail(data);

    if (response.isError) {
      const emailErrorMessage = response.response?.data?.email?.email || response.response?.data?.message;
      const message = getSimpleErrorMessage(
        emailErrorMessage ? { message: emailErrorMessage } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getVaultProfileData = createAsyncThunk<VaultProfileDataAction, void, ThunkApiConfig>(
  'vault/profile-details',
  async (_, { extra: services, rejectWithValue }) => {
    const responseVaultProfile: any = await services.httpServiceVault.VaultService.getVaultProfileData();
    const responseUserInfo: any = await services.httpService.VaultService.vaultUserInfo();

    if (responseVaultProfile.isError || responseUserInfo.isError) {
      const message = getSimpleErrorMessage(
        responseVaultProfile.response?.data?.message
          ? { message: responseVaultProfile.response.data.message }
          : responseVaultProfile.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return { vaultProfile: responseVaultProfile.data, vaultUserInfo: responseUserInfo.data };
  },
);

export const getVaultWallets = createAsyncThunk<VaultWalletResponseType, void, ThunkApiConfig>(
  'vault/wallets',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpServiceVault.VaultService.getVaultWallets();

    if (response.isError) {
      const message = getSimpleErrorMessage(
        response.response?.data?.message ? { message: response.response.data.message } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getVaultTransactionHistory = createAsyncThunk<VaultWalletResponseType, void, ThunkApiConfig>(
  'vault/history',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpServiceVault.VaultService.getVaultTransactionHistory();

    if (response.isError) {
      const message = getSimpleErrorMessage(
        response.response?.data?.message ? { message: response.response.data.message } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const purchaseGemsWithVault = createAsyncThunk<VaultWalletResponseType, number, ThunkApiConfig>(
  'vault/purchase-gems',
  async (count, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.purchaseGems(count);

    if (response.isError) {
      const message = getSimpleErrorMessage({ message: response.response.data.message || 'Purchase failed' });
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const passKYC1Request = createAsyncThunk<KYC1Response, void, ThunkApiConfig>(
  'vault/kyc1',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.passKYC1Request();
    if (response.isError) {
      return rejectWithValue({ message: 'Something went wrong', isError: true });
    }

    return response.data;
  },
);

export const finishKYC1Request = createAsyncThunk<KYC1Response, string, ThunkApiConfig>(
  'vault/kyc1-finish',
  async (id, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.finishKYC1Request(id);
    if (response.isError) {
      return rejectWithValue({ message: 'Something went wrong', isError: true });
    }

    return response.data;
  },
);

export const getKYC = createAsyncThunk<VaultKYCResponse, void, ThunkApiConfig>(
  'vault/kyc',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.getKYC();
    if (response.isError) {
      return rejectWithValue({ message: 'Something went wrong', isError: true });
    }

    return response.data;
  },
);

export const vaultConnectWalletRequest = createAsyncThunk<VaultResponse, VaultWalletConnectType, ThunkApiConfig>(
  'vault/wallet-connect',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.VaultService.vaultConnectWalletRequest(data);

    if (response.isError) {
      const message = getSimpleErrorMessage(
        response.response?.data?.message ? { message: response.response.data.message } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const addCard = createAsyncThunk<VaultCardType, VaultCardType, ThunkApiConfig>(
  'vault/add-card',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpServiceVault.VaultService.addCard(data);

    if (response.isError) {
      const emailErrorMessage = response.response?.data?.email?.email || response.response?.data?.message;
      const message = getSimpleErrorMessage(
        emailErrorMessage ? { message: emailErrorMessage } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getVaultCardsData = createAsyncThunk<VaultCardDataResponse, void, ThunkApiConfig>(
  'vault/get-cards',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpServiceVault.VaultService.getVaultCardsData();
    if (response.isError) {
      return rejectWithValue({ message: 'Something went wrong', isError: true });
    }

    return response.data;
  },
);

export const createOffer = createAsyncThunk<ResponseOfferType, CreateOfferType, ThunkApiConfig>(
  'vault/create-offer',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpServiceVault.VaultService.createOffer(data);

    if (response.isError) {
      const emailErrorMessage = response.response?.data?.email?.email || response.response?.data?.message;
      const message = getSimpleErrorMessage(
        emailErrorMessage ? { message: emailErrorMessage } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const updateOffer = createAsyncThunk<ResponseOfferType, UpdateOfferType, ThunkApiConfig>(
  'vault/update-offer',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpServiceVault.VaultService.updateOffer(data);

    if (response.isError) {
      const emailErrorMessage = response.response?.data?.email?.email || response.response?.data?.message;
      const message = getSimpleErrorMessage(
        emailErrorMessage ? { message: emailErrorMessage } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const confirmOffer = createAsyncThunk<ConfirmOfferResponseType, ConfirmOfferType, ThunkApiConfig>(
  'vault/confirm-offer',
  async (data, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpServiceVault.VaultService.confirmOffer(data);

    if (response.isError) {
      const emailErrorMessage = response.response?.data?.email?.email || response.response?.data?.message;
      const message = getSimpleErrorMessage(
        emailErrorMessage ? { message: emailErrorMessage } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);

export const getPayInStatus = createAsyncThunk<PayInStatusResponse, number, ThunkApiConfig>(
  'vault/payin-status',
  async (id, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpServiceVault.VaultService.getPayInStatus(id);

    if (response.isError) {
      const emailErrorMessage = response.response?.data?.email?.email || response.response?.data?.message;
      const message = getSimpleErrorMessage(
        emailErrorMessage ? { message: emailErrorMessage } : response.response.data,
      );
      return rejectWithValue({ message, isError: true });
    }

    return response.data;
  },
);
