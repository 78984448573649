import { styled } from 'styled-components';
import media from 'theme/media';
import offsets from 'theme/offsets';
import { Icon } from 'ui/Icon';
import { P } from 'ui/Text';
import colors from 'theme/colors';
import light from 'assets/light.svg';

type TokenSectionProps = {
  $background: string;
};

export const TokenSection = styled.section<TokenSectionProps>`
  background-color: ${colors.onBlack};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7.5rem;
  padding: 9.375rem ${offsets.sectionHorizontal};

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.3;
    background-position: center;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${light});
  }

  &::before {
    content: '';
    position: absolute;
    background-image: ${({ $background }) => `url(${$background})`};
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${media.laptop}px) {
    padding: 6.625rem 8.563rem;
    gap: 5.075rem;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 3.5rem 2.5rem;
    gap: 6.313rem;
  }

  @media (max-width: ${media.mobile}px) {
    padding: 1.75rem 1rem;
    gap: 4.375rem;
  }
`;

export const ElementRowToken = styled.div`
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1.5rem;
  justify-content: center;

  @media (max-width: ${media.laptop}px) {
    grid-template-columns: repeat(7, 90px);
  }

  @media (max-width: ${media.tablet}px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 0.5rem;
    & > *:nth-child(1) {
      grid-column: 1/4;
    }

    & > *:nth-child(2) {
      grid-column: 4/7;
    }

    & > *:nth-child(3) {
      grid-column: 7/10;
    }

    & > *:nth-child(4) {
      grid-column: 10/13;
    }

    & > *:nth-child(5) {
      grid-column: 1/5;
    }

    & > *:nth-child(6) {
      grid-column: 5/9;
    }

    & > *:nth-child(7) {
      grid-column: 9/13;
    }
  }

  @media (max-width: ${media.mobile}px) {
    gap: 0.5rem;
  }
`;

export const ElementRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 200px);
  justify-content: center;
  gap: 1.5rem;
  z-index: 3;

  @media (max-width: ${media.laptop}px) {
    grid-template-columns: repeat(5, 150px);
  }

  @media (max-width: ${media.tablet}px) {
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5rem;
    & > *:nth-child(1) {
      grid-column: 1/3;
    }

    & > *:nth-child(2) {
      grid-column: 3/5;
    }

    & > *:nth-child(3) {
      grid-column: 5/7;
    }

    & > *:nth-child(4) {
      grid-column: 1/4;
    }

    & > *:nth-child(5) {
      grid-column: 4/7;
    }
  }
`;

type CardProps = {
  size?: 'small' | 'large';
  $target?: 'token' | 'all';
  $isLiquid?: boolean;
};

export const Card = styled.div<CardProps>`
  width: ${({ $isLiquid }) => ($isLiquid ? '11.25rem' : 'auto')};
  height: ${({ $isLiquid }) => ($isLiquid ? '11.25rem' : 'auto')};
  padding: ${({ $target }) => ($target === 'token' ? '1.875rem' : '2.5rem 1.5rem')};
  border-radius: 0.75rem;
  border: 2px solid #ffffff20;
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  cursor: default;
  @media (max-width: ${media.laptop}px) {
    padding: ${({ $target }) => ($target === 'token' ? '1.125rem 0.563rem' : '1.125rem 0.938rem')};
    width: ${({ $isLiquid }) => ($isLiquid ? '8rem' : 'auto')};
    height: ${({ $isLiquid }) => ($isLiquid ? '8rem' : 'auto')};
  }
  @media (max-width: ${media.mobile}px) {
    padding: 10px;
    width: ${({ $isLiquid }) => ($isLiquid ? '5.25rem' : 'auto')};
    height: ${({ $isLiquid }) => ($isLiquid ? '5.25rem' : 'auto')};
  }
`;

export const CardInner = styled.div<CardProps>`
  height: 100%;
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type TokenIconProps = {
  size?: 'medium' | 'large';
};

export const TokenIcon = styled(Icon)<TokenIconProps>`
  width: ${({ size }) => (size === 'large' ? '4.5rem' : '2.5rem')};
  aspect-ratio: 1;
  flex-shrink: 0;

  @media (max-width: ${media.laptop}px) {
    width: ${({ size }) => (size === 'large' ? '3.125rem' : '1.75rem')};
  }

  @media (max-width: ${media.tablet}px) {
    width: ${({ size }) => (size === 'large' ? '4.5rem' : '2.5rem')};
  }

  @media (max-width: ${media.mobile}px) {
    width: ${({ size }) => (size === 'large' ? '2.063rem' : '1.125rem')};
  }
`;

export const CardTitle = styled(P)<{ $isTokenTitle?: boolean }>`
  text-transform: ${({ $isTokenTitle }) => ($isTokenTitle ? 'uppercase' : 'unset')};
  font-weight: 700;
  @media (max-width: ${media.laptop}px) {
    font-size: 0.75rem;
  }
`;

export const CardText = styled(P)`
  font-weight: 400;
  @media (max-width: ${media.laptop}px) {
    font-size: 0.75rem;
  }
`;
