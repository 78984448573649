import { TooltipPosition } from 'ui/Tooltip/Tooltip.styled';
import { TooltipProps } from 'ui/Tooltip';

const TOOLTIP_MAX_WIDTH = 320;
const WIDTH_TRIANGLE = 14.14;

const defaultTooltipPosition = (
  width: number,
  TOOLTIP_MAX_WIDTH_PARAM: number,
  tooltipPosition: TooltipPosition,
  disableAround: TooltipProps['disableAroundArrowPosition'],
) => {
  const arrowLeft = (width - WIDTH_TRIANGLE) / 2;
  if (arrowLeft < 8) {
    tooltipPosition.$left = '-10px';
    tooltipPosition.$arrowLeft = arrowLeft - 10;
  } else {
    tooltipPosition.$left = '0%';
    tooltipPosition.$arrowLeft = disableAround ? arrowLeft : Math.min(arrowLeft, 25);
  }

  tooltipPosition.$left = '0%';
  tooltipPosition.$maxWidth = `${TOOLTIP_MAX_WIDTH_PARAM}px`;
  tooltipPosition.$width = 'max-content';
};

export const calculateTooltipPosition = (
  rect: DOMRect,
  place: TooltipProps['place'],
  disableAround: TooltipProps['disableAroundArrowPosition'],
  isMobile: boolean,
) => {
  const { top, width, left } = rect;
  const right = document.body.clientWidth - rect.right;
  const tooltipPosition: TooltipPosition = {};

  const isTopPlacement = top < 100 || place === 'top';
  const isCombine = (width + left < TOOLTIP_MAX_WIDTH && width + right < TOOLTIP_MAX_WIDTH) || place === 'combine';
  const isRight = width + right < TOOLTIP_MAX_WIDTH || place === 'right';

  const vertical = isTopPlacement ? '$top' : '$bottom';

  tooltipPosition[vertical] = 'calc(100% + 8px)';
  tooltipPosition.$arrowOnTop = vertical === '$top';

  if (isCombine) {
    if (left > right) {
      tooltipPosition.$right = `${10 - right}px`;
      tooltipPosition.$arrowRight = right - 10 + (width - WIDTH_TRIANGLE) / 2;
    } else {
      tooltipPosition.$left = isMobile ? `0px` : `${10 - left}px`;
      tooltipPosition.$arrowLeft = isMobile ? (width - WIDTH_TRIANGLE) / 2 : left - 10 + (width - WIDTH_TRIANGLE) / 2;
    }

    tooltipPosition.$width = 'max-content';
    tooltipPosition.$maxWidth = `${TOOLTIP_MAX_WIDTH}px`;
  } else if (isRight) {
    const arrowRight = (width - WIDTH_TRIANGLE) / 2;

    if (arrowRight < 8) {
      tooltipPosition.$right = '-10px';
      tooltipPosition.$arrowRight = arrowRight + 10;
    } else {
      tooltipPosition.$right = '0%';
      tooltipPosition.$arrowRight = disableAround ? arrowRight : Math.min(arrowRight, 25);
    }

    tooltipPosition.$maxWidth = `${TOOLTIP_MAX_WIDTH}px`;
    tooltipPosition.$width = 'max-content';
  } else {
    defaultTooltipPosition(width, TOOLTIP_MAX_WIDTH, tooltipPosition, disableAround);
  }

  return tooltipPosition;
};
