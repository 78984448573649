import { styled } from 'styled-components';
import { media } from 'theme';

interface FlexProps {
  $direction?: 'row' | 'column' | 'column-reverse';
  $align?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
  $justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  $gap?: 'xs' | 'xsm' | 'sm' | 'form' | 'smlg' | 'md' | 'lg' | 'xl';
  $columnGap?: 'xs' | 'xsm' | 'sm' | 'form' | 'md' | 'lg' | 'xl' | 'xxl';
  $wrap?: boolean;
  /*
   * @param maxWidth - max-width for flex container
   */
  $maxWidth?: string;
}

const getGap = (gap?: 'xs' | 'xsm' | 'sm' | 'smlg' | 'form' | 'md' | 'lg' | 'xl') => {
  switch (gap) {
    case 'xs':
      return '0.25rem';
    case 'xsm':
      return '0.5rem';
    case 'sm':
      return '0.75rem';
    case 'smlg':
      return '2rem';
    case 'lg':
      return '2.5rem';
    case 'xl':
      return '3.75rem';
    case 'form':
      return '1rem';
    case 'md':
    default:
      return '1.5rem';
  }
};

export const Flex = styled.div<FlexProps>`
  display: flex;
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth || 'unset'};
  flex-direction: ${(props) => props.$direction || 'row'};
  align-items: ${(props) => props.$align || 'center'};
  justify-content: ${(props) => props.$justify || 'center'};
  gap: ${(props) => {
    switch (props.$gap) {
      case 'xs':
        return '0.25rem';
      case 'xsm':
        return '0.5rem';
      case 'sm':
        return '0.75rem';
      case 'smlg':
        return '2rem';
      case 'lg':
        return '2.5rem';
      case 'xl':
        return '3.75rem';
      case 'form':
        return '1rem';
      case 'md':
      default:
        return '1.5rem';
    }
  }};
  column-gap: ${(props) => {
    switch (props.$columnGap) {
      case 'xs':
        return '0.25rem';
      case 'xsm':
        return '0.5rem';
      case 'sm':
        return '0.75rem';
      case 'lg':
        return '2.5rem';
      case 'xl':
        return '3rem';
      case 'xxl':
        return '3.75rem';
      case 'form':
        return '1rem';
      case 'md':
      default:
        return getGap(props.$gap);
    }
  }};
  flex-wrap: ${(props) => (props.$wrap ? 'wrap' : 'nowrap')};

  @media (max-width: ${media.mobile}px) {
    gap: ${(props) => getGap(props.$gap)};
    column-gap: ${(props) => {
      switch (props.$gap) {
        case 'xs':
          return '0.25rem';
        case 'sm':
          return '0.5rem';
        case 'lg':
          return '1.5rem';
        case 'xl':
          return '2.5rem';
        case 'md':
        default:
          return '1rem';
      }
    }};
  }
`;
