import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationCategoryType } from 'types/notifications';
import { StoreType } from './types';
import { getNotifications, getNotificationCategories } from './actionsAsync';

const initialState: StoreType = {
  notifications: [],
  currentNotifications: [],
  activeCategory: undefined,
  isNotificationLoaded: false,
  isNotificationsDisabled: false,
  currentPage: 1,
  totalPage: 0,
  totalCategoryPage: 0,
  notificationsLimit: 0,
  categories: [],
  isCategoriesLoaded: false,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationActiveChapter: (state, action: PayloadAction<NotificationCategoryType>) => {
      if (state.activeCategory?.slug === action.payload.slug) {
        state.activeCategory = undefined;
      } else {
        state.activeCategory = action.payload;
      }
      state.currentNotifications = [];
      state.isNotificationLoaded = false;
      state.currentPage = 1;
    },
    setNotificationLoadedFlag: (state, action: PayloadAction<boolean>) => {
      state.isNotificationLoaded = action.payload;
    },
    setInitialNotificationList: (state) => {
      const copiedNotificationList = [...state.currentNotifications];
      state.currentNotifications = copiedNotificationList.slice(0, state.notificationsLimit);
      state.currentPage = 1;
      state.isNotificationsDisabled = false;
    },
    incrementNotificationCurrentPage: (state) => {
      state.currentPage += 1;
      state.isNotificationsDisabled = true;
    },
    clearNotificationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.currentNotifications = [...state.currentNotifications, ...action.payload.results];
      state.notifications = [...state.notifications, ...action.payload.results];

      const keyToFilter = 'id';
      const notificationsWithoutDuplicates = [
        ...new Map(state.notifications.map((notification) => [notification[keyToFilter], notification])).values(),
      ];

      state.notifications = notificationsWithoutDuplicates;

      if (state.activeCategory) {
        state.totalCategoryPage = action.payload.total_pages;
      } else {
        state.totalPage = action.payload.total_pages;
      }

      state.notificationsLimit = action.payload.limit;

      state.isNotificationLoaded = true;
      state.isNotificationsDisabled = false;
    });
    builder.addCase(getNotificationCategories.fulfilled, (state, action) => {
      state.categories = action.payload.results;
      state.isCategoriesLoaded = true;
    });
  },
});

export const {
  setNotificationActiveChapter,
  setNotificationLoadedFlag,
  setInitialNotificationList,
  incrementNotificationCurrentPage,
  clearNotificationState,
} = notificationSlice.actions;

export default notificationSlice.reducer;
