/* eslint-disable no-var */
import { createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'services/client';
import { getSimpleErrorMessage } from 'utils/error';
import { subtractGems } from 'store';
import { TBasePrize, TBox, TCasesRibbon, TPrize, TReward, LiquidationPrizesResponseType } from 'types/liquidation';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';

const FREE_CASE_ID = 1;

export const getCases = createAsyncThunk<
  { cases: TBox[]; isOpenedByUuid: boolean; freeCasePrizeNonAuth: TReward | null },
  string | null,
  ThunkApiConfig
>('liquidation/cases', async (order, { extra: services, rejectWithValue, getState }) => {
  let uuid = localStorage.getItem(LocalStorageKeys.NANO_ID);
  if (!uuid) {
    uuid = nanoid();
    localStorage.setItem(LocalStorageKeys.NANO_ID, uuid);
  }
  const { username } = getState().user;
  const requestSign = order && order === 'More expensive first' ? '-' : '';
  const response: any = await services.httpService.LiquidationService.getCases(requestSign);
  let isOpenedByUuid = false;
  if (response.isError) {
    const message = getSimpleErrorMessage(response.data);

    return rejectWithValue({ message, isError: true });
  }

  const getUuid: any = await services.httpService.LiquidationService.getUuid(uuid);
  const isUuidResultEmpty = getUuid.isError ? true : Object.keys(getUuid.data).length === 0;

  if (!isUuidResultEmpty && !username) {
    localStorage.setItem(LocalStorageKeys.ANIMATION_KEY, 'has');
    isOpenedByUuid = true;
  }

  if (username || (!username && !isUuidResultEmpty)) {
    const liquidationAllCases = response.results;
    const uuidPrize = !isUuidResultEmpty && getUuid.data;

    const freeCaseData = liquidationAllCases.find((liquidationCase: TBox) => liquidationCase.id === FREE_CASE_ID);
    const nonAuthPrize = uuidPrize && freeCaseData.prizes.find((elem: TPrize) => elem.prize.id === uuidPrize.prize.id);

    return {
      cases: liquidationAllCases.filter((el: TBox) => (el.price === 0 ? el.free_spin > 0 : el)),
      isOpenedByUuid,
      freeCasePrizeNonAuth: username ? null : { ...uuidPrize, chance_rounded: nonAuthPrize ? nonAuthPrize.chance : 0 },
    };
  }
  return { cases: response.results, isOpenedByUuid, freeCasePrizeNonAuth: null };
});

export const getBox = createAsyncThunk<TBox, string, ThunkApiConfig>(
  'liquidation/box',
  async (id, { extra: services, rejectWithValue, getState }) => {
    const { username } = getState().user;
    let uuid = localStorage.getItem(LocalStorageKeys.NANO_ID);
    if (!uuid) {
      uuid = nanoid();
      localStorage.setItem(LocalStorageKeys.NANO_ID, uuid);
    }
    const response: any = await services.httpService.LiquidationService.getBox(id);
    const uuidResponse: any = await services.httpService.LiquidationService.getUuid(uuid);
    const isUuidResultEmpty = uuidResponse.isError ? true : Object.keys(uuidResponse.data).length === 0;
    const isAnonymousFreeCaseOpened =
      !username && (id === '1' || id === import.meta.env.VITE_FREE_CASE_ID) && !isUuidResultEmpty;
    const isUserHasFreeCase = response.data.free_spin === 0 && response.data.price === 0 && username;

    if (isAnonymousFreeCaseOpened || isUserHasFreeCase) {
      const message = 'You are not allowed to open free case';
      return rejectWithValue({ message });
    }

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);
      return rejectWithValue({ message });
    }
    return response.data;
  },
);

export const userPrizes = createAsyncThunk<LiquidationPrizesResponseType, number, ThunkApiConfig>(
  'liquidation/prizes',
  async (page, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.LiquidationService.getPrizes(page);
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }
    return response.data;
  },
);

export const getPrizeFreeCase = createAsyncThunk<TBasePrize, string, ThunkApiConfig>(
  'liquidation/prize/free',
  async (id, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.LiquidationService.getPrizeFreeCase(id);
    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }
    return response;
  },
);

export const openCase = createAsyncThunk<
  TPrize[],
  { id: string; quantity: number; order: string | null },
  ThunkApiConfig
>('liquidation/openCase', async (props, { extra: services, rejectWithValue, dispatch, getState }) => {
  const response: any = await services.httpService.LiquidationService.openCase(props);

  if (response.isError) {
    const message = getSimpleErrorMessage(response.response.data);
    return rejectWithValue({ message });
  }

  const box = getState().liquidation?.box;
  if (box) {
    const { price, free_spin } = box;
    const boxPrice = price * props.quantity;
    const freeSpinPrice = price * free_spin;
    const discountPrice = boxPrice - freeSpinPrice;
    dispatch(subtractGems(discountPrice > 0 ? discountPrice : 0));
  }
  if (props.id !== import.meta.env.VITE_FREE_CASE_ID) dispatch(getBox(props.id));
  if (props.id === import.meta.env.VITE_FREE_CASE_ID) dispatch(getCases(props.order));

  return response.data;
});

export const freeCase = createAsyncThunk<TBasePrize, void, ThunkApiConfig>(
  'liquidation/freeCase',
  async (_, { extra: services, rejectWithValue }) => {
    let uuid = localStorage.getItem(LocalStorageKeys.NANO_ID);
    if (!uuid) {
      uuid = nanoid();
      localStorage.setItem(LocalStorageKeys.NANO_ID, uuid);
    }
    const response: any = await services.httpService.LiquidationService.freeCase(uuid);

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }
    return response.data;
  },
);

export const casesRibbon = createAsyncThunk<TCasesRibbon, void, ThunkApiConfig>(
  'liquidation/casesRibbon',
  async (_, { extra: services, rejectWithValue }) => {
    const response: any = await services.httpService.LiquidationService.casesRibbon();

    if (response.isError) {
      const message = getSimpleErrorMessage(response.data);

      return rejectWithValue({ message, isError: true });
    }
    return response;
  },
);
