import { ApiEndpoints, instance } from 'services/client';

class SupportService {
  static async getFAQ(params: {
    topic: number | null;
    search: string | null;
    group: 'liquidation_faq' | 'support_faq';
  }) {
    const response = await instance.get(`${ApiEndpoints.v1.support.getFAQ}`, {
      params,
    });

    return response;
  }

  static async getFAQTopics(group: 'liquidation_faq' | 'support_faq') {
    const response = await instance.get(`${ApiEndpoints.v1.support.getFAQTopics}`, {
      params: {
        group,
      },
    });

    return response;
  }
}

export default SupportService;
