import { ApiEndpoints, instance } from 'services/client';

class CryptoService {
  static async getPhraseRequest(publicKey: string): Promise<string> {
    const body = {
      phrase: `Sign to auth ${publicKey}`,
    };
    const url = ApiEndpoints.v1.users.encrypt_phrase;
    const response = await instance.post(url, body);

    return response.data.phrase;
  }
}

export default CryptoService;
