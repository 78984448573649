import React from 'react';
import { styled } from 'styled-components';

type BlurProps = {
  color: '#FFD966' | '#71C9BB' | '#2D52DA';
};

const BgSvg = styled.svg`
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // TODO: the roulette on the landing page is broken on the iOS platform, fix it if necessary
  //transform: translateZ(0);
  //filter: blur(1rem);
`;

const Blur: React.FC<BlurProps> = ({ color = '#FFD966' }) => (
  <BgSvg width="332" height="309" viewBox="0 0 332 309" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2" filter="url(#filter0_f_1802_4652)">
      <circle cx="142" cy="154" r="90" fill={color} />
    </g>
    <defs>
      <filter
        id="filter0_f_1802_4652"
        x="-48"
        y="-36"
        width="380"
        height="380"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_1802_4652" />
      </filter>
    </defs>
  </BgSvg>
);

export default Blur;
