import { createSlice } from '@reduxjs/toolkit';
import { TPowerGrid, IPowerGridNft, TEnergyGraph, ConsumptionSetupsType } from 'src/types/powerGrid';
import { getConsumptionSetups, getEnergyGraph, getMostEnergyNft, getPowerGrid } from './actionAsync';

type TInitialState = {
  powerGrid: TPowerGrid;
  isLoading: boolean;
  mostEnergyNft: IPowerGridNft;
  graph: TEnergyGraph;
  consumptionSetups: ConsumptionSetupsType[];
};

const initialState: TInitialState = {
  powerGrid: {
    created_at: '',
    value: 0,
    id: 0,
    percent: 0,
    image_link: '',
    energy_consumption: 0,
  },
  isLoading: false,
  mostEnergyNft: {
    energy: 0,
    spent_energy: 0,
    id: 0,
    boost: false,
    image_link: '',
    status: 0,
    contract: {
      address: '',
      id: 0,
      image: null,
      name: '',
      price: 0,
    },
    durability: 0,
    max_durability: 0,
    energy_consumption: 0,
    accessible_crypto_for_mining: [],
    energy_diagram_percent: 0,
    mining_now_data: [],
    power: 0,
    is_asic: false,
    created_at: '',
    used: false,
    name: '',
    free_boost_ts: 0,
    components_count: 0,
    collection_name: '',
  },
  graph: {
    count: 0,
    current_page: 0,
    total_page: 0,
    limit: 0,
    has_next: false,
    has_previous: false,
    current_year: 0,
    results: [],
    avg_energy: 0,
  },
  consumptionSetups: [],
};

const powerGridSlice = createSlice({
  name: 'power-grid',
  initialState,
  reducers: {
    clearImage: (state) => {
      state.powerGrid.image_link = initialState.powerGrid.image_link;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPowerGrid.fulfilled, (state, action) => {
        state.powerGrid = action.payload;
      })
      .addCase(getMostEnergyNft.fulfilled, (state, action) => {
        state.mostEnergyNft = action.payload;
      })
      .addCase(getEnergyGraph.fulfilled, (state, action) => {
        state.graph = action.payload;
      })
      .addCase(getConsumptionSetups.fulfilled, (state, action) => {
        state.consumptionSetups = action.payload.filter((el) => el.components_count > 0);
      })
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('power-grid') && type.endsWith('/pending');
        },
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('power-grid') && type.endsWith('/rejected');
        },
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('power-grid') && type.endsWith('/fulfilled');
        },
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const { clearImage } = powerGridSlice.actions;

export default powerGridSlice.reducer;
