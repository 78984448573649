import { styled } from 'styled-components';
import colors from 'theme/colors';
import { Button } from 'ui/index';
import media from 'theme/media';

export const PreviewText = styled.h1`
  text-transform: uppercase;
  font-family: Unbounded, sans-serif;
  font-size: 74px;
  font-weight: 800;
  line-height: 120%;
  max-width: 1132px;
  text-align: center;
  color: ${colors.yellow};

  @media (max-width: 768px) {
    font-size: 48px;
  }

  @media (max-width: 480px) {
    font-size: 36px;
  }
`;

type PreviewWrapperProps = {
  $background: string;
};

export const PreviewWrapper = styled.section<PreviewWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${({ $background }) => `url(${$background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${colors.black};
  min-height: 100vh;
  gap: 40px;

  width: 100%;
  padding: 12.125rem 6.25rem;
  max-width: 100rem;
  margin: 0 auto;

  @media (max-width: ${media.mobile}px) {
    padding: 180px 16px;
  }
`;

export const PreviewButton = styled(Button.Link)`
  width: min-content;
  @media (max-width: ${media.mobile}px) {
    width: 100%;
  }
`;
