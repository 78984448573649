import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type BaseLayoutBg = 'primary' | 'secondary' | 'image' | string;

type InitState = {
  isOpenNavBar: boolean;
  bg: BaseLayoutBg;
  isHaveButtonBack: boolean;
  isScrollDisabled: boolean;
  isImageBg: boolean;
  isAnotherBg: boolean;
};

const initState: InitState = {
  isOpenNavBar: false,
  bg: 'primary',
  isHaveButtonBack: false,
  isScrollDisabled: false,
  isImageBg: false,
  isAnotherBg: false,
};

const baseLayoutSlice = createSlice({
  name: 'baseLayout',
  initialState: initState,
  reducers: {
    toggleNavBar: (state) => {
      state.isOpenNavBar = !state.isOpenNavBar;
    },
    setBg: (state, action: PayloadAction<BaseLayoutBg>) => {
      state.bg = action.payload;
      state.isAnotherBg = false;
    },
    setIsHaveButtonBack: (state, action: PayloadAction<boolean>) => {
      state.isHaveButtonBack = action.payload;
    },
    setIsScrollDisabled: (state, action: PayloadAction<boolean>) => {
      state.isScrollDisabled = action.payload;
    },
    setIsImageBg: (state, action: PayloadAction<boolean>) => {
      state.isImageBg = action.payload;
      state.isAnotherBg = true;
    },
  },
});

export const { toggleNavBar, setIsScrollDisabled, setIsHaveButtonBack, setBg, setIsImageBg } = baseLayoutSlice.actions;

export default baseLayoutSlice.reducer;
