import styled from 'styled-components';
import { Icon } from 'ui';

type AccordionContainerProps = {
  $padding?: string;
  $margin?: string;
  $borderRadius?: string;
  $background?: string;
  $active: boolean;
};

export const AccordionContainer = styled.div<AccordionContainerProps>`
  padding: ${({ $padding }) => $padding || '2rem'};
  margin: ${({ $margin }) => $margin || '0 0 1.5rem 0'};
  border: 2px solid ${({ theme }) => theme.accordion.borderColor};
  border-radius: ${({ $borderRadius }) => $borderRadius || '0.75rem'};
  background: ${({ $background }) => $background || 'rgba(255, 255, 255, 0.01)'};
  backdrop-filter: blur(25px);
  max-height: 100%;
  cursor: pointer;
`;

export const AccordionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

type AccordionTitleProps = {
  $color?: string;
  $fontSize?: string;
  $fontStyle?: string;
  $fontWeight?: string;
  $lineHeight?: string;
};
export const AccordionTitle = styled.div<AccordionTitleProps>`
  color: ${({ $color, theme }) => $color || theme.fontsColor.primary};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ $fontSize }) => $fontSize || '1.25rem'};
  font-style: ${({ $fontStyle }) => $fontStyle || 'normal'};
  font-weight: ${({ $fontWeight }) => $fontWeight || '600'};
  line-height: ${({ $lineHeight }) => $lineHeight || '150%'};
`;

type AccordionArrowProps = {
  $size?: string;
  $isOpen: boolean;
};

export const AccordionArrow = styled(Icon)<AccordionArrowProps>`
  width: ${({ $size }) => $size || '1.25rem'};
  height: ${({ $size }) => $size || '1.25rem'};
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
  transition: transform 0.3s ease-in-out;
  color: ${({ theme }) => theme.accordion.arrowColor};
  flex-shrink: 0;
`;

type AccordionContentProps = {
  $isOpen: boolean;
};
export const AccordionContent = styled.div<AccordionContentProps>`
  max-height: ${({ $isOpen }) => ($isOpen ? '1000px' : 0)};
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0.42, 0, 0.58, 1);
`;
