import { createSlice } from '@reduxjs/toolkit';
import { getCalculatorDurability, getCalculatorStats } from 'store/slices/calculator/actionAsync';
import {
  CalculatorFormattedConstants,
  CalculatorInfoBlock,
  CalculatorTypeNFT,
  DurabilityType,
  ProductCalculator,
} from 'types/calculator';
import { getConstants } from 'store/slices/calculator/utils';

interface CalculatorState {
  durability: number[];
  costArray: DurabilityType[];
  constants: CalculatorFormattedConstants;
  infoBlock: CalculatorInfoBlock[];
  product: ProductCalculator | null;
  isLoading: boolean;
}

const initialState: CalculatorState = {
  durability: [],
  costArray: [],
  constants: {
    type: CalculatorTypeNFT.LONG,
    dailyReward: 0,
    totalElectricityCost: 0,
    totalMiningReward: 0,
  },
  infoBlock: [],
  product: null,
  isLoading: false,
};

const gemsSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    clearStateCalculator: (state) => {
      const { constants, infoBlock, product } = initialState;

      state.product = product;
      state.infoBlock = infoBlock;
      state.constants = constants;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCalculatorDurability.fulfilled, (state, action) => {
        state.durability = action.payload.results.map((result) => result.durability).sort((a, b) => a - b);
        state.costArray = action.payload.results;
      })
      .addCase(getCalculatorStats.fulfilled, (state, action) => {
        const { product, constants, info_blocks } = action.payload;

        state.product = product;
        state.infoBlock = info_blocks;
        state.constants = getConstants(constants);
        state.isLoading = false;
      })
      .addCase(getCalculatorStats.pending, (state) => {
        state.isLoading = true;
        state.product = null;
      })
      .addMatcher(
        (action) => {
          const { type } = action;
          return type.includes('logout') || type.includes('login');
        },
        (state) => {
          state.durability = [];
        },
      );
  },
});

export default gemsSlice.reducer;

export const { clearStateCalculator } = gemsSlice.actions;
