import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dark } from 'theme';

type InitState = {
  theme: typeof dark;
  themeName: 'light' | 'dark';
};

const initState: InitState = {
  theme: dark,
  themeName: 'dark',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState: initState,
  reducers: {
    toggleTheme: (state, action: PayloadAction<string>) => {},
  },
});

export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;
