import { styled } from 'styled-components';
import colors from 'theme/colors';
import { media, SkeletonStyle } from 'theme';
import { Button, Flex } from 'ui';

export const FixedWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

type DescProps = {
  color?: string;
};
export const Desc = styled.p<DescProps>`
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${({ color }) => color || colors.white};
  transition: color 0.2s;
`;

export const CustomButton = styled(Button.Link)`
  width: min-content;
  @media (max-width: ${media.mobile}px) {
    width: 100%;
  }
`;

export const CalculatorWrapper = styled(Flex).attrs({ as: 'section' })`
  padding: 6.25rem 12.125rem;

  @media (max-width: ${media.laptop}px) {
    padding: 6.25rem 3.75rem;
  }

  @media (max-width: ${media.tablet}px) {
    padding: 6.25rem 2.5rem;
  }

  @media (max-width: ${media.mobile}px) {
    padding: 6.25rem 1rem;
  }
`;
