import React from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery';
import { H3 } from 'ui/Text';
import { AppRoutes } from 'router';
import { VIDEO_URL } from 'assets/Urls';
import { RP, StartSectionInner, VideoBg, VideoStart, DemoTitle, DemoBlockButton } from './style';

interface IDemoProps {
  refValue: React.RefObject<HTMLDivElement>;
}

const StartDemo: React.FC<IDemoProps> = ({ refValue }) => {
  const { t } = useTranslation('landing');
  const { isMobile, isTablet } = useMediaQuery();
  return (
    <VideoStart ref={refValue}>
      <VideoBg autoPlay muted playsInline={isMobile || isTablet} loop src={VIDEO_URL.Landing.Start_Demo} />
      <StartSectionInner>
        <DemoTitle fontFamily="secondary" size="lg" color="secondary">
          {t('demo_block.category')}
        </DemoTitle>
        <H3 style={{ maxWidth: 784 }}>{t('demo_block.title')}</H3>
        <RP fontFamily="primary" size="md" max={466}>
          {t('demo_block.text_1')}
        </RP>
        <DemoBlockButton to={AppRoutes.LIQUIDATION} size="large">
          {t('demo_block.button_text')}
        </DemoBlockButton>
      </StartSectionInner>
    </VideoStart>
  );
};

export default StartDemo;
