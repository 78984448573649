import { ReadClaimNft } from 'types/myNfts';

export enum MyNftsChaptersList {
  unclaimed = 'Unclaimed',
  claimed = 'Claimed',
}

export type MyNftsStoreType = {
  myNfts: ReadClaimNft[];
  selectedNfts: number[];
  activeChapter: MyNftsChaptersList;
  isNftsLoaded: boolean;
  claimPrice: number;
  isClaimDisabled: boolean;
};
