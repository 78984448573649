import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { LocalStorageKeys } from 'appConstants/LocalStorageKeys';
import Cookies from 'js-cookie';
import { HttpServiceVault } from 'services/Vault/client/types';
import VaultService from 'services/Vault/VaultService';

const API_URL = import.meta.env.VITE_VAULT_URL;

export const instanceVault = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
});

const onFulfilledRequest = (config: any) => {
  const token = Cookies.get(LocalStorageKeys.VAULT_TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }
  return config;
};
const onRejectedRequest = (error: unknown): Promise<AxiosRequestConfig> => Promise.reject(error);
instanceVault.interceptors.request.use(onFulfilledRequest, onRejectedRequest);

const onFulfilledResponse = (response: AxiosResponse<any, any>) => ({ isError: false, ...response });
const onRejectedResponse = (error: AxiosError<any>) => ({ isError: true, ...error });
instanceVault.interceptors.response.use(onFulfilledResponse, onRejectedResponse);

export const httpServiceVault: HttpServiceVault = {
  VaultService,
};
