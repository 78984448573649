import { NFTPreviewType } from 'pages/MarketPlace/types';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { PaymentServiceEnum } from 'types/wallets';
import { URLPiece } from 'types/builder';

export type Modify<T, R> = Omit<T, keyof R> & R;

export type AssetKeys = 'image' | 'id' | 'price' | 'name' | 'collection' | 'energy';

export type BagItem = Pick<NFTPreviewType, AssetKeys>;

export type EnergyItem = {
  amount: number;
};

export interface BuilderItem extends IGPUBuilder {
  item_components: IGPUBuilder[];
}

export type BuilderType = MarketObjectBuilder;

export type PriceType = {
  NFTs: number;
  energy: number;
  builder: number;
  shortContact: number;
  total: number;
};

export type LastSignatureType = {
  expirationTS: number;
  signature: string;
  amountGems: number;
};

interface NFTProduct {
  id: number;
  image: string;
  price: number;
  title: string;
  collection_name: string;
  contract_purchase_id: number;
}

export interface UnSyncNFTType {
  id: number;
  product: NFTProduct;
}

export interface UnSyncNFTMarketType extends UnSyncNFTType {
  count: number;
}
export type UnSyncNFTSCType = UnSyncNFTType;

export type MarketObjectBuilder = Modify<
  MarketObjectBase,
  {
    product: null;
    builder_item: BuilderItem;
    product_code: null;
  }
>;

export type InitStateBag = {
  nft: BodyAsset[];
  unSyncNFTMarket: UnSyncNFTMarketType[];
  unSyncNFTSC: UnSyncNFTSCType[];
  energy: EnergyObject | null;
  builder: MarketObjectBuilder[];
  shortContract: MarketObject[];
  price: PriceType;
  step: number;
  transactionHash: string;
  isNeedUpdateMarket: boolean;
  isNeedUpdateSC: boolean;
  isCartInit: boolean;
  amountUsedGems: number;
  lastSignature: LastSignatureType | null;
  isApprovedEarlier: boolean;
  isGettingBalance: boolean;
  isCartOpen: boolean;
  isItemLoading: boolean;
  selectedPayment: PaymentServiceEnum;
  builderItemToRemove: number | null;
};

export type BagReducers = {
  changeNFTsMarketPlace: CaseReducer<InitStateBag, PayloadAction<UnSyncNFTMarketType>>;
  clearUnSyncNFTAll: CaseReducer<InitStateBag, PayloadAction<void>>;
  addUnSyncSCMarketPlace: CaseReducer<InitStateBag, PayloadAction<UnSyncNFTSCType>>;
  removeUnSyncSCMarketPlace: CaseReducer<InitStateBag, PayloadAction<number>>;
  clearAllUnSyncSCMarketPlace: CaseReducer<InitStateBag, PayloadAction<void>>;
  setTransactionHash: CaseReducer<InitStateBag, PayloadAction<string>>;
  nextStep: CaseReducer<InitStateBag, PayloadAction<void>>;
  prevStep: CaseReducer<InitStateBag, PayloadAction<void>>;
  clearNFTs: CaseReducer<InitStateBag, PayloadAction<void>>;
  clearBag: CaseReducer<InitStateBag, PayloadAction<void>>;
  setAmountUsedGems: CaseReducer<InitStateBag, PayloadAction<number>>;
  clearLastSignature: CaseReducer<InitStateBag, PayloadAction<void>>;
  setIsApprovedEarlier: CaseReducer<InitStateBag, PayloadAction<boolean>>;
  setIsGettingBalance: CaseReducer<InitStateBag, PayloadAction<boolean>>;
  setIsCartOpen: CaseReducer<InitStateBag, PayloadAction<boolean>>;
  disableMarketButton: CaseReducer<InitStateBag, PayloadAction<boolean>>;
  selectPaymentService: CaseReducer<InitStateBag, PayloadAction<PaymentServiceEnum>>;
};

export enum CategoryMarketObjectEnum {
  shortContracts = 'short-contracts',
}

export type MarketObjectBase = {
  id: number;
  builder_item: IGPUBuilder | null;
  cart: number;
  count: number;
  created_at: string;
  is_purchased: boolean;
  product: NFTProduct | null;
  product_code: ProductCodes | null;
  /**
   * Do not use it. Is used to back capability with SC
   * @ignore
   */
  category: null | CategoryMarketObjectEnum;
};

export type MarketObject = Modify<
  MarketObjectBase,
  {
    product: NFTProduct;
  }
>;

export type BodyAsset = MarketObject;

export enum ProductCodes {
  'ENERGY' = 'energy',
}

export type MarketObjectEnergy = Modify<
  MarketObjectBase,
  {
    product_code: ProductCodes.ENERGY;
  }
>;

// to back capability
export type EnergyObject = MarketObjectEnergy;

export type ResponseInitState = {
  market: MarketObject[];
  shortContracts: MarketObject[];
  energy: MarketObjectEnergy;
  builder: MarketObjectBuilder[];
};

export type CreateMarketObjectBody = {
  id: number;
  count: number;
};

export type CreateMarketBuilderObjectBody = {
  /**
   * root item id
   */
  builder_item: number;
  count: number;
};

export type ResponseCreateMarketItem = MarketObject;

export type ChangeAmountMarketObjectBody = {
  id: number;
  count: number;
};

export type UpdateMarketObjectBody = {
  id: number;
};

export type CartCommission = {
  total_price: string;
  commission: number;
  gems_used: number;
};

export type ResponseChangeValueMarketItem = MarketObject;

export type DeleteMarketObjectBody = number;

export type CreateEnergyObjectBody = number;
export type ResponseCreateEnergyObject = EnergyObject;

export type ChangeValueEnergyObjectBody = {
  value: number;
  id: number;
  is_purchased: boolean;
};
export type ResponseChangeValueEnergyObject = EnergyObject;
export type ResponseDeleteEnergyObject = {
  id: number;
};

export type DeleteEnergyObjectBody = {
  id: number;
};

export type DeleteBuilderObjectBody = number;
export type UpdateBuilderObjectBody = IGPUBuilder;
export type SyncCartMarketObjectBody = {
  unSyncCart: UnSyncNFTMarketType[];
  syncCart: MarketObject[];
};

export type SyncCartSCObjectBody = {
  syncSC: MarketObject[];
  unSyncSC: UnSyncNFTSCType[];
};
export type SyncCartObjectResponse = MarketObject[];

export interface IGPUBuilder {
  id: number;
  image_link: string;
  product_name: string;
  contract_purchase_id: number;
  collection_name: string;
  can_be_purchased: boolean;
  max_components: number;
  url_piece: URLPiece;
  created_at: string;
  used: boolean;
  name: string;
  is_purchased?: boolean;
  item: number;
  contract_address: string;
  is_cart?: boolean;
  synced: boolean;
  price: number;
  components: number;
  power_rate?: number;
  energy_consumption?: number;
}

export type INFTBuilder<T extends 'gpu' | 'rig' | 'shelf'> = {
  [key in T extends 'gpu'
    ? `${T}${'1' | '2' | '3' | '4' | '5' | '6'}_details`
    : `${T}${'1' | '2' | '3' | '4'}_details`]: IGPUBuilder | null;
};

export interface IRigBuilder extends IGPUBuilder, INFTBuilder<'gpu'> {}

export interface IRackBuilder extends IGPUBuilder, INFTBuilder<'rig'> {}

export interface IContainerBuilder extends IGPUBuilder, INFTBuilder<'shelf'> {}

export interface IGemsSignature {
  id: number;
  signature: string;
  expiration_date: string;
  gems: number;
  expiration_ts: number;
}
