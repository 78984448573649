import { ApiEndpoints, instance } from 'services/client';
import { InventoryItemstype, MarketplaceMock } from 'src/types/builder';

type MarketEqp = {
  count: number;
  results: MarketplaceMock[];
};

type Update = {
  [k: string]: number;
};

class BuilderService {
  static async getAllItemsN() {
    const response: InventoryItemstype = await instance.get(`${ApiEndpoints.v2.builder.mainUrl}`, {});

    return response;
  }

  static async getAllItems(type: string) {
    const response: InventoryItemstype = await instance.get(`${ApiEndpoints.v2.builder.mainUrl}?url_piece=${type}`, {});

    return response;
  }

  static async createItem(id: number[]) {
    const arr = id.map((el) => ({ product: el, is_cart: false }));
    const response = await instance.post(ApiEndpoints.v2.builder.mainUrl, arr);

    return response.data;
  }

  static async createItemBuilderAfterPurchase(id: number[]) {
    const arr = id.map((el) => ({ product: el, is_purchased: true }));
    const response = await instance.post(ApiEndpoints.v2.builder.mainUrl, arr);

    return response.data;
  }

  static async getItem(id: number, type: string) {
    const response = await instance.get(`${ApiEndpoints.v2.builder.mainUrl}${id}/get-${type}/`);

    return response;
  }

  static async updateItem(id: number, type: string, item: Update, isCart: boolean) {
    let parsedType = type;
    if (type === 'rack' || type?.toLowerCase().includes('rack')) parsedType = 'shelf';
    const response = await instance.patch(`${ApiEndpoints.v2.builder.mainUrl}${id}/update-${parsedType}/`, {
      ...item,
      is_cart: isCart,
    });

    return response;
  }

  static async getItemEquipment(type: string) {
    let parsedType = type;
    if (type === 'rack' || type?.toLowerCase().includes('rack')) parsedType = 'shelf';
    const response: { data: MarketEqp } = await instance.get(`${ApiEndpoints.v2.builder.productEqp}${type}-equipment/`);

    return response.data.results;
  }
}

export default BuilderService;
