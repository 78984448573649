import React, { useEffect } from 'react';
import { useAppDispatch, useAchievementSelector } from 'store';
import { clearAchievementState } from 'store/slices/achievements/achievementsSlice';

import { Achievements } from 'pages/Profile/Achievements';
import { SingleAchievement } from 'pages/Profile/SingleAchievement';
import { getAchievements, getAchievementsStatistic } from 'store/slices/achievements/actionsAsync';

type AchievementPageControllerPropsType = {
  isMainAchievementPage?: boolean;
};

const AchievementPageController: React.FC<AchievementPageControllerPropsType> = ({ isMainAchievementPage }) => {
  const dispatch = useAppDispatch();
  const { isAchievementsLoaded } = useAchievementSelector();

  useEffect(() => {
    if (!isAchievementsLoaded) {
      dispatch(getAchievements());
      dispatch(getAchievementsStatistic());
    }
    return () => {
      dispatch(clearAchievementState());
    };
  }, []);

  return isMainAchievementPage ? <Achievements /> : <SingleAchievement />;
};

export default AchievementPageController;
