import { TBox, TCasesRibbonResults, TPrize, TReward } from 'types/liquidation';

export enum LiquidationSortOptions {
  lowerPrice = 'First cheaper',
  higherPrice = 'More expensive first',
}

export type LiquidationSortOptionType = {
  id: number;
  name: string;
  option: LiquidationSortOptions;
};

export interface ILiquidationSliceStore {
  cases: TBox[];
  box: TBox | null;
  prizes: TReward[];
  currentCases: TPrize[];
  isHasButtonBack: boolean;
  isLoading: boolean;
  isUuidResultLoaded: boolean;
  indexOpeningCaseInRow: number;
  isSkippedOpening: boolean;
  isAnimationEnd: boolean;
  ribbons: TCasesRibbonResults[];
  online: number;
  isAnimationFreeCaseShow: boolean;
  liquidationSortOption: LiquidationSortOptions | null;
  freeCasePrizeNonAuth: TReward | null;
  isMorePrizesLoading: boolean;
  prizesLimit: number;
  prizesCurrentPage: number;
  prizesTotalPage: number;
}
