import { styled } from 'styled-components';
import colors from 'theme/colors';
import media from 'theme/media';

export const ProgressRing = styled.svg<{ $active?: boolean }>`
  stroke-dasharray: 326.726, 326.726;
  stroke-dashoffset: 212.372;
  stroke: ${({ $active }) => ($active ? colors.yellow : colors.grey)};
  transition: 0.3s;
  stroke-width: 1;
  fill: transparent;
`;

export const ProgressRingCircle = styled.circle`
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export const ProgressWrapper = styled.div<{ $active?: boolean }>`
  position: absolute;
  display: ${({ $active }) => ($active ? 'block' : 'none')};
  top: 50px;

  @media (max-width: ${media.mobile}px) {
    display: block;
    position: relative;
    top: unset;
  }
`;
