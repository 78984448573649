/* eslint-disable react-hooks/rules-of-hooks */
import { createGlobalStyle, css } from 'styled-components';

interface GlobalStylesProps {
  $color: 'primary' | 'secondary' | 'image' | string;
  $isScrollDisabled: boolean;
  $isImageBg: boolean;
}

const ScrollDisabledStyles = css`
  overflow: hidden;
`;

const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.app.scrollbarThumb} transparent;
    scroll-behavior: smooth;
    // TODO: uncomment or delete after test on windows
    // &::-webkit-scrollbar {
    //   width: 4px;
    //   height: 4px;
    // }
    // &::-webkit-scrollbar-track {
    //   background: transparent;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background-color: ${({ theme }) => theme.app.scrollbarThumb};
    //   border-radius: 2px;
    // }
    // &::-webkit-scrollbar-thumb:hover {
    //   opacity: 0.8;
    // }
  }

  body{
    font-family: ${({ theme }) => theme.fonts.primary};
    min-height: 100vh;
    width: 100%;
    position: static;
    scroll-behavior: smooth;
  
    overflow-y: ${({ $isImageBg }) => ($isImageBg ? 'hidden' : 'scroll')};

    ${({ $isScrollDisabled }) => ($isScrollDisabled ? ScrollDisabledStyles : '')}
    
    background: ${({ theme, $color }) => {
      switch ($color) {
        case 'secondary':
          return theme.app.backgroundSecondary;
        case 'image':
          return `url(${theme.images.bg})`;
        case 'primary':
        default:
          return theme.app.backgroundPrimary;
      }
    }};
  }
  
`;

export default GlobalStyles;
