import React, { forwardRef, ReactNode, useState } from 'react';

import { useClickAway } from 'react-use';
import {
  AccordionArrow,
  AccordionContainer,
  AccordionContent,
  AccordionTitle,
  AccordionWrapper,
} from './Accordion.styled';

type AccordionProps = {
  children?: ReactNode;
  title: string;
  isInitOpen?: boolean;
  handleActive: (isOpen: boolean) => boolean;
};
const Accordion = forwardRef<HTMLDivElement | null, AccordionProps>(
  ({ title, children, isInitOpen = false, handleActive = () => false }, ref) => {
    const [isOpen, setOpen] = useState(isInitOpen);
    const closeHandle = () => {
      setOpen(false);
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useClickAway(ref, closeHandle);
    const toggleHandle = () => {
      setOpen(!isOpen);
    };

    return (
      <AccordionContainer ref={ref} $active={handleActive(isOpen)} onClick={toggleHandle}>
        <AccordionWrapper>
          <AccordionTitle>{title}</AccordionTitle>
          <AccordionArrow name="arrowRight" $isOpen={isOpen} />
        </AccordionWrapper>
        <AccordionContent $isOpen={isOpen}>{children}</AccordionContent>
      </AccordionContainer>
    );
  },
);

export default Accordion;
